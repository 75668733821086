import React from "react";
import autoBind from 'react-autobind';
import { Form, Divider, Row, Col, Input, Select, DatePicker, Checkbox, Button, Tooltip, message } from 'antd';
import moment from 'moment';
import { CloseCircleOutlined } from '@ant-design/icons';
//
import CustomComponent from '@/components/CustomComponent';
import Utils from "@/components/Utils";
import Globals from "@/config/Globals";
import CommonEmployerSelectionModal from "@/views/commonComponents/CommonEmployerSelectionModal";
//
export default class CommonMemberWorkHistoryForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      countries: [],
      provinces: [],
      selectedEmployer: null,
    };
  }

  componentDidMount() {
    const { provinces } = this.props.app.sharedCache().getConfig();
    this.setState({ provinces: provinces[Globals.DefaultWorkHistoryCountry] });
  }

  resetFields() {
    this.form.resetFields();
  }

  setFieldsValue(data) {
    if (data.employerID) {
      this.employerSelectionModal.setEmployer(data.employerID);
    } else {
      this.handleDeselectEmployer();
    }

    this.form.setFieldsValue({
      ...data,
      clearanceDate: data.clearanceDate ? moment(data.clearanceDate) : null,
      startDate: data.startDate ? moment(data.startDate) : null,
      endDate: data.endDate ? moment(data.endDate) : null,
      registerDate: data.registerDate ? moment(data.registerDate) : null,
    });
  }

  handleSearchEmployer() {
    this.employerSelectionModal.show();
  }

  handleSelectEmployer(employer) {
    this.setState({ selectedEmployer: employer });
  }

  handleDeselectEmployer() {
    this.setState({ selectedEmployer: null });
  }

  async validateFields() {
    const formData = await this.form.validateFields();

    if (formData) {
      const payload = {
        ...formData,
        employerID: this.state.selectedEmployer?.id,
        country: Globals.DefaultWorkHistoryCountry,
        registerNumber: formData.registerNumber ? String(formData.registerNumber) : null,
        altered: !!formData.altered,
        archieved: !!formData.archieved,
        phone: Utils.sanitizePhone(formData.phone),
        fax: Utils.sanitizePhone(formData.fax),
        clearanceDate: (formData.clearanceDate ? moment(formData.clearanceDate).format(Globals.DefaultSimpleDateFormat) : null),
        startDate: (formData.startDate ? moment(formData.startDate).format(Globals.DefaultSimpleDateFormat) : null),
        endDate: (formData.endDate ? moment(formData.endDate).format(Globals.DefaultSimpleDateFormat) : null),
        registerDate: (formData.registerDate ? moment(formData.registerDate).format(Globals.DefaultSimpleDateFormat) : null),
        province: formData?.province || null,
      };

      Object.keys(payload).forEach((key) => {
        if (payload[key] == null) delete payload[key];
      });

      return payload;
    }

    return false;
  }

  //UI
  render() {
    return (
      <>
        <CommonEmployerSelectionModal
          app={this.props.app}
          onSelect={this.handleSelectEmployer}
          {...Utils.propagateRef(this, 'employerSelectionModal')}
        />

        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row>
            <Col span={4}>
              <Form.Item label="Clearance Date" name="clearanceDate" rules={[{ required: false, message: 'This field is required!' }]}>
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Form.Item label="Start Date" name="startDate" rules={[{ required: false, message: 'This field is required!' }]}>
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Form.Item label="End Date" name="endDate" rules={[{ required: false, message: 'This field is required!' }]}>
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Form.Item label="Register Date" name="registerDate" rules={[{ required: false, message: 'This field is required!' }]}>
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Form.Item label="Register No." name="registerNumber" rules={[{ required: false, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              <Form.Item label="Activity Code" name="activityID" rules={[{ required: true, message: 'This field is required!' }]} >
                <Select placeholder="Select..." showSearch
                filterOption={(input, option) => 
                  option.children?.toString()?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                  {this.props.activityCodes.map((code) => (
                    <Select.Option key={code.id} value={code.id}>{code.activityCode}: {code.description}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item label="Job Classification" name="classificationID" rules={[{ required: false, message: 'This field is required!' }]} >
                <Select placeholder="Select..." showSearch allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) => 
                    option.children?.toString()?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.props.classifications.map((code) => (
                    <Select.Option key={code.id} value={code.id}>
                      {code.classificationNumber} - {code.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col offset={1}>
              <Form.Item label="Altered" name="altered" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col> */}
            {/* <Col offset={1}>
              <Form.Item label="Archieved" name="archieved" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col> */}
          </Row>

          <Row>
            <Col span={4}>
              <Form.Item label="Employee No." name="employeeNumber" rules={[{ required: false, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Form.Item label="OCS Number" name="ocsNumber" rules={[{ required: false, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={10} offset={1}>
              <Form.Item label="Location" name="workLocation" rules={[{ required: false, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left">Employer</Divider>
          <Row>
            <Col span={11}>
              <Form.Item label="Employer">
                {!this.state.selectedEmployer && <Button type="ghost" onClick={this.handleSearchEmployer}>Click to find an employer</Button>}
                {this.state.selectedEmployer && (
                  <span>
                    {this.state.selectedEmployer.name}
                    <Tooltip title="Deselect">
                      <Button icon={<CloseCircleOutlined />} type="link" onClick={this.handleDeselectEmployer} />
                    </Tooltip>
                  </span>
                )}
              </Form.Item>
            </Col>
            {this.state.selectedEmployer && (
              <Col span={4} offset={1}>
                <Form.Item label="Employer No.">
                  {this.state.selectedEmployer.companyNumber}
                </Form.Item>
              </Col>
            )}
          </Row>
          
          <Divider orientation="left">Contact Information</Divider>
          <Row>
            <Col span={9}>
              <Form.Item label="Email" name="email" rules={[{ required: false, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item label="Phone" name="phone" rules={[{ required: false, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item label="Fax" name="fax" rules={[{ required: false, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left">Address Information</Divider>
          <Row>
            <Col span={6}>
              <Form.Item label="Province" name="province" rules={[{ required: false, message: 'This field is required!' }]}>
                <Select placeholder={!this.state.provinces ? 'N/A' : "Select a Province"} showSearch disabled={!this.state.provinces}
                  filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}>
                  {(this.state.provinces).map((province) => (
                    <Select.Option key={province.provinceCode} value={province.provinceCode}>{province.province}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item label="City" name="city" rules={[{ required: false, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Form.Item label="Postal Code" name="postalCode" rules={[{ required: false, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={11}>
              <Form.Item label="Address" name="street1" rules={[{ required: false, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item label="Address 2" name="street2" rules={[{ required: false, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    )
  }
}
