import React from "react";
//
import '@/stylesheets/MenuBadge.scss';
//
const config = require('@/config/config');

//props are: showZeroed, count, collapsed
export default class MenuBadge extends React.Component {
  render() {
    if (!this.props.showZeroed && this.props.count == 0) return (<></>);
    return (
      <div className={`menuBadge ${!this.props.collapsed ? 'expanded' : ''}`}>
        {this.props.count > 99 ? '99+' : this.props.count}
      </div>
    )
  }
}
