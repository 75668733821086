import React from "react";
import { Layout, PageHeader, Table } from 'antd';
import autoBind from 'react-autobind';
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import AdminMemberOptions, { AdminMemberOptionTypes } from '@/views/commonComponents/AdminMemberOptions';
//
import config from '@/config/config';
import Globals from "@/config/Globals";
//
export default class CommonMemberDispatchNotesView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    // Initialize variables
    this.memberName = null;
    this.memberID = null;
    this.viewOptions = null;

    if (this.props.app.isAdmin()) {
      this.memberID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
      this.memberName = this.props.app.urlManager.getQueryParam('n');
      this.viewOptions = new AdminMemberOptions(AdminMemberOptionTypes.DISPATCH_NOTES, this.memberID, this.props.app, this.memberName);
    }

    if (this.props.app.isMember()) {
      const ibewUser = this.props.app.sharedCache().getUser() || {};
      this.memberID = ibewUser.id;
    }
    
    this.state = { isLoading: false, data: [], sortedInfo: null };
  }
  
  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Dispatch Notes`;
    this._fetch();
  }

  //Actions
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  
  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const props = {
      onChange: this.handleFilterChange, rowKey: 'id', loading: this.props.isLoading,
      scroll: { x: 1500 },
    };

    const columns = [
      { title: 'Date', dataIndex: 'createdOn', key: 'createdOn',
        render: date => date ? moment(date).format(Globals.DefaultUIBirthDateFormatWithTime) : '-',
        sorter: (a, b) => (new Date(a.createdOn).getTime()) - (new Date(b.createdOn).getTime()),
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order
      },
      { title: 'Job No', dataIndex: 'jobNumber', key: 'jobNumber',
        sorter: (a, b) => a.jobNumber.localeCompare(b.jobNumber),
        sortOrder: sortedInfo.columnKey === 'jobNumber' && sortedInfo.order
      },
      { title: 'Employer', dataIndex: 'companyName', key: 'companyName',
        sorter: (a, b) => a.companyName.localeCompare(b.companyName),
        sortOrder: sortedInfo.columnKey === 'companyName' && sortedInfo.order
      },
      { title: 'Member SIN', dataIndex: 'sin', key: 'sin',
        sorter: (a, b) => a.sin.localeCompare(b.sin),
        sortOrder: sortedInfo.columnKey === 'sin' && sortedInfo.order
      },
      { title: 'Member Name', dataIndex: 'fullname', key: 'fullname',
        sorter: (a, b) => a.fullname.localeCompare(b.fullname),
        sortOrder: sortedInfo.columnKey === 'fullname' && sortedInfo.order
      },
      { title: 'Dispatcher', dataIndex: 'dispatcherName', key: 'dispatcherName',
        sorter: (a, b) => a.dispatcherName.localeCompare(b.dispatcherName),
        sortOrder: sortedInfo.columnKey === 'dispatcherName' && sortedInfo.order
      },
      { title: 'Reminder', dataIndex: 'reminderDate', key: 'reminderDate',
        render: date => date ? moment(date).format(Globals.DefaultUIBirthDateFormat) : '-',
        sorter: (a, b) => (new Date(a.reminderDate).getTime()) - (new Date(b.reminderDate).getTime()),
        sortOrder: sortedInfo.columnKey === 'reminderDate' && sortedInfo.order
      },
      { title: 'Email', dataIndex: 'reminderEmail', key: 'reminderEmail',
        sorter: (a, b) => a.reminderEmail.localeCompare(b.reminderEmail),
        sortOrder: sortedInfo.columnKey === 'reminderEmail' && sortedInfo.order
      },
      { title: 'Note', dataIndex: 'content', key: 'content',
        sorter: (a, b) => a.content.localeCompare(b.content),
        sortOrder: sortedInfo.columnKey === 'content' && sortedInfo.order
      },
    ];

    const onBack = this.props.app.isAdmin() ? () => window.history.back() : null;

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader
          title={this.memberName ? `${this.memberName}'s Dispatch Notes` : 'Dispatch Notes'} 
          onBack={onBack}
          extra={this.viewOptions?.getOptions()}
        />
        <Layout.Content>
          <WhiteBox>
            <Table
              columns={columns}
              dataSource={this.state.data}
              {...props}
            />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _fetch(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.members.getDispatchNotes(this.memberID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.notes) {
      const data = resp.body.notes.sort((a, b) => b.id - a.id);
      this.setState({ data, isLoading: false});
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
