import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Button, message, Tooltip, Popconfirm, Tag, Modal, Result, Dropdown, Menu } from 'antd';
import { UploadOutlined, DownloadOutlined, DeleteOutlined, FileTextOutlined, FileExclamationOutlined } from '@ant-design/icons';
import moment from 'moment';
//
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonRemittancesUploadModal from './CommonRemittancesUploadModal';
import AdminEmployerOptions, { AdminEmployerOptionTypes } from '@/views/commonComponents/AdminEmployerOptions';
//
import config from '@/config/config';
import Globals from '@/config/Globals';
import Utils from "@/components/Utils";
//
export default class CommonRemittancesView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    const user = this.props.app.sharedCache().getUser();

    this.employerName = this.props.app.urlManager.getQueryParam('n');
    this.remittanceEnabled = !this.props.app.isAdmin() ? user.remittanceEnabled : true;
    this.employerID = (
      this.props.app.isAdmin()
      ? this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this)
      : user.id
      );

    this.state = {
      isLoading: false,
      data: [],
      remittancesNotEnabledModalVisible: false,
    };
    this.viewOptions = new AdminEmployerOptions(AdminEmployerOptionTypes.REMITTANCES, this.employerID, this.props.app, this.employerName);
    if (!this.props.app.isAdmin()) this.viewOptions.hideAll();
  }
  //Life cycle
  async componentDidMount() {
    document.title = `${config.ApplicationName} - Remittances`;
    await this._fetch();

    const operation = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Operation);
    if (operation === 'new') {
      this.uploadModal.show(this.employerID);
    } else if (operation === 'arrears') {
      this.uploadModal.show(this.employerID, 'arrears');
    }
  }
  //Actions
  handleCloseRemittancesNotEnabledModal() {
    this.setState({ remittancesNotEnabledModalVisible: false });
  }
  handleFilterChange(pagination, filters, sortedInfo) {
    this.setState({ sortedInfo });
  }
    //Upload
  handleUpload(type) {
    return () => {
      if (!this.remittanceEnabled) {
        this.setState({ remittancesNotEnabledModalVisible: true });
        return;
      }
      this.uploadModal.show(this.employerID, type);
    };
  }
  handleSuccessUpload() {
    this.uploadModal.close();
    message.success('Remittance successfully uploaded!');
    this._fetch();
  }
    //Row actions
  handleDelete(fileObj) { this._delete(fileObj.employerID, fileObj.id); }
  handleDownload(fileObj) { this._download(fileObj.employerID, fileObj); }
  handleDownloadLogs(fileObj, contentType) { this._downloadLogs(fileObj, contentType); }
  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const props = {
      onChange: this.handleFilterChange, rowKey: 'id', loading: this.props.isLoading,
      scroll: { x: true },
    };
    
    let columns = [
      { title: 'Type', dataIndex: 'uploadType', key: 'uploadType', width: 100,
        render: (type) => Globals.Remittance_UpTypes[type]?.name || '-',
      },
      { title: 'Submission Period', dataIndex: 'submissionPeriod', key: 'submissionPeriod', width: 150,
        render: (period) => moment(period).format(Globals.DefaultMonthDateFormat),
        sorter: (a, b) => a.submissionPeriod.localeCompare(b.submissionPeriod),
        sortOrder: (Object.keys(sortedInfo).length === 0 ? 'descend' : sortedInfo.columnKey === 'submissionPeriod' && sortedInfo.order)
      },
      { title: 'Submission Date', dataIndex: 'createdOn', key: 'submissionDate', width: 150, align: 'right',
        render: (period) => moment(period).format(Globals.DefaultUIBirthDateFormat),
      },
    ];

    if (this.props.app.isAdmin()) {
      columns.push({
        title: 'Processed Date', key: 'processedDate', width: 150,
        render: (props) => {
          const successBatch = props.batchs.find(item => item.batchState == Globals.Remittance_BatchState.SUCCESS.key);
          if (successBatch) { return moment(successBatch.createdOn).format(Globals.DefaultUIBirthDateFormat) }
          else return '-';
        }
      });
    }

    columns = [
      ...columns,
      { title: 'Payment Type', dataIndex: 'paymentType', key: 'paymentType', width: 150,
        render: (value) => Globals.Remittance_PaymentTypes[value]?.name || value,
      },
      { title: 'Status', key: 'status', width: 150,
        render: (props) => {
          if (props.batchs.length < 1) {
            return <Tag color="green">NEW</Tag>;
          } else if (!props.batchs.some(item => item.batchState == Globals.Remittance_BatchState.SUCCESS.key)) {
            return <Tag color="red">ERROR</Tag>;
          } else {
            return <Tag color="blue">IMPORTED</Tag>;
          }
        }
      },
      { title: 'Actions', key: 'actions', width: 150,
        render: (props) => (
          <span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title="Download">
              <Button variant="none" icon={<DownloadOutlined />} shape="circle" onClick={this.handleDownload.bind(this, props)}/>
            </Tooltip>{' '}
            {(this.props.app.isAdmin() && props.batchs.length > 0) && (
              <>
                <Tooltip placement="bottomLeft" title="View Import Log">
                  <Button variant="none" icon={<FileTextOutlined />} shape="circle" onClick={this.handleDownloadLogs.bind(this, props, 'application/pdf')}/>
                </Tooltip>{' '}
                <Tooltip placement="bottomLeft" title="View Exception Log">
                  <Button variant="none" icon={<FileExclamationOutlined />} shape="circle" onClick={this.handleDownloadLogs.bind(this, props, 'text/plain')}/>
                </Tooltip>{' '}
              </>
            )}
            {(this.props.app.isAdmin() && props.batchs.length < 1) && (
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm
                  title={`Do you really want to delete remittance from '${moment(props.submissionPeriod).format(Globals.DefaultMonthDateFormat)}'?`}
                  placement="bottomRight"
                  onConfirm={this.handleDelete.bind(this, props)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button variant="none" icon={<DeleteOutlined />} shape="circle"/>
                </Popconfirm>
              </Tooltip>
            )}
          </span>
        ),
      },
    ];

    const lastRemittance = this.state.data.sort((a, b) => {
      const aDate = new Date(a.submissionPeriod).getTime();
      const bDate = new Date(b.submissionPeriod).getTime();

      return aDate - bDate;
    })[this.state.data.length - 1];

    const pageHeader = this.props.app.isAdmin()
      ? { onBack: () => window.history.back(), title: `${this.employerName || ''} Remittances` } 
      : { title: 'Remittances' };

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <CommonRemittancesUploadModal
          app={this.props.app}
          onSuccess={this.handleSuccessUpload}
          {...Utils.propagateRef(this, 'uploadModal')}
          lastRemittance={lastRemittance}
        />
        {this._renderRemittancesNotEnabledModal()}
        <PageHeader
          {...pageHeader} 
          extra={this.viewOptions.getOptions().concat([
            !this.props.app.isAdmin()
              ? (
                <Button icon={<UploadOutlined />} type='primary' onClick={this.handleUpload('employer')}>Upload</Button>
              ) : (
                <Dropdown 
                  key="btn"
                  overlay={(
                  <Menu>
                    <Menu.Item onClick={this.handleUpload('employer')}>Employer</Menu.Item>
                    <Menu.Item onClick={this.handleUpload('arrears')}>Arrears</Menu.Item>
                  </Menu>
                )}>
                  <Button icon={<UploadOutlined />} type='primary'>Upload</Button>
                </Dropdown>
              )
          ])}
        />
        <Layout.Content>
          <WhiteBox>
            <Table dataSource={this.state.data} columns={columns} {...props}/>
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // Private UI
  _renderRemittancesNotEnabledModal() {
    return (
      <Modal
        title="Ops!"
        visible={this.state.remittancesNotEnabledModalVisible}
        onCancel={this.handleCloseRemittancesNotEnabledModal}
        footer={null}
      >
        <Result
          status="error"
          title="Unable to upload file!"
          subTitle="Your account is not configured to allow remittance uploads. If you wish to submit your remittance file, please contact your IBEW local and request that your account is configured to allow electronic submissions."
          extra={[<Button key="1" type="primary" onClick={this.handleCloseRemittancesNotEnabledModal}>Close</Button>]}
        />
      </Modal>
    );
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.employerFiles.getAll(this.employerID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.files) {
      this.setState({ isLoading: false, data: resp.body.files });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _delete(employerID, fileID) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.employerFiles.remove(employerID, fileID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Remittance successfully deleted!');
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _download(employerID, fileObject) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.employerFiles.download(employerID, fileObject.fileID, fileObject.uploadType);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      Utils.downloadBlob(resp.body, `${fileObject.employerName}-${moment(fileObject.submissionPeriod).format(Globals.DefaultMonthDateFormat)}`, 'xlsx');
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    } this.stopLoading(true);
  }

  async _downloadLogs(fileObj, contentType) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.employerFiles.getCommitLogs(fileObj.employerID, fileObj.id, contentType);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      Utils.downloadBlob(resp.body, `${fileObj.employerName}-${moment(fileObj.submissionPeriod).format(Globals.DefaultMonthDateFormat)}-logs`, contentType == 'application/pdf' ? 'pdf' : 'txt');
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    } this.stopLoading(true);
  }
}
