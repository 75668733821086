import Globals from '@/config/Globals';
//
export default class APITrainingCodes {
  constructor(API) {
    this.api = API;
  }

  async getAll() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_TrainingCodes;
    return await req.exec();
  }

  async create(trainingCodeObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_TrainingCode + Globals.URL_NewIndentifier;
    req.body = { ...trainingCodeObj };
    return await req.exec();
  }

  async update(trainingCodeObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_TrainingCode + trainingCodeObj.id;
    delete trainingCodeObj.id;
    req.body = { ...trainingCodeObj };
    return await req.exec();
  }

  async remove(trainingCodeID) {
    const req = await this.api.newBaseRequest('DELETE');
    req.path = Globals.APIPath_TrainingCode + trainingCodeID;
    return await req.exec();
  }
}
