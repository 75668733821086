import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Row, Tag, Col, Table, Tooltip, message, Dropdown, Button, Menu } from 'antd';
import moment from 'moment';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import Utils from '@/components/Utils';
import Globals from "@/config/Globals";
import config from "@/config/config";
// 
import TableActions from '@/views/commonComponents/TableActions';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import AdminBatchEditReadOnlyForm from '@/views/adminSubviews/batch/Forms/AdminBatchEditReadOnlyForm';
import CommonDuesEntryReceiptDrawer from '@/views/commonComponents/CommonDuesEntryReceiptDrawer';
import CommonDuesEntryDuesDrawer from '@/views/commonComponents/CommonDuesEntryDuesDrawer';
import CommonDuesEntryOtherDrawer from '@/views/commonComponents/CommonDuesEntryOtherDrawer';
//
import '@/stylesheets/AdminBatchEditView.scss';
//
export default class AdminBatchEditView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const employerID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    const batchID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID2_Placeholder, this);
    this.state = { isLoading: false, batchObj: null, employerObj: null, employerID, batchID, selectedRowID: null };
  }
  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Dues Batch`;
    this.loadData();
  }
  componentDidUpdate() {
    if (this.state.batchObj && this.state.employerObj) this.form.setFields(this.state.batchObj, this.state.employerObj);
  }
  //Utils
  async loadData() {
    this.startLoading();
    await Utils.execRequests([this._loadBatch(), this._loadEmployer()]);
    this.stopLoading();
  }
  //Actions
    //Table actions
  handleFilterChange(pagination, filters, sortedInfo) { this.setState({ sortedInfo }, this.handleSearch); }
  handleEditEntry(entryObj) { 
    this.setState({ selectedRowID: entryObj.id });
    if (entryObj.transactionCode == 'O') this.entryOtherDrawer.show(entryObj.id, entryObj.memberID, this.state.employerObj, this.state.batchObj);
    else if (entryObj.transactionCode == 'D') this.entryDuesDrawer.show(entryObj.id, entryObj.memberID, this.state.employerObj, this.state.batchObj);
    else message.error(`Editing entry of type ${entryObj.transactionCode} is not supported!`);
  }
  handleDeleteEntry(entryObj) { this._deleteEntry(entryObj); }
  handleCreateEntry(entryType) { 
    if (entryType == 'O') this.entryOtherDrawer.show(null, null, this.state.employerObj, this.state.batchObj);
    else if (entryType == 'D') this.entryDuesDrawer.show(null, null, this.state.employerObj, this.state.batchObj);
    else message.error(`Editing entry of type ${entryType} is not supported!`);
  }
  handleShowReceipt(entryObj) { this.receiptDrawer.show(entryObj, this.state.employerObj); }
  //Modal
  async onModalClose(id) {
    if (id) {
      await this.loadData();
      this.setState({ selectedRowID: id });
    }
    setTimeout(() => { this.setState({ selectedRowID: null }); }, 1000)
  }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <CommonDuesEntryReceiptDrawer {...Utils.propagateRef(this, 'receiptDrawer')} app={this.props.app}/>
        <CommonDuesEntryDuesDrawer {...Utils.propagateRef(this, 'entryDuesDrawer')} app={this.props.app} onEnd={this.onModalClose}/>
        <CommonDuesEntryOtherDrawer {...Utils.propagateRef(this, 'entryOtherDrawer')} app={this.props.app} onEnd={this.onModalClose}/>
        <PageHeader title='Edit Batch' onBack={() => window.history.back()}/>
        <Layout.Content>
          <WhiteBox>
            <AdminBatchEditReadOnlyForm app={this.props.app} {...Utils.propagateRef(this, 'form')} />
          </WhiteBox>
          <Row type='flex' justify='end'>
            <Col>
              <Dropdown overlay={(
                <Menu>
                  <Menu.Item onClick={this.handleCreateEntry.bind(this, 'D')}> Dues</Menu.Item>
                  <Menu.Item onClick={this.handleCreateEntry.bind(this, 'O')}> Code Change</Menu.Item>
                </Menu>
              )}>
                <Button type="primary" style={{margin: '0px 0px 10px 0px'}}>
                  Create Entry
                </Button>
              </Dropdown>
            </Col>
          </Row>
          {this._renderEntriesTable()}
        </Layout.Content>
      </Layout.Content>
    )
  }
  // Private renders
  _renderEntriesTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const props = {
      onChange: this.handleFilterChange, rowKey: 'id', loading: this.props.isLoading,
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, showSizeChanger: false, hideOnSinglePage: true, position: 'bottom' },
      scroll: { x: 1550 }, rowSelection: {
        columnWidth: 0, selectedRowKeys: (this.state.selectedRowID ? [this.state.selectedRowID] : [])
      }
    };
    const columns = [
      {
        title: <>Entry<br/>type</>, key: 'transactionCode',
        render: (props) => {
          let title = null;
          if (props.transactionCode == 'O') title = 'Activity Code';
          else if (props.transactionCode == 'D') title = 'Dues';
          //
          if (title) return (<Tooltip title={title}> <Tag color='purple'>{props.transactionCode || '-'}</Tag> </Tooltip>) ;
          else return (<Tag>{props.transactionCode || '-'}</Tag> );
        },
        sorter: (a, b) => (a.transactionCode || '').localeCompare((b.transactionCode || '')),
        sortOrder: sortedInfo.columnKey === 'transactionCode' && sortedInfo.order
      },
      {
        title: 'SIN', dataIndex: 'memberSIN', key: 'memberSIN',
        sorter: (a, b) => (a.memberSIN || '').localeCompare(b.memberSIN || ''),
        sortOrder: sortedInfo.columnKey === 'memberSIN' && sortedInfo.order
      },
      {
        title: <>First<br />Name</>, key: 'memberFirstName',
        sorter: (a, b) => (a.memberLastName ? a.memberFirstName : '').localeCompare((b.memberFirstName ? b.memberFirstName : '')),
        sortOrder: sortedInfo.columnKey === 'memberFirstName' && sortedInfo.order,
        render: (props) => (props.memberFirstName ? props.memberFirstName : '')
      },
      {
        title: <>Last<br />Name</>, key: 'memberLastName',
        sorter: (a, b) => (a.memberLastName ? a.memberLastName : '').localeCompare((b.memberLastName ? b.memberLastName : '')),
        sortOrder: sortedInfo.columnKey === 'memberLastName' && sortedInfo.order,
        render: (props) => (props.memberLastName ? props.memberLastName : '')
      },
      {
        title: 'Amount', dataIndex: 'amount', key: 'amount', align: 'right',
        render: (amount) => `$${Utils.toCurrencyFormat(amount)}`,
        sorter: (a, b) => Number(a.amount) - Number(b.amount),
        sortOrder: sortedInfo.columnKey === 'amount' && sortedInfo.order
      },
      {
        title: 'Months', key: 'monthsPaid', align: 'center',
        sorter: (a, b) => (a.monthsPaid || 0) - (b.monthsPaid || 0),
        sortOrder: sortedInfo.columnKey === 'monthsPaid' && sortedInfo.order,
        render: (props) => (props.monthsPaid || 0)
      },
      {
        title: 'Paid thru', dataIndex: 'paidToDate', key: 'paidToDate', align: 'center',
        render: (date) => date ? moment(date).format(Globals.DefaultSimpleDateFormat) : '-',
        sorter: (a, b) => (new Date(a.paidToDate).getTime()) - (new Date(b.paidToDate).getTime()),
        sortOrder: sortedInfo.columnKey === 'paidToDate' && sortedInfo.order
      },
      {
        title: 'IO', dataIndex: 'io', key: 'io', align: 'right',
        render: (io) => `$${Utils.toCurrencyFormat(io)}`,
        sorter: (a, b) => Number(a.io) - Number(b.io),
        sortOrder: sortedInfo.columnKey === 'io' && sortedInfo.order
      },
      {
        title: 'LU', dataIndex: 'lu', key: 'lu', align: 'right',
        render: (lu) => `$${Utils.toCurrencyFormat(lu)}`,
        sorter: (a, b) => Number(a.lu) - Number(b.lu),
        sortOrder: sortedInfo.columnKey === 'lu' && sortedInfo.order
      },
      {
        title: 'Suspense', dataIndex: 'suspense', key: 'suspense', align: 'right',
        render: (suspense) => `$${Utils.toCurrencyFormat(suspense)}`,
        sorter: (a, b) => Number(a.suspense) - Number(b.suspense),
        sortOrder: sortedInfo.columnKey === 'suspense' && sortedInfo.order
      },
      {
        title: 'Transaction date', dataIndex: 'transactionDate', key: 'transactionDate', align: 'center',
        render: (date) => date ? moment(date).format(Globals.DefaultSimpleDateFormat) : '-',
        sorter: (a, b) => (new Date(a.transactionDate).getTime()) - (new Date(b.transactionDate).getTime()),
        sortOrder: sortedInfo.columnKey === 'transactionDate' && sortedInfo.order
      },
      {
        title: 'Receipt', key: 'receiptNumber', align: 'center',
        sorter: (a, b) => ((a.receiptNumber || '') + '').localeCompare((b.receiptNumber || '') + ''),
        sortOrder: sortedInfo.columnKey === 'receiptNumber' && sortedInfo.order,
        render: (props) => {
          if (props.receiptNumber) return (<Button type='link' onClick={this.handleShowReceipt.bind(this, props)}>{props.receiptNumber}</Button>)
          else return '';
        }
      },
      {
        title: 'Actions', width: 120, key: 'Actions', fixed: 'right',
        render: props => {
          let enabledActions = (props.transactionCode == 'O' || props.transactionCode == 'D');
          return (<span className='tableButtonContainer'>
            <TableActions
              options={[
                { alwaysVisible: true, label: 'Edit Entry', icon: EditOutlined, onClick: this.handleEditEntry.bind(this, props), hide: !enabledActions },
                { 
                  alwaysVisible: true, label: 'Delete Entry', icon: DeleteOutlined, hide: !enabledActions,
                  confirm: {
                    message: `Do you really want to delete the entry for '${props.memberFirstName} ${props.memberLastName}'?`,
                    onConfirm: this.handleDeleteEntry.bind(this, props),
                  },
                },
              ]}
            />
          </span>);
        }
      },
    ];
    return (
      <WhiteBox>
        <Table className='batchEditTable' dataSource={this.state?.batchObj?.entries} columns={columns} {...props} />
      </WhiteBox>
    );
  }

  // API Calls
  async _loadBatch() {
    const resp = await this.props.app.api.duesBatch.getByID(this.state?.employerID, this.state?.batchID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.setState({ batchObj: resp.body });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ batchObj: null });
    } return resp;
  }
  async _loadEmployer() {
    const resp = await this.props.app.api.employers.getByID(this.state?.employerID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.setState({ employerObj: resp.body });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ employerObj: null });
    } return resp;
  }
  async _deleteEntry(entryObj) {
    this.startLoading();
    const resp = await this.props.app.api.duesEntry.remove(entryObj.employerID, entryObj.batchID, entryObj.id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', `Dues entry from '${entryObj.memberFirstName} ${entryObj.memberLastName}' was deleted success!`);
      this.loadData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
