import React from "react";
import { Route } from "react-router-dom";
import { Can } from '@ikonintegration/mod-fine-auth';
//
import UnauthorizedView from "@/views/commonComponents/UnauthorizedView";
//
export default ({ component: C, appRef: cRef, componentID, accessLevel, validationMode, ...rest }) => {
  return (
    <Can componentID={componentID} accessLevel={accessLevel} acl={cRef.props.app.acl} validationMode={validationMode}>
      {(hasPermission) => {
        if (hasPermission) {
          return <Route {...rest} render={props => <C {...props} {...cRef.childProps} app={cRef.props.app} />} />;
        }

        return <UnauthorizedView app={cRef.props.app} />;
      }}
    </Can>
  );
}
