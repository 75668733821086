import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Row, Col, Button, Divider, Table, Popover, Tag, message } from 'antd';
import moment from 'moment';
//
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import config from "@/config/config";
import Globals from "@/config/Globals";
import Utils from "@/components/Utils";
import CommonMemberSelectionModal from "../commonComponents/CommonMemberSelectionModal";
//
import '@/stylesheets/AdminDispatchMembersView.scss';
//
export default class AdminDispatchMembersView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    const user = this.props.app.sharedCache().getUser();
    this.employerID = (
      this.props.app.isAdmin()
      ? this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this)
      : user.id
      );
    this.dispatchID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID2_Placeholder, this);

    const { classifications } = this.props.app.sharedCache().getConfig();

    this.state = {
      isLoading: false,
      isLoadingMembers: false,
      classifications: classifications || [],
      data: {},
      employer: {},
      logs: [],
      members: [],
      memberIDs: [],
    };
  }

  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Dispatch Members`;
    this._fetch();
  }

  async handleResetMembersList() {
    if (!this._isMounted) return;
    this.form.setFieldsValue({ sin: '' });
    this.setState({ isLoadingMembers: true, memberIDs: [] });
    await this._getMembers();
    this.setState({ isLoadingMembers: false });
  }

  handleSearchMember() {
    this.memberSelectionModal.show();
  }

  handleSelectMember(member) {
    const members = [...this.state.members, member].sort((a, b) => {
      const aDate = new Date(a.outOfWorkRegistrationDate).getTime();
      const bDate = new Date(b.outOfWorkRegistrationDate).getTime();
      return aDate - bDate;
    });
    this.setState({ members });
  }

  handleDispatchMembers(close) {
    return () => {
      const { memberIDs } = this.state;

      if (memberIDs.length < 1) {
        message.error('Select one or members before dispatch.');
        return;
      }

      this._dispatchMembers(memberIDs, close);
    };
  }
  
  render() {
    const pageHeader = { onBack: () => window.history.back(), title: 'Dispatch Members' };
    const statisticValueStyle = { fontSize: 18, marginTop: -4, marginBottom: 8 };
    const { data, employer } = this.state;

    return (
      <Layout.Content className="pageContent">
        <CommonMemberSelectionModal app={this.props.app} onSelect={this.handleSelectMember}
                                      {...Utils.propagateRef(this, 'memberSelectionModal')}/>

        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader {...pageHeader} />
        <Layout.Content>
          <WhiteBox>
            <Row>
              <Col span={8}>
                <div className="data-display">
                  <small>Employer</small>
                  <strong>{employer?.name}</strong>
                </div>
              </Col>
              <Col span={3} offset={1}>
                <div className="data-display">
                  <small>Job Number</small>
                  <strong>{data.jobNumber}</strong>
                </div>
              </Col>
              <Col span={5} offset={1}>
                <div className="data-display">
                  <small>Start Date</small>
                  <strong>{data.startDate || '-'}</strong>
                </div>
              </Col>
              <Col span={5} offset={1}>
                <div className="data-display">
                  <small>Classification</small>
                  <strong>{data.classification?.name || '-'}</strong>
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: 16 }}>
              <Col span={12}>
                <div className="data-display">
                  <small>Details</small>
                  <strong>{data.details || '-'}</strong>
                </div>
              </Col>
              <Col span={11} offset={1}>
                <div className="data-display">
                  <small>Work Location</small>
                  <strong>{data.workLocation || '-'}</strong>
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: 16 }}>
              <Col span={7}>
                <div className="data-display">
                  <small>Region</small>
                  <strong>{data.regionID || '-'}</strong>
                </div>
              </Col>
              <Col span={7} offset={1}>
                <div className="data-display">
                  <small>Instructions</small>
                  <strong>{data.instructions || '-'}</strong>
                </div>
              </Col>
              <Col span={7} offset={1}>
                <div className="data-display">
                  <small>Named Requests</small>
                  <strong>{data.namedRequests || '-'}</strong>
                </div>
              </Col>
            </Row>
          </WhiteBox>

          <WhiteBox>
              <Row>
                <Col>
                  <Button type="primary" onClick={this.handleSearchMember}>Click to add member</Button>
                  <Button type="ghost" style={{ marginLeft: 10 }} onClick={this.handleResetMembersList}>
                    Reset members list
                  </Button>
                </Col>
              </Row>

            <Divider />

            {this._renderMembersTable()}
          </WhiteBox>

          <WhiteBox>
            {this._renderLogsTable()}
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // UI
  _renderMembersTable() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        const memberIDs = selectedRows.map((member) => member.id);
        this.setState({ memberIDs });
      },
    };

    const columns = [
      { title: 'SIN', dataIndex: 'sin', key: 'sin', width: 70 },
      { title: 'Member Name', dataIndex: 'fullname', key: 'fullname', width: 200 },
      {
        title: 'Type', key: 'type', width: 50,
        render: (props) => {
          return (
            <Popover title="Member Type" content={
              <ul style={{ padding: '0 0 0 10px' }}>
                <li><b>Current Type</b>: {props.memberType} - {this.props.app.sharedCache().getMemberStatusByTypeCode(props.memberType)?.description || 'N/A'}</li>
              </ul>
            }>
              <Tag color="purple">{(props.memberType || '')}</Tag>
            </Popover>
          )
        },
      },
      { title: 'Phone No', dataIndex: 'phone', key: 'phone', width: 100 },
      { title: 'Cell Phone', dataIndex: 'cell', key: 'cell', width: 120 },
      { title: 'Pager', dataIndex: 'pager', key: 'pager', width: 100 },
      {
        title: 'Registration Date', dataIndex: 'outOfWorkRegistrationDate', key: 'outOfWorkRegistrationDate', width: 160,
        render: date => date ? moment(date).format(Globals.DefaultUIBirthDateFormat) : '-',
      },
      { title: 'Comments', dataIndex: 'comments', key: 'comments', width: 500 },
      { title: 'Training', dataIndex: 'trainingCodes', key: 'trainingCodes' },
    ];

    return (
      <>
        <div className="dispatch-members-buttons-sticky">
          <Button disabled={!this.state.memberIDs?.length > 0} type="primary" onClick={this.handleDispatchMembers(false)}>Dispatch</Button>
          <Button disabled={!this.state.memberIDs?.length > 0} type="primary" onClick={this.handleDispatchMembers(true)} style={{ marginLeft: 5 }}>
            Dispatch and close job
          </Button>
        </div>
        <Table
          dataSource={this.state.members}
          columns={columns}
          rowKey="id"
          scroll={{ x: 1680 }}
          loading={this.state.isLoading || this.state.isLoadingMembers}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
        />
      </>
    );
  }

  _renderLogsTable() {
    const columns = [
      {
        title: 'Date', dataIndex: 'createdOn', key: 'createdOn',
        render: date => date ? moment(date).format(Globals.DefaultUIBirthDateFormatWithTime) : '-',
      },
      { title: 'Job No', dataIndex: 'jobNumber', key: 'jobNumber' },
      { title: 'Employer', dataIndex: 'companyName', key: 'companyName' },
      { title: 'Member SIN', dataIndex: 'sin', key: 'sin' },
      { title: 'Member Name', dataIndex: 'fullname', key: 'fullname' },
      { title: 'Dispatcher', dataIndex: 'dispatcherName', key: 'dispatcherName' },
      {
        title: 'Reminder', dataIndex: 'reminderDate', key: 'reminderDate',
        render: date => date ? moment(date).format(Globals.DefaultUIBirthDateFormat) : '-',
      },
      { title: 'Email', dataIndex: 'reminderEmail', key: 'reminderEmail' },
      { title: 'Note', dataIndex: 'content', key: 'content' },
    ];

    return (
      <Table
        dataSource={this.state.logs}
        columns={columns}
        rowKey="id"
        scroll={{ x: 1500 }}
      />
    );
  }

  // API Calls
  async _fetch() {
    if (!this._isMounted) return;
    this.startLoading();
    await Utils.execRequests([ this._getEmployer(), this._getDispatch(), this._getMembers(), this._getLogs() ]);
    this.stopLoading(true);
  }
   
  async _getEmployer() {
    const resp = await this.props.app.api.employers.getByID(this.employerID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.setState({ employer: resp.body });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }

  async _getDispatch() {
    const resp = await this.props.app.api.dispatchJob.getByID(this.employerID, this.dispatchID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      const data = resp.body;

      const [hours, minutes] = data.startTime?.split(':') || [];

      data.classification = this.state.classifications.find((item) => item.id === data.classificationID);
      data.startDate = (data.startDate)
        ? moment(data.startDate)
          .hour(hours || 0)
          .minute(minutes || 0)
          .format(Globals.DefaultUIBirthDateFormatWithTime)
        : '-';
      this.setState({ data });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }

  async _getMembers() {
    const resp = await this.props.app.api.members.search({
      term: '',
      filter: {
        dispatchJobID: this.dispatchID,
      },
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.members) {
      const members = resp.body.members.sort((a, b) => {
        const aDate = new Date(a.outOfWorkRegistrationDate).getTime();
        const bDate = new Date(b.outOfWorkRegistrationDate).getTime();
        return aDate - bDate;
      });
      this.setState({ members });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }

  async _getMembersBySin(sin) {
    this.setState({ isLoadingMembers: true });
    const resp = await this.props.app.api.members.search({ term: sin, filter: {} });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.members) {
      const members = resp.body.members.sort((a, b) => {
        const aDate = new Date(a.outOfWorkRegistrationDate).getTime();
        const bDate = new Date(b.outOfWorkRegistrationDate).getTime();
        return aDate - bDate;
      });
      this.setState({ members });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.setState({ isLoadingMembers: false });
  }

  async _getLogs() {
    //request
    const resp = await this.props.app.api.dispatchJob.getDispatchNotes(this.dispatchID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.notes) {
      const data = resp.body.notes.sort((a, b) => b.id - a.id);
      this.setState({ logs: data });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }
  
  async _dispatchMembers(memberIDs, close) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.dispatchJob.dispatchMembers(this.employerID, this.dispatchID, { memberIDs, close });
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success(
        memberIDs.length > 1
          ? 'Members successfully dispatched!'
          : 'Member successfully dispatched!'
      );

      if (!this._isMounted) return;
      await Utils.execRequests([ this._getMembers(), this._getLogs() ]);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading(true);
  }
}
