import React from "react";
import autoBind from 'react-autobind';
//
import CustomComponent from '@/components/CustomComponent';
//
import { Form, Input, Row, Col, DatePicker, Checkbox } from 'antd';
import Utils from "@/components/Utils";
//
export default class AdminNotesForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  validateFields() {
    return this.form.validateFields();
  }

  resetFields() {
    return this.form.resetFields();
  }

  setFieldsValue(data) {
    return this.form.setFieldsValue(data);
  }

  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row>
          <Col span={24}>
            <Form.Item
              name="content"
              label="Note"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col spn={4}>
            <Form.Item name="reminderDate" label="Reminder Date">
              <DatePicker onChange={(date, dateString) => {}} />
            </Form.Item>
          </Col>
          <Col span={16} offset={1}>
            <Form.Item name="reminderEmail" label="Reminder Email">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={3}>
            <Form.Item
              name="private"
              label="Private"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={3} offset={1}>
            <Form.Item
              name="urgent"
              label="Urgent"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}
