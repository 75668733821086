import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button, message, Alert } from 'antd';
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import Utils from '@/components/Utils';
import Globals from "@/config/Globals";
import config from "@/config/config";
// 
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import AdminMemberOptions, { AdminMemberOptionTypes } from '@/views/commonComponents/AdminMemberOptions';
import AdminMemberForm from '@/views/adminSubviews/member/Forms/AdminMemberForm';
//
import '@/stylesheets/AdminMemberUpdateView.scss';
//
export default class AdminMemberUpdateView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.memberID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    this.memberName = this.props.app.urlManager.getQueryParam('n');
    this.viewOptions = new AdminMemberOptions(AdminMemberOptionTypes.MEMBER, this.memberID, this.props.app, this.memberName);
    
    this.state = { isLoading: false, memberObj: {}};
  }
  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Update Member`;
    this.loadData();
  }
  //Utils
  async loadData() {
    this.startLoading();
    await Utils.execRequests([ this._fetch(), this._loadNotes() ]);
    this.stopLoading();
  }
  //Actions
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) {
      //Check if changing password, if yes, prompt
      if (this.state.memberObj && this.state.memberObj.email != formData.email && this.state.memberObj.idmID) {
        const confirmation = await this.props.app.alertController.showPromptAlert('Attention!', `Member email will be updated to "${formData.email}". Are you sure you want to update it? (type the new email address to confirm)`);
        if (confirmation != formData.email) return;
      }

      const additionalFields = [];
      Object.keys(formData).forEach(key => {
        if (key.includes('additionalField.')) {
          const [, fieldID] = key.split('.');

          additionalFields.push({
            fieldID,
            value: formData[key],
          });

          delete formData[key];
        }
      });
      //update it
      this._update({ ...formData, additionalFields });
    }
  }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent" style={{ marginTop: 0, paddingTop: 8 }}>
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader
          title={
            <>
              Update Member
              <div className='subtitle'>Last update {moment(this.state.memberObj?.modificationTime).format(Globals.DefaultUIExtendedDateFormat)}</div>
            </>
          }
          onBack={() => window.history.back()}
          extra={this.viewOptions.getOptions().concat([<Button key="btn" onClick={this.handleSubmit} type='primary'>Save</Button>])}
        />
        <Layout.Content>
          {this._renderUrgentNotes()}
          <WhiteBox>
            <AdminMemberForm app={this.props.app} {...Utils.propagateRef(this, 'form')} />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // Private UI
  _renderUrgentNotes() {
    const urgentNotes = (this.state.notes || []).filter((n)=>n.urgent);
    if (urgentNotes?.length > 0) {
      const notes = urgentNotes.map((n)=> <li key={n.id}> {n.content} </li>);
      return ( <Alert message="Urgent Notes" type="warning" showIcon description={<ul>{notes}</ul>} style={{ marginBottom: 24 }} /> );
    } else return (<></>);
  }

  // API Calls
  async _fetch() {
    const resp = await this.props.app.api.members.getByID(this.memberID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.setState({ memberObj: resp.body });
      this.form.setFields(resp.body);
      this.viewOptions.setMemberName(`${resp.body.firstName} ${resp.body.lastName}`);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ memberObj: null });
    } return resp;
  }
  async _loadNotes() {
    const resp = await this.props.app.api.notes.getAll('any', this.memberID, Globals.Notes_relTypes.member.apiKey);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.setState({ notes: resp.body.notes });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ notes: null });
    } return resp;
  }
  async _update(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.members.update({id: this.memberID, ...data});
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Member successfully updated!');
      await this.loadData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
