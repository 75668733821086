import Globals from '@/config/Globals';
//
export default class APIEmployerContacts {
  constructor(API) {
    this.api = API;
  }

  async getAll(employerID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Employer + employerID + '/contacts';
    return await req.exec();
  }
  
  async getByID(employerID, contactID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Employer + employerID + '/contact/' + contactID;
    return await req.exec();
  }

  async create(contactObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Employer + contactObj.employerID + '/contact/' + Globals.URL_NewIndentifier;
    delete contactObj.employerID;
    req.body = { ...contactObj };
    return await req.exec();
  }

  async update(contactObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_Employer + contactObj.employerID + '/contact/' + contactObj.id;
    delete contactObj.id;
    delete contactObj.employerID;
    req.body = { ...contactObj };
    return await req.exec();
  }

  async remove(employerID, contactID) {
    const req = await this.api.newBaseRequest('DELETE');
    req.path = Globals.APIPath_Employer + employerID + '/contact/' + contactID;
    return await req.exec();
  }
}
