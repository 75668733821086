import React from "react";
import autoBind from 'react-autobind';
//
import CustomComponent from '@/components/CustomComponent';
import config from '@/config/config';
//
export default class EmployerDashboardView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.props.app.urlManager.pushPage(config.ApplicationRoutes.remittances);
  }

  render() {
    return (<>EmployerDashboardView</>)
  }
}
