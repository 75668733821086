import React, { Component } from "react";
import { message, notification } from 'antd';
import autoBind from 'react-autobind';
import Utils from "./Utils";
import { datadogRum } from '@datadog/browser-rum';
//
class AlertController extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Actions
  getAPIError(resp, resp2) {
    console.error(resp, resp2)
    const errMessage = Utils.extractAPIErrors(resp, resp2);
    return errMessage;
  }
  showAPIErrorAlert(title, resp, resp2) {
    console.error(resp, resp2)
    let errMessage = Utils.extractAPIErrors(resp, resp2);
    //
    if (!errMessage) errMessage = "Unknown error.\nTry reloading the page, if the error persists, please, contact the support!";
    notification.error({message: (!title ? 'Error' : title), description: errMessage});
    datadogRum.addError(new Error(errMessage));
  }
  showErrorAlert(title, description) {
    notification.error({message: title, description: description});
    datadogRum.addError(new Error(description));
  }
  showWarningAlert(title, description) {
    notification.warning({message: title, description: description});
  }
  showSuccessAlert(title, description) {
    message.success(title + " " + description, 5);
  }
  showQuestionAlert(title, description) {
    return (window.confirm(title + "\n" + description));
  }
  showPromptAlert(title, description) {
    return (window.prompt(title + "\n" + description));
  }
  //UI
  render() {
    return (<></>);
  }
}
export default AlertController;
