import React from "react";
import autoBind from 'react-autobind';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import Utils from '@/components/Utils';
import config from '@/config/config';
//
import { Layout, PageHeader, Form, Input, Button, Row, Col, Divider, Table, Tooltip, Popconfirm, message, Tag, Checkbox } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
export default class AdminChartOfAccountsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      data: [],
      isEditing: false,
      editingObject: {},
      sortedInfo: null,
    };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Chart of Accounts`;
    this._fetch();
  }

  handleManageClick(option) {
    this.props.app.urlManager.pushPage(option.route);
  }

  handleDelete(item) {
    this._delete(item.id);
  }

  handleEdit(item) {
    this.form.setFieldsValue(item);
    this.setState({ isEditing: true, editingObject: item });
  }

  handleCancelEdit() {
    this.form.resetFields();
    this.setState({ isEditing: false, editingObject: null });
  }

  async handleAddOrSave() {
    const formData = await this.form.validateFields();
    if (this.state.isEditing) this._edit(formData);
    else this._add(formData);
  }

  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const props = { onChange: this.handleFilterChange, rowKey: 'id', loading: this.props.isLoading };

    const columns = [
      { title: 'Code', dataIndex: 'code', key: 'code', width: 75,
        sorter: (a, b) => a.code.localeCompare(b.code),
        sortOrder: sortedInfo.columnKey === 'code' && sortedInfo.order
      },
      { title: 'Account', dataIndex: 'account', key: 'account', width: 150,
        sorter: (a, b) => a.account.localeCompare(b.account),
        sortOrder: sortedInfo.columnKey === 'account' && sortedInfo.order
      },
      { title: 'Description', dataIndex: 'description', key: 'description', width: 150,
        sorter: (a, b) => a.description.localeCompare(b.description),
        sortOrder: sortedInfo.columnKey === 'description' && sortedInfo.order
      },
      { title: 'Assessment', dataIndex: 'assessment', key: 'assessment', width: 75,
        render: (assessment) => assessment ? <Tag color="green">YES</Tag> : <Tag color="red">NO</Tag>,
        sorter: (a, b) => (a.assessment ? 'yes' : 'no').localeCompare((b.assessment ? 'yes' : 'no')),
        sortOrder: sortedInfo.columnKey === 'assessment' && sortedInfo.order
      },
      { title: 'Actions', width: 80, key: 'Actions',
        render: props => {
          return (<span className='tableButtonContainer'>
                    <Tooltip placement="bottomLeft" title='Delete Unit'>
                      <Popconfirm title={`Do you really want to unit '${props.description}'?`} placement="bottomRight" onConfirm={this.handleDelete.bind(this, props)} okText="Yes" cancelText="No">
                        <Button variant="none" icon={<DeleteOutlined />} shape="circle"/>
                      </Popconfirm>
                    </Tooltip>{' '}
                    <Tooltip placement="bottomLeft" title='Edit Unit'>
                      <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleEdit.bind(this, props)}/>
                    </Tooltip>
                  </span>);
        }
      }
    ];

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Chart of Accounts" onBack={() => window.history.back()} />
        <Layout.Content>
          <WhiteBox>
            <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
              <Row>
                <Col span={4}>
                  <Form.Item name="code" label="Code" rules={[{ required: true, message: 'Please, type the code!' }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item name="account" label="Account" rules={[{ required: true, message: 'Please, type the account!' }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8} offset={1}>
                  <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please, type the description!' }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col offset={1}>
                  <Form.Item label="Assessment" name="assessment" valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  <Button type="primary" onClick={this.handleAddOrSave}>
                    {this.state.isEditing ? 'Save' : 'Add'}
                  </Button>
                  {this.state.isEditing && (
                    <Button type="link" onClick={this.handleCancelEdit}>Cancel</Button>
                  )}
                </Col>
              </Row>
            </Form>

            <Divider />

            <Table style={{ opacity: this.state.isEditing ? 0.3 : 1 }} dataSource={this.state.data} columns={columns} {...props} />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.chartOfAccounts.getAll();
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.accounts) {
      this.setState({ data: resp.body.accounts, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _add(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.chartOfAccounts.create({
      ...data,
      assessment: !!data.assessment,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Account successfully created!');
      this.props.app.refreshConfig();
      this.form.resetFields();
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _edit(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.chartOfAccounts.update({
      id: this.state.editingObject.id,
      ...data,
      assessment: !!data.assessment,
    });

    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Account successfully updated!');
      this.props.app.refreshConfig();
      this.form.resetFields();
      this.handleCancelEdit();
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _delete(id) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.chartOfAccounts.remove(id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Account successfully removed!');
      this.props.app.refreshConfig();
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
