import Globals from '@/config/Globals';
//
export default class APIMembers {
  constructor(API) {
    this.api = API;
  }

  async getDues(memberID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Member + memberID + '/duesEntries';
    return await req.exec();
  }

  async findMembersWithNoApplicationBySIN(sins) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Members + 'find/noapplication';
    req.body = { sins };
    return await req.exec();
  }

  async getDispatchNotes(memberID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Notes + 'dispatch/' + memberID;
    return await req.exec();
  }
  
  async getByID(memberID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Member + memberID;
    return await req.exec();
  }

  async getByIDMID(memberID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Member + 'idmid/' + memberID;
    return await req.exec();
  }

  async create(memberObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Member + Globals.URL_NewIndentifier;
    req.body = { ...memberObj };
    return await req.exec();
  }

  async update(memberObj, setResourcesToken) {
    const req = await this.api.newBaseRequest('PUT');
    if (setResourcesToken) {
      const resourcesToken = await this.api.idm.session.data.getResourcesToken();
      if (resourcesToken) req.appendHeader('ResourcesToken', resourcesToken);
    }
    req.path = Globals.APIPath_Member + memberObj.id;
    delete memberObj.id;
    req.body = { ...memberObj };
    return await req.exec();
  }

  async activate(memberID) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Member + 'activate/' + memberID;
    return await req.exec();
  }

  async search(searchObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Members + 'search';
    req.body = { ...searchObj };
    return await req.exec();
  }

  async rollover() {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Member + 'rollover';
    return await req.exec();
  }
}
