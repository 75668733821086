import React from "react";
import autoBind from 'react-autobind';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import config from '@/config/config';
//
import { Layout, PageHeader, Form, Row, Col, InputNumber, Radio, Button, message } from 'antd';
import Utils from "@/components/Utils";
//
export default class AdminDuesRatesView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      data: {},
    };

    this.fields = [
      {
        id: 'workingDues',
        label: 'Working Dues',
      },
      {
        id: 'basicDuesInJurisdiction',
        label: 'Basic Dues in Jurisdiction',
      },
      {
        id: 'basicDuesOutOfJurisdiction',
        label: 'Basic Dues Out of Jurisdiction',
      },
      {
        id: 'aMemberIo',
        label: 'A Member IO',
      },
      {
        id: 'baMemberIo',
        label: 'BA Member IO',
      },
      {
        id: 'pensionBenefitAdmission', // TODO
        label: 'PBA',
      },
      {
        id: 'aMemberLuReinstatement',
        label: 'A Member LU Reinstatement',
      },
      {
        id: 'aMemberIoReinstatement',
        label: 'A Member IO Reinstatement',
      },
      {
        id: 'baMemberLuReinstatement',
        label: 'BA Member LU Reinstatement',
      },
      {
        id: 'baMemberIoReinstatement',
        label: 'BA Member IO Reinstatement',
      },
      {
        id: 'aMemberInitiation',
        label: 'A Member Initiation',
      },
      {
        id: 'baMemberInitiation',
        label: 'BA Member Initiation',
      },
      {
        id: 'ioInitiation',
        label: 'IO Initiation',
      },
      {
        id: 'defenseFund',
        label: 'Defense Fund',
      },
      {
        id: 'eiaf',
        label: 'EIAF',
      },
      {
        id: 'ioDefenseFund',
        label: 'IO Defense Fund',
      },
      {
        id: 'strikeFund',
        label: 'Strike Fund',
      },
      {
        id: 'aMemberIoNextYear',
        label: 'A Member IO Next Year',
      },
      {
        id: 'baMemberIoNextYear',
        label: 'BA Member IO Next Year',
      },
    ];
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Dues Rates`;
    this._fetch();
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();
    this._update(formData);
  }

  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Dues Rates" onBack={() => window.history.back()} extra={[
          <Button key="save" type="primary" onClick={this.handleSubmit}>Save</Button>
        ]} />
        <Layout.Content>
          <WhiteBox>
            <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
              <Row>
                {this.fields.map((field) => (
                  <React.Fragment key={field.id}>
                    <Col span={8}>
                      <Form.Item
                        name={field.id}
                        label={field.label}
                      >
                        <InputNumber style={{ width: '100%' }} precision={2} decimalSeparator="." />
                      </Form.Item>
                    </Col>
                    <Col span={3} style={{ marginLeft: 10 }}>
                      <Form.Item name={`${field.id}Percent`} label=" ">
                        <Radio.Group>
                          <Radio value={false}>$</Radio>
                          <Radio value={true}>%</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </React.Fragment>
                ))}
              </Row>
            </Form>
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.settings.getRates();
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      delete resp.body.transactionID;

      this.setState({ data: resp.body, isLoading: false });
      this.form.setFieldsValue({
        ...resp.body,
        adminErUploadEmail: resp.body.adminErUploadEmail ? resp.body.adminErUploadEmail.split(',').map(item => item.trim()) : [],
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  
  async _update(data) {
    this.startLoading();
    //request
    const payload = Object.entries(data).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: typeof value === 'number' ? String(value) : value,
    }), {});
    const resp = await this.props.app.api.settings.updateRates({
      ...this.state.data,
      ...payload,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Dues Rates successfully updated!');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading(true);
  }
}
