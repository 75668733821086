import Globals from '@/config/Globals';
//
export default class APIMemberWorkHistories {
  constructor(API) {
    this.api = API;
  }

  async getAll(memberID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Member + memberID + '/workHistories';
    return await req.exec();
  }

  async getByID(memberID, workHistoryID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Member + memberID + '/workHistory/' + workHistoryID;
    return await req.exec();
  }

  async create(memberWorkHistoryObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Member + memberWorkHistoryObj.memberID + '/workHistory/' + Globals.URL_NewIndentifier;
    req.body = { ...memberWorkHistoryObj };
    return await req.exec();
  }

  async update(memberWorkHistoryObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_Member + memberWorkHistoryObj.memberID + '/workHistory/' + memberWorkHistoryObj.workHistoryID;
    delete memberWorkHistoryObj.workHistoryID;
    req.body = { ...memberWorkHistoryObj };
    return await req.exec();
  }

  async remove(memberID, workHistoryID) {
    const req = await this.api.newBaseRequest('DELETE');
    req.path = Globals.APIPath_Member + memberID + '/workHistory/' + workHistoryID;
    return await req.exec();
  }
}
