import React from "react";
import autoBind from 'react-autobind';
import { Drawer, Row, Col, Button, message } from 'antd';
//
import CustomComponent from '@/components/CustomComponent';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import CommonMemberWorkHistoryForm from './CommonMemberWorkHistoryForm';
import Utils from "@/components/Utils";
//
export default class CommonMemberWorkHistoryEditDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      isVisible: false,
      memberID: null,
      workHistoryID: null,
      data: {},
    };
  }

  show(memberID, workHistoryID) {
    this.setState({ isVisible: true, memberID, workHistoryID }, this._fetch);
  }

  handleClose() {
    this.form.resetFields();
    this.setState({
      isLoading: false,
      isVisible: false,
      memberID: null,
    });
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) {
      this._update(formData);
    }
  }

  //UI
  render() {
    return (
      <Drawer
        visible={this.state.isVisible}
        title="Edit Work History"
        placement="right"
        onClose={this.handleClose}
        width={800}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <CommonMemberWorkHistoryForm
          app={this.props.app} {...Utils.propagateRef(this, 'form')}
          activityCodes={this.props.activityCodes} classifications={this.props.classifications}
        />
        <Row>
          <Col>
            <Button type="primary" onClick={this.handleSubmit}>Save</Button>
          </Col>
        </Row>
      </Drawer>
    )
  }

  // API Calls
  async _fetch() {
    this.setState({ isLoading: true });
    const { memberID, workHistoryID } = this.state;
    const resp = await this.props.app.api.memberWorkHistories.getByID(memberID, workHistoryID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.form.setFieldsValue(resp.body);
      this.setState({ data: resp.body });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.setState({ isLoading: false });
  }

  async _update(data) {
    if (!this._isMounted) return;
    this.startLoading();
    const resp = await this.props.app.api.memberWorkHistories.update({ 
      ...data,
      workHistoryID: this.state.workHistoryID,
      memberID: this.state.memberID,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Work History successfully updated!');
      this.handleClose();
      if (this.props.onUpdate) this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading(true);
  }
}
