import React from 'react';

export default function ACLComponent({ component: Component, componentID, accessLevel, acl, children, validationMode, ...props }) {
  let hasPermission = true;
  
  if (componentID && accessLevel) {
    if (validationMode === 'any') {
      hasPermission = acl.hasAnyPermission(componentID, accessLevel);
    } else {
      hasPermission = acl.hasPermission(componentID, accessLevel);
    }
  }

  if (hasPermission) {
    return (<Component {...props}>{children}</Component>);
  }

  return null;
}