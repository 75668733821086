import React from "react";
import autoBind from 'react-autobind';
import { Drawer } from 'antd';
//
import CustomComponent from '@/components/CustomComponent';
import CommonDuesEntryReceiptForm from '@/views/commonComponents/Forms/CommonDuesEntryReceiptForm';
//
import Utils from '@/components/Utils';
//
export default class CommonDuesEntryReceiptDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, isVisible: false, entryObj: null, employerObj: null, };
  }
  //Public
  show(entryObj, employerObj) {
    this.setState({ isVisible: true, entryObj, employerObj }, () => {
      this.form.setFields(entryObj, employerObj);
    });
  }
  //Actions
  handleClose() { this.setState({ isVisible: false, }); }
  //UI
  render() {
    return (
      <Drawer title="Dues Entry Receipt" visible={this.state.isVisible}
              onClose={this.handleClose} footer={null} closable  width={700}>
        <CommonDuesEntryReceiptForm {...Utils.propagateRef(this, 'form')} app={this.props.app}/>
      </Drawer>
    )
  }
}
