import React from "react";
import Cache from "./Cache";
import IDM from "@ikonintegration/idmclient";
import { ACL } from '@ikonintegration/mod-fine-auth';
import { datadogRum } from '@datadog/browser-rum';
//
import Utils from './Utils';
import AlertController from "./AlertController";
import URLManager from "./URLManager";
import API from './API/API';
//
import config from '@/config/config';
import packageJSON from './../../../package.json';
import Globals from '@/config/Globals';
//
export default class Authenticator extends React.Component {
		constructor(props) {
			super(props);
			console.debug(`IBEW FE - version: ${packageJSON.version}@${process.env.COMMIT_HASH}`);
			//
			this.isAuthenticating = false;
			this._sharedCache = null;
			this.alertController = null; //ref
			//comps
			this.urlManager = new URLManager(this);
			//IDM
			this.idm = new IDM(config.IDMClientTokenKey,config.IDMClientTokenSecret, config.IDMClientOptions);
			this.idm.sessionLoadedHandler = this.sessionLoadedHandler.bind(this);
			this.changingPassword = this.urlManager.getQueryParam(Globals.URLQueryParam_ChangePassword);
			// API
			this.api = new API(this.idm);
			//ACL
			this.acl = new ACL();
		}
	  async componentDidMount() {
			this.startLoading(true);
			this.redirectMode = this.idm.impersonate.isImpersonatingCBURL();
	    if (!this.idm.isLogged()) await this.idm.load(); // ask for revalidation if have session - will redirect
	    if (!this.idm.isLogged()) this.endLoading(true); //if we kept logged out, stop loading
		}
		
		setAcls(acls) {
			this.acl.setAcls(acls);
			this.forceUpdate();
		}

		redirectToHome() {
			const userObj = this.sharedCache().getUser();
			function checkForRoles(roles) {
				const userRoles = userObj.acls || [];
				return !!userRoles.find(role => roles.includes(role.componentID));
			}

			if (checkForRoles([Globals.Components.MEMBERS_MANAGEMENT])) {
				this.urlManager.pushPage(config.ApplicationRoutes.members);
				return;
			}

			if (checkForRoles([Globals.Components.EMPLOYERS_MANAGEMENT])) {
				this.urlManager.pushPage(config.ApplicationRoutes.employers);
				return;
			}

			if (checkForRoles([Globals.Components.DISPATCH_MANAGEMENT])) {
				this.urlManager.pushPage(config.ApplicationRoutes.dispatch);
				return;
			}

			this.urlManager.pushPage(config.ApplicationRoutes.adminSettings);
		}

		//Shortcuts on application levels
		isAdmin() {
			return (this.idm.session.authorization ? this.idm.session.authorization.hasClaim('ADMIN') : false);
		}
		isEmployer() {
			if (this.isAdmin()) { return false; }
			return (this.idm.session.authorization ? this.idm.session.authorization.hasClaim('EMPLOYER') : false);
		}
		isMember() {
			if (this.isAdmin()) { return false; }
			return (this.idm.session.authorization ? this.idm.session.authorization.hasClaim('MEMBER') : false);
		}
		getAuthorizedUserID() { return this.idm?.session?.authorization?.getUserID(); }

		//Loading
		startLoading(reload, isRenewing) {
			if (!isRenewing) this.isAuthenticating = true;
			if (reload) this.forceUpdate();
		}
		endLoading(reload) {
			this.isAuthenticating = false;
			if (reload) this.forceUpdate();
		}
		//Refresh
		async refreshConfig(reload) {
			await this.sharedCache().loadConfig();
			if (reload) this.forceUpdate();
		}
		async _loadCache() {
			//Check if could load cache properly, if not we can't proceed :/
			//Since the user has already logged it worth trying 2/3 times so
			//we dont loose user retetion :p
			let retries = 0;
			const maxAttempts = 3;

			while (retries < maxAttempts) {
				console.debug('Loading cache');
				if ((await this.sharedCache().loadCache())) break;
				await Utils.shortRandSleep(1500);
				retries++;
			}

			//Check if reach max attempts
			if (maxAttempts == retries) {
				await this.idm.session.clearSession(true); //light logout
				this.sharedCache().clearCache();
				if (this.sessionDidFailLoadHandler) this.sessionDidFailLoadHandler();
			} else {
				if (this.sessionDidLoadHandler) this.sessionDidLoadHandler();
			}
		}
		//Singleton
		sharedCache() {
	    if (this._sharedCache == null) { this._sharedCache = new Cache(this); }
	    return this._sharedCache;
	  }
		async checkForAuthetication() {
			//session will be loaded by this.idm.load or by login call, both should be
	    //loading while this is called, so we dont update state when starting loading for session load
	    //reloading state will cause authorized area to be called while we haven't fully loaded the session
			this.startLoading(false);
			this._identifyUser();
			if (this.redirectMode) console.log('Not loading application cache due redirect nature of the authentication.');
			else await this._loadCache();
	    // this.urlManager.pushPage(config.ApplicationRoutes.homepage);
	    this.endLoading(true);
		}
		// IDM delegate calls
		async sessionLoadedHandler(isRenewing) {
			//session will be loaded by this.idm.load or by login call, both should be
    	//loading while this is called, so we dont update state when starting loading for session load
    	//reloading state will cause authorized area to be called while we haven't fully loaded the session
			this.startLoading(false, isRenewing);
			this._identifyUser();
			await this._loadCache();
			// this.urlManager.pushPage(config.ApplicationRoutes.homepage);
			this.endLoading(true);
		}
		
		//UI
		render() {
			return (
				<>
					<AlertController {...Utils.propagateRef(this, 'alertController')}/>
					{this.idm.isLogged() && !this.redirectMode && !this.isAuthenticating ? this.renderAuthorizedView() : this.renderUnauthorizedView()}
				</>
			)
		}

		//Private
		async _identifyUser() {
			if (process.env.REACT_APP_OFFLINE) return;
			//Identify
			const userObj = await this.idm.session.data.getUserObject();
			const userID = this.idm.session.authorization.getUserID();
			if (!userObj || !userID) return;
			datadogRum.setUser({
				id: this.idm.session.authorization.getUserID(),
				name: userObj.firstName + ' ' + userObj.lastName,
				email: userObj.email,
				userType: (this.isAdmin() ? 'Admin' : (this.isEmployer() ? 'Employer' : (this.isMember() ? 'Member' : 'User')))
			});
		}
};