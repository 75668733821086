import Globals from '@/config/Globals';
//
export default class APISettings {
  constructor(API) {
    this.api = API;
  }

  async getGeneral() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Settings + 'general';
    return await req.exec();
  }

  async updateGeneral(settingsObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_Settings + 'general';
    req.body = { ...settingsObj };
    return await req.exec();
  }

  async getRates() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Settings + 'rates';
    return await req.exec();
  }

  async updateRates(settingsObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_Settings + 'rates';
    req.body = { ...settingsObj };
    return await req.exec();
  }
}
