module.exports = {
  "IDMClientTokenKey": "fa5f070d-45b3-42d4-ae7a-dfaabfc30a72",
  "IDMClientTokenSecret": "432d7850-37da-4182-b774-1ba6b827a42c",
  "IDMClientOptions": {
    "externalAuth": false,
    "autoEnrollRole": [
      "59a72bbd-9a43-427e-9c92-afd74ab19431"
    ],
    "apiEndpoint": "https://api-auth.ibew.ikonint-dev.com",
    "cookiesEndpoint": "https://auth.ibew.ikonint-dev.com",
    "roles": {
      "ADMIN": "d456cc4a-694_IBEW",
      "EMPLOYER": "237b259e-79b_IBEW",
      "MEMBER": "c31eb08f-e37_IBEW"
    },
    "partitions": {}
  },
  "ApplicationName": "IBEW",
  "ApplicationAPIEndpoint": "https://api.ibew.ikonint-dev.com",
  "ApplicationRecaptchaKey": "6Ld5uMwZAAAAALUYoCDAL5dW5dC7rp3QfUg41Rl0",
  "ApplicationRoutes": {
    "login": "/",
    "homepage": "/",
    "register": "/register",
    "registrationConfirmation": "/registrationConfirmation/:id/:id2",
    "registrationConfirmationMail": "/registrationConfirmation/:id",
    "registrationConfirmationReset": "/registrationConfirmation/reset/:id",
    "resetPassword": "/resetPass/:id",
    "resetPasswordRaw": "/resetPass/",
    "resetPasswordConfirmation": "/resetPass/confirm/:id",
    "setupPassword": "/setupPass/:id/:id2",
    "user": "/user/:id",
    "profile": "/user",
    "users": "/users",
    "authError": "/authError",
    "contactAdmin": "mailto:support@ikonintegration.com",
    "adminSettings": "/settings",
    "adminSettingsDepartments": "/settings/departments",
    "adminSettingsSchedules": "/settings/schedules",
    "adminSettingsSectors": "/settings/sectors",
    "adminSettingsAppConfig": "/settings/app-config",
    "adminSettingsDuesRates": "/settings/dues-rates",
    "adminSettingsMemberStatus": "/settings/member-status",
    "adminSettingsTrainingCodes": "/settings/training-codes",
    "adminSettingsDispatchList": "/settings/dispatch-lists",
    "adminSettingsChartOfAccounts": "/settings/chart-of-accounts",
    "adminSettingsUnits": "/settings/units",
    "adminSettingsClassifications": "/settings/classifications",
    "adminSettingsJobClassifications": "/settings/jobClassifications",
    "adminSettingsWorkActivityCodes": "/settings/work-activity-codes",
    "admins": "/admins",
    "adminsCreate": "/admin/new",
    "adminsUpdate": "/admin/update/:id",
    "adminsManageAcls": "/admin/acls/:id",
    "employers": "/employers",
    "employersCreate": "/employer/new",
    "employersUpdate": "/employer/update/:id",
    "employerRemittances": "/employer/:id/remittance",
    "remittances": "/remittances",
    "adminRemittances": "/employers/remittances",
    "adminImportRemittance": "/employers/remittances/import/:id/:id2",
    "notes": "/:id/notes/:id2",
    "contacts": "/employers/:id/contacts",
    "dispatch": "/dispatch",
    "members": "/members",
    "memberCreate": "/member/new",
    "memberUpdate": "/member/update/:id",
    "memberWorkHistory": "/member/:id/work-history",
    "memberDuesHistory": "/member/:id/dues-history",
    "memberDispatchNotes": "/member/:id/dispatch-notes",
    "reportMembersLabel": "/reports/members/label",
    "reportMembersList": "/reports/members/list",
    "reportShopStewards": "/reports/shop-stewards",
    "reportMembersArrears": "/reports/members/arrears",
    "ioPerCapita": "/reports/members/io",
    "reportCustomMembersReport": "/reports/members/custom",
    "reportMembersDuesPosting": "/reports/members/dues-posting",
    "reportEmployersLabel": "/reports/employers/label",
    "reportDispatchList": "/reports/dispatchList",
    "reportTaxReceipts": "/reports/taxReceipts",
    "reportDispatchSlip": "/reports/dispatchSlip",
    "reportActiveMembers": "/reports/activeMembers",
    "reportEmploymentByClassification": "/reports/employment-by-classification",
    "dispatchJob": "/dispatch-job",
    "crateDispatchJob": "/dispatch-job/new",
    "duesBatches": "/duesBatches",
    "duesBatchUpdate": "/duesBatch/:id/:id2",
    "updateDispatchJob": "/employer/:id/dispatch-job/update/:id2",
    "dispatchMembers": "/employer/:id/dispatch-job/:id2/dispatch-members",
    "employerDispatchNotes": "/employer/:id/dispatch-notes/:id2",
    "trainingHistory": "/training-history/:id",
    "reportArrearsLetters": "/reports/arrears-letters",
    "resetYTD": "/reset-ytd",
    "applicationForm": "/application-form",
    "applications": "/applications",
    "duesHistory": "/dues-history",
    "workHistory": "/work-history",
    "memberDispatch": "/dispatch",
    "reportMembersDuesYTD": "/reports/members-dues-ytd"
  },
  "Theme": {
    "colors": {
      "@primary-color": "#D7503F",
      "@link-color": "#D6664A",
      "@redMain": "#D6664A",
      "@greyDark": "#136fba"
    },
    "privacyURL": null,
    "helpURL": null,
    "termsURL": null
  },
  "DatadogOptions": {
    "applicationId": "ce95eb6c-83f4-482d-a7ec-4d7a458629a8",
    "clientToken": "pub31f2ac38cf0d4b82e67899bf03c4c36b",
    "site": "datadoghq.com",
    "service": "ibew-fe",
    "env": "dev",
    "sampleRate": 100,
    "forwardErrorsToLogs": true,
    "trackInteractions": true,
    "defaultPrivacyLevel": "allow"
  }
}