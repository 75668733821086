import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Button, message, Tooltip, Form, Row, Col, DatePicker } from 'antd';
import { DeleteOutlined, EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import TableActions from '@/views/commonComponents/TableActions';
import Utils from "@/components/Utils";
import config from '@/config/config';
import Globals from "@/config/Globals";
//
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import CommonEmployerSelectionModal from "@/views/commonComponents/CommonEmployerSelectionModal";
import AdminDuesBatchCreateModal from "@/views/commonComponents/AdminDuesBatchCreateModal";
//
export default class AdminBatchesSearchView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.employerName = this.props.app.urlManager.getQueryParam('n') || null;
    this.fromEmployers = !!this.employerName;

    this.state = { isLoading: false, data: [], ...this._getInitialState(), sortedInfo: null };
  }
  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Dues Batches`;
    this.handleSearch();
    // Populate employer search modal component
    const employerID = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Employer) || null;
    if (!this.fromEmployers && employerID) {
      this.employerSelectionModal.setEmployer(employerID, { preventFetch: true });
    }
  }
  //Actions
    //Table + add button actions
  handleCreateDuesBatch() { this.createBatchModal.show(); }
  handleFilterChange(pagination, filters, sortedInfo) { this.setState({ sortedInfo }, this.handleSearch); }
  handlePagination(currentPage) { this.setState({ currentPage }, this.handleSearch); }
    //Rows actions
  handleEditBatch(batchObj) { 
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.duesBatchUpdate, null, batchObj.employerID, batchObj.id);
  }
  handleDeleteBatch(batchObj) { this._deleteBatch(batchObj); }
  // filters
  async handleSearch(resetPage) {
    const formData = await this.form.validateFields();
    if (formData) {
      this._setSearchQueryParams(formData, resetPage);
      const filter = this._getSearchFilterObject(formData);
      this._fetch(filter);
    }
  }
    //Employer
  handleSearchEmployer() { this.employerSelectionModal.show(); }
  handleSelectEmployer(employer, args) {
    if (args?.preventFetch) {
      this.setState(prevState => ({
        ...prevState,
        searchObj: { ...prevState.searchObj, employerID: employer.id, },
        selectedEmployer: employer
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        selectedEmployer: employer,
        searchObj: {
          ...prevState.searchObj,
          employerID: employer.id,
        },
      }), this.handleSearch.bind(this, true));
    }
  }
  handleDeselectEmployer() {
    this.setState(prevState => ({
      ...prevState,
      searchObj: { ...prevState.searchObj, employerID: null, },
      selectedEmployer: null,
    }), this.handleSearch.bind(this, true));
  }

  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const props = {
      rowKey: 'id', loading: this.props.isLoading, onChange: this.handleFilterChange,
      locale: { emptyText: (this.state.selectedEmployer ? 'Batch not found for employer' : 'Batches not found') },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage, showSizeChanger: false, hideOnSinglePage: true, position: 'bottom',
        total: this.state.total, onChange: this.handlePagination, current: this.state.currentPage,
      }
    };

    const columns = [
      {
        title: 'Date', key: 'postingDate', dataIndex: 'postingDate',
        render: (props) => (props ? Utils.getDateOnUIFormat(new Date(props)) : ''),
        sorter: (a, b) => (a.postingDate ? (new Date(a.postingDate).getTime()) : 0) - (b.postingDate ? (new Date(b.postingDate).getTime()) : 0),
        sortOrder: sortedInfo.columnKey === 'postingDate' && sortedInfo.order
      },
      { title: 'Employer Name', key: 'employerName', dataIndex: 'employerName',
        sorter: (a, b) => (a.employerName || '').localeCompare((b.employerName || '')),
        sortOrder: sortedInfo.columnKey === 'employerName' && sortedInfo.order
      },
      {
        title: 'Total', dataIndex: 'batchTotal', key: 'batchTotal',
        render: (amount) => (amount && amount != '0.00') ? `$${Utils.toCurrencyFormat(amount)}` : '',
      },
      {
        title: 'Creation Date', dataIndex: 'createdOn', key: 'createdOn',
        render: (props) => (props ? Utils.getDateAndTimeOnUIFormat(new Date(props)) : ''),
        sorter: (a, b) => (a.createdOn ? (new Date(a.createdOn).getTime()) : 0) - (b.createdOn ? (new Date(b.createdOn).getTime()) : 0),
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order
      },
      {
        title: 'Last Modified Date', dataIndex: 'updatedOn', key: 'updatedOn',
        render: (props) => (props ? Utils.getDateAndTimeOnUIFormat(new Date(props)) : ''),
        sorter: (a, b) => (a.updatedOn ? (new Date(a.updatedOn).getTime()) : 0) - (b.updatedOn ? (new Date(b.updatedOn).getTime()) : 0),
        sortOrder: sortedInfo.columnKey === 'updatedOn' && sortedInfo.order
      },
      { title: 'Created By', key: 'userID',
        render: (props) => (props.adminFirstName ? `${props.adminFirstName} ${props.adminLastName}` : '-'),
        sorter: (a, b) => ((a.adminFirstName || '') + (a.adminLastName || '')).localeCompare((b.adminFirstName || '') + (b.adminLastName || '')),
        sortOrder: sortedInfo.columnKey === 'userID' && sortedInfo.order
      },
      { title: 'Actions',  key: 'Actions', align: 'right',
        render: props => {
          return (<span className='tableButtonContainer'>
                    <TableActions
                      options={[
                        { alwaysVisible: true, label: 'Edit batch', icon: EditOutlined, onClick: this.handleEditBatch.bind(this, props) },
                        {
                          label: 'Delete batch', icon: DeleteOutlined, alwaysVisible: true,
                          confirm: {
                            message: `Do you really want to delete the batch from '${props.employerName}' with the date '${Utils.getDateOnUIFormat(new Date(props.postingDate))}'?`,
                            onConfirm: this.handleDeleteBatch.bind(this, props),
                          },
                        },
                      ]}
                    />
                  </span>);
        }
      },
    ];

    const pageHeaderProps = {
      ...(this.fromEmployers ? { onBack: () => window.history.back() } : {}),
    };

    return (
      <Layout.Content className="pageContent">
        <CommonEmployerSelectionModal app={this.props.app} onSelect={this.handleSelectEmployer}
                                      {...Utils.propagateRef(this, 'employerSelectionModal')}/>
        <AdminDuesBatchCreateModal app={this.props.app} {...Utils.propagateRef(this, 'createBatchModal')}/>
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title={this.employerName ? `${this.employerName}'s Dues Batches` : 'Dues Batches'}
                    extra={[<Button key="1" onClick={this.handleCreateDuesBatch} type='primary'>Create Batch</Button>]}
                    {...pageHeaderProps}/>
        <Layout.Content>
          <WhiteBox>
            <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
              <Row type='flex' justify='start' align='middle'>
              {!this.fromEmployers && (
                <Col>
                  <Form.Item label="Employer">
                    {!this.state.selectedEmployer && <Button type="ghost" onClick={this.handleSearchEmployer}>Click to find an employer</Button>}
                    {this.state.selectedEmployer && (
                      <span>
                        {this.state.selectedEmployer.name}
                        <Tooltip title="Remove Filter">
                          <Button icon={<CloseCircleOutlined />} type="link" onClick={this.handleDeselectEmployer} />
                        </Tooltip>
                      </span>
                    )}
                  </Form.Item>
                </Col>
              )}
                <Col span={4} offset={(!this.fromEmployers ? 1 : 0)}>
                  <Form.Item name="batchDate" label="Batch Date" initialValue={this.state.searchObj.batchDate}>
                    <DatePicker format={Globals.DefaultSimpleDateFormat} style={{ width: '100%' }} onChange={this.handleSearch.bind(this, true)}/>
                  </Form.Item>
                </Col>
                <Col offset={1}>
                  <Button style={{marginTop: '14px' /*fix for forms h.align */ }} type='primary' onClick={this.handleSearch.bind(this, true)}>Search</Button>
                </Col>
              </Row>
            </Form>
          </WhiteBox>
          <WhiteBox>
            <Table dataSource={this.state.data} columns={columns} {...props}/>
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // Utils
  _getInitialState() {
    const employerID = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Employer) || null;
    const currentPage = parseInt(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Page) || 1);
    const batchDate = (this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_BatchDate) ? moment(new Date(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_BatchDate))) : null);
    const columnKey = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SortField) || '';
    const order = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SortOrder) || '';
    //
    return {
      searchObj: { employerID, batchDate },
      currentPage, total: 0, sortedInfo: { columnKey, order },
      selectedEmployer: null,
    };
  }
  _setSearchQueryParams(formData, resetPage) {
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_Page, (resetPage ? 1 : this.state.currentPage));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_Employer, (this.state.searchObj?.employerID || null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_BatchDate, (formData?.batchDate ? moment(formData?.batchDate).format(Globals.DefaultSimpleDateFormat) : null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SortField, (this.state.sortedInfo && this.state.sortedInfo.order && !resetPage ? this.state.sortedInfo.columnKey : ''));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SortOrder, (this.state.sortedInfo && !resetPage ? this.state.sortedInfo.order : null));
  }
  _getSearchFilterObject(formData) {
    //page
    const from = (Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1));
    //sort
    const sortField = (this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey ? this.state.sortedInfo.columnKey : '');
    const sortOrder = (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : '');
    //filter
    const batchDate = (formData?.batchDate ? moment(new Date(formData.batchDate)).format(Globals.DefaultSimpleDateFormat) : null);
    return {
      ...(this.state.searchObj.employerID ? { employerID: this.state.searchObj.employerID } : {}),
      ...(batchDate ? { date: batchDate } : {}),
      filter: { from, sortField, sortOrder: (sortOrder == 'descend' ? 'desc' : 'asc')  },
    };
  }

  // API calls
  async _fetch(filter) {
    if (!this._isMounted) return;
    //
    this.setState({ data: [], total: 0, isLoading: true });
    //request
    const resp = await this.props.app.api.duesBatch.search(filter);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.batchs) {
      this.setState({ data: resp.body.batchs, total: resp.body.total, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  async _deleteBatch(batchObj) {
    this.startLoading();
    const resp = await this.props.app.api.duesBatch.remove(batchObj.employerID, batchObj.id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', `Dues batch from '${batchObj.employerName}' with the date '${Utils.getDateOnUIFormat(new Date(batchObj.postingDate))}' was deleted success!`);
      this.handleSearch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
