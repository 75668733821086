import React from "react";
import autoBind from 'react-autobind';
import { Layout, Result, Button } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
//
import CustomComponent from '@/components/CustomComponent';
//
import config from '@/config/config';
import Globals from "@/config/Globals";
//
export default class UnauthorizedView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleBackToHome() {
    this.props.app.redirectToHome();
  }
  
  render() {
    return (
      <Layout.Content className="pageContent">
        <Layout.Content>
          <Result
              status="warning"
              title="Unauthorized"
              subTitle="You don't have the necessary permissions to access this page. If you think this is wrong, contact the system admin."
              extra={
                <Button icon={<ArrowLeftOutlined />} type="primary" key="console" onClick={this.handleBackToHome}>
                  Back to home
                </Button>
              }
            />
        </Layout.Content>
      </Layout.Content>
    )
  }
}
