import Globals from '@/config/Globals';
//
export default class APIAdmins {
  constructor(API) {
    this.api = API;
  }

  async getAll() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Admins;
    return await req.exec();
  }

  async getByID(adminID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Admin + adminID;
    return await req.exec();
  }

  async getByIDMID(adminID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Admin + 'idmid/' + adminID;
    return await req.exec();
  }

  async create(adminObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Admin + Globals.URL_NewIndentifier;
    req.body = { ...adminObj };
    return await req.exec();
  }

  async update(adminObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_Admin + adminObj.id;
    delete adminObj.id;
    req.body = { ...adminObj };
    return await req.exec();
  }

  async activate(adminID) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Admin + 'activate/' + adminID;
    return await req.exec();
  }

  async search(searchObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Admins + 'search';
    req.body = { ...searchObj };
    return await req.exec();
  }

  async getAcls(adminID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Admin + adminID + '/acls';
    return await req.exec();
  }

  async updateAcls(adminID, acls) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Admin + adminID + '/acls';
    req.body = { adminID, acls };
    return await req.exec();
  }
}
