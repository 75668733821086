import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button, message, Alert } from 'antd';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import Utils from '@/components/Utils';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import AdminEmployerOptions, { AdminEmployerOptionTypes } from '@/views/commonComponents/AdminEmployerOptions';
import Globals from "@/config/Globals";
import config from "@/config/config";
//
import CommonEmployersForm from '@/views/commonSubviews/CommonEmployersForm';
//
export default class AdminEmployersUpdateView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.employerID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    this.state = { isLoading: false, employerObj: {}};
    this.viewOptions = new AdminEmployerOptions(AdminEmployerOptionTypes.EMPLOYER, this.employerID, this.props.app, null, this);
  }
  async componentDidMount() {
    document.title = `${config.ApplicationName} - Update Employer`;
    this.startLoading();
    await Utils.execRequests([ this._fetch(), this._loadNotes() ]);
    this.stopLoading();
  }
  //Actions
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) {
      //Check if changing password, if yes, prompt
      if (this.state.employerObj && this.state.employerObj.email != formData.email && this.state.employerObj.idmID) {
        const confirmation = await this.props.app.alertController.showPromptAlert('Attention!', `Employer email will be updated to "${formData.email}". Are you sure you want to update it? (type the new email address to confirm)`);
        if (confirmation != formData.email) return;
      }
      //update it
      this._update(formData);
    }
  }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader
          title="Update Employer"
          onBack={() => window.history.back()}
          extra={this.viewOptions.getOptions().concat([<Button key="btn" onClick={this.handleSubmit} type='primary'>Save</Button>])}
        />
        <Layout.Content>
          {this._renderUrgentNotes()}
          <WhiteBox>
            <CommonEmployersForm editMode app={this.props.app} {...Utils.propagateRef(this, 'form')} />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _loadNotes() {
    const resp = await this.props.app.api.notes.getAll('any', this.employerID, Globals.Notes_relTypes.employer.apiKey);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.setState({ notes: resp.body.notes });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ notes: null });
    } return resp;
  }
  _renderUrgentNotes() {
    const urgentNotes = (this.state.notes || []).filter((n)=>n.urgent);
    if (urgentNotes?.length > 0) {
      const notes = urgentNotes.map((n)=> <li key={n.id}> {n.content} </li>);
      return ( <Alert message="Urgent Notes" type="warning" showIcon description={<ul>{notes}</ul>} style={{ marginBottom: 24 }} /> );
    } else return (<></>);
  }
  async _fetch() {
    //request
    const resp = await this.props.app.api.employers.getByID(this.employerID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.setState({ employerObj: resp.body });
      this.form.setFields({
        ...resp.body,
        phone: Utils.formatPhone(resp.body.phone),
        altPhone: Utils.formatPhone(resp.body.altPhone),
        fax: Utils.formatPhone(resp.body.fax),
      });
      this.viewOptions.setEmployerName(resp.body.name);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ employerObj: null });
    }
  }
  async _update(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.employers.update({
      id: this.employerID,
      country: Globals.DefaultAdminCountry,
      ...data,
      phone: Utils.sanitizePhone(data.phone),
      altPhone: Utils.sanitizePhone(data.altPhone),
      fax: Utils.sanitizePhone(data.fax),
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Employer successfully updated!');
      await this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading(true);
  }
}
