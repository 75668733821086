import React from "react";
import autoBind from 'react-autobind';
import { Form, Row, Col, Input, Select, Radio, Divider, Popover, Typography, DatePicker } from 'antd';
import moment from 'moment';
//
import Utils from '@/components/Utils';
import CustomComponent from '@/components/CustomComponent';
import Globals from "@/config/Globals";
//
export default class MemberProfileForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    const { countries, provinces } = this.props.app.sharedCache().getConfig();

    this.state = {
      countries: countries || [],
      provinces: provinces || [],
      selectedCountryProvinces: null,
    };
  }

  //Actions
  handleChangeCountry(countryCode) {
    this.setState({ selectedCountryProvinces: this.state.provinces[countryCode], province: '' });
    this.form.resetFields(['province']);
  }

  //Public
  async validateFields() {
    const formData = await this.form.validateFields();
    if (formData) {
      const data = {
        ...formData,
        street1: formData.street1 || '',
        street2: formData.street2 || '',
        phone: formData.phone || '', province: formData?.province || null,
        birthDate: formData.birthDate ? moment(formData.birthDate).format(Globals.DefaultAPIDateFormat) : null,
      };

      return data;
    }

    return false;
  }

  resetFields() {
    return this.form.resetFields();
  }

  setFields(data) {
    this.handleChangeCountry(data.country);
    return this.form.setFieldsValue({
      ...data,
      birthDate: data.birthDate ? moment(data.birthDate) : null,
    });
  }

  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row>
          <Col span={4}>
            <Form.Item label="SIN" name="sin">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item label="Card #" name="cardNumber">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={7}>
            <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label="Birth Date" name="birthDate">
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={3} offset={1}>
            <Form.Item label="Sex" name="sex">
              <Radio.Group>
              <Radio value="M">M</Radio>
              <Radio value="F">F</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">Address Information</Divider>

        <Row>
          <Col span={4}>
            <Form.Item label="Country" name="country" rules={[{ required: true, message: 'This field is required!' }]} >
              <Select placeholder="Select a Country" onChange={this.handleChangeCountry} showSearch
                filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}>
                {this.state.countries.map((country) => (
                  <Select.Option key={country.countryCode} value={country.countryCode}>{country.country}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item label="Province" name="province" rules={[{ required: !!this.state.selectedCountryProvinces , message: 'This field is required!' }]}>
              <Select placeholder="Select a Province" disabled={!this.state.selectedCountryProvinces} showSearch
                filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
                placeholder={!this.state.selectedCountryProvinces ? 'N/A' : "Select a Province"}>
                {(this.state.selectedCountryProvinces || []).map((province) => (
                  <Select.Option key={province.provinceCode} value={province.provinceCode}>{province.province}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item label="City" name="city" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label="Postal Code" name="postalCode" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={10}>
            <Form.Item label="Address" name="street1">
              <Input />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item label="Address 2" name="street2">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">Contact Information</Divider>

        <Row>
          <Col span={7}>
            {this._wrapIntoEmailPopover(
              <Form.Item label="Email" name="email" rules={[{ required: true, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            )}
          </Col>
          <Col span={5} offset={1}>
            <Form.Item label="Phone" name="phone">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
  /* private functions */
  _wrapIntoEmailPopover(toWrap) {
    if (this.props.app.isAdmin()) {
      return (<Popover trigger="focus" getPopupContainer={triggerNode => triggerNode.parentNode} content={
          <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '400px' }}>
            <Typography.Title level={4}>Important notes for email change:</Typography.Title>
            <ul>
              <li>This is the email employer will use for <b>online access</b>.</li>
              <li>In order to take effect the employer need to <u>logout</u> after the change.</li>
              <li>If wrong email is typed, email change can lock the employer out of the account.</li>
            </ul>
          </div>
        }
      >{toWrap}</Popover>);
    } else {
      return (<Popover getPopupContainer={triggerNode => triggerNode.parentNode} content={
          <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '400px' }}>
            <Typography.Title level={4}>Important notes for email change:</Typography.Title>
            <ul>
              <li>In order to take effect you <u>will be logged out</u> after changing the email.</li>
              <li>If wrong email is typed, email change can lock you out of your account.</li>
              <li>You will be asked to confirm the new email by entering a code sent on the new email.</li>
            </ul>
          </div>
        }
      >{toWrap}</Popover>);
    }
  }
}
