import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button, Table, message } from 'antd';
import moment from 'moment';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import config from '@/config/config';
//
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import Globals from "@/config/Globals";
import Utils from "@/components/Utils";
import TableActions from "@/views/commonComponents/TableActions";
import AdminMemberOptions, { AdminMemberOptionTypes } from '@/views/commonComponents/AdminMemberOptions';
//
import CommonMemberWorkHistoryCreateDrawer from './CommonMemberWorkHistoryCreateDrawer';
import CommonMemberWorkHistoryEditDrawer from './CommonMemberWorkHistoryEditDrawer';
//
export default class CommonMemberWorkHistoryView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    // Initialize variables
    this.memberName = null;
    this.memberID = null;
    this.viewOptions = null;

    if (this.props.app.isAdmin()) {
      this.memberName = this.props.app.urlManager.getQueryParam('n');
      this.memberID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
      this.viewOptions = new AdminMemberOptions(AdminMemberOptionTypes.WORK_HISTORY, this.memberID, this.props.app, this.memberName);
    }

    if (this.props.app.isMember()) {
      const ibewUser = this.props.app.sharedCache().getUser() || {};
      this.memberID = ibewUser.id;
    }

    const { workActivities, classifications } = this.props.app.sharedCache().getConfig();

    this.state = {
      isLoading: false,
      sortedInfo: null,
      data: [],
      activityCodes: workActivities || [],
      classifications: classifications || [],
    };
  }

  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Work History`;
    this._loadData();

    const operation = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Operation);
    if (operation === 'new') {
      this.createDrawer.show(this.memberID);
    }
  }

  handleCreate() {
    this.createDrawer.show(this.memberID);
  }

  handleEdit(item) {
    this.editDrawer.show(item.memberID, item.id);
  }

  handleDelete(item) {
    this._delete(item);
  }


  handleFilterChange(pagination, filters, sortedInfo) {
    this.setState({ sortedInfo });
  }

  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const props = {
      onChange: this.handleFilterChange, rowKey: 'id', loading: this.state.isLoading,
      scroll: { x: true },
    };

    const columns = [
      {
        title: 'Clearance Date', dataIndex: 'clearanceDate', key: 'clearanceDate',
        render: date => date ? moment(date).format(Globals.DefaultUIShortDateFormat) : '-',
      },
      {
        title: 'Start Date', dataIndex: 'startDate', key: 'startDate',
        render: date => date ? moment(date).format(Globals.DefaultUIShortDateFormat) : '-',
      },
      {
        title: 'End Date', dataIndex: 'endDate', key: 'endDate',
        render: date => date ? moment(date).format(Globals.DefaultUIShortDateFormat) : '-',
      },
      {
        title: 'Reg. Date', dataIndex: 'registerDate', key: 'registerDate',
        render: date => date ? moment(date).format(Globals.DefaultUIShortDateFormat) : '-',
      },
      {
        title: 'Reg. No.', dataIndex: 'registerNumber', key: 'registerNumber',
      },
      {
        title: 'Employee Number', dataIndex: 'employeeNumber', key: 'employeeNumber',
      },
      {
        title: 'Activity', dataIndex: 'activityID', key: 'activityID',
        render: code => {
          const activity = this.state.activityCodes.find((item) => item.id == code);
          return activity ? `${activity.activityCode}: ${activity.description}` : '-';
        },
      },
      {
        title: 'Employer', dataIndex: 'employerName', key: 'employerName',
      },
      {
        title: 'Job Classif.', dataIndex: 'classificationID', key: 'classificationID',
        render: code => {
          const classification = this.state.classifications.find((item) => item.id == code);
          return classification ? classification.name : '-';
        },
      },
      // {
      //   width: 130,
      //   title: 'Employee Number', dataIndex: 'employeeNumber', key: 'employeeNumber',
      // },
      {
        title: 'Location', dataIndex: 'workLocation', key: 'workLocation',
      },
    ];

    if (this.props.app.isAdmin()) {
      columns.push({
        title: '', key: 'Actions', align: 'right', fixed: 'right',
        render: props => (
          <span className='tableButtonContainer'>
            <TableActions
              options={[
                { alwaysVisible: true, label: 'Edit', icon: EditOutlined, onClick: this.handleEdit.bind(this, props) },
                {
                  alwaysVisible: true, label: 'Delete', icon: DeleteOutlined,
                  confirm: {
                    message: 'Are you sure you want to delete this item?',
                    onConfirm: this.handleDelete.bind(this, props),
                  },
                },
              ]}
            />
          </span>
        )
      });
    }

    const pageHeaderProps = {
      onBack: this.props.app.isAdmin() ? () => window.history.back() : null,
      extra: this.props.app.isAdmin()
        ? this.viewOptions.getOptions().concat([<Button key="save" type="primary" onClick={this.handleCreate}>Add Work History</Button>])
        : null,
    };

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <CommonMemberWorkHistoryCreateDrawer
          app={this.props.app} {...Utils.propagateRef(this, 'createDrawer')}
          activityCodes={this.state.activityCodes} classifications={this.state.classifications}
          onCreate={this._refreshList}
        />
        <CommonMemberWorkHistoryEditDrawer
          app={this.props.app} {...Utils.propagateRef(this, 'editDrawer')}
          activityCodes={this.state.activityCodes} classifications={this.state.classifications}
          onUpdate={this._refreshList}
        />
        <PageHeader title={this.memberName ? `${this.memberName}'s Work History` : 'Work History'} {...pageHeaderProps} />
        <Layout.Content>
          <WhiteBox>
            <Table
              className="squeeze-table-workhistory"
              columns={columns}
              dataSource={this.state.data}
              {...props}
            />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _loadData() {
    this.startLoading();
    await Utils.execRequests([this._fetch()]);
    this.stopLoading(true);
  }

  async _refreshList() {
    this.startLoading();
    await this._fetch();
    this.stopLoading(true);
  }

  async _fetch() {
    const resp = await this.props.app.api.memberWorkHistories.getAll(this.memberID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      let data = resp.body.workHistories;
      this.setState({ data });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }

  // API Actions
  async _delete(item) {
    this.startLoading();
    const resp = await this.props.app.api.memberWorkHistories.remove(item.memberID, item.id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Work History successfully deleted!');
      this._refreshList();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
