import React from "react";
import autoBind from 'react-autobind';
import * as XLSX from 'xlsx';
import { Upload, Spin, Alert, Button, message } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
//
import CustomComponent from '@/components/CustomComponent';
import Globals from '@/config/Globals';
import Utils from "@/components/Utils";
//
export default class UploadStep extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      file: null,
      isReading: false,
      exceededRowsLimit: false,
      isValid: false,
      errors: [],
      missingRequiredColumns: [],
    };
  }

  resetState() {
    this.setState({
      file: null,
      isReading: false,
      exceededRowsLimit: false,
      isValid: false,
      errors: [],
    });
  }

  async handleSelectFile({ file }) {
    if (!file) return;
    
    let ext = file.name?.split('.');
    ext = ext[ext.length - 1];
    if (ext?.toLowerCase() !== 'xlsx') {
      message.error('Only .XLSX files are accepted!');
      return;
    }
    
    try {
      this.resetState();
      this.setState({ isReading: true, errors: [], missingRequiredColumns: [] });
  
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  
      const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
  
      const newHeaders = headers.map(header => header.replace(/\s+/g, '').toLowerCase());
  
      for (let colIndex = 0; colIndex < newHeaders.length; colIndex++) {
        const cell = XLSX.utils.encode_cell({ r: 0, c: colIndex });
        worksheet[cell].v = newHeaders[colIndex];
      }
  
      const newWorkbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'file');
      const convertedBinaryFile = XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' });
      const convertedBlob = new Blob([convertedBinaryFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const convertedFile = new File([convertedBlob], file.name, { type: convertedBlob.type });
      const rows = await Utils.readXLSX(convertedFile);
      
      if (rows.length > Globals.Remittance_RowsLimit) {
        this.setState({ isReading: false, exceededRowsLimit: true });
        return;
      }

      const { errors, missingRequiredColumns, sum, isValid, extraColumns } = this._processData(rows);

      const invalidSins = [];
      if (Object.keys(errors).length === 0 && missingRequiredColumns.length === 0) {
        const invalidSinsRes = await this._validateSins(rows);

        if (invalidSinsRes.length > 0) {
          invalidSinsRes.forEach(sin => {
            const row = rows.find(member => Utils.extractNumbers(String(member.sin)) === Utils.extractNumbers(sin)) || { name: 'Member not registered', sin: `SIN: ${sin}` };
            invalidSins.push(row);
          });
        }
      }
      
      this.setState({ isReading: false, errors, missingRequiredColumns, isValid,  file: convertedFile });

      if (isValid) {
        this.props.onSelectValidFile({
          file: convertedFile, extraColumns, sum: { ...sum, members: rows.length }, invalidSins,
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({ isReading: false });
    }
  }

  render() {
    return (
      <>
        {this._renderErrors()}
        {this._renderUploadDragger()}
      </>
    )
  }

  _renderUploadDragger() {
    if (this.state.isReading) {
      return (
        <div>
          <Spin />
          <span style={{ marginLeft: 15 }}>
            We are processing your file, please wait...
          </span>
        </div>
      );
    }

    return (
      <Upload.Dragger beforeUpload={() => false} onChange={this.handleSelectFile} fileList={[]}>
        <p className="ant-upload-drag-icon">
          {<InboxOutlined />}
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Only .XLSX files are accepted.
        </p>
        <Button icon={<UploadOutlined />} style={{ marginTop: 10 }}>Select File...</Button>
      </Upload.Dragger>
    );
  }

  _renderErrors() {
    const { errors, missingRequiredColumns, exceededRowsLimit } = this.state;

    console.log({state: missingRequiredColumns})

    if (missingRequiredColumns.length > 0) {
      const moreThanOne = missingRequiredColumns.length > 1;

      return (
        <Alert
          message="Attention!"
          description={(
            <>
              Remittance file does not have the column{moreThanOne ? 's' : ''} {missingRequiredColumns.join(', ')}.
              <br />
              Please update your file with the correct columns and attempt your submission again.
            </>
          )}
          type="error"
          showIcon
          style={{ marginBottom: 20 }}
        />
      );
    }

    if (exceededRowsLimit) {
      return (
        <Alert
          message="Attention!"
          description={`You can't upload files with more than ${Globals.Remittance_RowsLimit} rows. If you need some help, contact support at TBD.`}
          type="error"
          showIcon
          style={{ marginBottom: 20 }}
        />
      );
    }

    if (Object.keys(errors).length > 0) {
      return (
        <Alert
          message="Error"
          description={
            <ul>
              {Object.keys(errors).map((row) => (
                <li key={row}>
                  {errors[row].length} problem{errors[row].length > 1 ? 's' : ''} found at <strong>row {row}</strong>: {errors[row].join(', ')}.
                </li>
              ))}
            </ul>
          }
          type="error"
          showIcon
          style={{ marginBottom: 20 }}
        />
      );
    }

    return null;
  }

  _processData(rows) {
    rows = this._sanitizeRows(rows);

    const errors = this._validateRows(rows);
    let sum = null;
    let isValid = false;

    const documentColumns = rows.reduce((acc, curr) => [...new Set([...acc, ...Object.keys(curr)])], []);
    // Detect extra columns
    const knownColumns = ['name', 'sin', 'employeeid', 'percapita', 'workingdues','initiationfee'];
    const extraColumns = documentColumns.filter(column => !knownColumns.includes(column));
    // Detect missing required columns
    const requiredColumns = ['name', 'sin', 'percapita', 'workingdues'];
    const missingRequiredColumns = requiredColumns.filter(column => !documentColumns.includes(column?.toLowerCase().trim()));

    console.log({documentColumns})

    if (Object.keys(errors).length < 1 && missingRequiredColumns.length < 1) {
      sum = this._sumRows(rows);
      isValid = true;
    }

    return { errors, missingRequiredColumns, sum, isValid, extraColumns };
  }

  async _validateSins(rows) {
    const sins = rows.reduce((acc, curr) => [...acc, Utils.extractNumbers(String(curr.sin))], []);
    
    const resp = await this.props.app.api.members.findMembersWithNoApplicationBySIN(sins);
    
    if (resp.statusCode != 200) {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return null;
    }

    return resp.body.matches || [];
  }

  _sanitizeRows(rows) {
    return rows.map((row) => {
      const newRow = {};
      Object.keys(row).forEach((key) => {
        newRow[key?.trim()?.toLocaleLowerCase()] = row[key]?.trim ? row[key]?.trim() : row[key];
      });
      return newRow;
    });
  }

  _validateRows(rows) {
    const errors = {};

    rows.forEach((row, index) => {
      // +2 because of row 1 = columns and index starts in zero
      const rowNumber = index + 2;

      if (!errors[rowNumber]) {
        errors[rowNumber] = [];
      }

      if (!row.name) {
        errors[rowNumber].push('Name is empty');
      }

      let sin = row.sin;
      sin = typeof sin !== 'string' ? String(sin) : sin;
      sin = sin ? sin.replace('-', '').replace(/\s+/g, '') : sin;

      if (sin.length < 9) {
        errors[rowNumber].push('Invalid SIN');
      }

      if (row.percapita && isNaN(row.percapita)) {
        if (row.percapita == 'formula') {
          errors[rowNumber].push(`Per Capita cannot be a formula`);
        } else {
          errors[rowNumber].push('Invalid Per Capita');
        }
      }

      if (row.workingdues && isNaN(row.workingdues)) {
        if (row.workingdues == 'formula') {
          errors[rowNumber].push(`Working Dues cannot be a formula`);
        } else {
          errors[rowNumber].push('Invalid Working Dues');
        }
      }

      if (row.initiationfee && isNaN(row.initiationfee)) {
        if (row.initiationfee == 'formula') {
          errors[rowNumber].push(`Initiation Fee cannot be a formula`);
        } else {
          errors[rowNumber].push('Invalid Initiation Fee');
        }
      }
    });

    Object.keys(errors).forEach((rowIndex) => {
      if (errors[rowIndex].length < 1) {
        delete errors[rowIndex];
      }
    });
    
    return errors;
  }

  _sumRows(rows) {
    const sum = {
      percapita: 0,
      workingdues: 0,
      initiationfee: 0,
    };

    rows.forEach((row) => {
      if (!isNaN(row.percapita)) {
        sum.percapita += Number(row.percapita);
      }
      
      if (!isNaN(row.workingdues)) {
        sum.workingdues += Number(row.workingdues);
      }
      
      if (!isNaN(row.initiationfee)) {
        sum.initiationfee += Number(row.initiationfee);
      }
    });

    sum.percapita = Number(sum.percapita.toFixed(2));
    sum.workingdues = Number(sum.workingdues.toFixed(2));
    sum.initiationfee = Number(sum.initiationfee.toFixed(2));

    return sum;
  }
}
