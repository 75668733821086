import React from "react";
import autoBind from 'react-autobind';
import { Form, Row, Col, Input, Select, Popover, Button, Divider, Typography, Checkbox, DatePicker, InputNumber, Tooltip, Tag } from 'antd';
import moment from 'moment';
//
import Utils from '@/components/Utils';
import Globals from '@/config/Globals';
import config from '@/config/config';
//
import '@/stylesheets/CommonMemberReadOnlyForm.scss';
import WhiteBox from "../WhiteBox";
//
export default class CommonMemberReadOnlyForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }
  //Actions
  handleEditMember() { this.props.app.urlManager.pushPage(config.ApplicationRoutes.memberUpdate, {}, this.state.id + ''); }
  //Form
  resetFields() {
    return this.form.resetFields();
  }
  setFieldsValue(data) {
    this.setState({ ...data });
    return this.form.setFieldsValue({
      ...data,
      birthDate: (data.birthDate && data.birthDate.length > 0 ? moment(data.birthDate) : ''),
      classificationDate: (data.classificationDate && data.classificationDate.length > 0 ? moment(data.classificationDate) : ''),
      initiationDate: (data.initiationDate && data.initiationDate.length > 0 ? moment(data.initiationDate) : ''),
      tqDate: (data.tqDate && data.tqDate.length > 0 ? moment(data.tqDate) : ''),
      ipDate: (data.ipDate && data.ipDate.length > 0 ? moment(data.ipDate) : ''),
      reinstatementDate: (data.reinstatementDate && data.reinstatementDate.length > 0 ? moment(data.reinstatementDate) : ''),
      priorStatusDate: (data.priorStatusDate && data.priorStatusDate.length > 0 ? moment(data.priorStatusDate) : ''),
      currentStatusDate: (data.currentStatusDate && data.currentStatusDate.length > 0 ? moment(data.currentStatusDate) : ''),
      duesPaidThru: (data.duesPaidThru && data.duesPaidThru.length > 0 ? moment(data.duesPaidThru) : ''),
      currentEmployerRegisterDate: (data.currentEmployerRegisterDate && data.currentEmployerRegisterDate.length > 0 ? moment(data.currentEmployerRegisterDate) : ''),
      lastDuesEntryMonthsPaid: data.lastDuesEntryMonthsPaid || 'N/A',
      priorStatusCodeDescription: data?.priorStatusCodeDescription || 'N/A',
      currentStatusCodeDescription: data?.currentStatusCodeDescription || 'N/A', 
      currentEmployerName: data?.currentEmployerName || 'N/A',
      memberType: data?.memberType || '',
      lastDuesEntryLU: data?.lastDuesEntryLU || 0, lastDuesEntryIO: data?.lastDuesEntryIO || 0,
      unitNumberID: data?.unitNumberID && data?.unitDescription ? `${data?.unitNumberID} (${data?.unitDescription})` : 'N/A',
      phone: Utils.formatPhone(data.phone), cell: Utils.formatPhone(data.cell), pager: Utils.formatPhone(data.pager),
    });
  }
  //
  render() {
    const { memberClassifications } = this.props.app.sharedCache().getConfig();
    return (
      <Form layout="inline" {...Utils.propagateRef(this, 'form')} className='memberReadOnlyForm'>
        <Divider orientation='left' style={{margin: 0}}>Member Information {this.props.extraHeaderContent} </Divider>
        <Row type='flex'>
          <Col>
            <Form.Item label="Name" name="name">
              <Button type='link' onClick={this.handleEditMember}>
                {this.state.firstName ? `${this.state.firstName}${(this.state.middleName ? ` ${this.state.middleName}` : '')}${(this.state.lastName ? ` ${this.state.lastName}` : '')}` : ''}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={8}>
            <Form.Item label="SIN" name="sin">
              <Input disabled bordered={false}/>
            </Form.Item>
          </Col>
          <Col span={8} style={{ marginLeft: '20px' }}>
            <Form.Item label="Card No." name="cardNumber" rules={[{ required: false }]}>
              <Input maxLength={80} disabled bordered={false}/>
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label="Member Type" name="memberType" rules={[{ required: false }]}>
              <Popover title="Member Type" content={
                <ul style={{ padding: '0 0 0 10px' }}>
                  <li><b>Current Type</b>: {this.state.memberType || ''} - {this.props.app.sharedCache().getMemberStatusByTypeCode(this.state.memberType)?.description || 'N/A'}</li>
                </ul>
              }>
                <Tag color="purple" style={{ marginLeft: '20px '}}>{(this.state.memberType || '')}</Tag>
              </Popover>
            </Form.Item>
          </Col>
        </Row>    
        <Row type='flex'>
          <Col span={24}>
            {/* TODO: check field */}
            <Form.Item label="Out of Jurisdiction" name="outOfJurisdiction">
              <Typography.Text>{!!this.state.outOfJurisdiction ? 'Yes' : 'No'}</Typography.Text>
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={24} style={{ marginLeft: '65px' }}>
            <Form.Item label="Status" name="classificationID" >
              <Select placeholder="Select a classification" showSearch bordered={false} disabled
                filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}>
                <Select.Option key={'empty'} value={null}><div style={{ opacity: .5 }}>None</div></Select.Option>
                {(memberClassifications || []).map((classification) => (
                  <Select.Option key={classification.id} value={classification.name}>{classification.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left" style={{ margin: '4px' }}/>
        <Row type='flex'>
          <Col>
            <Form.Item label="Current Activ. Date" name="currentStatusDate">
              <DatePicker bordered={false} disabled bordered={false} format={Globals.DefaultUIBirthDateFormat} placeholder="N/A" style={{width: '200px'}}/>
            </Form.Item>
          </Col>
        </Row><Row type='flex'>
          <Col span={24}>
            <Form.Item label="Current Activity" name="currentStatusCodeDescription">
              <Input bordered={false} disabled bordered={false} style={{ minWidth: '300px' }}/>
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={24}>
            <Form.Item label="Initiation Fee Paid" name="initiationFeePaid" rules={[{ required: false }]}>
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} disabled bordered={false} style={{ minWidth: '100px' }}/>
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col style={{marginLeft: '1px'}}>
            <Form.Item label="Suspense Bal." name="suspenseBalance">
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} disabled bordered={false} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item label="Comments" name="comments">
              <Typography.Text mark disabled style={{ width: '100%', opacity: .5 }}>
                {this.state.comments}
              </Typography.Text>
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left" style={{ margin: '4px' }}/>
        <Row type='flex'>
          <Col col={24}>
            <Form.Item label="Date Paid Thru" name="duesPaidThru" rules={[{ required: false }]}>
              <DatePicker format={Globals.DefaultUIBirthDateFormat} disabled bordered={false} style={{ width: '360px' }}/>
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col>
            <Form.Item label="LU Arrears" name="luArrears">
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser}
                           disabled bordered={false}/>
            </Form.Item>
            <Form.Item label="Months Arrears" name="monthsArrears">
              <InputNumber disabled formatter={Globals.MonthInput_Validator} parser={Globals.MonthInput_Parser} bordered={false} />
            </Form.Item>
          </Col>
        </Row><Row type='flex'>
          <Col offset={1}>
            <Form.Item label="IO Arrears" name="ioArrears">
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser}
                           disabled bordered={false}/>
            </Form.Item>
          </Col>
        </Row>
        <Tag style={{ marginLeft: '10px', marginTop: '-25px' }} className='monthToDateBox'>
          <Row type='flex'>
            <Col>
              <Typography.Text strong>This Month to Date</Typography.Text>
            </Col>
          </Row><Row type='flex'>
            <Col>
              <Form.Item label="IO" name="lastDuesEntryIO">
                <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} bordered={false} disabled />
              </Form.Item>
            </Col>
          </Row><Row type='flex'>
            <Col>
              <Form.Item label="LU" name="lastDuesEntryLU">
                <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} bordered={false} disabled />
              </Form.Item>
            </Col>
          </Row>
        </Tag>
      </Form>
    )
  }
}
