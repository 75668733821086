import Globals from '@/config/Globals';
//
export default class APIReports {
  constructor(API) {
    this.api = API;
  }

  async membersArrears(filters, contentType) {
    const req = await this.api.newBaseRequest('POST');
    req.appendHeader('Accept', contentType);
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'membersArrears';
    req.body = { ...filters };
    return await req.exec();
  }

  async membersArrearsLetter(filters, contentType) {
    const req = await this.api.newBaseRequest('POST');
    req.appendHeader('Accept', contentType);
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'membersArrearsLetter';
    req.body = { ...filters };
    return await req.exec();
  }

  async employmentByClassification(filters) {
    const req = await this.api.newBaseRequest('POST');
    req.appendHeader('Accept', 'application/pdf');
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'employmentByClassification';
    req.body = { ...filters };
    return await req.exec();
  }

  async membersLabel(filters, contentType) {
    const req = await this.api.newBaseRequest('POST');
    req.appendHeader('Accept', contentType);
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'membersLabel';
    req.body = { ...filters };
    return await req.exec();
  }

  async memberLabel(memberID, contentType) {
    const req = await this.api.newBaseRequest('POST');
    req.appendHeader('Accept', contentType);
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'memberLabel';
    req.body = { memberID };
    return await req.exec();
  }

  async membersIoReport({ startDate, endDate }) {
    const req = await this.api.newBaseRequest('POST');
    req.appendHeader('Accept', 'application/pdf');
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'ioReport';
    req.body = { startDate, endDate };
    return await req.exec();
  }

  async employerLabel(employerID, contentType) {
    const req = await this.api.newBaseRequest('POST');
    req.appendHeader('Accept', contentType);
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'employerLabel';
    req.body = { employerID };
    return await req.exec();
  }
  
  async membersByClassification(filters, contentType) {
    const req = await this.api.newBaseRequest('POST');
    req.appendHeader('Accept', contentType);
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'membersByClassification';
    req.body = { ...filters };
    return await req.exec();
  }
  
  async membersByEmployer(filters, contentType) {
    const req = await this.api.newBaseRequest('POST');
    req.appendHeader('Accept', contentType);
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'membersByEmployer';
    req.body = { ...filters };
    return await req.exec();
  }
  
  async membersBySector(filters, contentType) {
    const req = await this.api.newBaseRequest('POST');
    req.appendHeader('Accept', contentType);
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'membersBySector';
    req.body = { ...filters };
    return await req.exec();
  }
  
  async membersCustom(filters, contentType) {
    const req = await this.api.newBaseRequest('POST');
    req.appendHeader('Accept', contentType);
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'membersCustom';
    req.body = { ...filters };
    return await req.exec();
  }

  async activeMembers(isRaw) {
    const req = await this.api.newBaseRequest('POST');
    if (!isRaw) req.bodyType = 'JSON/BLOB';
    else {
      req.appendHeader('Accept', 'application/json');
      req.bodyType = 'JSON';
    }
    req.path = Globals.APIPath_Report + 'activeMembers';
    return await req.exec();
  }
  
  async duesPosting(filters, contentType) {
    const req = await this.api.newBaseRequest('POST');
    req.appendHeader('Accept', contentType);
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'duesPosting';
    req.body = { ...filters };
    return await req.exec();
  }
  
  async employersLabel(filters, contentType) {
    const req = await this.api.newBaseRequest('POST');
    req.appendHeader('Accept', contentType);
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'employersLabel';
    req.body = { ...filters };
    return await req.exec();
  }

  async shopStewards(filters, contentType) {
    const req = await this.api.newBaseRequest('POST');
    req.appendHeader('Accept', contentType);
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'shopStewards';
    req.body = { ...filters };
    return await req.exec();
  }

  async dispatchList(filters, contentType) {
    const req = await this.api.newBaseRequest('POST');
    req.appendHeader('Accept', contentType);
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'dispatchList';
    req.body = { ...filters };
    return await req.exec();
  }

  async membersTaxReceipts() {
    const req = await this.api.newBaseRequest('POST');
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'taxReceipts';
    return await req.exec();
  }

  async membersDuesYTD() {
    const req = await this.api.newBaseRequest('POST');
    req.bodyType = 'JSON/BLOB';
    req.path = Globals.APIPath_Report + 'membersDuesYTD';
    return await req.exec();
  }
}
