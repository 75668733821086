import React from "react";
import { Modal, Form, Row, Col, Input, message, DatePicker, Checkbox, Select, InputNumber } from 'antd';
import autoBind from 'react-autobind';
//
import CustomComponent from '@/components/CustomComponent';
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
import Utils from "@/components/Utils";
import Globals from "@/config/Globals";
//
export default class AdminApplicationApproveModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isVisible: false,
      isLoading: false,
      applicationID: null,
    };
  }

  show(applicationID) {
    this.setState({ isVisible: true, applicationID });
  }

  handleClose() {
    this.setState({
      isVisible: false,
      isLoading: false,
      applicationID: null,
    });
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();
    
    if (formData) {
      formData.isOnlineActive = !!formData.isOnlineActive;

      this._approve(formData);
    }
  }

  // UI
  render() {
    return (
      <Modal
        visible={this.state.isVisible}
        title="Approve Application"
        onCancel={this.handleClose}
        onOk={this.handleSubmit}
        okText="Approve"
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row>
            <Col span={24}>
              <Form.Item name="cardNumber" label="Card number" rules={[{ required: true, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={7}>
              <Form.Item name="initiationDate" label="Initiation date" rules={[{ required: true, message: 'This field is required!' }]}>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item name="initiationFeePaid" label="Initiation fee paid" rules={[{ required: true, message: 'This field is required!' }]}>
                <InputNumber
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name="isOnlineActive" label="Allow online access" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={6}>
              <Form.Item name="memberType" label="Member type" rules={[{ required: true, message: 'This field is required!' }]}>
                <Select placeholder="Select...">
                  {Object.keys(Globals.MemberStatuses).map((status) => (
                    <Select.Option key={status} value={status}>
                      {status}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={17} offset={1}>
              <Form.Item name="classification" label="Classification">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }

  // API Calls
  async _approve(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.applications.approve(this.state.applicationID, data);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.handleClose();
      message.success('Application succesfully approved!');
      if (this.props.onApprove) this.props.onApprove();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
