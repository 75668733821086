import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Button, Select } from 'antd';
import {
  IssuesCloseOutlined, CheckCircleOutlined, CloseCircleOutlined, EditOutlined,
  ToolOutlined, PoweroffOutlined, DollarOutlined, FileTextOutlined, ContactsOutlined, TeamOutlined,
} from '@ant-design/icons';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import TableActions from '@/views/commonComponents/TableActions';
import Utils from "@/components/Utils";
import config from '@/config/config';
import Globals from "@/config/Globals";
//
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import CommonSetPasswordModal from '@/views/commonComponents/CommonSetPasswordModal';
import CommonSearchBar from '@/views/commonComponents/CommonSearchBar';
//
export default class AdminEmployersView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false, data: [],
      sortedInfo: null, ...this._getInitialState(),
    };
  }
  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Employers`;
    this._fetch();
  }
  //Actions
    //Search actions
  handleSearch(term) {
    this.setState(prevState => ({
      ...prevState,
      searchObj: { ...prevState.searchObj, term },
      sortedInfo: null,
      currentPage: 1
    }), this._fetch);
  }
  handleChangeFilter(field) {
    return (value) => {
      this.setState(prevState => ({
        ...prevState,
        searchObj: {
          ...prevState.searchObj,
          [field]: value,
        },
      }));
    };
  }
    //Table + add button actions
  handleCreateEmployer() { this.props.app.urlManager.pushPage(config.ApplicationRoutes.employersCreate); }
  handleFilterChange(pagination, filters, sortedInfo) { this.setState({ sortedInfo }, this._fetch); }
  handlePagination(currentPage) { this.setState({ currentPage }, this._fetch); }
    //Rows actions
  handleActivateUser(userObj) { this._activateUser(userObj); }
  handleToggleEnable(userObj) {
    if (userObj.idmDisabled) this._enableUser(userObj);
    else this._disableUser(userObj);
  }
  handleInvalidatePassword(userObj) { this._resetUsersPassword(userObj); }
  handleSetUserPassword(userObj) { this.changePassModal.handleShow(userObj.idmID); }
  handleUserEdit(userObj) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.employersUpdate, null, userObj.id); }
  handleEmployees(userObj) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.members, { [Globals.URLQueryParam_Employer]: userObj.id, n: userObj.name }); }
  handleEmployerRemittances(userObj) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.employerRemittances, { n: userObj.name }, userObj.id); }
  handleNotes(userObj) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.notes, { n: encodeURIComponent(userObj.name) }, Globals.Notes_relTypes.employer.routeAlias, userObj.id);
  }
  handleContacts(userObj) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.contacts, { n: encodeURIComponent(userObj.name) }, userObj.id);
  }

  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const props = {
      onChange: this.handleFilterChange, rowKey: 'id', loading: this.props.isLoading,
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage, showSizeChanger: false, hideOnSinglePage: true, position: 'bottom',
        total: this.state.total, onChange: this.handlePagination, current: this.state.currentPage,
      }
    };

    const columns = [
      { title: 'Name', dataIndex: 'name', key: 'name', width: 200,
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order
      },
      {
        title: 'Employer number', dataIndex: 'companyNumber', key: 'companyNumber', width: 200,
        sorter: (a, b) => a.companyNumber.localeCompare(b.companyNumber),
        sortOrder: sortedInfo.columnKey === 'companyNumber' && sortedInfo.order
      },
      { title: 'Phone', dataIndex: 'phone', key: 'phone', width: 150,
        render: (phone) => Utils.formatPhone(phone),
        sorter: (a, b) => a.phone.localeCompare(b.phone),
        sortOrder: sortedInfo.columnKey === 'phone' && sortedInfo.order
      },
      {
        title: 'Status', dataIndex: 'disabled', key: 'disabled', width: 200,
        sorter: (a, b) => a.disabled - b.disabled,
        render: (disabled) => (disabled ? 'Inactive' : 'Active'),
        sortOrder: sortedInfo.columnKey === 'disabled' && sortedInfo.order
      },
      { title: 'Actions', width: 200, key: 'Actions', align: 'right',
        render: props => {
          return (<span className='tableButtonContainer'>
                    <TableActions
                      options={[
                        { label: 'Remittances', icon: DollarOutlined, onClick: this.handleEmployerRemittances.bind(this, props) },
                        { label: 'Notes', icon: FileTextOutlined, onClick: this.handleNotes.bind(this, props) },
                        { label: 'Contacts', icon: ContactsOutlined, onClick: this.handleContacts.bind(this, props) },
                        { label: 'Set password', icon: ToolOutlined, onClick: this.handleSetUserPassword.bind(this, props), hide: !props.idmID },
                        {
                          label: 'Invalidate password', icon: IssuesCloseOutlined, hide: !props.idmID,
                          confirm: {
                            message: `Do you really want to invalidate employer '${props.name}' password?`,
                            onConfirm: this.handleInvalidatePassword.bind(this, props),
                          },
                        },
                        {
                          label: (props.idmDisabled ? 'Enable Online ID': 'Disable Online ID'),
                          icon: props.idmDisabled ? CheckCircleOutlined : CloseCircleOutlined, hide: !props.idmID,
                          confirm: {
                            message: `Do you really want to ${props.idmDisabled ? "enable" : "disable"} employer '${props.name}'?`,
                            onConfirm: this.handleToggleEnable.bind(this, props),
                          },
                        },
                        {
                          alwaysVisible: true, label: 'Activate Online ID', icon: PoweroffOutlined, hide: !!props.idmID,
                          confirm: {
                            message: `Do you really want to activate employer '${props.name}'?`,
                            onConfirm: this.handleActivateUser.bind(this, props),
                          },
                        },
                        { alwaysVisible: true, label: 'Edit employer', icon: EditOutlined, onClick: this.handleUserEdit.bind(this, props) },
                        { alwaysVisible: true, label: 'Employees', icon: TeamOutlined, onClick: this.handleEmployees.bind(this, props) },
                      ]}
                    />
                  </span>);
        }
      },
    ];

    const { admins, schedules } = this.props.app.sharedCache().getConfig();

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <CommonSetPasswordModal {...Utils.propagateRef(this, 'changePassModal')} app={this.props.app} alertController={this.props.app.alertController}/>
        <PageHeader title="Employers" extra={[<Button key="1" onClick={this.handleCreateEmployer} type='primary'>Create Employer</Button>]} />
        <Layout.Content>
          <CommonSearchBar
            alwaysEnabled
            defaultValue={this.state.searchObj.term}
            handleSearch={this.handleSearch}
            extra={(
              <>
                <Select
                  placeholder="Business Agent"
                  showSearch
                  filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
                  onChange={this.handleChangeFilter('businessAgentID')}
                >
                  {admins.map((admin) => (
                    <Select.Option key={admin.id} value={admin.id}>
                      {admin.firstName} {admin.lastName}
                    </Select.Option>
                  ))}
                </Select>{' '}
                <Select
                  placeholder="Schedule"
                  showSearch
                  filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
                  onChange={this.handleChangeFilter('scheduleID')}
                >
                  {schedules.map((schedule) => (
                    <Select.Option key={schedule.id} value={schedule.id}>
                      {schedule.name}
                    </Select.Option>
                  ))}
                </Select>
              </>
            )}
          />
          <WhiteBox>
            <Table
              dataSource={this.state.data}
              columns={columns}
              {...props}
            />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // Utils
  _getInitialState() {
    const search = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SearchTerm) || null;
    const currentPage = parseInt(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Page) || 1);
    const columnKey = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SortField) || 'name';
    const order = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SortOrder) || 'ascend';
    const businessAgentID = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_BusinessAgent) || '';
    const scheduleID = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Schedule) || '';

    return {
      searchObj: { term: search, businessAgentID, scheduleID },
      currentPage, sortedInfo: { columnKey, order },
      total: 0
    };
  }

  _setSearchQueryParams() {
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_Page, this.state.currentPage);
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SearchTerm, (this.state.searchObj.term || null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_BusinessAgent, (this.state.searchObj.businessAgentID || null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_Schedule, (this.state.searchObj.scheduleID || null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SortField, (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.columnKey : ''));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SortOrder, (this.state.sortedInfo ? this.state.sortedInfo.order : null));
  }

  _getSearchFilterObject() {
    //page
    const from = (Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1));
    //sort
    const sortField = (this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey ? this.state.sortedInfo.columnKey : '');
    const sortOrder = (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : '');
    return {
      term: this.state.searchObj.term || '',
      filter: {
        from,
        sortField,
        sortOrder: (sortOrder == 'descend' ? 'desc' : 'asc'),
        businessAgentID: Number(this.state.searchObj.businessAgentID) || '',
        scheduleID: Number(this.state.searchObj.scheduleID) || '',
      },
    };
  }

  // API calls
  async _fetch() {
    if (!this._isMounted) return;
    //
    this.setState({ data: [], total: 0, isLoading: true });
    this._setSearchQueryParams();
    //request
    const resp = await this.props.app.api.employers.search(this._getSearchFilterObject());
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.employers) {
      this.setState({ data: resp.body.employers, total: resp.body.total, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

 async _activateUser(userObj) {
    this.startLoading();
    const resp = await this.props.app.api.employers.activate(userObj.id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', `Employer '${userObj.name}' activated with success! We've emailed the user a temporary password.`);
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _enableUser(userObj) {
    this.startLoading();
    const resp = await this.props.app.idm.api.user.enable(userObj.idmID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', `Employer '${userObj.name}' enabled with success! This process can take up to a minute and change may not be immediately reflected on the table below.`);
      this._fetch();
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _disableUser(userObj) {
    this.startLoading();
    const resp = await this.props.app.idm.api.user.disable(userObj.idmID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', `Employer '${userObj.name}' disabled with success! This process can take up to a minute and change may not be immediately reflected on the table below.`);
      this._fetch();
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _resetUsersPassword(userObj) {
    this.startLoading();
    const resetResp = await this.props.app.idm.api.password.reset(userObj.email);
    if (!this._isMounted) return;
    if (resetResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', `Employer '${userObj.name}' password invalidated with success!`);
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resetResp);
      this.stopLoading(true);
    }
  }
}
