import React from "react";
import autoBind from 'react-autobind';
import moment from 'moment';
//
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import CustomComponent from '@/components/CustomComponent';
import AdminNotesForm from './Forms/AdminNotesForm';
//
import { message, Drawer, Row, Col, Button } from 'antd';
import Utils from "@/components/Utils";
//
export default class AdminEditNotesDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      isVisible: false,
      noteID: null,
      data: {},
    };
  }

  show(noteID) {
    this.setState({ isVisible: true, noteID }, this._fetch);
  }

  handleClose() {
    this.form.resetFields();
    this.setState({
      isLoading: false,
      isVisible: false,
      noteID: null,
      data: {},
    });
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();

    if (formData) {
      this._update(formData);
    }
  }

  render() {
    return (
      <Drawer
        visible={this.state.isVisible}
        title="Edit Note"
        placement="right"
        onClose={this.handleClose}
        width={600}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <AdminNotesForm {...Utils.propagateRef(this, 'form')} />
        <Row>
          <Col>
            <Button type="primary" onClick={this.handleSubmit}>Save</Button>
          </Col>
        </Row>
      </Drawer>
    )
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.notes.getByID(this.state.noteID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      const data = resp.body;
      this.setState({ data, isLoading: false });
      this.form.setFieldsValue({
        ...resp.body,
        reminderDate: data.reminderDate ? moment(data.reminderDate) : null,
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  
  async _update(data) {
    this.startLoading();
    //request
    const payload = {
      ...this.state.data,
      ...data,
      reminderDate: data.reminderDate ? moment(data.reminderDate).format('YYYY-MM-DD') : null,
      private: !!data.private,
      urgent: !!data.urgent,
    };
    //
    delete payload.secondaryRelName;
    delete payload.secondaryRelID;
    delete payload.userID;
    delete payload.createdOn;
    delete payload.transactionID;
    //
    const resp = await this.props.app.api.notes.update(payload);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Note successfully updated!');
      if (this.props.onUpdate) this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading(true);
  }
}
