import React from "react";
import { Layout, Row, Col } from 'antd';
import autoBind from 'react-autobind';
import { IDMGlobals } from "@ikonintegration/idmclient";
//
import Utils from '@/components/Utils';
import config from "@/config/config";
import Globals from "@/config/Globals";
//
import Logo from '../commonComponents/Logo';
import CustomComponent from "@/components/CustomComponent";
import CommonLoadingView from "../commonComponents/CommonLoadingView";
import CommonRegistrationConfirmationForm from "./Forms/CommonRegistrationConfirmationForm";
import CommonBoxLinks from '../commonComponents/CommonBoxLinks';
//
import "@/stylesheets/CommonRegistration.scss";
//
export default class CommonRegistrationConfirmationView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const regToken = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    const email = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID2_Placeholder, this);
    this.state = { data:{ regToken: (regToken != Globals.URL_Path_ID_Placeholder ? regToken : ''), email: email }, isLoading: false};
  }

  componentDidMount() {
    document.title = `Confirm your ${config.ApplicationName} account`;
    super.componentDidMount();
    //Check if have params from email
    const token = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this) || /* comp. */ this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_RegConfirmationToken);
    const code = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_RegConfirmationCode);
    const email = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID2_Placeholder, this);
    if (token && code) {
      this.state.data.regToken = token;
      this.state.data.regCode = code;
      if (email) this.state.data.email = email;
      this.setState(this.state);
      this._confirmUser(); //try to confirm user
    }
  }

  //Actions handlers
  handleResendConfirmation() { this.props.app.urlManager.pushPage(config.ApplicationRoutes.registrationConfirmationReset, null, this.state.data.email); }
  async handleConfirmation(e) {
    const data = await this.form.validatedForm();
    if (!data) return;
    this._confirmUser(data);
  }
  //UI
  render() {
    let isLoading = this.state.isLoading || this.props.app.isAuthenticating;
    return (
      <Layout.Content className='pageContentFullScreen'>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <Layout.Content className='registerContainer'>
          <Row type="flex" justify="center" align="middle" className='authLogoRow'><Col align="center"> <Logo /> </Col></Row>
          <Row type="flex" justify="center" align="middle">
            <Col span={18} className='registerConfirmationBoxContainer'>
              <div className='registerBox'>
                <Row> <Col align="center"> <h5 className="registerBoxTitle">Confirm your {config.ApplicationName} account</h5> </Col> </Row>
                <CommonRegistrationConfirmationForm handleConfirmation={this.handleConfirmation} onResend={this.handleResendConfirmation} dataSource={this} {...Utils.propagateRef(this, 'form')}/>
              </div>
            </Col>
          </Row>
          <CommonBoxLinks width={500}/>
        </Layout.Content>
      </Layout.Content>
    );
  }

  async _confirmUser(data) {
    this.startLoading();
    //Confirm registration
    const { regToken, regCode, email } = { ...this.state.data, ...data };
    const confirmationResp = await this.props.app.idm.api.registrationConfirmation.confirm(regToken, regCode, email);
    if (confirmationResp.statusCode == 200) {
      if (confirmationResp.body.redirectURL) {
        console.debug('Setting redirect URL', confirmationResp.body.redirectURL);
        this.props.app.idm.impersonate.setCallbackURL(confirmationResp.body.redirectURL);
      }
      this.props.app.alertController.showSuccessAlert("", "Registration is confirmed!");
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.login, { [IDMGlobals.URLQueryParam_UserEmail]: this.state.data.email });
    } else if (confirmationResp.statusCode == 204) {
      this.props.app.alertController.showSuccessAlert("", "Your account is already confirmed!");
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.login, { [IDMGlobals.URLQueryParam_UserEmail]: this.state.data.email });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, confirmationResp);
      this.stopLoading();
    }
  }
}
