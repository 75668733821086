import React from "react";
import autoBind from 'react-autobind';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import Utils from '@/components/Utils';
import config from '@/config/config';
//
import { Layout, PageHeader, Form, Input, Button, Row, Col, Divider, Table, Tooltip, Popconfirm, message, Checkbox, Tag } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
export default class AdminStatusCodeView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      data: [],
      isEditing: false,
      editingObject: {},
      sortedInfo: null,
    };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Status (Activity) Codes`;
    this._fetch();
  }

  handleManageClick(option) {
    this.props.app.urlManager.pushPage(option.route);
  }

  handleDelete(item) {
    this._delete(item.id);
  }

  handleEdit(item) {
    this.form.setFieldsValue(item);
    this.setState({ isEditing: true, editingObject: item });
  }

  handleCancelEdit() {
    this.form.resetFields();
    this.setState({ isEditing: false, editingObject: null });
  }

  async handleAddOrSave() {
    const formData = await this.form.validateFields();
    if (this.state.isEditing) this._edit(formData);
    else this._add(formData);
  }

  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const props = { onChange: this.handleFilterChange, rowKey: 'id', loading: this.props.isLoading };

    const columns = [
      { title: 'Code', dataIndex: 'statusCode', key: 'statusCode', width: 50,
        sorter: (a, b) => a.statusCode.localeCompare(b.statusCode),
        sortOrder: sortedInfo.columnKey === 'statusCode' && sortedInfo.order
      },
      { title: 'Description', dataIndex: 'description', key: 'description', width: 150,
        sorter: (a, b) => a.description.localeCompare(b.description),
        sortOrder: sortedInfo.columnKey === 'description' && sortedInfo.order
      },
      { title: 'Type', dataIndex: 'memberType', key: 'memberType', width: 50,
        sorter: (a, b) => a.memberType.localeCompare(b.memberType),
        sortOrder: sortedInfo.columnKey === 'memberType' && sortedInfo.order
      },
      {
        title: 'Remittance', dataIndex: 'acceptInRemittance', key: 'acceptInRemittance', width: 150,
        render: (acceptInRemittance) => acceptInRemittance ? <Tag color="green">Enabled</Tag> : <Tag color="red">Disabled</Tag>,
        sorter: (a, b) => (a.acceptInRemittance ? 'active' : 'inactive').localeCompare(b.acceptInRemittance ? 'active' : 'inactive'),
        sortOrder: sortedInfo.columnKey === 'acceptInRemittance' && sortedInfo.order
      },
      {
        title: 'IO to Suspense', dataIndex: 'moveIoToSuspense', key: 'moveIoToSuspense', width: 150,
        render: (moveIoToSuspense) => moveIoToSuspense ? <Tag color="green">Enabled</Tag> : <Tag color="red">Disabled</Tag>,
        sorter: (a, b) => (a.moveIoToSuspense ? 'active' : 'inactive').localeCompare(b.moveIoToSuspense ? 'active' : 'inactive'),
        sortOrder: sortedInfo.columnKey === 'moveIoToSuspense' && sortedInfo.order
      },
      { title: 'Status', dataIndex: 'disabled', key: 'disabled', width: 150,
        render: (disabled) => disabled ? <Tag color="red">Inactive</Tag> : <Tag color="green">Active</Tag>,
        sorter: (a, b) => (a.disabled ? 'active' : 'inactive').localeCompare(b.disabled ? 'active' : 'inactive'),
        sortOrder: sortedInfo.columnKey === 'disabled' && sortedInfo.order
      },
      { title: 'Actions', width: 80, key: 'Actions',
        render: props => {
          return (<span className='tableButtonContainer'>
                    <Tooltip placement="bottomLeft" title='Delete'>
                      <Popconfirm title={`Do you really want to delete this status code?`} placement="bottomRight" onConfirm={this.handleDelete.bind(this, props)} okText="Yes" cancelText="No">
                        <Button variant="none" icon={<DeleteOutlined />} shape="circle"/>
                      </Popconfirm>
                    </Tooltip>{' '}
                    <Tooltip placement="bottomLeft" title='Edit'>
                      <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleEdit.bind(this, props)}/>
                    </Tooltip>
                  </span>);
        }
      }
    ];

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Status (Activity) Codes" onBack={() => window.history.back()} />
        <Layout.Content>
          <WhiteBox>
            <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
              <Row gutter={24}>
                <Col span={3}>
                  <Form.Item name="statusCode" label="Code" rules={[{ required: true, message: 'Please, type the code!' }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please, type the description!' }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Tooltip title="Leave blank if this code applies to all members.">
                    <Form.Item name="memberType" label="Member Type" rules={[{ max: 2, message: 'Member type must have only 2 characters' }]}>
                      <Input />
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col span={4}>
                  <Tooltip title="Members with this status are inactive.">
                    <Form.Item label="Inactive" name="disabled" valuePropName="checked">
                      <Checkbox id="disabled" />
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col span={3}>
                  <Form.Item label="Remittance" name="acceptInRemittance" valuePropName="checked">
                    <Checkbox id="acceptInRemittance" />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="IO to Suspense" name="moveIoToSuspense" valuePropName="checked">
                    <Checkbox id="moveIoToSuspense" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={4}>
                  <Button type="primary" onClick={this.handleAddOrSave}>
                    {this.state.isEditing ? 'Save' : 'Add'}
                  </Button>
                  {this.state.isEditing && (
                    <Button type="link" onClick={this.handleCancelEdit}>Cancel</Button>
                  )}
                </Col>
              </Row>
            </Form>

            <Divider />

            <Table style={{ opacity: this.state.isEditing ? 0.3 : 1 }} dataSource={this.state.data} columns={columns} {...props} />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.memberStatus.getAll();
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.statuses) {
      this.setState({ data: resp.body.statuses, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _add(data) {
    this.startLoading();
    //request

    if (!data.memberType) data.memberType = '';

    const resp = await this.props.app.api.memberStatus.create({
      ...data,
      disabled: !!data.disabled,
      acceptInRemittance: !!data.acceptInRemittance,
      moveIoToSuspense: !!data.moveIoToSuspense,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Status successfully created!');
      this.props.app.refreshConfig();
      this.form.resetFields();
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _edit(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.memberStatus.update({
      id: this.state.editingObject.id,
      ...data,
      disabled: !!data.disabled,
      acceptInRemittance: !!data.acceptInRemittance,
      moveIoToSuspense: !!data.moveIoToSuspense,
    });

    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Status successfully updated!');
      this.props.app.refreshConfig();
      this.form.resetFields();
      this.handleCancelEdit();
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _delete(id) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.memberStatus.remove(id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Status successfully removed!');
      this.props.app.refreshConfig();
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
