import React from "react";
import { Layout, Button, Row, Col, Form, Input } from 'antd';
import autoBind from 'react-autobind';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { IDMGlobals } from "@ikonintegration/idmclient";
//
import Logo from '../commonComponents/Logo';
import CustomComponent from "@/components/CustomComponent";
import CommonLoadingView from "../commonComponents/CommonLoadingView";
import Utils from '@/components/Utils';
import CommonBoxLinks from '../commonComponents/CommonBoxLinks';
//
import config from "@/config/config";
import Globals from "@/config/Globals";
//
import "@/stylesheets/CommonReset.scss";
//
class CommonResetPasswordRequestView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const paramValue = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    this.state = { data: { username: (paramValue ? paramValue : '') }, isLoading: false };
  }

  componentDidMount() {
    super.componentDidMount();
    Utils.showGoogleRecaptchaBagde();
    document.title = `Forgot Password - ${config.ApplicationName}`;
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    Utils.hideGoogleRecaptchaBagde();
  }

  //Actions
	async handleForgotPassword(){
    const formData = await this.form.validateFields();
    if (formData) {
      this._forgotPassword(formData.email);
    }
  }
  
  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.isAuthenticating;
    return (
      <Layout.Content className='pageContentFullScreen'>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <Layout.Content className='resetContainer'>
          <Row type="flex" justify="center" align="middle" className='authLogoRow'><Col align="center"> <Logo /> </Col></Row>
          <Row type="flex" justify="center" align="middle">
          <Col className='resetBoxContainer' align='middle' justify="center">
            <div className='resetBox'>
              <Row type="flex" justify="center" align="middle">
                <Col>
                  <h5 className="resetBoxTitle">Reset your {config.ApplicationName}<br></br> account password</h5>
                </Col>
              </Row>
              <Form layout="vertical" {...Utils.propagateRef(this, 'form')} className='resetForm'>
                <Row type="flex" justify="center" align="middle">
                  <Col span={22}>
                    <Form.Item
                      initialValue={this.state.data.username}
                      name="email"
                      label="Email"
                      rules={[
                        { required: true, message: 'This field is required' },
                        { min: 10, max: 255, message: 'Email must be between 10 and 255 characters' },
                        { type: 'email', message: 'Invalid email format' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Button block onClick={this.handleForgotPassword} className='resetButton' type="primary" htmlType="submit"> Reset </Button>
              </Form>
            </div>
          </Col>
        </Row>
          <CommonBoxLinks width={500}/>
        </Layout.Content>
      </Layout.Content>
    );
  }

  async _forgotPassword(email) {
    this.startLoading();
    //
    //Try to get captcha
    const token = await this.props.googleReCaptchaProps.executeRecaptcha('resetPassword');
    if (!token) {
      this.props.app.alertController.showErrorAlert('Error!', 'Invalid captcha.');
      this.stopLoading();
      return;
    }
    //
    const resetResp = await this.props.app.idm.api.password.reset(email, token);
    if (resetResp.statusCode == 200) {
      if (resetResp.body.reregistered) {
        this.props.app.urlManager.pushPage(config.ApplicationRoutes.login, { [IDMGlobals.URLQueryParam_UserEmail]: email });
        this.props.app.alertController.showSuccessAlert("", "Password reset succeed, check you email for a new password!");
      } else {
        this.props.app.alertController.showSuccessAlert("", "Password reset succeed, check you email for a reset code!");
        this.props.app.urlManager.pushPage(config.ApplicationRoutes.resetPasswordConfirmation, null, email);
      }
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resetResp);
      this.stopLoading();
    }
  }
}

const CommonResetPasswordRequestViewRecaptcha = withGoogleReCaptcha(CommonResetPasswordRequestView);
export default class CommonResetPasswordRequestViewHOC extends React.Component {
    render() {
      return (
        <CommonResetPasswordRequestViewRecaptcha {...this.props}/>
      );
    }
}
