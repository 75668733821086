import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button, message } from 'antd';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import Utils from '@/components/Utils';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import AdminMemberForm from '@/views/adminSubviews/member/Forms/AdminMemberForm';
//
import config from "@/config/config";
//
export default class AdminMemberCreateView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
  }
  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Create Member`;
  }
  //Actions
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) {
      const additionalFields = [];
      Object.keys(formData).forEach(key => {
        if (key.includes('additionalField.')) {
          const [, fieldID] = key.split('.');

          additionalFields.push({
            fieldID,
            value: formData[key],
          });

          delete formData[key];
        }
      });

      this._create({ ...formData, additionalFields });
    }
  }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent" style={{ marginTop: 0, paddingTop: 8 }}>
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Create Member" onBack={() => window.history.back()} 
                    style={{ marginTop: 0, paddingTop: 0 }}
                    extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>Create</Button>]} />
        <Layout.Content>
          <WhiteBox>
            <AdminMemberForm app={this.props.app} isNew {...Utils.propagateRef(this, 'form')} />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _create(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.members.create(data);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Member successfully created!');
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.memberUpdate, null, resp.body.id);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading(true);
  }
}
