import React from "react";
import autoBind from 'react-autobind';
import { Drawer, Row, Col, Button, message } from 'antd';
//
import CustomComponent from '@/components/CustomComponent';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import CommonMemberWorkHistoryForm from './CommonMemberWorkHistoryForm';
import Utils from "@/components/Utils";
//
export default class CommonMemberWorkHistoryCreateDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      isVisible: false,
      memberID: null,
    };
  }

  show(memberID) {
    this.setState({ isVisible: true, memberID: Number(memberID) });
  }

  handleClose() {
    this.form.resetFields();
    this.setState({
      isLoading: false,
      isVisible: false,
      memberID: null,
    });
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) {
      this._create(formData);
    }
  }

  //UI
  render() {
    return (
      <Drawer
        visible={this.state.isVisible}
        title="Add Work History"
        placement="right"
        onClose={this.handleClose}
        width={800}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <CommonMemberWorkHistoryForm
          app={this.props.app} {...Utils.propagateRef(this, 'form')}
          activityCodes={this.props.activityCodes} classifications={this.props.classifications}
        />
        <Row>
          <Col>
            <Button type="primary" onClick={this.handleSubmit}>Create</Button>
          </Col>
        </Row>
      </Drawer>
    )
  }

  // API Calls
  async _create(data) {
    if (!this._isMounted) return;
    this.startLoading();
    const resp = await this.props.app.api.memberWorkHistories.create({ 
      ...data,
      memberID: this.state.memberID,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Work History successfully created!');
      this.handleClose();
      if (this.props.onCreate) this.props.onCreate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading(true);
  }
}
