import Globals from '@/config/Globals';
//
export default class APIMemberStatus {
  constructor(API) {
    this.api = API;
  }

  async getAll() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_MemberStatuses;
    return await req.exec();
  }

  async create(memberStatusObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_MemberStatus + Globals.URL_NewIndentifier;
    req.body = { ...memberStatusObj };
    return await req.exec();
  }

  async update(memberStatusObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_MemberStatus + memberStatusObj.id;
    delete memberStatusObj.id;
    req.body = { ...memberStatusObj };
    return await req.exec();
  }

  async remove(memberStatusID) {
    const req = await this.api.newBaseRequest('DELETE');
    req.path = Globals.APIPath_MemberStatus + memberStatusID;
    return await req.exec();
  }
}
