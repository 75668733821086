import React from "react";
import autoBind from 'react-autobind';
import { Layout, Row, Col, PageHeader, Table, Button, Typography } from 'antd';
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import config from "@/config/config";
import Utils from "@/components/Utils";
//
export default class AdminActiveMembersReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, previewData: null };
  }

  //Life cycle
  componentDidMount() { 
    document.title = `${config.ApplicationName} - Active Members`; 
    this._loadPreview();
  }
  //Actions
  async handleDownload() { this._generate(); }

  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Active Members" extra={[<Button key="1" onClick={this.handleDownload} type='primary'>Download Report</Button>]} />
        <Layout.Content>
          <WhiteBox>
            {this.state.previewData && this.state.previewData.length > 0 && <Table dataSource={this.state.previewData.slice(0, this.state.previewData.length - 2)} 
              pagination={{ pageSize: 1000, disabled: true }} rowKey={(row) => row[0]}
              columns={[
                { title: 'Sector', key: 'sectorName', render: props => props && props[0] ? props[0] : '', },
                { title: 'A', key: 'a', render: props => props && props[1] ? props[1] : '-', },
                { title: 'BA', key: 'ba', render: props => props && props[2] ? props[2] : '-', },
                { title: 'PA', key: 'pa', render: props => props && props[3] ? props[3] : '-', },
                { title: 'PB', key: 'pb', render: props => props && props[4] ? props[4] : '-', },
                { title: 'FP', key: 'fp', render: props => props && props[5] ? props[5] : '-', },
              ]}
            />}
            {this.state.previewData && this.state.previewData.length > 0  && <Row>
              <Col>
                <Typography.Text>{this.state.previewData?.[this.state.previewData?.length - 1]?.[0]}</Typography.Text></Col>
            </Row>}
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  /* private API */
  async _loadPreview() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.reports.activeMembers(true);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.setState({ previewData: resp.body.rows, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _generate() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.reports.activeMembers();
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      Utils.downloadBlob(resp.body, `Active Members - ${moment(new Date()).format('MMYY')}`, 'xlsx');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.setState({ isLoading: false });
  }
}
