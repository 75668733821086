import React from "react";
import autoBind from 'react-autobind';
import { Form, Row, Col, Input, Select, Checkbox, Divider, Popover, Typography, Button, Modal } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TagOutlined, CopyOutlined, DownloadOutlined } from '@ant-design/icons';
//
import Utils from '@/components/Utils';
import CustomComponent from '@/components/CustomComponent';
//
export default class CommonEmployersForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      hasIDMID: false,
      data: {},
      countries: [],
      provinces: [],
      schedules: [],
      sectors: [],
      admins: [],
      selectedCountryProvinces: null,
      isPrintingLabel: false,
      labelPreview: null,
      isPrintModalVisible: false,
      isSavingPDF: false,
    };
  }
  //Life cycle
  componentDidMount() {
    const { countries, provinces, schedules, sectors, admins } = this.props.app.sharedCache().getConfig();
    this.setState({ countries, provinces, schedules, sectors, admins });
  }
  //Actions
  handleChangeCountry(countryCode) {
    this.setState({ selectedCountryProvinces: this.state.provinces[countryCode], province: null });
    this.form.resetFields(['province']);
  }

  async handlePrintLabel() {
    this.setState({ isPrintingLabel: true });

    const resp = await this._printLabel('preview');
    if (resp.statusCode == 200 && resp.body) {
      this.setState({ isPrintingLabel: false, labelPreview: resp.body.preview, isPrintModalVisible: true });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isPrintingLabel: false });
    }
  }

  async handleSavePDF() {
    this.setState({ isSavingPDF: true });

    const resp = await this._printLabel('pdf');
    if (resp.statusCode == 200 && resp.body) {
      Utils.downloadBlob(resp.body, `${this.state.data.name} Label`, 'pdf');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.setState({ isSavingPDF: false });
  }

  closePrintLabelModal() {
    this.setState(({ isPrintModalVisible: false, labelPreview: null, labelCopied: false, isSavingPDF: false }));
  }

  //Public
  async validateFields() {
    const formData = await this.form.validateFields();
    if (formData) {
      const data = {
        ...formData,
        street1: formData.street1 || '', street2: formData.street2 || '',
        phone: formData.phone || '', altPhone: formData.altPhone || '',
        fax: formData.fax || '',
        province: formData?.province || null,
        postalCode: formData?.postalCode?.replace(/\s/g, '') || '',
        ...(this.props.app.isAdmin() ? {
          scheduleID: Number(formData.scheduleID),
          sectorID: Number(formData.sectorID),
          businessAgentID: Number(formData.businessAgentID),
          disabled: !!formData.disabled,
          dispatchEnabled: !!formData.dispatchEnabled,
          remittanceEnabled: !!formData.remittanceEnabled,
        } : {})
      };

      return data;
    }

    return false;
  }

  resetFields() {
    return this.form.resetFields();
  }

  setFields(data) {
    if (data.idmID) {
      this.setState({ hasIDMID: true })
    }
    this.setState({ data });
    this.handleChangeCountry(data.country);
    return this.form.setFieldsValue({
      ...data,
      postalCode: data?.postalCode ? Utils.mask(data.postalCode?.replace(/\s/g, ''), '### ###') : '',
    });
  }

  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Modal
          visible={this.state.isPrintModalVisible}
          title="Print label"
          onCancel={this.closePrintLabelModal}
          footer={null}
        >
          <pre>
            {this.state.labelPreview}
          </pre>

          <CopyToClipboard text={this.state.labelPreview} onCopy={() => this.setState({ labelCopied: true })}>
            <Button icon={<CopyOutlined />}>
              {!this.state.labelCopied ? 'Copy to clipboard' : 'Copied!'}
            </Button>
          </CopyToClipboard>
          <Button
            type="primary"
            loading={this.state.isSavingPDF}
            onClick={this.handleSavePDF}
            style={{ marginLeft: 8 }}
            icon={<DownloadOutlined />}
          >
            Save as PDF
          </Button>
        </Modal>

        <Row>
          <Col span={7}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
          {this.props.app.isAdmin() && 
            <Col span={3} offset={1}>
              <Form.Item label="Company No." name="companyNumber" rules={[{ required: true, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            </Col>}
          {this.props.app.isAdmin() && 
            <Col span={2} offset={1}>
              <Form.Item label="Inactive" name="disabled" valuePropName="checked">
                <Checkbox id="disabled" />
              </Form.Item>
            </Col>}
            {this.state.data?.id && (
              <Col>
                <Form.Item label=" ">
                  <Button
                    size="small"
                    type="primary"
                    icon={<TagOutlined />}
                    onClick={this.handlePrintLabel}
                    loading={this.state.isPrintingLabel}
                  >
                    Print label
                  </Button>
                </Form.Item>
              </Col>
            )}
        </Row>
        <Divider orientation="left">Address Information</Divider>
        <Row>
          <Col span={4}>
            <Form.Item label="Country" name="country" rules={[{ required: true, message: 'This field is required!' }]} >
              <Select placeholder="Select a Country" onChange={this.handleChangeCountry} showSearch allowClear
                filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}>
                {this.state.countries.map((country) => (
                  <Select.Option key={country.countryCode} value={country.countryCode}>{country.country}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item label="Province" name="province" rules={[{ required: !!this.state.selectedCountryProvinces, message: 'This field is required!' }]}>
              <Select placeholder={!this.state.selectedCountryProvinces ? 'N/A' : "Select a Province"} disabled={!this.state.selectedCountryProvinces} showSearch 
                allowClear disabled={!this.state.selectedCountryProvinces}
                filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}>
                {(this.state.selectedCountryProvinces || []).map((province) => (
                  <Select.Option key={province.provinceCode} value={province.provinceCode}>{province.province}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item label="City" name="city" rules={[{ required: false, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label="Postal Code" name="postalCode" rules={[{ required: false, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={10}>
            <Form.Item label="Address" name="street1">
              <Input />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item label="Address 2" name="street2">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">Contact Information</Divider>

        <Row>
          <Col span={6}>
            {this._wrapIntoEmailPopover(
              <Form.Item label="Remittance Email" name="email" rules={[{ required: this.state.hasIDMID, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            )}
          </Col>
          <Col span={5} offset={1}>
            <Form.Item label="Phone" name="phone">
              <Input />
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item label="Alt Phone" name="altPhone">
              <Input />
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item label="Fax" name="fax">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <Form.Item label="Contact Email" name="contactEmail" rules={[{ required: this.state.hasIDMID, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {this.props.app.isAdmin() && <Divider orientation="left">Extra Information</Divider>}

        {this.props.app.isAdmin() && <Row>
          <Col span={5}>
            <Form.Item
              label="Schedule No."
              name="scheduleID"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Select
                placeholder="Select a Schedule"
                showSearch
                filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
              >
                {(this.state.schedules || []).map((schedule) => (
                  <Select.Option key={schedule.id} value={schedule.id}>
                    {`${schedule.number} - ${schedule.name}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          
          <Col span={5} offset={1}>
            <Form.Item
              label="Business Agent"
              name="businessAgentID" 
              initialValue={0}
            >
              <Select
                placeholder="Select an agent"
                showSearch
                filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
              >
                {(this.state.admins || []).map((admin) => (
                  <Select.Option key={admin.id} value={admin.id}>{`${admin.firstName} ${admin.lastName}`}</Select.Option>
                ))}
                <Select.Option key={'none'} value={0}>None</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          
          <Col span={5} offset={1}>
            <Form.Item
              label="Business Sector"
              name="sectorID"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Select
                placeholder="Select a sector"
                showSearch
                filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
              >
                {(this.state.sectors || []).map((sector) => (
                  <Select.Option key={sector.id} value={sector.id}>{sector.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>}

        {this.props.app.isAdmin() && <Row>
          <Col>
            <Form.Item
              label="Allow for Dispatch"
              name="dispatchEnabled"
              valuePropName="checked"
            >
              <Checkbox id="dispatchEnabled" />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item
              label="Remits dues Electronically"
              name="remittanceEnabled"
              valuePropName="checked"
            >
              <Checkbox id="remittanceEnabled" />
            </Form.Item>
          </Col>
        </Row>}
      </Form>
    )
  }
  /* private functions */
  _printLabel(type) {
    return this.props.app.api.reports.employerLabel(this.state.data.id, type !== 'preview' ? 'application/pdf' : '');
  }

  _wrapIntoEmailPopover(toWrap) {
    if (this.props.app.isAdmin()) {
      return (<Popover trigger="focus" getPopupContainer={triggerNode => triggerNode.parentNode} content={
          <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '400px' }}>
            <Typography.Title level={4}>Important notes for email change:</Typography.Title>
            <ul>
              <li>This is the email employer will use for <b>online access</b>.</li>
              <li>In order to take effect the employer need to <u>logout</u> after the change.</li>
              <li>If wrong email is typed, email change can lock the employer out of the account.</li>
            </ul>
          </div>
        }
      >{toWrap}</Popover>);
    } else {
      return (<Popover getPopupContainer={triggerNode => triggerNode.parentNode} content={
          <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '400px' }}>
            <Typography.Title level={4}>Important notes for email change:</Typography.Title>
            <ul>
              <li>In order to take effect you <u>will be logged out</u> after changing the email.</li>
              <li>If wrong email is typed, email change can lock you out of your account.</li>
              <li>You will be asked to confirm the new email by entering a code sent on the new email.</li>
            </ul>
          </div>
        }
      >{toWrap}</Popover>);
    }
  }
}
