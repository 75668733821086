import React from "react";
import { Modal, Form, Row, Col, Input, message } from 'antd';
import autoBind from 'react-autobind';
//
import CustomComponent from '@/components/CustomComponent';
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
import Utils from "@/components/Utils";
//
export default class AdminApplicationRejectModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isVisible: false,
      isLoading: false,
      applicationID: null,
    };
  }

  show(applicationID) {
    this.setState({ isVisible: true, applicationID });
  }

  handleClose() {
    this.setState({
      isVisible: false,
      isLoading: false,
      applicationID: null,
    });
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();
    
    if (formData) {
      this._reject(formData);
    }
  }

  // UI
  render() {
    return (
      <Modal
        visible={this.state.isVisible}
        title="Reject Application"
        onCancel={this.handleClose}
        onOk={this.handleSubmit}
        okText="Reject"
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row>
            <Col span={24}>
              <Form.Item name="reason" label="Reason" rules={[{ required: true, message: 'This field is required!' }]}>
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }

  // API Calls
  async _reject(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.applications.reject(this.state.applicationID, data);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.handleClose();
      message.success('Application succesfully rejected!');
      if (this.props.onReject) this.props.onReject();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
