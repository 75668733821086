import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button, message } from 'antd';
//
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
//
import config from '@/config/config';
import Globals from '@/config/Globals';
//
import CommonDispatchJobForm from './CommonDispatchJobForm';
import Utils from "@/components/Utils";
//
export default class CommonUpdateDispatchJobView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    const user = this.props.app.sharedCache().getUser();
    this.employerID = (
      this.props.app.isAdmin()
      ? this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this)
      : user.id
      );
    this.dispatchID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID2_Placeholder, this);

    this.state = {
      isLoading: false,
      data: {},
    };
  }

  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Update Dispatch Job`;
    this._fetch();
  }

  handleDispatchMembers() {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.dispatchMembers, null, this.employerID, this.dispatchID);
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) {
      this._update(formData);
    }
  }
  
  //UI
  render() {
    const pageHeader = { onBack: () => window.history.back(), title: 'Update Dispatch Job' };

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader {...pageHeader} extra={[
          <Button key="1" type="ghost" onClick={this.handleDispatchMembers}>Dispatch Members</Button>,
          <Button key="2" type="primary" onClick={this.handleSubmit}>Save</Button>,
        ]} />
        <Layout.Content>
          <WhiteBox>
            <CommonDispatchJobForm
              app={this.props.app}
              employerID={this.employerID}
              {...Utils.propagateRef(this, 'form')}
            />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    const resp = await this.props.app.api.dispatchJob.getByID(this.employerID, this.dispatchID);
    if (resp.statusCode == 200 && resp.body) {
      this.setState({ data: resp.body, isLoading: false });
      this.form.setFieldsValue(resp.body);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  
  async _update(data) {
    this.startLoading();
    const resp = await this.props.app.api.dispatchJob.update(this.employerID, this.dispatchID, { ...data, jobNumber: this.state.data.jobNumber });
    if (resp.statusCode == 200 && resp.body) {
      message.success('Dispatch Job successfully updated!');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading(true);
  }
}
