import React from "react";
import autoBind from 'react-autobind';
import { Drawer, Button, Row, Col, message } from "antd";
//
import CustomComponent from '@/components/CustomComponent';
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
import Utils from "@/components/Utils";
//
import CommonTrainingHistoryForm from './CommonTrainingHistoryForm';
//
export default class CommonEditTrainingHistoryDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      isVisible: false,
      trainingID: null,
    };
  }

  handleClose() {
    this.form.resetFields();
    this.setState({
      isLoading: false,
      isVisible: false,
    });
  }

  show(trainingID) {
    this.setState({ isVisible: true, trainingID }, this._fetch);
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();

    if (formData) {
      this._update(formData);
    }
  }
  
  render() {
    return (
      <Drawer
        visible={this.state.isVisible}
        title="Update Training"
        placement="right"
        onClose={this.handleClose}
        width={600}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <CommonTrainingHistoryForm app={this.props.app} {...Utils.propagateRef(this, 'form')} />
        <Row>
          <Col>
            <Button type="primary" onClick={this.handleSubmit}>Save</Button>
          </Col>
        </Row>
      </Drawer>
    );
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.trainingHistory.getByID(this.props.memberID, this.state.trainingID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      const data = resp.body;
      this.setState({ isLoading: false });
      this.form.setFieldsValue(resp.body);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _update(data) {
    if (!this._isMounted) return;
    this.startLoading();
    //request
    const resp = await this.props.app.api.trainingHistory.update(this.props.memberID, this.state.trainingID, data);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.handleClose();
      message.success('Training successfully updated!');
      if (this.props.onUpdate) this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.setState({ isLoading: false });
  }
}
