import React from "react";
import autoBind from 'react-autobind';
import { Layout, message, PageHeader, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import config from "@/config/config";
import Globals from "@/config/Globals";
import Utils from "@/components/Utils";
import AdminMemberOptions, { AdminMemberOptionTypes } from "@/views/commonComponents/AdminMemberOptions";
import CommonDuesEntryDuesDrawer from "../commonComponents/CommonDuesEntryDuesDrawer";
import CommonDuesEntryOtherDrawer from "../commonComponents/CommonDuesEntryOtherDrawer";
import TableActions from "../commonComponents/TableActions";
//
export default class CommonMemberDuesHistoryView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    // Initialize variables
    this.memberName = null;
    this.memberID = null;
    this.viewOptions = null;

    if (this.props.app.isAdmin()) {
      this.memberName = this.props.app.urlManager.getQueryParam('n');
      this.memberID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
      this.viewOptions = new AdminMemberOptions(AdminMemberOptionTypes.DUES, this.memberID, this.props.app, this.memberName)
    }

    if (this.props.app.isMember()) {
      const ibewUser = this.props.app.sharedCache().getUser() || {};
      this.memberID = ibewUser.id;
    }

    this.state = {
      isLoading: false,
      data: [],
    };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Dues History`;
    this._fetch();
  }

  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }

  async handleEditEntry(entryObj) {
    this.startLoading();
    
    const [employerObj, batchObj] = await Promise.all([
      this._loadEmployer(entryObj.employerID),
      this._loadBatch(entryObj.employerID, entryObj.batchID),
    ]);

    this.stopLoading();
    
    if (!employerObj || !batchObj) {
      return;
    }

    if (entryObj.transactionCode == 'O') this.entryOtherDrawer.show(entryObj.id, entryObj.memberID, employerObj, batchObj);
    else if (entryObj.transactionCode == 'D') this.entryDuesDrawer.show(entryObj.id, entryObj.memberID, employerObj, batchObj);
    else message.error(`Editing entry of type ${entryObj.transactionCode} is not supported!`);
  }

  onModalClose(id) {
    this._fetch();
  }
  
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const props = {
      onChange: this.handleFilterChange, rowKey: 'id', loading: this.state.isLoading,
      scroll: { x: 1300 },
    };

    const columns = [
      {
        title: 'Batch Date', dataIndex: 'batchDate', key: 'batchDate',
        render: (date) => date ? moment(date).format(Globals.DefaultUIShortDateFormat) : '-',
      },
      {
        title: 'Entry Time', dataIndex: 'entryTime', key: 'entryTime',
        render: (date) => date ? moment(date).format(Globals.DefaultUIShortDateFormat) : '-',
      },
      {
        title: 'Receipt', key: 'receipt',
        render: (item) => {
          if (!item?.receiptNumber) return '';
          const type = item.official ? 'O' : (item.receiptNumber ? 'M' : '');
          return `${type}${item.receiptNumber}`
        },
      },
      {
        title: 'TC', dataIndex: 'transactionCode', key: 'transactionCode',
      },
      {
        title: 'AC', dataIndex: 'activityCode', key: 'activityCode',
      },
      {
        title: 'Date', dataIndex: 'statusDate', key: 'statusDate',
        render: (date) => date ? moment(date).format(Globals.DefaultUIShortDateFormat) : '-',
      },
      {
        title: 'MP', dataIndex: 'monthsPaid', key: 'monthsPaid',
        render: (monthsPaid) => monthsPaid || 0,
      },
      {
        title: 'Paid thru', key: 'paidToDate',
        render: (item) => {
          if (item.transactionCode == 'O') return '-';
          else return item.paidToDate ? moment(item.paidToDate).format(Globals.DefaultUIShortDateFormat) : '-';
        },
      },
      // {
      //   title: 'Classification', key: 'classification',
      //   render: (item) => {
      //     if (item.transactionCode == 'M' || item.transactionCode == 'O') {
      //       return item.description;
      //     }

      //     return '-';
      //   },
      // },
      {
        title: 'Empl', dataIndex: 'employerName', key: 'employerName',
      },
      {
        title: 'Amount', dataIndex: 'amount', key: 'amount', align: 'right',
        render: (amount) => (amount && amount != '0.00') ? `${Utils.toCurrencyFormat(amount)}` : '',
      },
      {
        title: 'IO', dataIndex: 'io', key: 'io', align: 'right',
        render: (io) => (io && io != '0.00') ? `${Utils.toCurrencyFormat(io)}` : '',
      },
      {
        title: 'LU', dataIndex: 'lu', key: 'lu', align: 'right',
        render: (lu) => (lu && lu != '0.00') ? `${Utils.toCurrencyFormat(lu)}` : '',
      },
      {
        title: (<span>IO<br/>Arr.</span>), dataIndex: 'arrearsIO', key: 'arrearsIO', align: 'right',
        render: (arrearsIO) => (arrearsIO && arrearsIO != '0.00') ? `${Utils.toCurrencyFormat(arrearsIO)}` : '',
      },
      {
        title: (<span>LU<br/>Arr.</span>), dataIndex: 'arrearsLU', key: 'arrearsLU', align: 'right',
        render: (arrearsLU) => (arrearsLU && arrearsLU != '0.00') ? `${Utils.toCurrencyFormat(arrearsLU)}` : '',
      },
      {
        title: 'Suspense', dataIndex: 'suspense', key: 'suspense', align: 'right',
        render: (suspense) => (suspense && suspense != '0.00') ? `${Utils.toCurrencyFormat(suspense)}` : '',
      },
      {
        title: 'Pens. Benef.', dataIndex: 'pensionBenefitAdmission', key: 'pensionBenefitAdmission', align: 'right',
        render: (pensionBenefitAdmission) => (pensionBenefitAdmission && pensionBenefitAdmission != '0.00') ? `${Utils.toCurrencyFormat(pensionBenefitAdmission)}` : '',
      },
      {
        title: (<span>IO<br/>Init.</span>), dataIndex: 'ioInitiation', key: 'ioInitiation', align: 'right',
        render: (ioInitiation) => (ioInitiation && ioInitiation != '0.00') ? `${Utils.toCurrencyFormat(ioInitiation)}` : '',
      },
      {
        title: (<span>LU<br/>Init.</span>), dataIndex: 'luInitiation', key: 'luInitiation', align: 'right',
        render: (luInitiation) => (luInitiation && luInitiation != '0.00') ? `${Utils.toCurrencyFormat(luInitiation)}` : '',
      },
      {
        title: (<span>IO<br/>Reins.</span>), dataIndex: 'ioReinstatement', key: 'ioReinstatement', align: 'right',
        render: (ioReinstatement) => (ioReinstatement && ioReinstatement != '0.00') ? `${Utils.toCurrencyFormat(ioReinstatement)}` : '',
      },
      {
        title: (<span>LU<br/>Reins.</span>), dataIndex: 'luReinstatement', key: 'luReinstatement', align: 'right',
        render: (luReinstatement) => (luReinstatement && luReinstatement != '0.00') ? `${Utils.toCurrencyFormat(luReinstatement)}` : '',
      },
      {
        title: 'Code', dataIndex: 'assessmentCode', key: 'assessmentCode',
      },
      {
        title: 'Amount', dataIndex: 'assessmentAmt', key: 'assessmentAmt',
        render: (assessmentAmt) => (assessmentAmt && assessmentAmt != '0.00') ? `${Utils.toCurrencyFormat(assessmentAmt)}` : '',
      },
    ];

    const onBack = this.props.app.isAdmin() ? () => window.history.back() : null;

    return (
      <Layout.Content className="pageContent">
        <CommonDuesEntryDuesDrawer {...Utils.propagateRef(this, 'entryDuesDrawer')} app={this.props.app} onEnd={this.onModalClose} />
        <CommonDuesEntryOtherDrawer {...Utils.propagateRef(this, 'entryOtherDrawer')} app={this.props.app} onEnd={this.onModalClose}/>

        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader
          title={this.memberName ? `${this.memberName}'s Dues History` : 'Dues History'} 
          extra={this.viewOptions?.getOptions()}
          onBack={onBack} />
        <Layout.Content>
          <WhiteBox>
            <Table
              className="squeeze-table"
              dataSource={this.state.data}
              columns={columns}
              onRow={record => ({
                onClick: () => {
                  const editEnabled = (record.transactionCode == 'O' || record.transactionCode == 'D');
                  if (editEnabled) this.handleEditEntry(record)
                },
              })}
              pagination={{ showSizeChanger: true }}
              {...props}
            />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _fetch() {
    if (!this._isMounted) return;
    this.startLoading();
    //request
    const resp = await this.props.app.api.members.getDues(this.memberID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.entries) {
      const data = resp.body.entries.sort((a, b) => new Date(b.batchDate).getTime() - (new Date(a.batchDate)).getTime());
      this.setState({ data, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  async _loadEmployer(employerID) {
    const resp = await this.props.app.api.employers.getByID(employerID);
    if (resp.statusCode == 200 && resp.body) {
      return resp.body;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return null;
    }
  }
  async _loadBatch(employerID, batchID) {
    const resp = await this.props.app.api.duesBatch.getByID(employerID, batchID);
    if (resp.statusCode == 200 && resp.body) {
      return resp.body;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return null;
    }
  }
}
