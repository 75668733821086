import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button, message } from 'antd';
import { Link } from "react-router-dom";
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import Utils from '@/components/Utils';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import Globals from "@/config/Globals";
import config from "@/config/config";
//
import CommonEmployersForm from '../commonSubviews/CommonEmployersForm';
//
export default class AdminEmployersUpdateView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.employerID = this.props.app.sharedCache().getUser().id;
    this.state = { isLoading: false, employerObj: null };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Profile`;
    this._fetch();
  }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) {
      //Check if changing password, if yes, prompt
      if (this.state.employerObj && this.state.employerObj.email != formData.email) {
        const confirmation = await this.props.app.alertController.showPromptAlert('Attention!', `Your email will be updated to "${formData.email}". Are you sure you want to update it? (type the new email address to confirm)`);
        if (confirmation != formData.email) return;
      }
      //update it
      this._update(formData);
    }
  }
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader
          title="Profile"
          onBack={() => window.history.back()}
          extra={[
            <Button key="1" onClick={this.handleSubmit} type='primary'>Save</Button>,
          ]}
        />
        <Layout.Content>
          <WhiteBox>
            <CommonEmployersForm editMode isProfile app={this.props.app} {...Utils.propagateRef(this, 'form')} />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.employers.getByID(this.employerID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.setState({ employerObj: resp.body, isLoading: false });
      this.form.setFields({
        ...resp.body,
        phone: Utils.formatPhone(resp.body.phone),
        altPhone: Utils.formatPhone(resp.body.altPhone),
        fax: Utils.formatPhone(resp.body.fax),
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false, employerObj: null });
    }
  }
  async _update(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.employers.update({
      id: this.employerID, idmID: this.state.employerObj.idmID,
      companyNumber: this.state.employerObj.companyNumber,
      sectorID: this.state.employerObj.sectorID,
      businessAgentID: this.state.employerObj.businessAgentID,
      scheduleID: this.state.employerObj.scheduleID,
      disabled: this.state.employerObj.disabled,
      dispatchEnabled: this.state.employerObj.dispatchEnabled,
      remittanceEnabled: this.state.employerObj.remittanceEnabled,
      country: Globals.DefaultAdminCountry,
      ...data,
      phone: Utils.sanitizePhone(data.phone),
      altPhone: Utils.sanitizePhone(data.altPhone),
      fax: Utils.sanitizePhone(data.fax),
    }, true);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      if (resp.body && resp.body.emailChangeConfirmationToken) {
        this.props.app.alertController.showSuccessAlert("", `Email updated with success. Please, confirm the new email by entering the code you just received!`);
        await this.props.app.idm.authenticator.logout();
        this.props.app.urlManager.pushPage(config.ApplicationRoutes.registrationConfirmation, null, resp.body.emailChangeConfirmationToken, data.email); //push to main page
      } else {
        message.success('Profile successfully updated!');
        this._fetch();
      }
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    } 
  }
}
