import Utils from '@/components/Utils';

class Cache {
	constructor(auth) {
		this.auth = auth;
		this.isLoading = false;
		this.config = {};
		this.user = {};
	}
	async loadCache() {
		//avoid double cache loading
		if (this.isLoading) return false;
		this.isLoading = true;
		//Parallel requests
		console.debug('init');
		const respAll = await Utils.execRequests([
				this.loadConfig(),
				(this.auth.isAdmin() ? this.loadAdmin() : null),
				(this.auth.isEmployer() ? this.loadEmployer() : null),
				(this.auth.isMember() ? this.loadMember() : null),
			], null, null, true, );
		console.debug('end');
		//
		this.isLoading = false;
		return respAll;
	}
	clearCache() {
		this.isLoading = false;
		this.config = {};
		this.user = {};
	}
	getMemberStatusByTypeCode(code) {
		return this.config?.memberStatuses?.find((m) => m.memberType == code);
	}
	getDispatchStatusByCode(code) {
		return this.config?.dispatchStatuses?.find((s) => s.id == code);
	}
	getApplicationsCount() {
		return this.config?.pendingApplicationsCount;
	}
	getPendingImportCount() { return (this.config ? this.config.pendingEmployerFilesCount : 0); }
	getConfig() { return this.config; }
	setConfig(config) { this.config = config; }
	getUser() { return this.user; }
	async loadConfig() {
		const resp = await this.auth.api.config.getConfig();

		if (resp.statusCode == 200) {
			let { provinces, countries } = resp.body;

			provinces = provinces.reduce((acc, curr) => {
				if (!acc[curr.countryCode]) {
					acc[curr.countryCode] = [];
				}

				acc[curr.countryCode].push({
					provinceCode: curr.provinceCode,
					province: curr.province,
				});

				return acc;
			}, {});
			
			this.config = {
				...resp.body,
				countries,
				provinces,
			};
		}

		return resp;
	}
	async loadAdmin() {
		const userID = this.auth.idm.session.authorization.getUserID();
		const resp = await this.auth.api.admins.getByIDMID(userID);
		
		if (resp.statusCode == 200) {
			this.user = resp.body;
			this.auth.setAcls(resp.body.acls);
		}

		return resp;
	}
	async loadEmployer() {
		const userID = this.auth.idm.session.authorization.getUserID();
		const resp = await this.auth.api.employers.getByIDMID(userID);
		
		if (resp.statusCode == 200) {
			this.user = resp.body;
		}

		return resp;
	}
	async loadMember() {
		const userID = this.auth.idm.session.authorization.getUserID();
		const resp = await this.auth.api.members.getByIDMID(userID);
		
		if (resp.statusCode == 200) {
			this.user = resp.body;
		}

		return resp;
	}
};

export default Cache;
