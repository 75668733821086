import React from "react";
import autoBind from 'react-autobind';
//
import CustomComponent from '@/components/CustomComponent';
//
export default class MemberDashboardView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  
  render() {
    return (<>MemberDashboardView</>)
  }
}
