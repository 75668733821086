import React from "react";
import autoBind from 'react-autobind';
import moment from 'moment';
//
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import CustomComponent from '@/components/CustomComponent';
import AdminNotesForm from './Forms/AdminNotesForm';
//
import { message, Drawer, Row, Col, Button } from 'antd';
import Utils from "@/components/Utils";
import Globals from "@/config/Globals";
//
export default class AdminCreateNotesDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      isVisible: false,
      relType: null,
      relID: null,
    };
  }

  show(relType, relID) {
    this.setState({ isVisible: true, relType, relID });
  }

  handleClose() {
    this.form.resetFields();
    this.setState({
      isLoading: false,
      isVisible: false,
      relID: null,
      relType: null,
    });
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();

    if (formData) {
      this._create(formData);
    }
  }

  render() {
    return (
      <Drawer
        visible={this.state.isVisible}
        title="Create Note"
        placement="right"
        onClose={this.handleClose}
        width={600}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <AdminNotesForm {...Utils.propagateRef(this, 'form')} />
        <Row>
          <Col>
            <Button type="primary" onClick={this.handleSubmit}>Create</Button>
          </Col>
        </Row>
      </Drawer>
    )
  }

  // API Calls
  async _create(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.notes.create({
      ...data,
      reminderDate: data.reminderDate ? moment(data.reminderDate).format(Globals.DefaultSimpleDateFormat) : null,
      relName: this.state.relType,
      relID: Number(this.state.relID),
      private: !!data.private,
      urgent: !!data.urgent,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.handleClose();
      message.success('Note successfully created!');
      if (this.props.onCreate) this.props.onCreate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
