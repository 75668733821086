import React from "react";
import autoBind from 'react-autobind';
import { Drawer, Button, Row, Col, message } from "antd";
//
import CustomComponent from '@/components/CustomComponent';
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
import Utils from "@/components/Utils";
//
import CommonTrainingHistoryForm from './CommonTrainingHistoryForm';
//
export default class CommonCreateTrainingHistoryDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      isVisible: false,
    };
  }

  handleClose() {
    this.form.resetFields();
    this.setState({
      isLoading: false,
      isVisible: false,
    });
  }

  show() {
    this.setState({ isVisible: true });
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();

    if (formData) {
      this._create(formData);
    }
  }
  
  render() {
    return (
      <Drawer
        visible={this.state.isVisible}
        title="Create Training"
        placement="right"
        onClose={this.handleClose}
        width={600}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <CommonTrainingHistoryForm app={this.props.app} {...Utils.propagateRef(this, 'form')} />
        <Row>
          <Col>
            <Button type="primary" onClick={this.handleSubmit}>Create</Button>
          </Col>
        </Row>
      </Drawer>
    );
  }

  // API Calls
  async _create(data) {
    if (!this._isMounted) return;
    this.startLoading();
    //request
    const resp = await this.props.app.api.trainingHistory.create(this.props.memberID, data);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.handleClose();
      message.success('Training successfully created!');
      if (this.props.onCreate) this.props.onCreate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.setState({ isLoading: false });
  }
}
