import React from "react";
import autoBind from 'react-autobind';
import { Result, Layout, PageHeader, Button, Modal, Divider, message } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
//
import CustomComponent from '@/components/CustomComponent';
//
import config from '@/config/config';
import CommonLoadingView from "../commonComponents/CommonLoadingView";
import WhiteBox from "../commonComponents/WhiteBox";
//
export default class AdminResetYTD extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Reset YTD`;
  }

  handleUpdate() {
    Modal.confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Remember: this step cannot be reversed.',
      onOk: this.handleOk,
      okText: 'Yes',
      cancelText: 'No',
    });
  }

  handleOk() {
    this._rollover();
  }
  
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Reset YTD" />
        <Layout.Content>
          <WhiteBox>
            <Result
              status="warning"
              title="Attention!"
              subTitle="This step cannot be reversed and should not be executed until all remittances for the previous year have been processed."
              extra={
                <>
                  <Divider />
                  <div style={{ textAlign: 'left', display: 'flex', justifyContent: 'center' }}>
                    <div>
                      <strong>If you click the Update button below, the following activities will take place:</strong>
                      <ol>
                        <li>Reset the Dues Paid YTD amounts</li>
                        <li>Roll the Next Dues Paid YTD fields into Dues YTD</li>
                        <li>Reset the Next Dues Paid YTD fields</li>
                        <li>Increment the posting year</li>
                      </ol>
                    </div>
                  </div>
                  <Divider />
                  <Button type="primary" key="console" onClick={this.handleUpdate}>
                    Update
                  </Button>
                </>
              }
            />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    );
  }

  async _rollover() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.members.rollover();
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Successfully updated!');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading(true);
  }
}
