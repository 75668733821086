import React from 'react';
import autoBind from 'react-autobind';

import { Button, Dropdown, Menu, Modal, Tooltip } from 'antd';
import { MoreOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

export default class TableActions extends React.Component  {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleConfirm(content, onOk) {
    return () => {
      Modal.confirm({
        title: 'Attention!',
        icon: <ExclamationCircleOutlined />,
        content,
        onOk,
        okText: 'Yes',
        cancelText: 'No',
      });
    };
  }

  render() {
    const { options } = this.props;

    const alwaysVisibleOptions = options?.filter((option) => option.alwaysVisible);
    const dropDownOptions = options?.filter((option) => !option.alwaysVisible);

    const menu = (
      <Menu>
        {dropDownOptions.map(this._renderOption)}
      </Menu>
    );
  
    return (
      <>
        {alwaysVisibleOptions.length > 0 && alwaysVisibleOptions.map((option) => this._renderOption(option, 'button'))}
        {dropDownOptions.length > 0 && (
          <Dropdown overlay={menu} placement="bottomLeft" arrow>
            <Button variant="none" icon={<MoreOutlined />} shape="circle" />
          </Dropdown>
        )}
      </>
    );
  }

  _renderOption(option, type) {
    const { icon: Icon } = option;

    if (option.hide) {
      return null;
    }

    const handleClick = !option.confirm
      ? option.onClick
      : this.handleConfirm(option.confirm.message, option.confirm.onConfirm);

    if (type == 'button') {
      return (
        <Tooltip key={option.label} title={option.label}>
          <Button variant="none" icon={<Icon {...option.iconProps} />} onClick={handleClick} shape="circle" />
          {' '}
        </Tooltip>
      );
    }

    return (
      <Menu.Item key={option.label} onClick={handleClick} icon={<Icon {...option.iconProps} />}>
        {option.label}
      </Menu.Item>
    );
  }
}