import React from "react";
import autoBind from 'react-autobind';
import {
  Form, Row, Col, Input, Select, Radio, Divider, Popover, Modal,
  Typography, Checkbox, DatePicker, InputNumber, Tooltip, Tag, Button,
} from 'antd';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TagOutlined, CopyOutlined, DownloadOutlined, PlusCircleOutlined } from '@ant-design/icons';
//
import Utils from '@/components/Utils';
import Globals from '@/config/Globals';
import '@/stylesheets/AdminMemberForm.scss';
//
export default class AdminMemberForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      country: Globals.DefaultMembersCountry,
      isPrintModalVisible: false,
      isPrintingLabel: false,
      labelPreview: null,
      labelCopied: false,
      isSavingPDF: false,
      appAdditionalFields: [],
      selectedField: null,
      additionalFields: [],
    };
  }

  async componentDidMount() {
    const { provinces, additionalFields } = this.props.app.sharedCache().getConfig();
    
    const defaultProvince = provinces[this.state.country].find(c => c.provinceCode == Globals.DefaultMembersProvince);
    this.setState({ appAdditionalFields: additionalFields?.sort((a, b) => a.displayOrder - b.displayOrder) });

    this.form.setFieldsValue({
      country: this.state.country,
      province: defaultProvince.provinceCode,
    });
  }

  async validateFields() {
    const formData = await this.form.validateFields();
    if (formData) {
      const data = {
        ...this.state.data,
        ...formData,
        phone: Utils.sanitizePhone(formData.phone), cell: Utils.sanitizePhone(formData.cell), pager: Utils.sanitizePhone(formData.pager),
        birthDate: (formData.birthDate ? moment(formData.birthDate).format(Globals.DefaultUIBirthDateFormat) : null),
        classificationDate: (formData.classificationDate ? moment(formData.classificationDate).format(Globals.DefaultUIBirthDateFormat) : null),
        initiationDate: (formData.initiationDate ? moment(formData.initiationDate).format(Globals.DefaultUIBirthDateFormat) : null),
        tqDate: (formData.tqDate ? moment(formData.tqDate).format(Globals.DefaultUIBirthDateFormat) : null),
        ipDate: (formData.ipDate ? moment(formData.ipDate).format(Globals.DefaultUIBirthDateFormat) : null),
        reinstatementDate: (formData.reinstatementDate ? moment(formData.reinstatementDate).format(Globals.DefaultUIBirthDateFormat) : null),
        priorStatusDate: (formData.priorStatusDate ? moment(formData.priorStatusDate).format(Globals.DefaultUIBirthDateFormat) : null),
        currentStatusDate: (formData.currentStatusDate ? moment(formData.currentStatusDate).format(Globals.DefaultUIBirthDateFormat) : null),
        duesPaidThru: (formData.duesPaidThru ? moment(formData.duesPaidThru).format(Globals.DefaultUIBirthDateFormat) : null),
        unitNumberID: this.state?.unitNumberID,
        middleName: formData?.middleName || '', email: formData?.email || '',
        meetingNoticeFlag: !!formData.meetingNoticeFlag, electedFlag: !!formData.electedFlag,
        street1: formData?.street1 || '', street2: formData?.street2 || '',
        city: formData?.city || '', postalCode: formData?.postalCode || '',
        province: formData?.province || null,
        postalCode: formData?.postalCode?.replace(/\s/g, '') || '',
        sin: formData?.sin?.replace(/\s/g, ''),
        cardNumber: formData?.cardNumber ? formData?.cardNumber.replace(/\s/g, '') : '',
        // disabled: !!formData.disabled
      };
      //Check for valid SIN
      if (data.sin && data.sin.length > 9) {
        this.props.app.alertController.showErrorAlert("Opss", "SIN has more than 9 digits. Please, review it and try again!");
        return;
      }
      //Remove readonly stuff
      delete data.unitNumber;
      delete data.currentEmployerName;
      delete data.currentEmployerRegisterDate;
      delete data.currentEmployerRegisterNumber;
      delete data.priorStatusCodeDescription;
      delete data.currentStatusCodeDescription;
      delete data.lastDuesEntryMonthsPaid;
      delete data.lastDuesEntryLU;
      delete data.lastDuesEntryBalFeeDues;
      delete data.lastDuesEntryReceiptNumber;
      delete data.assessments;
      //
      return data;
    } return false;
  }
  //Actions
  handleChangeCountry(countryCode) {
    this.setState({ country: countryCode, province: null});
    this.form.resetFields(['province']);
  }
  
  async handleShowLabel() {
    this.setState({ isPrintingLabel: true });

    const resp = await this._printLabel('preview');
    if (resp.statusCode == 200 && resp.body) {
      this.setState({ isPrintingLabel: false, labelPreview: resp.body.preview, isPrintModalVisible: true });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isPrintingLabel: false });
    }
  }
  
  async handlePrintLabel() {
    const resp = await this._printLabel('pdf');
    if (resp.statusCode == 200 && resp.body) {
      Utils.downloadBlob(resp.body, `${this.state.fullname} Label`, 'pdf');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }

  handleChangeFieldSelect(selectedField) {
    this.setState({ selectedField });
  }

  handleAddField() {
    const field = this.state.appAdditionalFields.find(field => field.id === this.state.selectedField);

    if (field) {
      this.setState(prevState => ({
        ...prevState,
        additionalFields: [
          ...prevState.additionalFields,
          field,
        ]?.sort((a, b) => a.displayOrder - b.displayOrder),
      }));
    }

    this.setState({ selectedField: null });
  }

  closePrintLabelModal() {
    this.setState(({ isPrintModalVisible: false, labelPreview: null, labelCopied: false, isSavingPDF: false }));
  }
  
  //Form
  resetFields() {
    return this.form.resetFields();
  }

  setFields(data) {
    const additionalFields = data?.additionalFields?.map((field) => this.state.appAdditionalFields.find(appField => appField.id == field.fieldID)) || [];
    this.setState({...data, additionalFields});
    
    const setAdditionalFields = {};
    if (data?.additionalFields) {
      data?.additionalFields.forEach(field => {
        setAdditionalFields[`additionalField.${field.fieldID}`] = field.value;
      });
    }
    
    return this.form.setFieldsValue({
      ...data,
      birthDate: (data.birthDate && data.birthDate.length > 0 ? moment(data.birthDate) : ''),
      classificationDate: (data.classificationDate && data.classificationDate.length > 0 ? moment(data.classificationDate) : ''),
      initiationDate: (data.initiationDate && data.initiationDate.length > 0 ? moment(data.initiationDate) : ''),
      tqDate: (data.tqDate && data.tqDate.length > 0 ? moment(data.tqDate) : ''),
      ipDate: (data.ipDate && data.ipDate.length > 0 ? moment(data.ipDate) : ''),
      reinstatementDate: (data.reinstatementDate && data.reinstatementDate.length > 0 ? moment(data.reinstatementDate) : ''),
      priorStatusDate: (data.priorStatusDate && data.priorStatusDate.length > 0 ? moment(data.priorStatusDate) : ''),
      currentStatusDate: (data.currentStatusDate && data.currentStatusDate.length > 0 ? moment(data.currentStatusDate) : ''),
      duesPaidThru: (data.duesPaidThru && data.duesPaidThru.length > 0 ? moment(data.duesPaidThru) : ''),
      currentEmployerRegisterDate: (data.currentEmployerRegisterDate && data.currentEmployerRegisterDate.length > 0 ? moment(data.currentEmployerRegisterDate) : ''),
      lastDuesEntryMonthsPaid: data.lastDuesEntryMonthsPaid || 'N/A',
      priorStatusCodeDescription: data?.priorStatusCodeDescription || 'N/A',
      currentStatusCodeDescription: data?.currentStatusCodeDescription || 'N/A', 
      street1: data?.street1 || '', street2: data?.street2 || '', city: data?.city || '', 
      postalCode: data.postalCode ? Utils.mask(data.postalCode?.replace(/\s/g, ''), '### ###') : '',
      sin: data.sin ? Utils.mask(Utils.extractNumbers(data.sin), '### ### ###') : '',
      currentEmployerName: data?.currentEmployerName || 'N/A',
      unitNumberID: data?.unitNumberID && data?.unitDescription ? `${data?.unitNumberID} (${data?.unitDescription})` : 'N/A',
      phone: Utils.formatPhone(data.phone), cell: Utils.formatPhone(data.cell), pager: Utils.formatPhone(data.pager),
      lastDuesEntryLU: data?.lastDuesEntryLU ? data.lastDuesEntryLU : 0,
      cardNumber: data?.cardNumber ? data?.cardNumber.replace(/\s/g, '') : '',
      ...setAdditionalFields,
    });
  }
  //
  render() {
    const { countries, provinces: provincesByCountry, dispatchLists, memberClassifications } = this.props.app.sharedCache().getConfig();
    const provinces = provincesByCountry[this.state.country];
    const layout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
    };

    return (
      <div className="admin-member-form">
        <Modal
          visible={this.state.isPrintModalVisible}
          title="Print label"
          onCancel={this.closePrintLabelModal}
          footer={null}
        >
          <pre>
            {this.state.labelPreview}
          </pre>

          <CopyToClipboard text={this.state.labelPreview} onCopy={() => this.setState({ labelCopied: true })}>
            <Button icon={<CopyOutlined />}>
              {!this.state.labelCopied ? 'Copy to clipboard' : 'Copied!'}
            </Button>
          </CopyToClipboard>
          <Button
            type="primary"
            loading={this.state.isSavingPDF}
            onClick={this.handlePrintLabel}
            style={{ marginLeft: 8 }}
            icon={<DownloadOutlined />}
          >
            Save as PDF
          </Button>
        </Modal>

        <Form {...layout} {...Utils.propagateRef(this, 'form')}>
          <Row>
            <Col span={6}>
              <Form.Item label="SIN" name="sin" rules={[
                { required: true, message: 'This field is required!' },
                { max: 11, message: 'Must have 11 characters!' }, // considering 2 whitespaces
              ]}>
                <Input />
              </Form.Item>
              <Form.Item label="Card No." name="cardNumber" rules={[{ required: false }]}>
                <Input maxLength={80}/>
              </Form.Item>
              <Form.Item label="Last name" name="lastName" rules={[{ required: true, message: 'This field is required!' }]}>
                <Input maxLength={80}/>
              </Form.Item>
              <Form.Item label="First name" name="firstName" rules={[{ required: true, message: 'This field is required!' }]}>
                <Input maxLength={80} autoFocus />
              </Form.Item>
              <Form.Item label="Middle name" name="middleName">
                <Input maxLength={80}/>
              </Form.Item>
              <Form.Item label="Birth Date" name="birthDate" rules={[{ required: false }]}>
                <DatePicker style={{ width: '100%' }}/>
              </Form.Item>
            </Col>

            <Col span={9}>
              <Form.Item label="Address" name="street1">
                <Input maxLength={80}/>
              </Form.Item>
              <Form.Item label="City" name="city">
                <Input maxLength={80}/>
              </Form.Item>
              <Form.Item label="Country" name="country" rules={[{ required: true, message: 'This field is required!' }]} >
                <Select placeholder="Select a Country" onChange={this.handleChangeCountry} showSearch
                  filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}>
                  {countries.map((country) => (
                    <Select.Option key={country.countryCode} value={country.countryCode}>{country.country}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Province" name="province" rules={[{ required: !!provinces, message: 'This field is required!' }]}>
                <Select placeholder={!provinces ? 'N/A' : "Select a Province"} disabled={!provinces} showSearch allowClear
                  filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}>
                  {(provinces || []).map((province) => (
                    <Select.Option key={province.provinceCode} value={province.provinceCode}>{province.province}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Postal Code" name="postalCode" rules={[{ required: false }]}>
                <Input maxLength={80}/>
              </Form.Item>
            </Col>

            <Col span={9}>
              <Form.Item label="Phone" name="phone">
                <Input maxLength={80}/>
              </Form.Item>
              <Form.Item label="Cell no." name="cell">
                <Input maxLength={80}/>
              </Form.Item>
              <Form.Item label="Work Phone" name="pager">
                <Input maxLength={80}/>
              </Form.Item>
              {this._wrapIntoEmailPopover(
                <Form.Item label="Email" name="email" rules={[{ required: !!this.state.idmID, message: 'This field is required!' }]}>
                  <Input maxLength={80}/>
                </Form.Item>
              )}
              {!this.props.isNew && (
                <Form.Item label=" " colon={false}>
                  <Button type="link" style={{ fontSize: 12, padding: 0 }} onClick={this.handleShowLabel}>
                    + Mailing Label
                  </Button>
                  <Button type="primary" size="small" style={{ marginLeft: 4, fontSize: 12 }} onClick={this.handlePrintLabel}>Print</Button>
                  {/* {this.state.labelPreview && (
                    <pre>
                      {this.state.labelPreview}
                    </pre>
                  )} */}
                </Form.Item>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              <Form.Item label="Sex" name="sex" rules={[{ required: true, message: 'This field is required' }]} initialValue="M">
                <Radio.Group options={[{ label: 'Male', value: 'M' }, { label: 'Female', value: 'F' }]} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Shop Steward" name="shopSteward" valuePropName="checked" style={{ width: 300 }}>
                <Checkbox id="shopSteward" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Meeting Notice" name="meetingNoticeFlag" valuePropName="checked">
                <Checkbox id="meetingNoticeFlag" style={{ width: 250 }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Elected Member" name="electedFlag" valuePropName="checked" style={{ width: 350 }}>
                <Checkbox id="electedFlag" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={9}>
              <Form.Item label="Unit Number">
                <strong>
                  {this.state?.unitNumberID && this.state?.unitDescription ? `${this.state?.unitNumberID} (${this.state?.unitDescription})` : 'N/A'}
                </strong>
              </Form.Item>
              <Form.Item label="Unemployed List" name="dispatchListID" >
                <Select placeholder="Select a Dispatch List" showSearch
                  filterOption={(input, option) => (option.value && option.children && option.children.join ? option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)}>
                  <Select.Option key={'empty'} value={null}><div style={{ opacity: .5 }}>None</div></Select.Option>
                  {(dispatchLists || []).map((list) => (
                    <Select.Option key={list.id} value={list.id}>{list.number} - {list.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Miscellaneous" name="comments">
                <Input.TextArea />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                name="memberType"
                label="Member type"
                className="bold-label"
                rules={[{ required: true, message: 'This field is required!' }]}>
                <Select placeholder="Select...">
                  {Object.keys(Globals.MemberStatuses).map((status) => (
                    <Select.Option key={status} value={status}>
                      {status}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Status" name="classificationID" >
                <Select placeholder="Select a classification" showSearch
                  filterOption={(input, option) => (option.value && option.children ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)}>
                  <Select.Option key={'empty'} value={null}><div style={{ opacity: .5 }}>None</div></Select.Option>
                  {(memberClassifications || []).map((classification) => (
                    <Select.Option key={classification.id} value={classification.name}>{classification.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Status Date" name="classificationDate" rules={[{ required: false }]}>
                <DatePicker style={{ width: '100%' }}/>
              </Form.Item>
              <Form.Item label="Appl. Submition" name="applicationSubmitted" valuePropName="checked">
                <Checkbox id="applicationSubmitted" />
              </Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item label="Initiation Date" name="initiationDate" rules={[{ required: false }]}>
                <DatePicker style={{ width: '100%' }}/>
              </Form.Item>
              <Form.Item label="Initiation Place" name="initiationPlace" rules={[{ required: false }]}>
                <Input maxLength={80}/>
              </Form.Item>
              <Form.Item label="Initiation Fee Paid" name="initiationFeePaid" rules={[{ required: false }]}>
                <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser}/>
              </Form.Item>
              <Form.Item label="PBA Fee Paid" name="pensionBenefitAdmissionFeePaid" rules={[{ required: false }]}>
                <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser}/>
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginTop: 12 }}>
            <Col span={5} offset={1}>
              <Form.Item label="TQ Certificate" name="tqNumber">
                <Input maxLength={80}/>
              </Form.Item>
              <Form.Item label="TQ Issue Date" name="tqDate" rules={[{ required: false }]}>
                <DatePicker style={{ width: 200 }}/>
              </Form.Item>
              <Form.Item label="IP Seal" name="ipNumber">
                <Input maxLength={80}/>
              </Form.Item>
              <Form.Item label="IP Issue Date" name="ipDate" rules={[{ required: false }]}>
                <DatePicker style={{ width: 200 }}/>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label="Reinst. Date" name="reinstatementDate" rules={[{ required: false }]}>
                <DatePicker style={{ width: 200 }}/>
              </Form.Item>
              <Form.Item label="Reinst. Fee Paid" name="reinstatementFeePaid">
                <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser}/>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="Employer" name="currentEmployerName">
                <strong>{this.state.currentEmployerName}</strong>
              </Form.Item>
              <Form.Item label="Job Classification">
                <strong>{this.state.classificationName}</strong>
              </Form.Item>
              <Form.Item label="Last Register Date" name="currentEmployerRegisterDate">
                <DatePicker bordered={false} disabled style={{ width: 200 }} placeholder="N/A"/>
              </Form.Item>
              <Form.Item label="Last Register No." name="currentEmployerRegisterNumber">
                <Input bordered={false} disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginTop: 12 }}>
            <Col span={6} offset={1}>
              <Form.Item label="Date Paid Thru" className="bold-label" name="duesPaidThru" rules={[{ required: false }]}>
                <DatePicker style={{ width: '100%' }}/>
              </Form.Item>
              <Form.Item label="Months Arrears" name="monthsArrears">
                <InputNumber precision={0} />
              </Form.Item>
              <Form.Item label="L.U. Arrears Bal." name="luArrears">
                <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser}/>
              </Form.Item>
              <Form.Item label="IO Arrears Bal." name="ioArrears">
                <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser}/>
              </Form.Item>
              <Form.Item label="Suspense Bal." name="suspenseBalance" className={`bold-label ${this.state.suspenseBalance < 0 ? 'negative' : ''}`}>
                <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser}/>
              </Form.Item>
              <Form.Item label="Dues Paid YTD" name="duesPaidYtd">
                <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser}/>
              </Form.Item>
              <Form.Item label="Dues Paid Next YTD" name="duesPaidNextYtd">
                <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser}/>
              </Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item label="Prior Activ. Code" name="priorStatusCodeDescription">
                <Input bordered={false} disabled style={{ fontWeight: 'normal' }} className="weight-normal" />
              </Form.Item>
              <Form.Item label="Prior Activ. Date" name="priorStatusDate">
                <DatePicker bordered={false} disabled style={{ width: 200, fontWeight: 'normal !important' }} placeholder="N/A" className="weight-normal" />
              </Form.Item>
              <Form.Item label="Current Activ. Code" name="currentStatusCodeDescription">
                <Input bordered={false} disabled className="weight-normal" />
              </Form.Item>
              <Form.Item label="Current Activ. Date" name="currentStatusDate">
                <DatePicker bordered={false} disabled style={{ width: 200 }} placeholder="N/A" className="weight-normal" />
              </Form.Item>
              <Form.Item label="No. Months Paid" name="lastDuesEntryMonthsPaid">
                <Input bordered={false} disabled className="weight-normal" />
              </Form.Item>
              <Form.Item label="L.U. Portion of Fee" name="lastDuesEntryLU">
                <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} bordered={false} disabled className="weight-normal" />
              </Form.Item>
              <Form.Item label="Bal. of Fee Due" name="lastDuesEntryBalFeeDues">
                <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} bordered={false} disabled className="weight-normal" />
              </Form.Item>
              <Form.Item label="Receipt No." name="lastDuesEntryReceiptNumber">
                <Input bordered={false} disabled className="weight-normal" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <div style={{ display: 'flex' }}>
                <Select
                  style={{ width: '100%' }}
                  value={this.state.selectedField}
                  placeholder="Select field..."
                  onChange={this.handleChangeFieldSelect}
                >
                  {this.state.appAdditionalFields.filter(field => !this.state.additionalFields.find(item => item.name === field.name)).map(field => (
                    <Select.Option key={field.id} value={field.id}>
                      {field.name}
                    </Select.Option>
                  ))}
                </Select>
                <Button style={{ marginLeft: 8, height: 30 }} type="primary" icon={<PlusCircleOutlined />} onClick={this.handleAddField}>
                  Add
                </Button>
              </div>
              {this.state.additionalFields.map(field => (
                <Form.Item key={field.id} name={`additionalField.${field.id}`} label={field.name}>
                  <Input />
                </Form.Item>
              ))}
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
  /* private functions */
  _printLabel(type) {
    return this.props.app.api.reports.memberLabel(this.state.id, type !== 'preview' ? 'application/pdf' : '');
  }

  _wrapIntoEmailPopover(toWrap) {
    if (this.props.isProfile && this.state.idmID) {
      return (<Popover trigger="focus"  getPopupContainer={triggerNode => triggerNode.parentNode}
        content={
          <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '400px' }}>
            <Typography.Title level={4}>Important notes for email change:</Typography.Title>
            <ul>
              <li>In order to take effect you need to <u>logout</u> after changing the email.</li>
              <li>If wrong email is typed, email change can lock you out of your account.</li>
            </ul>
          </div>
        }
      >{toWrap}</Popover>);
    } else if (this.state.idmID) {
      return (<Popover  getPopupContainer={triggerNode => triggerNode.parentNode}
        content={
          <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '400px' }}>
            <Typography.Title level={4}>Important notes for email change:</Typography.Title>
            <ul>
              <li>In order to take effect the member need to <u>logout</u> after the change.</li>
              <li>If wrong email is typed, email change can lock the member out of the account.</li>
            </ul>
          </div>
        }
      >{toWrap}</Popover>);
    } return (toWrap);
  }
}
