import Globals from '@/config/Globals';
//
export default class APIWorkActivities {
  constructor(API) {
    this.api = API;
  }

  async getAll() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_WorkActivities;
    return await req.exec();
  }

  async create(workActivityObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_WorkActivity + Globals.URL_NewIndentifier;
    req.body = { ...workActivityObj };
    return await req.exec();
  }

  async update(workActivityObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_WorkActivity + workActivityObj.id;
    delete workActivityObj.id;
    req.body = { ...workActivityObj };
    return await req.exec();
  }

  async remove(workActivityID) {
    const req = await this.api.newBaseRequest('DELETE');
    req.path = Globals.APIPath_WorkActivity + workActivityID;
    return await req.exec();
  }
}
