import React from "react";
const config = require('@/config/config');

export default class Logo extends React.Component {
  render() {
    let className = this.props.isHeading ? `headingLogo ${this.props.squeeze ? 'squeeze' : ''}` : "bwLogo";
    if (this.props.size == 'small') className += '-small';
    return (
      <a href={config.ApplicationRoutes.login}>
        <img className={className} alt="logo" src={(this.props.isHeading ?
            require('./../../images/logoLarge.png') :
            require('./../../images/logoSmall.png'))}/>
      </a>
    )
  }
}
