import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button, message } from 'antd';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import Utils from '@/components/Utils';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import Globals from "@/config/Globals";
import config from "@/config/config";
//
import AdminAdminManageAclsForm from './AdminAdminManageAclsForm';
// 
import '@/stylesheets/AdminAdminManageAclsView.scss';
//
export default class AdminAdminManageAclsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.adminName = this.props.app.urlManager.getQueryParam('n') || null;
    this.adminID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Manage Access`;
  }

  async handleSubmit() {
    const acls = await this.form.getAcls();
    this._update(acls);
  }
  
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader
          title={this.adminName ? `Manage Access - ${this.adminName}` : 'Manage Access'}
          onBack={() => window.history.back()} extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>Save</Button>]} />
        <Layout.Content>
          <WhiteBox>
            <AdminAdminManageAclsForm adminID={this.adminID} app={this.props.app} {...Utils.propagateRef(this, 'form')} />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _update(acls) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.admins.updateAcls(this.adminID, acls);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Admin Access successfully updated!');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading(true);
  }
}
