import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button, message } from 'antd';
//
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
//
import config from '@/config/config';
import Globals from '@/config/Globals';
//
import CommonDispatchJobForm from './CommonDispatchJobForm';
import Utils from "@/components/Utils";
//
export default class CommonCreateDispatchJobView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    const user = this.props.app.sharedCache().getUser();
    this.employerID = (
      this.props.app.isAdmin()
      ? Number(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Employer, this)) || null
      : user.id
      );

    this.state = {
      isLoading: false,
    };
  }

  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Create Dispatch Job`;
  }

  async handleCreate() {
    const formData = await this.form.validateFields();
    if (formData) {
      this._create(formData);
    }
  }
  
  //UI
  render() {
    const pageHeader = { onBack: () => window.history.back(), title: 'Create Dispatch Job' };

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader {...pageHeader} extra={[<Button type="primary" onClick={this.handleCreate}>Create</Button>]} />
        <Layout.Content>
          <WhiteBox>
            <CommonDispatchJobForm
              app={this.props.app}
              employerID={this.employerID}
              {...Utils.propagateRef(this, 'form')}
            />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _create(data) {
    this.startLoading();
    const { employerID } = data;
    delete data.employerID;
    const resp = await this.props.app.api.dispatchJob.create(data, employerID);
    if (resp.statusCode == 200 && resp.body) {
      message.success('Dispatch Job successfully created!');
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.updateDispatchJob, null, employerID, resp.body.id);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading(true);
  }
}
