import React from "react";
import autoBind from 'react-autobind';
import { Form, Row, Col, Input, Popover, DatePicker, InputNumber, Descriptions } from 'antd';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
//
import Utils from '@/components/Utils';
import Globals from '@/config/Globals';
//
export default class AdminBatchEditForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  setFields(batchData, employerData) {
    this.setState({ ...batchData, employer: employerData });
    return this.form.setFieldsValue({
      employerName: (employerData?.name ? `${employerData?.companyNumber} - ${employerData?.name}` : ''),
      batchDate: (batchData.postingDate && batchData.postingDate.length > 0 ? moment(batchData.postingDate) : ''),
      batchID: batchData.id || '',
      batchTotal: batchData.batchTotal || 0
    });
  }
  //
  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')} className='batchEditForm'>
        <Form.Item label="Employer" name="employerName" initialValue=''>
          <Input bordered={false} disabled prefix={this._renderEmployerInformationSuffix()} />
        </Form.Item>
        <Row>
          <Col>
            <Form.Item label="Batch ID" name="batchID" initialValue=''>
              <Input bordered={false} disabled />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item label="Batch Date" name="batchDate">
              <DatePicker format={Globals.DefaultUIBirthDateFormat} style={{ width: 200 }} bordered={false} disabled/>
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item label="Batch Total" name="batchTotal" initialValue=''>
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} bordered={false} 
                           style={{ width: 200 }} disabled/>
            </Form.Item>
          </Col>
        </Row>    
      </Form>
    )
  }
  //Private UI
  _renderEmployerInformationSuffix() {
    return (
      this.state.employer ?
        <Popover title={<b>Employer information</b>} content={
          <Descriptions size='small' column={4} style={{ maxWidth: '400px' }}>
            <Descriptions.Item label={<b>Name</b>} span={4}> {this.state?.employer?.name} </Descriptions.Item>
            <Descriptions.Item label={<b>Number</b>} span={2}> {this.state?.employer?.companyNumber} </Descriptions.Item>
            <Descriptions.Item label={<b>Inactive</b>} span={2}> {this.state?.employer?.disabled ? 'Yes' : 'No'} </Descriptions.Item>
            <Descriptions.Item label={<b>Email</b>} span={4}> {this.state?.employer?.email} </Descriptions.Item>
            <Descriptions.Item label={<b>Phone</b>} span={4}> {this.state?.employer?.phone ? Utils.formatPhone(this.state?.employer?.phone) : 'N/A'} </Descriptions.Item>
            <Descriptions.Item label={<b>Alt Phone</b>} span={4}> {this.state?.employer?.altPhone ? Utils.formatPhone(this.state?.employer?.altPhone) : 'N/A'} </Descriptions.Item>
            <Descriptions.Item label={<b>Fax</b>} span={4}> {this.state?.employer?.fax ? Utils.formatPhone(this.state?.employer?.fax) : 'N/A'} </Descriptions.Item>
          </Descriptions>
        }>
          <InfoCircleOutlined className='formInlineButton' />
        </Popover> : <></>
    )
  }
}
