import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button, message } from 'antd';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import Utils from '@/components/Utils';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import AdminAdminsForm from './AdminAdminForm';
import Globals from "@/config/Globals";
import config from "@/config/config";
//
export default class AdminAdminCreateView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      departments: [],
      provinces: [],
    };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Create Admin`;
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) {
      this._create(formData);
    }
  }
  
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Create Admin" onBack={() => window.history.back()} extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>Create</Button>]} />
        <Layout.Content>
          <WhiteBox>
            <AdminAdminsForm app={this.props.app} {...Utils.propagateRef(this, 'form')} />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _create(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.admins.create({ ...data, country: Globals.DefaultAdminCountry });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Admin successfully created!');
      this.props.app.refreshConfig();
      this.form.resetFields();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading(true);
  }
}
