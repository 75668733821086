import Globals from '@/config/Globals';
//
export default class APISectors {
  constructor(API) {
    this.api = API;
  }

  async getAll() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Sectors;
    return await req.exec();
  }

  async getByID(sectorID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Sector + sectorID;
    return await req.exec();
  }

  async create(sectorObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Sector + Globals.URL_NewIndentifier;
    req.body = { ...sectorObj };
    return await req.exec();
  }

  async update(sectorObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_Sector + sectorObj.id;
    delete sectorObj.id;
    req.body = { ...sectorObj };
    return await req.exec();
  }

  async remove(sectorID) {
    const req = await this.api.newBaseRequest('DELETE');
    req.path = Globals.APIPath_Sector + sectorID;
    return await req.exec();
  }
}
