import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Form, Row, Col, Select, DatePicker, Button, Tag, Checkbox, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { GrDocumentPdf, GrDocumentExcel } from "react-icons/gr";
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import config from "@/config/config";
import CommonEmployerSelectionModal from "@/views/commonComponents/CommonEmployerSelectionModal";
import Utils from "@/components/Utils";
import Globals from "@/config/Globals";
import memberColumns from "./memberColumns";
//
export default class AdminCustomMembersReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    const { sectors, memberClassifications, unitsNumbers, memberStatuses } = this.props.app.sharedCache().getConfig();

    this.state = {
      isLoading: false,
      classifications: memberClassifications || [],
      sectors: sectors || [],
      units: unitsNumbers || [],
      memberStatuses: memberStatuses || [],
      selectedEmployers: [],
      allSectors: false,
      allUnits: false,
      allColumns: true,
      selectedColumns: memberColumns.map(column => column.value),
    };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Custom Members Report`;
  }

  handleSearchEmployer() {
    this.employerSelectionModal.show();
  }

  handleSelectEmployer(employer) {
    this.setState((prevState) => ({
      ...prevState,
      selectedEmployers: [...prevState.selectedEmployers, employer],
    }));
  }

  handleDeselectEmployer(removedEmployer) {
    this.setState((prevState) => ({
      ...prevState,
      selectedEmployers: prevState.selectedEmployers.filter((employer) => employer.id !== removedEmployer.id),
    }));
  }
  
  async handleSubmit() {
    const formData = await this.form.validateFields();

    if (formData) {
      formData.employerID = this.state.selectedEmployers.map((employer) => employer.id);
      formData.employerID = formData.employerID.length < 1 ? null : formData.employerID;

      formData.sectorID = formData.sectorID?.length < 1 ? null : formData.sectorID;
      formData.unitNumberID = formData.unitNumberID?.length < 1 ? null : formData.unitNumberID;

      formData.duesPaidThru = formData.duesPaidThru ? moment(formData.duesPaidThru).format(Globals.DefaultSimpleDateFormat) : null;
      formData.initiationDate = formData.initiationDate ? moment(formData.initiationDate).format(Globals.DefaultSimpleDateFormat) : null;

      formData.columns = this.state.selectedColumns;

      if (formData.columns.length < 1) {
        message.error('Select at least one column before requesting the export.');
        return;
      }

      const validFilters = Object.keys(formData).filter((key) => (key !== 'columns' && !!formData[key]));
      if (validFilters.length < 1) {
        message.error('Your export will generate too many results, please select one or more filters before requesting the export.');
        return;
      }

      this._generate(formData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }
  }

  handleChangeAllSectors({ target: { checked } }) {
    if (checked) {
      this.setState({ allSectors: true });
      this.form.setFieldsValue({
        sectorID: [],
      });
    } else {
      this.setState({ allSectors: false });
    }
  }

  handleChangeAllUnits({ target: { checked } }) {
    if (checked) {
      this.setState({ allUnits: true });
      this.form.setFieldsValue({
        unitNumberID: [],
      });
    } else {
      this.setState({ allUnits: false });
    }
  }

  handleChangeAllColumns({ target: { checked } }) {
    if (checked) {
      this.setState({
        allColumns: true,
        selectedColumns: memberColumns.map(column => column.value),
      });
    } else {
      this.setState({ allColumns: false, selectedColumns: [] });
    }
  }

  handleChangeColumns(selectedColumns) {
    this.setState({
      selectedColumns,
      allColumns: selectedColumns.length === memberColumns.length,
    });
  }

  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonEmployerSelectionModal
          app={this.props.app}
          onSelect={this.handleSelectEmployer}
          disable={this.state.selectedEmployers.map((employer) => employer.id)}
          {...Utils.propagateRef(this, 'employerSelectionModal')}
        />

        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Custom Members Report" extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>Generate Report</Button>]} />
        <Layout.Content>
          <WhiteBox>
            <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
              <Row>
                <Col>
                  <Form.Item label="Employers">
                    {this.state.selectedEmployers.length > 0 && (
                      this.state.selectedEmployers.map((employer) => (
                        <Tag
                          key={employer.id} closable
                          onClose={this.handleDeselectEmployer.bind(this, employer)}
                        >
                          {employer.name}
                        </Tag>
                      ))
                    )}
                    <Tag className="site-tag-plus" onClick={this.handleSearchEmployer}>
                      <PlusOutlined /> Select Employer
                    </Tag>
                  </Form.Item>
                </Col>
                <Col offset={1}>
                  <Form.Item name="employerStatus" label="Employer Status" initialValue={Globals.EmployerSearch_Status.ANY.key}>
                    <Select placeholder="Select...">
                      {Object.keys(Globals.EmployerSearch_Status).map((item) => (
                        <Select.Option
                          key={Globals.EmployerSearch_Status[item].key}
                          value={Globals.EmployerSearch_Status[item].key}
                        >
                          {Globals.EmployerSearch_Status[item].name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={5} style={{ display: 'flex' }}>
                  <Form.Item name="duesPaidThruOperator" label="Dues Paid Thru" initialValue="=">
                    <Select placeholder="Select..." style={{ width: 60 }}>
                      <Select.Option value=">">{`>`}</Select.Option>
                      <Select.Option value=">=">{`>=`}</Select.Option>
                      <Select.Option value="=">{`=`}</Select.Option>
                      <Select.Option value="<">{`<`}</Select.Option>
                      <Select.Option value="<=">{`<=`}</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="duesPaidThru" label=" " style={{ marginLeft: -30 }}>
                    <DatePicker format={Globals.DefaultSimpleDateFormat} style={{ flex: 1 }} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="initiationDate" label="Initiation Date">
                    <DatePicker format={Globals.DefaultSimpleDateFormat} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={11} offset={1}>
                  <Form.Item name="memberTypes" label="Member Types">
                    <Select placeholder="Select..." mode="multiple">
                      {Object.keys(Globals.MemberStatuses).map((key) => (
                        <Select.Option key={key} value={key}>{Globals.MemberStatuses[key]}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={9}>
                  <Form.Item name="sectorID" label="Sectors">
                    <Select placeholder="Select..." mode="multiple" disabled={this.state.allSectors}>
                      {this.state.sectors.map((sector) => (
                        <Select.Option key={sector.id} value={sector.id}>{sector.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: 10 }}>
                  <Form.Item label=" ">
                    <Checkbox onChange={this.handleChangeAllSectors}>All sectors</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8} offset={1}>
                  <Form.Item name="unitNumberID" label="Units">
                    <Select placeholder="Select..." mode="multiple" disabled={this.state.allUnits}>
                      {this.state.units.map((unit) => (
                        <Select.Option key={unit.id} value={unit.id}>{unit.number} - {unit.description}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: 10 }}>
                  <Form.Item label=" ">
                    <Checkbox onChange={this.handleChangeAllUnits}>All units</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={7}>
                  <Form.Item name="classificationID" label="Classification">
                    <Select placeholder="Select..." mode="multiple">
                      {this.state.classifications.map((code) => (
                        <Select.Option key={code.id} value={code.id}>{code.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item name="status" label="Member Status">
                    <Select placeholder="Select...">
                      {Object.keys(Globals.MemberSearch_Status).map((item) => (
                        <Select.Option
                          key={Globals.MemberSearch_Status[item].key}
                          value={Globals.MemberSearch_Status[item].key}
                        >
                          {Globals.MemberSearch_Status[item].name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={10} offset={1}>
                  <Form.Item name="statusID" label="Activity Code">
                    <Select placeholder="Select..." mode="multiple">
                      {this.state.memberStatuses.map((code) => (
                        <Select.Option key={code.id} value={code.id}>{code.statusCode} - {code.description}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label="Columns">
                <Col span={24}>
                  <Checkbox onChange={this.handleChangeAllColumns} checked={this.state.allColumns} style={{ lineHeight: '32px' }}>
                    <strong>
                      {this.state.allColumns ? 'Deselect' : 'Select'} All
                    </strong>
                  </Checkbox>
                </Col>

                <Checkbox.Group value={this.state.selectedColumns} onChange={this.handleChangeColumns}>
                  <Row>
                    {memberColumns.map((column) => (
                      <Col span={4} key={column.value}>
                        <Checkbox value={column.value} style={{ lineHeight: '32px' }}>
                          {column.label}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Form>
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  /* private API */
  async _generate(data, contentType) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.reports.membersCustom(data, contentType);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      Utils.downloadBlob(resp.body, `members-${moment(new Date()).format(Globals.DefaultSimpleDateFormat)}`, contentType === 'application/pdf' ? 'pdf' : 'xlsx');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.setState({ isLoading: false });
  }
}
