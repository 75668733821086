export default [
  {
    value: 'firstName',
    label: 'First Name',
  },
  {
    value: 'middleName',
    label: 'Middle Name',
  },
  {
    value: 'lastName',
    label: 'Last Name',
  },
  {
    value: 'birthDate',
    label: 'Birth Date',
  },
  {
    value: 'age',
    label: 'Age',
  },
  {
    value: 'memberType',
    label: 'Member Type',
  },
  {
    value: 'cardNumber',
    label: 'Card Number',
  },
  {
    value: 'companyName',
    label: 'Company Name',
  },
  {
    value: 'companyNumber',
    label: 'Company Number',
  },
  {
    value: 'street1',
    label: 'Street 1',
  },
  {
    value: 'street2',
    label: 'Street 2',
  },
  {
    value: 'city',
    label: 'City',
  },
  {
    value: 'postalCode',
    label: 'Postal Code',
  },
  {
    value: 'province',
    label: 'Province',
  },
  {
    value: 'country',
    label: 'Country',
  },
  {
    value: 'phone',
    label: 'Phone',
  },
  {
    value: 'cell',
    label: 'Cell',
  },
  {
    value: 'pager',
    label: 'Pager',
  },
  {
    value: 'altPhone',
    label: 'Alt. Phone',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'classification',
    label: 'Classification',
  },
  {
    value: 'sectorName',
    label: 'Sector Name',
  },
  {
    value: 'unitDescription',
    label: 'Unit Description',
  },
  {
    value: 'statusDescription',
    label: 'Status Description',
  },
  {
    value: 'initiationDate',
    label: 'Initiation Date',
  },
  {
    value: 'shopSteward',
    label: 'Shop Steward',
  },
  {
    value: 'comments',
    label: 'Comments',
  },
  {
    value: 'sin',
    label: 'SIN',
  },
  {
    value: 'jobclassificationname',
    label: 'Job Classification',
  },
  {
    value: 'sex',
    label: 'Sex',
  },
  {
    value: 'duesPaidThru',
    label: 'Dues Paid Thru',
  },
  {
    value: 'suspenseBalance',
    label: 'Suspense Balance',
  },
  {
    value: 'applicationSubmitted',
    label: 'Application Submitted',
  },
];