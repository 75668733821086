import React from "react";
import autoBind from 'react-autobind';
//
import CustomComponent from '@/components/CustomComponent';
//
import config from '@/config/config';
import Globals from "@/config/Globals";
//
export default class AdminDashboardView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.props.app.redirectToHome();
  }
  
  render() {
    return (<></>)
  }
}
