import React from "react";
import autoBind from 'react-autobind';
import { Statistic, Row, Col, Form, DatePicker, Select, Alert, Input } from 'antd';
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
import Utils from "@/components/Utils";
import Globals from "@/config/Globals";
//
const { Option } = Select;
//
export default class SummaryStep extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  validateFields() {
    return this.form.validateFields();
  }

  render() {
    let disablePeriod = false;
    let period = moment(new Date(), Globals.DefaultMonthDateFormat);

    if (this.props?.lastRemittance?.submissionPeriod) {
      const date = new Date(this.props.lastRemittance.submissionPeriod);
      period = moment(date, Globals.DefaultMonthDateFormat).add(1, 'M');
      disablePeriod = true;
    }

    return (
      <>
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          {(this.props.invalidSins && this.props.invalidSins.length > 0) && (
            <Row>
              <Col span={24}>
                <Alert
                  message="Attention!"
                  description={
                    <>
                      <span>Please submit a membership application for the following employees:</span>
                      <ul>
                        {this.props.invalidSins.map(member => (
                          <li key={member.sin}>{member.name} ({member.sin})</li>
                        ))}
                      </ul>
                    </>
                  }
                  type="warning"
                  showIcon
                  style={{ marginBottom: 20 }}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col span={4}>
              <Form.Item
                name="period"
                label="Period"
                initialValue={period}
                rules={[{ required: true, message: 'Period is required' }]}
              >
                <DatePicker format={Globals.DefaultMonthDateFormat} picker="month" disabled={disablePeriod} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            {this.props.type !== 'arrears' && (
              <Col span={7} offset={1}>
                <Form.Item
                  name="paymentType"
                  label="Payment Type"
                  rules={[{ required: true, message: 'Payment type is required' }]}
                >
                  <Select placeholder="Select the payment type">
                    {Object.keys(Globals.Remittance_PaymentTypes).map(key => (
                      <Option
                        value={Globals.Remittance_PaymentTypes[key].key}
                        key={Globals.Remittance_PaymentTypes[key].key}
                      >
                        {Globals.Remittance_PaymentTypes[key].name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="comments" label="Comments" rules={[{ max: 250, message: 'Only 250 chars are allowed' }, { required: false }]}>
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {(this.props.invalidSins && this.props.invalidSins.length > 0) && (
          <Row>
            <Col span={24}>
              <Alert
                message="Submission contains employees without or with a pending application."
                type="warning"
                style={{ marginBottom: 20 }}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col span={6}>
            <Statistic title="Employees" value={this.props.sum.members} />
          </Col>
          <Col span={6}>
            <Statistic title="Per Capita" value={`$${Utils.toCurrencyFormat(this.props.sum.percapita)}`} />
          </Col>
          <Col span={6}>
            <Statistic title="Working Dues" value={`$${Utils.toCurrencyFormat(this.props.sum.workingdues)}`} />
          </Col>
          {this.props.type !== 'arrears' && (
            <Col span={6}>
              <Statistic title="Init Fee" value={`$${Utils.toCurrencyFormat(this.props.sum.initiationfee)}`} />
            </Col>
          )}
          <Col span={6}>
            <Statistic title="Total" value={`$${Utils.toCurrencyFormat(this.props.sum.percapita + this.props.sum.workingdues + this.props.sum.initiationfee)}`} />
          </Col>
        </Row>
      </>
    );
  }
}
