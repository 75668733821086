import React from "react";
import autoBind from 'react-autobind';
import { Drawer, Row, Col, Button, Alert, Tooltip, Result, message } from 'antd';
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
//
import CustomComponent from '@/components/CustomComponent';
import CommonLoadingView from "./CommonLoadingView";
//
import CommonDuesEntryDuesForm from '@/views/commonComponents/Forms/CommonDuesEntryDuesForm';
import CommonMemberReadOnlyForm from '@/views/commonComponents/Forms/CommonMemberReadOnlyForm';
import CommonMemberSelectionPopover from '@/views/commonComponents/CommonMemberSelectionPopover';
//
import Utils from '@/components/Utils';
import Globals from '@/config/Globals';
//
export default class CommonDuesEntryDuesDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false, isVisible: false, isEdit: false,
      //refs
      entryID: null, memberID: null, 
      //API
      entryObj: null, memberObj: null, notes: null,
      //Local
      alerts: [], employerObj: null, batchObj: null
    };
  }
  //Public
  show(entryID, memberID, employerObj, batchObj) {
    this.setState({ 
      isVisible: true, entryID, memberID, entryObj: null, memberObj: null, 
      isLoading: true, employerObj, batchObj, isEdit: !!memberID
    }, () => {
        if (this.memberForm) this.memberForm.resetFields();
        if (this.duesForm) this.duesForm.resetFields();
        //smoother drawer transition
        setTimeout(this._fetchData, 400)
    });
  }
  //Actions
    //Form
  handleClose() {
    this.props.onEnd();
    this.setState({
      isVisible: false, entryID: null, memberID: null, 
      entryObj: null, memberObj: null, isLoading: false, 
      alerts: [], employerObj: null, batchObj: null,
      isEdit: false, notes: []
    }); 
  }
  async handleSave() {
    //Validate form
    const formData = await this.duesForm.validateFields();
    if (!formData) return;
    //make request
    if (this.state.entryObj) this._update(formData);
    else this._create(formData);
  }
    //Member selection
  handleSelectMember(employer, args) { 
    this.setState({ memberObj: employer }, () => {
      if (this.state.memberObj) this.memberForm.setFieldsValue(this.state.memberObj);
      if (this.state.memberObj) this.duesForm.setFieldsValue(this.state.entryObj, this.state.memberObj);
    }); 
  }
  handleDeselectMember() { this.setState({ memberObj: null }); }
  //Private main methods
  async _fetchData() {
    this.startLoading();
    await Utils.execRequests([
      (this.state.entryID ? this._loadEntry() : Promise.resolve()), 
      (this.state.memberID ? this._loadMember() : Promise.resolve()),
      (this.state.memberID ? this._loadNotes() : Promise.resolve())
    ]);
    if (this.state.memberObj) this.memberForm.setFieldsValue(this.state.memberObj);
    if (this.state.memberObj) this.duesForm.setFieldsValue(this.state.entryObj, this.state.memberObj);
    this.setState({ isLoading: false, alerts: this._checkAlerts() })
  }

  //UI
  render() {
    const buttonRow = (
      <Row type='flex' justify='end' style={{ marginBottom: '10px' }}>
        <Col> <Button type="primary" onClick={this.handleSave} disabled={!this.state.memberObj}> {this.state.entryObj ? 'Update Entry' : 'Create Entry'} </Button> </Col>
      </Row>
    );
    return (
      <Drawer title="Dues Entry" visible={this.state.isVisible} onClose={this.handleClose} footer={null} closable width={700}
              className='smallPaddingDrawer'>
        <CommonLoadingView isLoading={this.state.isLoading}/>
        {this._renderMessages()}
        {!this.state.memberObj && !this.state.isLoading ?
          <Result icon={<SearchOutlined />} title="Please, search for a member to get started!" extra={
              <CommonMemberSelectionPopover app={this.props.app} onSelect={this.handleSelectMember} {...Utils.propagateRef(this, 'memberSelectionModal')}>
                <Button type="ghost">Click to find an member</Button>
              </CommonMemberSelectionPopover>}/>
          :
          <span>
            <CommonMemberReadOnlyForm readOnly {...Utils.propagateRef(this, 'memberForm')} app={this.props.app} extraHeaderContent={
              !this.state.isEdit ? <Tooltip title="Select another member">
                <Button icon={<CloseCircleOutlined />} type="link" onClick={this.handleDeselectMember} />
              </Tooltip> : <></>
            } />
            <CommonDuesEntryDuesForm {...Utils.propagateRef(this, 'duesForm')} app={this.props.app} extra={buttonRow}
                                     employerObj={this.state.employerObj} batchObj={this.state.batchObj}/>
          </span>}
      </Drawer>
    )
  }
  
  /* private renders */
  _renderMessages() {
    return (
      <>
        {(this.state?.alerts || [])?.map((alert, i) => {
          return <Alert key={i} message={alert.message} type={alert.type} showIcon description={alert.description}/>
        })}
      </>
    )
  }
  // API Calls
  async _loadEntry() {
    return new Promise(async (resolve) => {
      const resp = await this.props.app.api.duesEntry.getByID(this.state?.employerObj?.id, this.state?.batchObj?.id, this.state?.entryID);
      if (!this._isMounted) {
        resolve(null);
        return;
      };
      if (resp.statusCode == 200 && resp.body) {
        this.setState({ entryObj: resp.body }, () => resolve(resp));
      } else {
        this.props.app.alertController.showAPIErrorAlert(null, resp);
        this.setState({ entryObj: null }, () => resolve(resp));
      }
    });
  }
  async _loadMember() {
    return new Promise(async (resolve) => {
      const resp = await this.props.app.api.members.getByID(this.state?.memberID);
      if (!this._isMounted) {
        resolve(null);
        return;
      }
      if (resp.statusCode == 200 && resp.body) {
        this.setState({ memberObj: resp.body }, () => resolve(resp));
      } else {
        this.props.app.alertController.showAPIErrorAlert(null, resp);
        this.setState({ memberObj: null }, () => resolve(resp));
      }
    });
  }
  async _loadNotes() {
    return new Promise(async (resolve) => {
      const resp = await this.props.app.api.notes.getAll('any', this.state?.memberID, Globals.Notes_relTypes.member.apiKey);
      if (!this._isMounted) {
        resolve(null);
        return;
      }
      if (resp.statusCode == 200 && resp.body) {
        this.setState({ notes: resp.body.notes }, () => resolve(resp));
      } else {
        this.props.app.alertController.showAPIErrorAlert(null, resp);
        this.setState({ notes: null }, () => resolve(resp));
      } return resp;
    });
  }
  async _update(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.duesEntry.update(this.state.employerObj.id, this.state.batchObj.id, this.state.entryObj.id, {
      ...data,
      memberID: this.state.memberObj.id
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Dues entry successfully updated!');
      this.props.onEnd(this.state.entryObj.id);
      this.setState({ isVisible: false });
      await this._fetchData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  async _create(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.duesEntry.create(this.state.employerObj.id, this.state.batchObj.id, {
      ...data,
      memberID: this.state.memberObj.id
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Dues entry successfully created!');
      this.props.onEnd(resp.body.id);
      this.setState({ isVisible: false });
      await this._fetchData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  

  /* private helpers */
  _checkAlerts() {
    let alerts = [];
    //Check for urgent notes
    const urgentNotes = (this.state?.notes || []).filter((n) => n.urgent);
    if (urgentNotes?.length > 0) {
      const notes = urgentNotes.map((n) => <li key={n.id}> {n.content} </li>);
      alerts.push({ type: 'warning', message: 'Urgent Notes', description: notes })
    }
    //Out of jurisdiction
    if (this.state?.memberObj?.outOfJurisdiction) alerts.push({ type: 'warning', message: 'Member is Out of Jurisdiction.' });
    //Arrears
    if (this.state?.memberObj?.arrearsIO > 0 || this.state?.memberObj?.arrearsLU > 0) alerts.push({ type: 'warning', message: 'Member is in Arrears.' });
    //Permit
    if (this.state?.memberObj?.memberType == 'PA' || this.state?.memberObj?.memberType == 'PB') alerts.push({ type: 'warning', message: 'Member is on permit.' });
    //Status
    if (this.state?.memberObj?.memberStatus == Globals.MemberStatus_Options.MEMBER_STATUS_DROPPED) alerts.push({ type: 'warning', message: 'Member is dropped.' });
    if (this.state?.memberObj?.memberStatus == Globals.MemberStatus_Options.MEMBER_STATUS_WITHDRAWAL_CARD) alerts.push({ type: 'warning', message: 'Member is on honorary withdrawal (card issued).' });
    if (this.state?.memberObj?.memberStatus == Globals.MemberStatus_Options.MEMBER_STATUS_PENSION) alerts.push({ type: 'warning', message: 'Member is on pension or traveling card.' });
    if (this.state?.memberObj?.memberStatus == Globals.MemberStatus_Options.MEMBER_STATUS_DECEASED) alerts.push({ type: 'warning', message: 'Member is deceased.' });
    if (this.state?.memberObj?.memberStatus == Globals.MemberStatus_Options.MEMBER_STATUS_EXPELLED) alerts.push({ type: 'warning', message: 'Member is expelled.' });
    if (this.state?.memberObj?.memberStatus == Globals.MemberStatus_Options.MEMBER_STATUS_BA_WITHDRAWAL) alerts.push({ type: 'warning', message: 'Member is on honorary withdrawal (no card issued).' });
    return alerts;
  }
}
