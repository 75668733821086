import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Form, Row, Col, Select, Button, message, Table } from 'antd';
import { GrDocumentPdf, GrDocumentExcel } from "react-icons/gr";
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import config from "@/config/config";
import Globals from "@/config/Globals";
import Utils from "@/components/Utils";
//
export default class AdminMemberDispatchSlipReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, dispatchSlips: [], selectedRows: [] };
  }
  //Life cycle
  componentDidMount() { 
    document.title = `${config.ApplicationName} - Dispatch Slip`; 
    if (!this.state.isLoading) this._loadData();
  }
  //Actions
    //Main actions
  handleMarkAsPrinted() { this._markAsPrinted(); }
  handlePrint() { this._generate(); }
    //Table actions
  handleTableSelection(e, selected, rows) {  this.setState({ selectedRows: rows.map((o) => o.id) }); }
  handleTableAllSelection(e, selected, rows) {
    if (e) this.setState({ selectedRows: rows.map((o) => o.id) });
    else this.setState({ selectedRows: [] });
  }
  handlePageChange() { this.setState({ selectedRows: [] }); } //reset rows on purpose

  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Dispatch List" extra={[
          <Button key="1" onClick={this.handlePrint} type='primary' disabled={!this.state.selectedRows || this.state.selectedRows.length < 1 || this.state.isLoading}>Print Slips</Button>,
          <Button key="2" onClick={this.handleMarkAsPrinted} type='secondary' disabled={!this.state.selectedRows || this.state.selectedRows.length < 1 || this.state.isLoading}>Mark as Printed</Button>
        ]} />
        <Layout.Content>
          <WhiteBox> {this._renderTable()} </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  /* private UI */
  _renderTable() {
    const columns = [
      { title: 'Job No', dataIndex: 'jobNumber', key: 'jobNumber' },
      { title: 'Member Name', dataIndex: 'memberName', key: 'memberName' },
      { title: 'Employer', dataIndex: 'employer', key: 'employer' },
      {
        title: 'Start Date', dataIndex: 'startDate', key: 'startDate',
        render: date => date ? moment(date).format(Globals.DefaultUIBirthDateFormat) : '-',
      },
    ];

    return (
      <Table dataSource={this.state.dispatchSlips} columns={columns}
        rowKey="id"  scroll={{ x: 1500 }} loading={this.state.isLoading}
        pagination={{ pageSize: 9, onChange: this.handlePageChange}}
        rowSelection={{
          type: 'checkbox', selectedRowKeys: this.state.selectedRows,
          onSelect: this.handleTableSelection, onSelectAll: this.handleTableAllSelection
        }}
      />
    );
  }


  /* private API */
  async _loadData() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.dispatchSlipsRun.getAll();
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.setState({ dispatchSlips: resp.body.dispatchSlips.map((o, i) => ({ ...o, id: i })), isLoading: false, selectedRows: [] });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _markAsPrinted() {
    this.startLoading();
    console.log(this.state.selectedRows.map((i) => this.state.dispatchSlips[i].dispatchSlipID));
    console.log(this.state)
    //request
    const slipIDs = this.state.selectedRows.map((i) => this.state.dispatchSlips[i].dispatchSlipID);
    const resp = await this.props.app.api.dispatchSlipsRun.markAsPrinted(slipIDs);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Successfully marked as read!');
      this._loadData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _generate() {
    this.startLoading();
    //request
    const slipIDs = this.state.selectedRows.map((i) => this.state.dispatchSlips[i].dispatchSlipID);
    const resp = await this.props.app.api.dispatchSlipsRun.print(slipIDs);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      Utils.downloadBlob(resp.body, `Slips - ${moment(new Date()).format('MM-DD-YYYY')}`, 'pdf');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.setState({ isLoading: false });
  }
}
