import React from "react";
import autoBind from 'react-autobind';
import moment from 'moment';
import {
  Layout, PageHeader, Row, Col, Statistic, Button, Table, Form, Select, Dropdown, Menu, Popover, Alert,
  Collapse, notification, Tooltip, Result, Typography, message
} from 'antd';
import { WarningOutlined, CloseCircleOutlined, CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { GrDocumentPdf, GrDocumentTxt } from "react-icons/gr";
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import config from '@/config/config';
import Globals from "@/config/Globals";
import Utils from "@/components/Utils";
//
import AdminEmployerOptions, { AdminEmployerOptionTypes } from '@/views/commonComponents/AdminEmployerOptions';
//
import '@/stylesheets/AdminImportRemittanceView.scss';
//
export default class AdminImportRemittanceView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.employerID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    this.fileID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID2_Placeholder, this);

    this.state = {
      // Load states
      isLoading: false, // when screen is loading
      isValidating: false,
      isCommitting: false,
      isDownloading: false,
      //
      commitError: null,
      isValid: false,
      successfullyCommitted: false,
      generalErrors: null,
      data: {},
      filter: Globals.Remittance_FilterTypes.WANDE,
      validationData: null,
      filteredEntries: [],
    };
    this.viewOptions = new AdminEmployerOptions(AdminEmployerOptionTypes.NONE, this.employerID, this.props.app, null, this);
  }
  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Import Remittance`;
    this._fetch();
  }
  //Actions
  handleValidate() { this._validate(); }
  handleChangeFilter(filter) { this.setState({ filter }, this._filterEntries); }
  handleValidationLogs(contentType) { return () => this._validate(contentType); }
  handleDownloadLogs(contentType) { return () => this._downloadCommitLogs(contentType); }
  async handleAction(action, data) {
    const entry = this.state?.validationData?.entries?.find((item) => item.index == data.entryID);
    const path = action.path.replace('{employerID}', this.employerID).replace('{employerFileID}', this.fileID)
                            .replace('{entryID}', entry?.index).replace('{memberID}', entry?.id)
                            .replace('{memberName}', entry?.name);
    if (action.type == Globals.Remittance_ActionTypes.LINK) window.open(path, '_blank');
    else if (action.type == Globals.Remittance_ActionTypes.API) this._performAPIAction(path);
  }
  handleOpenMember(entry) {
    const path = config.ApplicationRoutes.memberUpdate.replace(':id', entry.id);
    window.open(path, '_blank');
  }
  handleCommit() { this._commit(); }
  handleDownload() { this._download(); }
  //UI
  render() {
    const { data, validationData, isLoading, generalErrors, successfullyCommitted, commitError } = this.state;

    const columns = [
      {
        title: '#', dataIndex: 'index', key: 'index', width: 150,
        render: (index) => Number(index) + 2,
      },
      {
        title: 'Name', key: 'name', width: 150,
        render: (props) => (
          props.id
            ? <a href="javascript:;" onClick={() => this.handleOpenMember(props)}>{props.name}</a>
            : props.name
        ),
      },
      { title: 'SIN', dataIndex: 'sin', key: 'sin', width: 150 },
      {
        title: 'Hire Date', dataIndex: 'hiredate', key: 'hiredate', width: 150,
        render: date => date || '-',
      },
      {
        title: 'Lay Off Date', dataIndex: 'layoffdate', key: 'layoffdate', width: 150,
        render: date => date || '-',
      },
      {
        title: 'Init. Fees', dataIndex: 'initiationfee', key: 'initiationfee', width: 150, align: 'right',
        render: initiationfee => `$${Utils.toCurrencyFormat(initiationfee)}`,
      },
      {
        title: 'IO', dataIndex: 'percapita', key: 'percapita', width: 150, align: 'right',
        render: percapita => `$${Utils.toCurrencyFormat(percapita)}`,
      },
      {
        title: 'Local Dues', dataIndex: 'workingdues', key: 'workingdues', width: 150, align: 'right',
        render: workingdues => `$${Utils.toCurrencyFormat(workingdues)}`,
      },
      {
        title: 'Total', dataIndex: 'total', key: 'total', width: 150, align: 'right',
        render: total => `$${Utils.toCurrencyFormat(total)}`,
      },
      {
        title: 'Status', key: 'Errors', align: 'right', width: 150,
        render: (record) => {
          const errors = validationData.errors.filter((err) => err.entryID == record.index).map((err) => ({ ...err, type: 'error' }));
          const warnings = validationData.warnings.filter((warn) => warn.entryID == record.index).map((err) => ({ ...err, type: 'warning' }));;

          const errorsAndWarnings = [...errors, ...warnings];

          if (!errorsAndWarnings.length) {
            return (
              <CheckCircleOutlined style={{ color: 'green', fontSize: 22 }} />
            );
          }

          return (
            <Popover placement="leftTop" content={(
              <Collapse expandIconPosition="right" defaultActiveKey={errorsAndWarnings.length == 1 && errorsAndWarnings[0].actions ? ['0'] : []}>
                {errorsAndWarnings.map((item, index) => (
                  <Collapse.Panel key={index} showArrow={!(!item.actions || !item.actions?.length)} header={(
                      <>
                        {item.type == 'error' && <><CloseCircleOutlined style={{ color: 'red', marginRight: 10 }} /> Error: {item.message}</>}
                        {item.type == 'warning' && <><WarningOutlined style={{ color: '#FAAF21', marginRight: 10 }} /> Warning: {item.message}</>}
                      </>
                    )}
                  >
                    {(item.actions || []).map((action) => (
                      <p key={action.message} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <span>{action.message}</span> <Button size="small" type="primary" onClick={this.handleAction.bind(this, action, item)}>{action.actionTitle}</Button>
                      </p>
                    ))}
                  </Collapse.Panel>
                ))}
              </Collapse>
            )}>
              {errors.length > 0 && (
                <CloseCircleOutlined style={{ color: 'red', fontSize: 22 }} />
              )}
              {warnings.length > 0 && (
                <WarningOutlined style={{ color: '#FAAF21', fontSize: 22, marginLeft: (errors.length > 0 ? 10 : 0)}} />
              )}
            </Popover>
          );
        },
      },
    ];

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading || this.state.isValidating || this.state.isCommitting || this.state.isDownloading} isFixed />
        <PageHeader
          title="Import Remittance"
          onBack={() => window.history.back()}
          extra={this.viewOptions.getOptions().concat([
            <Button key="btn" onClick={this.handleDownload}>Download Remittance</Button>,
            <Button key="btn2"  type={!this.state.isValid ? 'primary' : 'secondary'} onClick={this.handleValidate}>Validate File</Button>,
            (
              validationData?.errors?.length
                ? (
                  <Tooltip key="btn3" title="You can't commit your file until fix all errors below.">
                    <Button disabled={!this.state.isValid || this.state.successfullyCommitted} type={this.state.isValid ? 'primary' : 'secondary'} onClick={this.handleCommit}>Commit</Button>
                  </Tooltip>
                )
                : <Button key="btn4" disabled={!this.state.isValid || this.state.successfullyCommitted} type={this.state.isValid ? 'primary' : 'secondary'} onClick={this.handleCommit}>Commit</Button>
            ),
          ])}
        />
        <Layout.Content>
          <WhiteBox>
            <Row>
              <Col>
                <Statistic title="Employer" value={isLoading ? '...' : `${data.employerCompanyNumber} - ${data.employerName}`} />
              </Col>
              <Col offset={1}>
                <Statistic title="Period" value={isLoading ? '...' : moment(data.submissionPeriod).format(Globals.DefaultMonthDateFormat)} />
              </Col>
              <Col offset={1}>
                <Statistic title="Type" value={isLoading ? '...' : Globals.Remittance_UpTypes[data.uploadType]?.name} />
              </Col>
              {this._renderStatus()}
              {data.comments && (
                <Col offset={1}>
                  <Statistic title="Comments" value={isLoading ? '...' : data.comments} />
                </Col>
              )}
            </Row>
          </WhiteBox>

          {successfullyCommitted && (
            <WhiteBox>
              <Result
                status="success"
                title="Successfully Committed!"
                subTitle={`${validationData?.entries?.length} entr${validationData?.entries?.length > 1 ? 'ies' : 'y'} commited for the Period of ${moment(data?.submissionPeriod).format(Globals.DefaultMonthDateFormat)}`}
                extra={[
                  <Dropdown
                    key="1"
                    overlay={(
                      <Menu>
                        <Menu.Item onClick={this.handleDownloadLogs('application/pdf')}><GrDocumentPdf /> PDF</Menu.Item>
                        <Menu.Item onClick={this.handleDownloadLogs('text/plain')}><GrDocumentTxt /> TXT</Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button type="dashed" icon={<CheckCircleOutlined style={{ color: 'green' }} /> }>
                      Download Logs
                    </Button>
                  </Dropdown>
                ]}
              />
            </WhiteBox>
          )}

          {commitError && (
            <WhiteBox>
              <Result
                status="error"
                title="An error occurred while committing your remittance. Please, try again."
                subTitle={commitError}
                extra={[
                  <Button key="1" type="primary" onClick={this.handleValidate}>Revalidate</Button>,
                  <span key="2" style={{ fontSize: 12, color: '#ccc' }}>OR</span>,
                  <Dropdown
                    key="3"
                    overlay={(
                      <Menu>
                        <Menu.Item onClick={this.handleDownloadLogs('application/pdf')}><GrDocumentPdf /> PDF</Menu.Item>
                        <Menu.Item onClick={this.handleDownloadLogs('text/plain')}><GrDocumentTxt /> TXT</Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button type="dashed" icon={<CloseCircleOutlined style={{ color: 'red' }} /> }>
                      Download Error Logs
                    </Button> 
                  </Dropdown>,
                ]}
              />
            </WhiteBox>
          )}

          {(generalErrors?.length > 0 && !successfullyCommitted && !commitError) && (
            <WhiteBox>
              {generalErrors.map((error) => (
                <Alert
                  key={error.message}
                  message={error.message}
                  description={(
                    error.actions.map((action) => (
                      <React.Fragment key={action.message}>
                        <span>{action.message}</span>
                        <Button onClick={this.handleAction.bind(this, action)} type="primary" size="small" style={{ marginLeft: 10 }}>
                          {action.actionTitle}
                        </Button>
                      </React.Fragment>
                    ))
                  )}
                  type="error"
                  showIcon
                />
              ))}
            </WhiteBox>
          )}

          {(validationData && !generalErrors?.length && !successfullyCommitted && !commitError) && (
            <>
              <WhiteBox>
                <Form layout="vertical">
                  <Row style={{ marginBottom: 20 }} justify="space-between">
                    <Col span={16} style={{ display: 'flex', alignItems: 'center' }}>
                      <Form.Item label="Group by">
                        <Select value={this.state.filter} style={{ width: '100%' }} onChange={this.handleChangeFilter}>
                          <Select.Option value={Globals.Remittance_FilterTypes.ALL}>All</Select.Option>
                          <Select.Option value={Globals.Remittance_FilterTypes.WANDE}>Warnings & Errors</Select.Option>
                          <Select.Option value={Globals.Remittance_FilterTypes.WARN}>Warnings only</Select.Option>
                          <Select.Option value={Globals.Remittance_FilterTypes.ERR}>Errors only</Select.Option>
                        </Select>
                      </Form.Item>

                      {data.uploadType == Globals.Remittance_UpTypes.ER_ARREARS_V1 && (
                        <Alert
                          description={<><strong>Attention!</strong> This is an arrears remittance.</>}
                          type="info"
                          style={{ marginLeft: 16 }}
                        />
                      )}
                    </Col>
                    <Col span={5} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                      <Form.Item label=" ">
                        <Dropdown
                          overlay={(
                            <Menu>
                              <Menu.Item onClick={this.handleValidationLogs('application/pdf')}><GrDocumentPdf /> PDF</Menu.Item>
                              <Menu.Item onClick={this.handleValidationLogs('text/plain')}><GrDocumentTxt /> TXT</Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button type="primary" icon={<DownloadOutlined />}>Validation Logs</Button>  
                        </Dropdown>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                <Row justify="end">
                  <Col offset={1} style={{ display: 'flex' }}>
                    <div>
                      Total Processed: {validationData.entries.length}
                    </div>

                    {validationData.errors.length > 0 && <div style={{ marginLeft: 15 }}>
                      <span style={{ color: 'red' }}>{validationData.errors.length}</span>
                      <CloseCircleOutlined style={{ color: 'red', marginLeft: 5 }} />
                    </div>}
                    {validationData.warnings.length > 0 && <div style={{ marginLeft: 10 }}>
                      <span style={{ color: '#FAAF21' }}>{validationData.warnings.length}</span>
                      <WarningOutlined style={{ color: '#FAAF21', marginLeft: 5 }} />
                    </div>}
                  </Col>
                </Row>

                <Table
                  columns={columns}
                  dataSource={this.state.filteredEntries}
                  rowKey="index"
                  pagination={{ pageSize: 50 }}
                  scroll={{ x: true }}
                />
              </WhiteBox>

              <WhiteBox>
                <Row type="flex" justify="center" align="center">
                  <Typography.Title level={4}>Summary</Typography.Title>
                </Row>
                <div style={{ float: 'right' }}>
                  <Dropdown
                    overlay={(
                      <Menu>
                        <Menu.Item onClick={this.handleValidationLogs('application/pdf')}><GrDocumentPdf /> PDF</Menu.Item>
                        <Menu.Item onClick={this.handleValidationLogs('text/plain')}><GrDocumentTxt /> TXT</Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button type="primary" icon={<DownloadOutlined />} />
                  </Dropdown>
                </div>
                <div className="summaryContainer">
                  <div>
                    <div className="summaryCol">
                      <div>
                        <span>IO for A members:</span> <strong>${Utils.toCurrencyFormat(validationData.summary.ioA)}</strong>
                      </div>
                      <div>
                        <span>IO for BA members:</span> <strong>${Utils.toCurrencyFormat(validationData.summary.ioBA)}</strong>
                      </div>
                      <div>
                        <span>IO for FP members:</span> <strong>${Utils.toCurrencyFormat(validationData.summary.ioFP)}</strong>
                      </div>
                    </div>
                    
                    <div className="summaryCol">
                      <div>
                        <span>IO for PAPB members:</span> <strong>${Utils.toCurrencyFormat(validationData.summary.ioPAPB)}</strong>
                      </div>
                      <div>
                        <span>Working Dues:</span> <strong>${Utils.toCurrencyFormat(validationData.summary.lu)}</strong>
                      </div>
                      <div>
                        <span>Init Fees:</span> <strong>${Utils.toCurrencyFormat(validationData.summary.initFees)}</strong>
                      </div>
                    </div>
                  </div>
                  
                  <div>
                    <div className="summaryTotal">
                      <span>Grand Total:</span> <strong>${Utils.toCurrencyFormat(validationData.summary.total)}</strong>
                    </div>
                  </div>
                </div>
              </WhiteBox>
            </>
          )}
        </Layout.Content>
      </Layout.Content>
    )
  }

  _renderStatus() {
    const { data, isLoading } = this.state;

    const batchsQty = data.batchs?.length;
    const lastBatch = batchsQty > 0 ? data.batchs[batchsQty - 1] : null;

    if (!lastBatch) {
      return (
        <Col offset={1}>
          <Statistic title="Status" value={isLoading ? '...' : Globals.Remittance_BatchState.NEW.name} />
        </Col>
      );
    }

    if (lastBatch.batchState == Globals.Remittance_BatchState.FAIL.key) {
      return (
        <>
          <Col offset={1}>
            <Statistic title="Status" value={isLoading ? '...' : Globals.Remittance_BatchState.FAIL.name} valueStyle={{ color: 'red' }} />
          </Col>
          <Col>
            <Dropdown
              overlay={(
                <Menu>
                  <Menu.Item onClick={this.handleDownloadLogs('application/pdf')}><GrDocumentPdf /> PDF</Menu.Item>
                  <Menu.Item onClick={this.handleDownloadLogs('text/plain')}><GrDocumentTxt /> TXT</Menu.Item>
                </Menu>
              )}
            >
              <Button type="dashed" icon={<CloseCircleOutlined style={{ color: 'red' }} /> } style={{ margin: '28px 10px 0' }}>
                Download Error Logs
              </Button> 
            </Dropdown>
          </Col>
        </>
      ); 
    }

    return null;
  }

  _filterEntries(showAllWhenListIsEmpty) {
    const { filter, validationData } = this.state;

    const withErrors = validationData.errors.reduce((acc, curr) => [...acc, Number(curr.entryID)], []);
    const withWarnings = validationData.warnings.reduce((acc, curr) => [...acc, Number(curr.entryID)], []);

    let ids = [];

    switch (filter) {
      case Globals.Remittance_FilterTypes.WANDE:
        ids = [...withErrors, ...withWarnings];
        break;
      case Globals.Remittance_FilterTypes.WARN:
        ids = withWarnings;
        break;
      case Globals.Remittance_FilterTypes.ERR:
        ids = withErrors;
        break;
    }

    // Remove duplicated ids
    ids = [...new Set(ids)]

    let filteredEntries = (
      filter == Globals.Remittance_FilterTypes.ALL
        ? validationData.entries
        : validationData.entries.filter((entry) => ids.includes(entry.index))
    );

    if (showAllWhenListIsEmpty && filter != Globals.Remittance_FilterTypes.ALL && !filteredEntries.length) {
      filteredEntries = validationData.entries;
      this.setState({ filteredEntries, filter: Globals.Remittance_FilterTypes.ALL });
      return;
    }
    
    this.setState({ filteredEntries });
  }

  _treatRequestPayload(data) {
    const validationData = data;
    const generalErrors = validationData.errors?.filter((err) => !err.entryID);

    if (validationData?.entries?.length > 0) {
      validationData.entries = validationData.entries
        .map((entry) => ({ ...entry, total: (entry.percapita + entry.workingdues) }))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      
    }
    
    return { validationData: validationData || {}, generalErrors: generalErrors || [] };
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.employerFiles.getByID(this.employerID, this.fileID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      const data = resp.body;
      data.batchs = data.batchs.sort((a, b) => new Date(a.createdOn).getTime() - (new Date(b.createdOn)).getTime());
      this.setState({ data, isLoading: false });
      this.viewOptions.setEmployerName(data.employerName);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  async _validate(contentType) {
    this.setState({
      isValidating: true,
      ...(contentType ? {} : {
        generalErrors: null,
        validationData: null,
        successfullyCommitted: false,
        commitError: null,
      })
    });
    //request
    const resp = await this.props.app.api.employerFiles.validate(this.employerID, this.fileID, contentType, this.state.filter);
    const { data } = this.state;
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      if (contentType) {
        Utils.downloadBlob(resp.body, `${data.employerName}-${moment(data.submissionPeriod).format(Globals.DefaultMonthDateFormat)}-validation-log`, contentType == 'application/pdf' ? 'pdf' : 'txt');
        this.setState({ isValidating: false });
        return;
      }

      const { validationData, generalErrors } = this._treatRequestPayload(resp.body);

      if (generalErrors?.length > 0) {
        this.setState({ generalErrors, isValidating: false });
        return;
      }

      this.setState({
        validationData,
        isValidating: false,
        isValid: !validationData.errors.length,
      }, () => this._filterEntries(true));
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isValidating: false });
    }
  }
  async _commit() {
    this.setState({ isCommitting: true, successfullyCommitted: false });
    //requests
    const resp = await this.props.app.api.employerFiles.commit(this.employerID, this.fileID);
    if (!this._isMounted) return;
    await this._fetch();
    if (!this._isMounted) return;
    //
    if (resp.statusCode == 200 && resp.body) {
      const { validationData, generalErrors } = this._treatRequestPayload(resp.body);

      if (generalErrors?.length > 0) {
        this.setState({ generalErrors, isCommitting: false });
        return;
      }

      if (validationData?.errors?.length > 0) {
        notification.error({
          message: 'Error!', description: 'Your commit returned the errors below.',
        });

        this.setState({ validationData, isValid: false, isCommitting: false }, () => this._filterEntries(true));
        return;
      }

      this.setState({ isCommitting: false, successfullyCommitted: true });
    } else {
      const commitError = this.props.app.alertController.getAPIError(resp) || true;
      this.setState({ isCommitting: false, commitError });
    }
  }
  async _download() {
    this.setState({ isDownloading: true });

    const { data } = this.state;

    const resp = await this.props.app.api.employerFiles.download(data.employerID, data.fileID, data.uploadType);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      Utils.downloadBlob(resp.body, `${data.employerName}-${moment(data.submissionPeriod).format(Globals.DefaultMonthDateFormat)}`, 'xlsx');
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    }

    this.setState({ isDownloading: false });
  }
  async _downloadCommitLogs(contentType) {
    this.setState({ isDownloading: true });

    const { data } = this.state;

    const resp = await this.props.app.api.employerFiles.getCommitLogs(data.employerID, data.id, contentType);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      Utils.downloadBlob(resp.body, `${data.employerName}-${moment(data.submissionPeriod).format(Globals.DefaultMonthDateFormat)}-logs`, contentType == 'application/pdf' ? 'pdf' : 'txt');
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    }

    this.setState({ isDownloading: false });
  }

  //Validation Actions
  async _performAPIAction(path) {
    this.startLoading();
    const req = await this.props.app.api.newBaseRequest('POST');
    req.path = path;
    const resp = await req.exec();
    if (resp && resp.statusCode == 200 && resp.body && resp.body.message) {
      message.success(resp.body.message);
      await this._validate(); //revalidate
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while performing action!', resp);
    } this.stopLoading();
  }
}
