import React from "react";
import Cache from "./Cache";
import IDM from "@ikonintegration/idmclient";
import URLQuery from 'query-string-manipulator';
//
import Utils from './Utils';
import AlertController from "./AlertController";
//
import config from '@/config/config';
import packageJSON from '../../../package.json';
import Globals from '@/config/Globals';
//
class URLManager  {
		constructor(auth) {
			this.auth = auth;
		}
		
		getQueryParam(paramName) {
			return this.auth.idm.urlmanager.getParam(paramName);
		}
		getPathParam(paramName, ref) {
			return ref?.props?.match?.params[paramName];
		}

		//Navigation stack
		parseUrl(pagePath, args, id, id2) {
			//replace id
			if (id2 != undefined) pagePath = pagePath.replace(":" + Globals.URL_Path_ID2_Placeholder, id2);
			if (id != undefined) pagePath = pagePath.replace(":" + Globals.URL_Path_ID_Placeholder, id);
			//build url
			const reqURL = URLQuery(pagePath, { set: (args ? args : {}) });
			return reqURL;
		}

		pushPage(pagePath, args, id, id2, state) {
			const reqURL = this.parseUrl(pagePath, args, id, id2);
			if (state) {
				console.log("Pushing page with state", reqURL);
				this.auth.props.history.push(reqURL, state)
			} else {
				console.log("Pushing page", reqURL);
				this.auth.props.history.push(reqURL)
			}
		}
		openPage(page) { window.location.assign(page); }

		//URL
		updateQueryStringParam(key, value) { // - https://gist.github.com/excalq/2961415
			let baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
				urlQueryString = document.location.search,
				newParam = key + '=' + value,
				params = '?' + newParam;
			// If the "search" string exists, then build params from it
			if (urlQueryString) {
				var updateRegex = new RegExp('([\?&])' + key + '[^&]*');
				var removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');
				if (typeof value == 'undefined' || value == null || value == undefined || value == '') { // Remove param if value is empty
					params = urlQueryString.replace(removeRegex, "$1");
					params = params.replace(/[&;]$/, "");
				} else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
					params = urlQueryString.replace(updateRegex, "$1" + newParam);
				} else { // Otherwise, add it to end of query string
					params = urlQueryString + '&' + newParam;
				}
			}
			// no parameter was set so we don't need the question mark
			params = params == '?' ? '' : params;
			window.history.replaceState({}, "", baseUrl + params);
		}
};

export default URLManager;
