import React from "react";
import autoBind from 'react-autobind';
import { Button, Modal, Steps } from 'antd';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import CustomComponent from '@/components/CustomComponent';
//
import UploadStep from './UploadStep';
import SummaryStep from './SummaryStep';
import Utils from "@/components/Utils";
import Globals from "@/config/Globals";
//
export default class CommonRemittancesUploadModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isVisible: false,
      employerID: null,
      current: 0,
      uploadType: {},
      data: {
        file: null,
        extraColumns: [],
        invalidSins: null,
        sum: {
          percapita: 0,
          workingdues: 0,
          rows: 0,
        },
      },
    };
  }

  show(employerID, type = 'employer') {
    const types = {
      employer: {
        key: 'employer',
        modalTitle: 'Upload remittance',
        type: Globals.Remittance_UpTypes.ER_SIMPLE_V1.key,
      },
      arrears: {
        key: 'arrears',
        modalTitle: 'Arrear type remittance ',
        type: Globals.Remittance_UpTypes.ER_ARREARS_V1.key,
      }
    };

    this.setState({ isVisible: true, employerID, uploadType: types[type] || types.employer });
  }

  close() {
    if (this.uploadStep) {
      this.uploadStep.resetState();
    }
    
    this.setState({
      isVisible: false,
      employerID: null,
      current: 0,
      uploadType: {},
    });
  }

  handleCancel() {
    this.close();
  }

  handleSelectValidFile({ file, sum, extraColumns, invalidSins }) {
    this.setState((prevState) => ({
      ...prevState,
      current: prevState.current + 1,
      data: {
        ...prevState.data,
        file,
        extraColumns,
        sum,
        invalidSins,
      },
    }));
  }

  handleNextStep() {
    this.setState((prevState) => ({
      ...prevState,
      current: prevState.current + 1,
    }));
  }

  async handleSubmit() {
    const formData = await this.summaryStep.validateFields();
    
    if (formData) {
      this.startLoading();
      const { employerID, data } = this.state;
      const period = formData.period.endOf('month').format(Globals.DefaultSimpleDateFormat);

      const fileReader = new FileReader();
      fileReader.onloadend = async (readerResp) => {
        const file = data.file;
        if (!file) {
          this.props.app.alertController.showErrorAlert("Error", "Invalid file, please, try again!");
          this.stopLoading();
          return;
        }

        const generatedFileID = this.props.app.api.employerFiles.encodedFileName(employerID, file.name);
        
        file.file = readerResp.currentTarget.result;
        if (!this._isMounted) return;
        const resp = await this.props.app.api.employerFiles.upload(
          file,
          this.state.employerID,
          generatedFileID,
          this.state.uploadType.type,
          period,
          this.state.uploadType.key === 'arrears' ? '' : formData.paymentType,
          formData.comments || '',
        );

        if (resp && resp.statusCode == 200) {
          this.stopLoading();

          if (this.props.onSuccess) {
            this.props.onSuccess(resp.body);
          }
        } else {
          this.props.app.alertController.showAPIErrorAlert(null, resp);
          this.stopLoading();
        }
      };

      fileReader.readAsDataURL(this.state.data.file);
    }
  }

  render() {
    const steps = [
      {
        title: 'Select File',
        content: <UploadStep onSelectValidFile={this.handleSelectValidFile} app={this.props.app} {...Utils.propagateRef(this, 'uploadStep')} />,
      },
      {
        title: 'Summary',
        content: (
          <SummaryStep
            sum={this.state.data.sum}
            extraColumns={this.state.data.extraColumns}
            lastRemittance={this.props.lastRemittance}
            invalidSins={this.state.data.invalidSins}
            type={this.state.uploadType.key}
            {...Utils.propagateRef(this, 'summaryStep')}
          />
        ),
      },
    ];

    const { current } = this.state;
    const isLastStep = (current + 1) === steps.length;

    return (
      <Modal
        title={this.state.uploadType.modalTitle}
        visible={this.state.isVisible}
        onCancel={this.handleCancel}
        width={850}
        footer={
          <>
            <Button onClick={this.handleCancel}>Cancel</Button>
            {current > 0 && (
              <Button type="primary" onClick={isLastStep ? this.handleSubmit : this.handleNextStep}>
                {isLastStep ? 'Submit' : 'Next'}
              </Button>
            )}
          </>
        }
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        {/* <Steps current={current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps> */}

        <div className="steps-content">{steps[current].content}</div>
      </Modal>
    )
  }
}
