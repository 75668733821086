import React from "react";
import { Layout, Button, Row, Col, Form, Popover, Typography, Input } from 'antd';
import autoBind from 'react-autobind';
import { CheckCircleOutlined, CloseCircleOutlined, UserOutlined } from '@ant-design/icons';
import ReactPasswordStrength from "@ikonintegration/react-password-strength";
//
import Logo from '../commonComponents/Logo';
import CustomComponent from "@/components/CustomComponent";
import CommonLoadingView from "../commonComponents/CommonLoadingView";
import CommonBoxLinks from '../commonComponents/CommonBoxLinks';
import Utils from '@/components/Utils';
//resources
import "@/stylesheets/CommonSetupPassword.scss";
//
import { IDMGlobals } from "@ikonintegration/idmclient";
import config from "@/config/config";
import Globals from "@/config/Globals";
//
export default class CommonNewPasswordSetup extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { data: { username: this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this),
                           challengeContext: this.props.app.urlManager.getPathParam(Globals.URL_Path_ID2_Placeholder, this) },
                           isLoading: false };
  }
  componentDidMount() {
    super.componentDidMount();
    document.title = `Setup new password - ${config.ApplicationName}`;
  }
  //Actions
	async handleForgotPasswordConfirmation(){
    const formData = await this.form.validateFields();
    if (formData) {
      if (!this.state.data.newPassword) {
        this.props.app.alertController.showErrorAlert("Error!", "New password is required");
        return;
      } else if (!this.state.data.passwordConfirmation) {
        this.props.app.alertController.showErrorAlert("Error!", "New password confirmation is required");
        return;
      } else if (this.state.data.passwordConfirmation != this.state.data.newPassword) {
        this.props.app.alertController.showErrorAlert("Error!", "Passwords don't match!");
        return;
      }
      this.setState(prevState => ({
        ...prevState,
        data: { ...prevState.data, username: formData.username },
      }), this._setupNewPassword);
    }
  }
  handleInputPasswordChange(fieldName) {
    return (event) => {
      this.setState(prevState => ({
        ...prevState,
        data: { ...prevState.data, [fieldName]: event.password }
      }));
    };
  }
  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.isAuthenticating;
    const faillingRules = this._calculateFaillingRules();
    const passwordRulesPassing = !!(faillingRules && faillingRules.length == 0);
    const PasswordRulesIconComponent = passwordRulesPassing ? CheckCircleOutlined : CloseCircleOutlined;
    return (
      <Layout.Content className='pageContentFullScreen'>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <Layout.Content className='setupContainer'>
          <Row type="flex" justify="center" align="middle" className='authLogoRow'><Col align="center"> <Logo /> </Col></Row>
          <Row type="flex" justify="center" align="middle">
            <Col className='setupBoxContainer' align='middle' justify="center">
              <div className='setupBox'>
                <Row type="flex" justify="center" align="middle">
                  <Col align="center" >
                    <h5 className="setupBoxTitle">{config.ApplicationName}</h5>
                    <h6 className="setupBoxDescription">Setup new password</h6>
                  </Col>
                </Row>
                <Form layout="vertical" onSubmit={this.handleForgotPasswordConfirmation} {...Utils.propagateRef(this, 'form')} className='setupForm'>
                  <Form.Item
                    name="username"
                    initialValue={this.state.data.username}
                    rules={[
                      { transform: (value) => value.trim() },
                      { required: true, message: 'Please, type your email!' },
                      { type: 'email', message: 'Please, type a valid email address!' },
                    ]}
                  >
                    <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" disabled />
                  </Form.Item>
                  <Form.Item label="* New password">
                    <ReactPasswordStrength enabledVisibityToogle minLength={5} minScore={2}
                      scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                      changeCallback={this.handleInputPasswordChange('newPassword')}
                      inputProps={{ 'data-dd-privacy': 'mask', name: 'newPassword', autoComplete: "off", className: "form-control", required: true }}
                    />
                    <Popover className='passwordTooltip' getPopupContainer={triggerNode => triggerNode.parentNode} visible={faillingRules && faillingRules.length > 0} content={
                      <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '300px' }}>
                        <Typography.Title level={4}>Password rules:</Typography.Title>
                        <ul>
                          <li>Have at least one lower case character {this._renderRuleStatus(faillingRules, Globals.PASSWORD_VALIDATION_RULES.LOWER)}</li>
                          <li>Have at least one capital letter {this._renderRuleStatus(faillingRules, Globals.PASSWORD_VALIDATION_RULES.UPPER)}</li>
                          <li>Have at least one number {this._renderRuleStatus(faillingRules, Globals.PASSWORD_VALIDATION_RULES.NUMBER)}</li>
                          <li>Not be the same as the account email {this._renderRuleStatus(faillingRules, Globals.PASSWORD_VALIDATION_RULES.MIN_CHARS)}</li>
                          <li>Be at least 8 characters long {this._renderRuleStatus(faillingRules, Globals.PASSWORD_VALIDATION_RULES.PASS_MATCH)}</li>
                        </ul>
                      </div>
                    }>
                      {faillingRules && <PasswordRulesIconComponent className={'passwordTooltipIcon ' + (passwordRulesPassing ? 'rulePass' : 'ruleFail')} />}
                    </Popover>
                  </Form.Item>
                  <Form.Item label="* New password confirmation">
                    <ReactPasswordStrength enabledVisibityToogle minLength={5} minScore={2}
                      scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                      changeCallback={this.handleInputPasswordChange('passwordConfirmation')}
                      inputProps={{ 'data-dd-privacy': 'mask', name: 'passwordConfirmation', autoComplete: "off", className: "form-control", required: true }} />
                  </Form.Item>
                  <Button block className='setupButton' type="primary" onClick={this.handleForgotPasswordConfirmation} htmlType="submit"
                    disabled={(!this.state.data.newPassword || !this.state.data.passwordConfirmation) || (faillingRules && faillingRules.length > 0)}> Setup password </Button>
                </Form>
              </div>
            </Col>
          </Row>
          <CommonBoxLinks width={400}/>
        </Layout.Content>
      </Layout.Content>
    );
  }
  /* private API */
  async _setupNewPassword() {
    this.startLoading();
    //
    let obj = Object.assign({}, this.state.data);
    obj.challengeState = IDMGlobals.AuthorizationChallengeState_NewPasswordRequired;
    delete obj.passwordConfirmation;
    //
    const resetResp = await this.props.app.idm.api.authorization.answerChallenge(obj);
    if (resetResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Password setup succeeded!");
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.login, { [IDMGlobals.URLQueryParam_UserEmail]: this.state.data.username });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resetResp);
      this.stopLoading();
    }
    this.stopLoading();
  }
  /* password helpers */
  _renderRuleStatus(faillingRules, ruleType) {
    if (faillingRules && faillingRules.indexOf(ruleType) != -1) return (<CloseCircleOutlined className="ruleFail" />);
    else if (faillingRules) return (<CheckCircleOutlined className="rulePass" />);
    else return (<></>);
  }
  _calculateFaillingRules() {
    if (!(this.state.data?.newPassword?.length > 0 || this.state.data?.passwordConfirmation?.length > 0)) return null;
    const faillingRules = [];
    const pass = this.state.data.newPassword;
    const passC = this.state.data.passwordConfirmation;

    //Check lower
    if (!(/[a-z]/.test(pass))) faillingRules.push(Globals.PASSWORD_VALIDATION_RULES.LOWER);
    //Check upper
    if (!(/[A-Z]/.test(pass))) faillingRules.push(Globals.PASSWORD_VALIDATION_RULES.UPPER);
    //Check number
    if (!(/[0-9]/.test(pass))) faillingRules.push(Globals.PASSWORD_VALIDATION_RULES.NUMBER);
    //Check min chars
    if (pass && pass.length < 8) faillingRules.push(Globals.PASSWORD_VALIDATION_RULES.MIN_CHARS);
    //Check confirmation
    if ((pass && pass != passC) || !passC) faillingRules.push(Globals.PASSWORD_VALIDATION_RULES.PASS_MATCH);
    return faillingRules;
  }
}
