import React from 'react';
import autoBind from 'react-autobind';
import { Input, Col, Row, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
//
import '@/stylesheets/CommonSearchBar.scss';
//
export default class CommonSearchBar extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { term: '' };
  }
  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({ term: this.props.defaultValue });
    }
  }
  //Actions
  handleChange(val) {
    this.setState({ term: val.target.value });
    if (this.props.onChange) this.props.onChange(val.target.value);
  }
  handleSearch() { this.props.handleSearch(this.state.term); }
  //UI
  render() {
    const disabled = (!this.props.alwaysEnabled && !(this.state.term && this.state.term.length > 0));
    return (
      <>
      <Row justify="start" align="middle" type='flex' className={this.props.extra ? 'usersSearchBar extraSize' : 'usersSearchBar'}>
        <Col span={2}>
            <SearchOutlined className="usersSearchBarIcon" />
        </Col><Col span={12}>
          <Input className={this.props.extra ? 'usersSearchBarInput extraSize' : 'usersSearchBarInput'} defaultValue={this.props.defaultValue || ''} placeholder={this.props.placeholder || 'Search...'}
                 onChange={this.handleChange} onPressEnter={this.handleSearch}/>
        </Col>
      </Row>
      <Row justify="end" align="middle" type='flex' className={this.props.extra ? 'usersSearchBarButtonRow extraSize' : 'usersSearchBarButtonRow'}>
        {this.props.extra && (
          <Col className='usersSearchBarExtra'>
            {this.props.extra}
          </Col>
        )}
        <Col className='usersSearchBarButtonColumn'>
          <Button className='usersSearchBarButton' type='primary' disabled={disabled}
                  onClick={this.handleSearch}>Search</Button>
        </Col>
      </Row>
      </>
    );
  }
}
