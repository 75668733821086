import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Row, Col, Divider } from 'antd';
import {
  FcDataConfiguration, FcReuse, FcDoughnutChart, FcCollaboration,
  FcConferenceCall, FcDam, FcCurrencyExchange, FcGraduationCap, FcOvertime, 
  FcOrgUnit, FcOrganization, FcSettings, FcDepartment, FcPortraitMode } from 'react-icons/fc';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import config from '@/config/config';
import Globals from "@/config/Globals";
//
import '@/stylesheets/AdminSettingsView.scss';
//
export default class AdminSettingsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.options = this._getOptions();
  }
  //Live cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Settings`;
  }
  //Actions
  handleManageClick(option) { if (option.route) this.props.app.urlManager.pushPage(option.route); }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <PageHeader title="Settings" />
        <Layout.Content>
          <WhiteBox>
              {this.options.map((group) => (
                <React.Fragment key={group.key}>
                  <Row>
                    <Col span={24}>
                      <Divider orientation="left">{group.name}</Divider>
                    </Col>
                  </Row>
                  <Row>
                    {group.options.filter((o) => !o.acl || this.props.app.acl.hasPermission(o.acl.componentID, o.acl.level)).map((option) => (
                      <Col span={8} key={option.key} style={{ padding: '15px' }}>
                        {/* TODO: remove disabled state style when no more disabled items */}
                        <div className="settingsCard" onClick={this.handleManageClick.bind(this, option)} style={{opacity: (option.route ? 1 : .3)}}>
                          {<option.Icon style={{ fontSize: 30 }} />}
                          
                          <div className="settingsCardInfo">
                            <h3>{option.name}</h3>
                            <span>{option.description}</span>
                          </div>
      
                          {/* <div className="settingsCardIcon">
                            <SettingOutlined />
                          </div> */}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </React.Fragment>
              ))}
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }
  /* private */
  _getOptions() {
    return [
      {
        key: 1,
        name: 'Members',
        options: [
          {
            key: 1, name: 'Dispatch Lists', Icon: FcDataConfiguration,
            description: 'Maintenance of Dispatch Lists',
            route: config.ApplicationRoutes.adminSettingsDispatchList,
            acl: {
              componentID: Globals.Components.DISPATCH_LIST_MANAGEMENT,
              level: Globals.AllLevels
            }
          },
          {
            key: 2, name: 'Dues Rates', Icon: FcCurrencyExchange,
            description: 'Maintenance of Dues Rates',
            route: config.ApplicationRoutes.adminSettingsDuesRates,
            acl: {
              componentID: Globals.Components.SETTINGS_DUE_RATES,
              level: Globals.AllLevels
            }
          },
          // {
          //   key: 3, name: 'Electoral Districts', Icon: FcDam,
          //   description: 'Maintenance of Electoral Districts',
          //   // route: config.ApplicationRoutes.adminSettingsSectors,
          //   // TODO -- please, add ACL when view is implemented
          //   // acl: {
          //   //   componentID: Globals.Components.SECTORS_MANAGEMENT,
          //   //   level: Globals.AllLevels
          //   // }
          // },
          {
            key: 4, name: 'Status (Activity) Codes', Icon: FcPortraitMode,
            description: 'Maintenance of Status Codes',
            route: config.ApplicationRoutes.adminSettingsMemberStatus,
            acl: {
              componentID: Globals.Components.MEMBER_STATUS_MANAGEMENT,
              level: Globals.AllLevels
            },
          },
          {
            key: 5, name: 'Training Codes', Icon: FcGraduationCap,
            description: 'Maintenance of Training Codes',
            route: config.ApplicationRoutes.adminSettingsTrainingCodes,
            acl: {
              componentID: Globals.Components.TRAINING_CODES_MANAGEMENT,
              level: Globals.AllLevels
            }
          },
          {
            key: 6, name: 'Work Activity Codes', Icon: FcReuse,
            description: 'Maintenance of Work Activity Codes',
            route: config.ApplicationRoutes.adminSettingsWorkActivityCodes,
            acl: {
              componentID: Globals.Components.WORK_ACTIVITIES_MANAGEMENT,
              level: Globals.AllLevels
            }
          },
          {
            key: 7, name: 'Job Classification', Icon: FcCollaboration,
            description: 'Maintenance of Job Classifications',
            route: config.ApplicationRoutes.adminSettingsJobClassifications,
            acl: {
              componentID: Globals.Components.CLASSIFICATION_MANAGEMENT,
              level: Globals.AllLevels
            }
          },
        ],
      },
      {
        key: 2,
        name: 'Employers',
        options: [
          {
            key: 1, name: 'Classifications', Icon: FcDoughnutChart,
            description: 'Maintenance of Employer Classifications',
            route: config.ApplicationRoutes.adminSettingsClassifications,
            acl: {
              componentID: Globals.Components.MEMBERS_CLASSIFICATION_MANAGEMENT,
              level: Globals.AllLevels
            }
          },
          {
            key: 2, name: 'Schedules', Icon: FcOvertime,
            description: 'Maintenance of Employer Schedules',
            route: config.ApplicationRoutes.adminSettingsSchedules,
            acl: {
              componentID: Globals.Components.SCHEDULES_MANAGEMENT,
              level: Globals.AllLevels
            }
          },
          {
            key: 3, name: 'Sectors', Icon: FcOrgUnit,
            description: 'Maintenance of Employer Sectors',
            route: config.ApplicationRoutes.adminSettingsSectors,
            acl: {
              componentID: Globals.Components.SECTORS_MANAGEMENT,
              level: Globals.AllLevels
            }
          },
          {
            key: 4, name: 'Units', Icon: FcDepartment,
            description: 'Maintenance of Employer Units',
            route: config.ApplicationRoutes.adminSettingsUnits,
            acl: {
              componentID: Globals.Components.UNIT_NUMBERS_MANAGEMENT,
              level: Globals.AllLevels
            }
          },
          // {
          //   key: 5, name: 'Dispatch Jobs', Icon: FcCollaboration,
          //   description: 'Maintenance of Employer Dispatch Jobs',
          //   // route: config.ApplicationRoutes.adminSettingsSectors,
          //   acl: {
          //     componentID: Globals.Components.DISPATCH_JOBS_MANAGEMENT,
          //     level: Globals.AllLevels
          //   }
          // },
        ],
      },
      {
        key: 3,
        name: 'System',
        options: [
          {
            key: 1, name: 'Application Configuration', Icon: FcSettings,
            description: 'Maintenance of Main App Configurations',
            route: config.ApplicationRoutes.adminSettingsAppConfig,
            acl: {
              componentID: Globals.Components.SETTINGS_GENERAL,
              level: Globals.AllLevels
            }
          },
          {
            key: 2, name: 'Departments', Icon: FcOrganization,
            description: 'Maintenance of Internal Departments',
            route: config.ApplicationRoutes.adminSettingsDepartments,
            acl: {
              componentID: Globals.Components.DEPTS_MANAGEMENT,
              level: Globals.AllLevels
            }
          },
          {
            key: 3, name: 'Chart of Accounts', Icon: FcConferenceCall,
            description: 'Maintenance of Chart of Accounts',
            route: config.ApplicationRoutes.adminSettingsChartOfAccounts,
            acl: {
              componentID: Globals.Components.ACCOUNTS_MANAGEMENT,
              level: Globals.AllLevels
            }
          },
        ],
      },
    ];
  }
}
