import React from "react";
import autoBind from 'react-autobind';
import { Form, Row, Col, Input, Select, Checkbox, Divider, Popover, Typography } from 'antd';
//
import Utils from '@/components/Utils';
import Globals from '@/config/Globals';
import CustomComponent from '@/components/CustomComponent';
//
export default class AdminAdminForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      hasIDMID: false,
      departments: [],
      provinces: [],
    };
  }

  componentDidMount() {
    const { departments, provinces } = this.props.app.sharedCache().getConfig();
    this.setState({
      departments,
      provinces: provinces[Globals.DefaultAdminCountry],
    });
  }

  async validateFields() {
    const formData = await this.form.validateFields();
    
    if (formData) {
      return {
        ...formData,
        disabled: formData.disabled || false,
        province: formData?.province || null,
        city: formData.city || '',
        postalCode: formData.postalCode || '',
        street1: formData.street1 || '',
        street2: formData.street2 || '',
      };
    }

    return false;
  }

  resetFields() {
    return this.form.resetFields();
  }

  setFields(data) {
    if (data.idmID) {
      this.setState({ hasIDMID: true })
    }
    return this.form.setFieldsValue(data);
  }

  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row>
          <Col span={6}>
            <Form.Item label="First name" name="firstName" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label="Last name" name="lastName" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        
        <Row>
          <Col span={6}>
            {this._wrapIntoEmailPopover(
              <Form.Item label="Email" name="email" rules={[{ required: true, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            )}
          </Col>
          <Col span={7} offset={1}>
            <Form.Item label="Department" name="departmentID" rules={[{ required: true, message: 'This field is required!' }]}>
              <Select placeholder="Select the department" showSearch
                filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}>
                {this.state.departments.map((department) => (
                  <Select.Option key={department.id} value={department.id}>
                    {department.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {!this.props.isProfile && <Col span={3} offset={1}>
            <Form.Item label="Inactive" name="disabled" valuePropName="checked">
              <Checkbox id="disabled" />
            </Form.Item>
          </Col>}
        </Row>

        <Divider orientation="left">Address Information</Divider>
        
        <Row>
          <Col span={7}>
            <Form.Item label="Province" name="province"
              rules={[{ required: !!this.state.provinces, message: 'This field is required!' }]}>
              <Select placeholder={!this.state.provinces ? 'N/A' : "Select a Province"} showSearch
                disabled={!this.state.provinces} allowClear
                filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}>
                {this.state.provinces.map((province) => (
                  <Select.Option key={province.provinceCode} value={province.provinceCode}>
                    {province.province}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item label="City" name="city">
              <Input />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item label="Postal Code" name="postalCode" rules={[{ max: 10, message: 'Max 10 characters' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        
        <Row>
          <Col span={7}>
            <Form.Item label="Street 1" name="street1">
              <Input />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item label="Street 2" name="street2">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
  /* private functions */
  _wrapIntoEmailPopover(toWrap) {
    if (this.props.isProfile) {
      return (<Popover trigger="focus"  getPopupContainer={triggerNode => triggerNode.parentNode}
        content={
          <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '400px' }}>
            <Typography.Title level={4}>Important notes for email change:</Typography.Title>
            <ul>
              <li>In order to take effect you need to <u>logout</u> after changing the email.</li>
              <li>If wrong email is typed, email change can lock you out of your account.</li>
            </ul>
          </div>
        }
      >{toWrap}</Popover>);
    } else {
      return (<Popover  getPopupContainer={triggerNode => triggerNode.parentNode}
        content={
          <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '400px' }}>
            <Typography.Title level={4}>Important notes for email change:</Typography.Title>
            <ul>
              <li>In order to take effect the admin need to <u>logout</u> after the change.</li>
              <li>If wrong email is typed, email change can lock the admin out of the account.</li>
            </ul>
          </div>
        }
      >{toWrap}</Popover>);
    }
  }
}
