import APIRequest from './APIRequest';
// API Compoenents
import APIAdmins from './operations/APIAdmins';
import APIChartOfAccounts from './operations/APIChartOfAccounts';
import APIClassifications from './operations/APIClassifications';
import APIConfig from './operations/APIConfig';
import APIDepartments from './operations/APIDepartments';
import APIDispatchLists from './operations/APIDispatchLists';
import APIDispatchSlipRun from './operations/APIDispatchSlipRun';
import APIEmployerContacts from './operations/APIEmployerContacts';
import APIEmployerFiles from './operations/APIEmployerFiles';
import APIEmployers from './operations/APIEmployers';
import APIMembers from './operations/APIMembers';
import APIMemberStatus from './operations/APIMemberStatus';
import APINotes from './operations/APINotes';
import APISchedules from './operations/APISchedules';
import APISectors from './operations/APISectors';
import APISettings from './operations/APISettings';
import APITrainingCodes from './operations/APITrainingCodes';
import APIUnitsNumber from './operations/APIUnitsNumber';
import APIWorkActivities from './operations/APIWorkActivities';
import APIMemberClassifications from './operations/APIMemberClassifications';
import APIMemberWorkHistories from './operations/APIMemberWorkHistories';
import APIReports from './operations/APIReports';
import APIDuesBatch from './operations/APIDuesBatch';
import APIDispatchJob from './operations/APIDispatchJob';
import APIDuesEntry from './operations/APIDuesEntry';
import APITrainingHistory from './operations/APITrainingHistory';
import APIApplications from './operations/APIApplications';
//
import config from '@/config/config';
//
export default class API {
  constructor(idm) {
    this.idm = idm;
    //api operations
    this.admins = new APIAdmins(this);
    this.chartOfAccounts = new APIChartOfAccounts(this);
    this.classifications = new APIClassifications(this);
    this.config = new APIConfig(this);
    this.departments = new APIDepartments(this);
    this.dispatchLists = new APIDispatchLists(this);
    this.dispatchSlipsRun = new APIDispatchSlipRun(this);
    this.employerContacts = new APIEmployerContacts(this);
    this.employerFiles = new APIEmployerFiles(this);
    this.employers = new APIEmployers(this);
    this.members = new APIMembers(this);
    this.memberStatus = new APIMemberStatus(this);
    this.notes = new APINotes(this);
    this.schedules = new APISchedules(this);
    this.sectors = new APISectors(this);
    this.settings = new APISettings(this);
    this.trainingCodes = new APITrainingCodes(this);
    this.unitsNumber = new APIUnitsNumber(this);
    this.workActivities = new APIWorkActivities(this);
    this.memberClassifications = new APIMemberClassifications(this);
    this.memberWorkHistories = new APIMemberWorkHistories(this);
    this.reports = new APIReports(this);
    this.duesBatch = new APIDuesBatch(this);
    this.dispatchJob = new APIDispatchJob(this);
    this.duesEntry = new APIDuesEntry(this);
    this.trainingHistory = new APITrainingHistory(this);
    this.applications = new APIApplications(this);
  }
  async newBaseRequest(method) {
    const req = new APIRequest(method);
    //Check for authorization token
    if (this.idm.session.authorization) {
      const token = await this.idm.session.getToken(true);
      console.debug('token', token);
      req.appendHeader('Authorization', `Bearer ${token}`);
    }
    //Base url
    req.baseURL = config.ApplicationAPIEndpoint;
    return req;
  }
}
