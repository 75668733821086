// TODO: organize ivars here
export default class Globals {}
//Comp.
Globals.CompabilityMessage = 'You are using Internet Explorer to connect to this application. Some functions may not work correctly using IE. In order to have a better experience please use Chrome, Firefox or Safari.';
//UI
Globals.Table_PagingItemsPerPage = 25;
//Paths
Globals.URL_Path_ID_Placeholder = 'id';
Globals.URL_Path_ID2_Placeholder = 'id2';
Globals.URL_NewIndentifier = 'new';
//Query
Globals.URLQueryParam_Operation = 'operation';
Globals.URLQueryParam_RegConfirmationToken = 'regToken';
Globals.URLQueryParam_RegConfirmationCode = 'regCode';
Globals.URLQueryParam_ChangePassword = 'changePassword';
Globals.URLQueryParam_SearchTerm = 'search';
Globals.URLQueryParam_Page = 'page';
Globals.URLQueryParam_SortField = 'sort';
Globals.URLQueryParam_SortOrder = 'order';
Globals.URLQueryParam_BusinessAgent = 'businessAgent';
Globals.URLQueryParam_Schedule = 'schedule';
Globals.URLQueryParam_Employer = 'employer';
Globals.URLQueryParam_EmploymentStatus = 'employmentStatus';
Globals.URLQueryParam_MemberStatus = 'status';
Globals.URLQueryParam_Unit = 'unitID';
Globals.URLQueryParam_DispatchList = 'dispatchListID';
Globals.URLQueryParam_Status = 'status';
Globals.URLQueryParam_BatchDate = 'batchDate';
//Defaults
Globals.DefaultAdminCountry = 'CA';
Globals.DefaultWorkHistoryCountry = 'CA';
Globals.DefaultMembersCountry = 'CA';
Globals.DefaultApplicationsCountry = 'CA';
Globals.DefaultMembersProvince = 'BC';
Globals.DefaultUIShortDateFormat = 'MMM DD, YYYY';
Globals.DefaultUIBirthDateFormat = 'MMMM DD, YYYY';
Globals.DefaultUIBirthDateFormatWithTime = 'MMMM DD, YYYY - HH:mm:ss';
Globals.DefaultUIMonthDateFormat = 'MMMM DD';
Globals.DefaultUIExtendedDateFormat = 'MMMM DD, YYYY HH:mm a';
Globals.DefaultSimpleDateFormat = 'YYYY/MM/DD';
Globals.DefaultMonthDateFormat = 'MM/YYYY';
Globals.DefaultAPIDateFormat = 'YYYY-MM-DD';

//Business logic
Globals.CounterCash_OrganizationNumber = 9998;
Globals.Cash_OrganizationNumber = 8000;

Globals.App_HowDidYouBecomeAnIbewMember = [
  'I was organized',
  'I was organized as an apprentice',
  'I was selected for an apprenticeship program',
  'Iam a new hire',
  'Other',
];
Globals.App_Industry = [
  'Traffic Control',
  'Government',
  'Inside Construction & Maintenance',
  'Utility',
  'Manufacturing',
  'Other',
];

//Import process
Globals.Remittance_RowsLimit = 10000;
Globals.Remittance_PaymentTypes = {
  etransfer: {
    key: 'etransfer',
    name: 'E-Transfer'
  },
  cc: {
    key: 'cc',
    name: 'CC'
  },
  cheque: {
    key: 'cheque',
    name: 'Cheque'
  },
  other: {
    key: 'other',
    name: 'Other'
  },
  etf: {
    key: 'etf',
    name: 'EFT'
  },
};
Globals.Remittance_UpTypes = {
  ER_SIMPLE_V1: {
    key: 'ER_SIMPLE_V1',
    name: 'Simple'
  },
  ER_ARREARS_V1: {
    key: 'ER_ARREARS_V1',
    name: 'Arrears'
  },
  ER_ADVANCED_V1: {
    key: 'ER_ADVANCED_V1',
    name: 'Advanced'
  },
  ER_ARREARS_V1: {
    key: 'ER_ARREARS_V1',
    name: 'Arrears'
  },
};
Globals.Remittance_BatchState = {
  NEW: {
    key: 'new',
    name: 'New'
  },
  FAIL: {
    key: 'FAIL',
    name: 'Fail'
  },
  SUCCESS: {
    key: 'SUCCESS',
    name: 'Success'
  },
};
Globals.Remittance_ActionTypes = {
  LINK: 'LINK',
  API: 'API',
};
Globals.Remittance_FilterTypes = {
  ALL: 'ALL',
  WARN: 'WARN',
  ERR: 'ERR',
  WANDE: 'WANDE',
};
//Notes
Globals.Notes_relTypes = {
  // prop name should be the same value of "routeAlias"
  employer: {
    routeAlias: 'employer',
    apiKey: 'EMPLOYER',
    name: 'Employer',
  },
  member: {
    routeAlias: 'member',
    apiKey: 'MEMBER',
    name: 'Member',
  },
  dispatchJob: {
    routeAlias: 'dispatchJob',
    apiKey: 'DISPATCH_JOB',
    name: 'Dispatch Job',
    hideViewOptions: true,
  },
};
//Member Status
Globals.MemberStatuses = {
  A: 'A',
  BA: 'BA',
  PA: 'PA',
  PB: 'PB',
  FP: 'FP'
};
//Member Search
Globals.MemberSearch_EmploymentStatus = {
  ANY: {
    key: 'ANY',
    name: 'Any'
  },
  EMPLOYED: {
    key: 'EMPLOYED',
    name: 'Employed'
  },
  UNEMPLOYED: {
    key: 'UNEMPLOYED',
    name: 'Unemployed'
  },
};
Globals.MemberSearch_Status = {
  ACTIVE: {
    key: 'ACTIVE',
    name: 'Active'
  },
  INACTIVE: {
    key: 'INACTIVE',
    name: 'Inactive'
  },
  ANY: {
    key: 'ANY',
    name: 'Any'
  },
};
Globals.EmployerSearch_Status = {
  ANY: {
    key: 'ANY',
    name: 'Any'
  },
  ACTIVE: {
    key: 'ACTIVE',
    name: 'Active'
  },
  INACTIVE: {
    key: 'INACTIVE',
    name: 'Inactive'
  },
};
Globals.MemberStatus_Options = {
  MEMBER_STATUS_RETURN: 1,
  MEMBER_STATUS_A_TO_BA: 2,
  MEMBER_STATUS_BA_TO_A: 3,
  MEMBER_STATUS_DROPPED: 4,
  MEMBER_STATUS_NAME_CHANGED: 5,
  MEMBER_STATUS_BA_WITHDRAWAL: 6,
  MEMBER_STATUS_TRANSFER_IN: 7,
  MEMBER_STATUS_NEW: 8,
  MEMBER_STATUS_REINSTATEMENT: 9,
  MEMBER_STATUS_UNKNOWN: 10,
  MEMBER_STATUS_PENSION: 11,
  MEMBER_STATUS_UNDROPPED: 12,
  MEMBER_STATUS_WITHDRAWAL_CARD: 13,
  MEMBER_STATUS_EXPELLED: 14,
  MEMBER_STATUS_DECEASED: 15,
  MEMBER_STATUS_FP_BA: 16,
};
//Password
Globals.PASSWORD_VALIDATION_RULES = {
  LOWER: 0, UPPER: 1,
  NUMBER: 2, MATCH_EMAIL: 3,
  MIN_CHARS: 4, PASS_MATCH: 5
};
//
Globals.API_MaxAllowedFileSize = 10485760; //2.5MB

//API Definitions
Globals.APIPath_Admin = '/admin/';
Globals.APIPath_Admins = '/admins/';
Globals.APIPath_ChartOfAccount = '/account/';
Globals.APIPath_ChartOfAccounts = '/accounts/';
Globals.APIPath_Classification = '/classification/';
Globals.APIPath_Classifications = '/classifications/';
Globals.APIPath_Config = '/config/';
Globals.APIPath_Department = '/department/';
Globals.APIPath_Departments = '/departments/';
Globals.APIPath_DispatchList = '/dispatchList/';
Globals.APIPath_DispatchLists = '/dispatchLists/';
Globals.APIPath_DispatchSlipsRun = '/dispatchSlipsRun';
Globals.APIPath_Employer = '/employer/';
Globals.APIPath_Employers = '/employers/';
Globals.APIPath_Member = '/member/';
Globals.APIPath_Members = '/members/';
Globals.APIPath_MemberStatus = '/memberStatus/';
Globals.APIPath_MemberStatuses = '/memberStatuses/';
Globals.APIPath_Note = '/note/';
Globals.APIPath_Notes = '/notes/';
Globals.APIPath_Schedule = '/schedule/';
Globals.APIPath_Schedules = '/schedules/';
Globals.APIPath_Sector = '/sector/';
Globals.APIPath_Sectors = '/sectors/';
Globals.APIPath_Settings = '/settings/';
Globals.APIPath_TrainingCode = '/trainingCode/';
Globals.APIPath_TrainingCodes = '/trainingCodes/';
Globals.APIPath_UnitNumber = '/unitNumber/';
Globals.APIPath_UnitsNumber = '/unitsNumber/';
Globals.APIPath_WorkActivities = '/workActivities/';
Globals.APIPath_WorkActivity = '/workActivity/';
Globals.APIPath_MemberClassifications = '/memberClassifications/';
Globals.APIPath_MemberClassification = '/memberClassification/';
Globals.APIPath_Report = '/report/';
Globals.APIPath_Applications = '/applications/';
Globals.APIPath_Application = '/application/';

//ACLs
Globals.Levels = {
  READ: 'READ',
  WRITE: 'WRITE',
  FULLACCESS: 'FULLACCESS',
};
Globals.Components = {
  ACCOUNTS_MANAGEMENT: 'ACCOUNTS_MANAGEMENT',
  ADMINS_MANAGEMENT_PERMISSIONS: 'ADMINS_MANAGEMENT_PERMISSIONS',
  ADMINS_MANAGEMENT: 'ADMINS_MANAGEMENT',
  CLASSIFICATION_MANAGEMENT: 'CLASSIFICATION_MANAGEMENT',
  DEPTS_MANAGEMENT: 'DEPTS_MANAGEMENT',
  DISPATCH_JOBS_MANAGEMENT: 'DISPATCH_JOBS_MANAGEMENT',
  DISPATCH_LIST_MANAGEMENT: 'DISPATCH_LIST_MANAGEMENT',
  EMPLOYER_CONTACTS_MANAGEMENT: 'EMPLOYER_CONTACTS_MANAGEMENT',
  EMPLOYER_DUES_BATCHES_MANAGEMENT: 'EMPLOYER_DUES_BATCHES_MANAGEMENT',
  EMPLOYER_ELECTRONIC_REMITTANCE: 'EMPLOYER_ELECTRONIC_REMITTANCE',
  EMPLOYER_WORK_HISTORY_MANAGEMENT: 'EMPLOYER_WORK_HISTORY_MANAGEMENT',
  EMPLOYERS_MANAGEMENT: 'EMPLOYERS_MANAGEMENT',
  MEMBER_DUES_ENTRIES_MANAGEMENT: 'MEMBER_DUES_ENTRIES_MANAGEMENT',
  MEMBER_STATUS_MANAGEMENT: 'MEMBER_STATUS_MANAGEMENT',
  NOTES_MANAGEMENT: 'NOTES_MANAGEMENT',
  REPORT_MEMBERS_LABEL: 'REPORT_MEMBERS_LABEL',
  SCHEDULES_MANAGEMENT: 'SCHEDULES_MANAGEMENT',
  SECTORS_MANAGEMENT: 'SECTORS_MANAGEMENT',
  SETTINGS_DUE_RATES: 'SETTINGS_DUE_RATES',
  SETTINGS_GENERAL: 'SETTINGS_GENERAL',
  TRAINING_CODES_MANAGEMENT: 'TRAINING_CODES_MANAGEMENT',
  UNIT_NUMBERS_MANAGEMENT: 'UNIT_NUMBERS_MANAGEMENT',
  WORK_ACTIVITIES_MANAGEMENT: 'WORK_ACTIVITIES_MANAGEMENT',
  DISPATCH_SLIP_MANAGEMENT: 'DISPATCH_SLIP_MANAGEMENT',
  MEMBERS_MANAGEMENT: 'MEMBERS_MANAGEMENT',
  MEMBERS_CLASSIFICATION_MANAGEMENT: 'MEMBERS_CLASSIFICATION_MANAGEMENT',
  REPORT_MEMBERS_BY_EMPLOYER: 'REPORT_MEMBERS_BY_EMPLOYER',
  REPORT_MEMBERS_BY_CLASSIFICATION: 'REPORT_MEMBERS_BY_CLASSIFICATION',
  REPORT_MEMBERS_BY_SECTOR: 'REPORT_MEMBERS_BY_SECTOR',
  REPORT_EMPLOYERS_LABEL: 'REPORT_EMPLOYERS_LABEL',
  REPORT_SHOP_STEWARDS: 'REPORT_SHOP_STEWARDS',
  REPORT_MEMBER_ARREARS: 'REPORT_MEMBER_ARREARS',
  REPORT_MEMBER_DISPATCH_LIST: 'REPORT_MEMBER_DISPATCH_LIST',
  PERSON_TRAINING_MANAGEMENT: 'PERSON_TRAINING_MANAGEMENT',
  REPORT_MEMBERS_CUSTOM: 'REPORT_MEMBERS_CUSTOM',
  REPORT_DUES_POSTING: 'REPORT_DUES_POSTING',
  REPORT_TAX_RECEIPTS: 'REPORT_TAX_RECEIPTS',
  REPORT_DISPATCH_SLIP: 'REPORT_DISPATCH_SLIP',
  REPORT_MEMBERS_DUES_YTD: 'REPORT_MEMBERS_DUES_YTD',
  CURRENT_YEAR_ROLLOVER: 'CURRENT_YEAR_ROLLOVER',
  MEMBERSHIP_APPLICATION: 'MEMBERSHIP_APPLICATION',
  REPORT_MEMBERS_ARREARS_REPORT: 'REPORT_MEMBERS_ARREARS_REPORT',
  REPORT_EMPLOYER_LABEL: 'REPORT_EMPLOYER_LABEL',
  REPORT_MEMBER_LABEL: 'REPORT_MEMBER_LABEL',
  REPORT_IO: 'REPORT_IO',
  REPORT_ACTIVE_MEMBERS: 'REPORT_ACTIVE_MEMBERS',
  REPORT_EMPLOYMENT_BY_CLASSIFICATION: 'REPORT_EMPLOYMENT_BY_CLASSIFICATION',
};
  //Helpers
Globals.AllLevels = [ Globals.Levels.READ, Globals.Levels.WRITE, Globals.Levels.FULLACCESS ];
Globals.AllWriteLevels = [ Globals.Levels.WRITE, Globals.Levels.FULLACCESS ];
  //ACL UI
Globals.ACLS_Levels = [
  { id: null, label: 'No Access', title: 'Admin will not have access at all and the page will be hidden.' },
  { id: Globals.Levels.READ, label: 'Read' },
  { id: Globals.Levels.WRITE, label: 'Write' },
  { id: Globals.Levels.FULLACCESS, label: 'Full Access', title: 'Full Access allow users to Read, Write and Delete if available.' },
];
Globals.Components_ForUI = [{
    label: 'Admin',
    components: [
      { id: Globals.Components.ADMINS_MANAGEMENT, label: 'Admins' },
      { id: Globals.Components.ADMINS_MANAGEMENT_PERMISSIONS, label: 'Admins Permissions' },
    ],
  }, {
    label: 'Configuration',
    components: [
      { id: Globals.Components.DEPTS_MANAGEMENT, label: 'Departments' },
      { id: Globals.Components.SCHEDULES_MANAGEMENT, label: 'Schedules' },
      { id: Globals.Components.SECTORS_MANAGEMENT, label: 'Sectors' },
      { id: Globals.Components.CLASSIFICATION_MANAGEMENT, label: 'Job Classifications' },
      { id: Globals.Components.ACCOUNTS_MANAGEMENT, label: 'Accounts' },
      { id: Globals.Components.SETTINGS_GENERAL, label: 'Main Settings' },
      { id: Globals.Components.SETTINGS_DUE_RATES, label: 'Dues Rates' },
      { id: Globals.Components.TRAINING_CODES_MANAGEMENT, label: 'Training Codes' },
      { id: Globals.Components.UNIT_NUMBERS_MANAGEMENT, label: 'Unit Numbers' },
      { id: Globals.Components.MEMBER_STATUS_MANAGEMENT, label: 'Members Status' },
      { id: Globals.Components.WORK_ACTIVITIES_MANAGEMENT, label: 'Work Activity Codes' },
    ],
  }, {
    label: 'General',
    components: [
      { id: Globals.Components.MEMBERSHIP_APPLICATION, label: 'Applications' },
      { id: Globals.Components.NOTES_MANAGEMENT, label: 'Notes' },
      { id: Globals.Components.CURRENT_YEAR_ROLLOVER, label: 'Reset YTD' },
    ],
  }, {
    label: 'Dispatch',
    components: [
      { id: Globals.Components.DISPATCH_LIST_MANAGEMENT, label: 'Dispatch Lists' },
      { id: Globals.Components.DISPATCH_JOBS_MANAGEMENT, label: 'Dispatch Jobs' },
      { id: Globals.Components.DISPATCH_SLIP_MANAGEMENT, label: 'Dispatch Slip' },
    ],
  }, {
    label: 'Employer',
    components: [
      { id: Globals.Components.EMPLOYERS_MANAGEMENT, label: 'Employers' },
      { id: Globals.Components.EMPLOYER_DUES_BATCHES_MANAGEMENT, label: 'Employers Dues Batches' },
      { id: Globals.Components.EMPLOYER_ELECTRONIC_REMITTANCE, label: 'Employers Eletronic Remittances' },
      { id: Globals.Components.EMPLOYER_WORK_HISTORY_MANAGEMENT, label: 'Employers Work History' },
      { id: Globals.Components.EMPLOYER_CONTACTS_MANAGEMENT, label: 'Employers Contacts' },
      { id: Globals.Components.REPORT_EMPLOYERS_LABEL, label: 'Report Employers Label' },
      { id: Globals.Components.REPORT_EMPLOYER_LABEL, label: 'Employer Label' },
    ],
  }, {
    label: 'Member',
    components: [
      { id: Globals.Components.MEMBERS_MANAGEMENT, label: 'Members' },
      { id: Globals.Components.MEMBER_DUES_ENTRIES_MANAGEMENT, label: 'Members Dues Entries' },
      { id: Globals.Components.MEMBERS_CLASSIFICATION_MANAGEMENT, label: 'Members Classifications' },
      { id: Globals.Components.REPORT_MEMBERS_BY_EMPLOYER, label: 'Report Members by Employer' },
      { id: Globals.Components.REPORT_MEMBERS_BY_CLASSIFICATION, label: 'Report Members by Classification' },
      { id: Globals.Components.REPORT_MEMBERS_BY_SECTOR, label: 'Report Members by Sector' },
      { id: Globals.Components.REPORT_MEMBERS_CUSTOM, label: 'Custom Members Report' },
      { id: Globals.Components.PERSON_TRAINING_MANAGEMENT, label: 'Person Training Management' },
      { id: Globals.Components.REPORT_MEMBER_LABEL, label: 'Member Label' },
    ],
  }, {
    label: 'Reports',
    components: [
      { id: Globals.Components.REPORT_MEMBERS_LABEL, label: 'Members Label' },
      { id: Globals.Components.REPORT_SHOP_STEWARDS, label: 'Shop Stewards' },
      { id: Globals.Components.REPORT_MEMBER_ARREARS, label: 'Members Arrears' },
      { id: Globals.Components.REPORT_MEMBERS_ARREARS_REPORT, label: 'Members Arrears Letter' },
      { id: Globals.Components.REPORT_MEMBER_DISPATCH_LIST, label: 'Dispatch List' },
      { id: Globals.Components.REPORT_DISPATCH_SLIP, label: 'Dispatch Slip' },
      { id: Globals.Components.REPORT_DUES_POSTING, label: 'Dues Posting' },
      { id: Globals.Components.REPORT_TAX_RECEIPTS, label: 'Tax Receipt' },
      { id: Globals.Components.REPORT_IO, label: 'IO Per Capita' },
      { id: Globals.Components.REPORT_ACTIVE_MEMBERS, label: 'Active Members' },
      { id: Globals.Components.REPORT_EMPLOYMENT_BY_CLASSIFICATION, label: 'Dispatch Employment By Classification' },
      { id: Globals.Components.REPORT_MEMBERS_DUES_YTD, label: 'Members Dues YTD' },
    ],
  },
];

Globals.CurrencyInput_Validator = value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
Globals.CurrencyInput_Parser = value => value.replace(/\$\s?|(,*)/g, '');

Globals.MonthInput_Validator = value => `${value} months`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
Globals.MonthInput_Parser = value => value.replace(/months\s?|(,*)/g, '');
