import React from "react";
import autoBind from 'react-autobind';
import { Button, Layout, PageHeader, Table, Select, message } from 'antd';
import { DeleteOutlined, EditOutlined, CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import CommonSearchBar from '@/views/commonComponents/CommonSearchBar';
//
import config from '@/config/config';
import Globals from "@/config/Globals";
import TableActions from "@/views/commonComponents/TableActions";
import Utils from "@/components/Utils";
//
import AdminCreateApplicationsDrawer from './AdminCreateApplicationsDrawer';
import AdminEditApplicationsDrawer from './AdminEditApplicationsDrawer';
import AdminApplicationApproveModal from './AdminApplicationApproveModal';
import AdminApplicationRejectModal from './AdminApplicationRejectModal';
//
export default class AdminApplicationsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      data: [],
      sortedInfo: null,
      ...this._getInitialState(),
    };
  }
  
  // Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Applications`;
    this._fetch();
  }

  // Actions
  handleCreateApplication() {
    this.createDrawer.show();
  }

  handleEditEntry(props) {
    this.updateDrawer.show(props.id);
  }

  handleDeleteEntry(props) {
    message.error('Not implemented!');
  }

  handleApprove(props) {
    this.approveModal.show(props.id);
  }

  handleReject(props) {
    this.rejectModal.show(props.id);
  }

  handleDownload(props) {
    this._download(props);
  }

  handleChangeFilter(key) {
    return (value) => {
      this.setState(prevState => ({
        ...prevState,
        searchObj: { ...prevState.searchObj, [key]: value },
        sortedInfo: null,
        currentPage: 1,
      }), this._fetch);
    };
  }

  handleSearch(term) {
    this.handleChangeFilter('term')(term);
  }

  handleFilterChange(pagination, filters, sortedInfo) {
    this.setState({ sortedInfo }, this._fetch);
  }
  
  // UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const props = {
      onChange: this.handleFilterChange, rowKey: 'id', loading: this.props.isLoading,
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage, showSizeChanger: false, hideOnSinglePage: true, position: 'bottom',
        total: this.state.total, onChange: this.handlePagination, current: this.state.currentPage,
      }
    };

    const columns = [
      {
        title: 'SIN', dataIndex: 'sin', key: 'sin',
        sorter: (a, b) => a.sin.localeCompare(b.sin),
        sortOrder: sortedInfo.columnKey === 'sin' && sortedInfo.order
      },
      {
        title: 'Name', dataIndex: 'fullName', key: 'fullName',
        sorter: (a, b) => a.fullName.localeCompare(b.fullName),
        sortOrder: sortedInfo.columnKey === 'fullName' && sortedInfo.order
      },
      {
        title: 'Email', dataIndex: 'email', key: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order
      },
      {
        title: 'Actions', key: 'actions', align: 'right',
        render: (props) => (
          <TableActions
            options={[
              {
                alwaysVisible: true, label: 'Edit Entry', icon: EditOutlined,
                onClick: this.handleEditEntry.bind(this, props), hide: props.status !== 'PENDING',
              },
              // { 
              //   alwaysVisible: true, label: 'Delete Entry', icon: DeleteOutlined,
              //   confirm: {
              //     message: `Do you really want to delete ${props.fullName}'s application?`,
              //     onConfirm: this.handleDeleteEntry.bind(this, props),
              //   },
              // },
              { 
                alwaysVisible: true, label: 'Approve', icon: CheckCircleOutlined,
                onClick: this.handleApprove.bind(this, props), hide: props.status !== 'PENDING',
              },
              { 
                alwaysVisible: true, label: 'Reject', icon: CloseCircleOutlined,
                onClick: this.handleReject.bind(this, props), hide: props.status !== 'PENDING',
              },
              { 
                alwaysVisible: true, label: 'Download', icon: DownloadOutlined,
                onClick: this.handleDownload.bind(this, props),
              },
            ]}
          />
        ),
      },
    ];

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <AdminCreateApplicationsDrawer app={this.props.app} {...Utils.propagateRef(this, 'createDrawer')} onCreate={this._fetch} />
        <AdminEditApplicationsDrawer app={this.props.app} {...Utils.propagateRef(this, 'updateDrawer')} onUpdate={this._fetch} />
        <AdminApplicationApproveModal app={this.props.app} {...Utils.propagateRef(this, 'approveModal')} onApprove={this._fetch} />
        <AdminApplicationRejectModal app={this.props.app} {...Utils.propagateRef(this, 'rejectModal')} onReject={this._fetch} />

        <PageHeader title="Applications"
          extra={[<Button key="1" onClick={this.handleCreateApplication} type='primary'>Create Application</Button>]} />
        <Layout.Content>
        <CommonSearchBar
          alwaysEnabled
          defaultValue={this.state.searchObj.term}
          handleSearch={this.handleSearch}
          placeholder="Search by SIN or name..."
          extra={(
            <Select
              placeholder="Status"
              showSearch
              defaultValue="PENDING"
              filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
              onChange={this.handleChangeFilter('status')}
            >
              <Select.Option value="PENDING">Pending</Select.Option>
              <Select.Option value="APPROVED">Approved</Select.Option>
              <Select.Option value="REJECTED">Rejected</Select.Option>
              <Select.Option value="ALL">All</Select.Option>
            </Select>
          )}
        />

          <WhiteBox>
            <Table
              columns={columns}
              dataSource={this.state.data}
              {...props}
            />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // Utils
  _getInitialState() {
    const search = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SearchTerm) || '';
    const currentPage = parseInt(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Page) || 1);
    const columnKey = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SortField) || null;
    const order = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SortOrder) || null;
    const status = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Status) || 'PENDING';

    return {
      searchObj: { term: search || '', status },
      currentPage, sortedInfo: { columnKey, order },
      total: 0,
    };
  }

  _setSearchQueryParams() {
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SearchTerm, (this.state.searchObj.term || ''));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_Page, this.state.currentPage);
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SortField, (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.columnKey : ''));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SortOrder, (this.state.sortedInfo ? this.state.sortedInfo.order : null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_Status, (this.state.searchObj.status || null));
  }

  _getSearchFilterObject() {
    //page
    const from = (Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1));
    //sort
    const sortField = (this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey ? this.state.sortedInfo.columnKey : '');
    const sortOrder = (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : '');
    return {
      term: this.state.searchObj.term || '',
      filter: {
        from,
        sortField,
        sortOrder: (sortOrder == 'descend' ? 'desc' : 'asc'),
        status: this.state.searchObj.status !== 'ALL' ? this.state.searchObj.status : null,
      },
    };
  }

  // API Calls
  async _fetch() {
    if (!this._isMounted) return;
    //
    this.setState({ data: [], total: 0, isLoading: true });
    this._setSearchQueryParams();
    //request
    const resp = await this.props.app.api.applications.search(this._getSearchFilterObject());
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.applications) {
      this.setState({ data: resp.body.applications, isLoading: false});
      this.props.app.refreshConfig(true);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _download(data) {
    this.setState({ isLoading: true });

    const resp = await this.props.app.api.applications.download(data.id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      Utils.downloadBlob(resp.body, `${data.fullName}'s Application - ${moment(data.createdOn).format(Globals.DefaultMonthDateFormat)}`, 'pdf');
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    }

    this.setState({ isLoading: false });
  }
}
