import React from "react";
import autoBind from 'react-autobind';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import Utils from '@/components/Utils';
import config from '@/config/config';
import Globals from '@/config/Globals';
//
import { Layout, PageHeader, Form, Select, Input, Button, Row, Col, Divider, Table, Tooltip, Popconfirm, message, InputNumber } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
export default class AdminJobClassificationsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      data: [],
      isEditing: false,
      editingObject: {},
      sortedInfo: null,
    };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Job Classifications`;
    this._fetch();
  }

  handleManageClick(option) {
    this.props.app.urlManager.pushPage(option.route);
  }

  handleDelete(item) {
    this._delete(item.id);
  }

  handleEdit(item) {
    this.form.setFieldsValue(item);
    this.setState({ isEditing: true, editingObject: item });
  }

  handleCancelEdit() {
    this.form.resetFields();
    this.setState({ isEditing: false, editingObject: null });
  }

  async handleAddOrSave() {
    const formData = await this.form.validateFields();
    if (this.state.isEditing) this._edit(formData);
    else this._add(formData);
  }

  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const props = { 
      onChange: this.handleFilterChange, rowKey: 'id', loading: this.props.isLoading,
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage, showSizeChanger: false, hideOnSinglePage: true, position: 'bottom',
        total: this.state.total, onChange: this.handlePagination, current: this.state.currentPage,
      }
    };

    const columns = [
      { title: 'Name', dataIndex: 'name', key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order
      },
      {
        title: 'Number', dataIndex: 'classificationNumber', key: 'classificationNumber',
        sorter: (a, b) => a.classificationNumber.localeCompare(b.classificationNumber),
        sortOrder: sortedInfo.columnKey === 'classificationNumber' && sortedInfo.order
      },
      {
        title: 'Schedule', dataIndex: 'scheduleID', key: 'scheduleID',
        sorter: (a, b) => {
          const schedules = this.props.app.sharedCache().getConfig().schedules;
          const scheduleA = schedules.find((s) => s.id == a.scheduleID)?.name || '-';
          const scheduleB = schedules.find((s) => s.id == b.scheduleID)?.name || '-';
          return scheduleA.localeCompare(scheduleB);
        },
        render: (scheduleID) => {
          const schedules = this.props.app.sharedCache().getConfig().schedules;
          return schedules.find((s) => s.id == scheduleID)?.name || '-';
        },
        sortOrder: sortedInfo.columnKey === 'scheduleID' && sortedInfo.order
      },
      { title: 'Actions', width: 150, key: 'Actions',
        render: props => {
          return (<span className='tableButtonContainer'>
                    <Tooltip placement="bottomLeft" title='Delete Classification'>
                      <Popconfirm title={`Do you really want to delete '${props.name}'?`} placement="bottomRight" onConfirm={this.handleDelete.bind(this, props)} okText="Yes" cancelText="No">
                        <Button variant="none" icon={<DeleteOutlined />} shape="circle"/>
                      </Popconfirm>
                    </Tooltip>{' '}
                    <Tooltip placement="bottomLeft" title='Edit Classification'>
                      <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleEdit.bind(this, props)}/>
                    </Tooltip>
                  </span>);
        }
      }
    ];
    const schedules = this.props.app.sharedCache().getConfig().schedules;
    //
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Job Classifications" onBack={() => window.history.back()} />
        <Layout.Content>
          <WhiteBox>
            <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
              <Row>
                <Col span={6}>
                  <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please, type the name!' }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                  <Form.Item name="classificationNumber" label="Number" rules={[
                    { required: true, message: 'Please, type the classification number!' },
                    { len: 3, message: 'The classification number should contain 3 characters!' }
                  ]}>
                    <Input maxLength={3} minLength={3}/>
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item label="Schedule" name="scheduleID" rules={[{ required: true, message: 'This field is required!' }]}>
                    <Select placeholder="Select a Schedule" showSearch
                            filterOption={(input, option) => (option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)}>
                      {(schedules || []).map((schedule) => (
                        <Select.Option key={schedule.id} value={schedule.id}> {`${schedule.number} - ${schedule.name}`} </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                  <Button style={{ marginTop: 30 }} type="primary" onClick={this.handleAddOrSave}>
                    {this.state.isEditing ? 'Save' : 'Add'}
                  </Button>
                  {this.state.isEditing && (
                    <Button style={{ marginTop: 30 }} type="link" onClick={this.handleCancelEdit}>Cancel</Button>
                  )}
                </Col>
              </Row>
            </Form>

            <Divider />

            <Table style={{ opacity: this.state.isEditing ? 0.3 : 1 }} dataSource={this.state.data} columns={columns} {...props} />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.classifications.getAll();
    console.log(resp);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.classifications) {
      this.setState({ data: resp.body.classifications, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _add(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.classifications.create(data);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Job classification successfully created!');
      this.props.app.refreshConfig();
      this.form.resetFields();
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _edit(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.classifications.update({
      id: this.state.editingObject.id, ...data,
    });

    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Job classification successfully updated!');
      this.props.app.refreshConfig();
      this.form.resetFields();
      this.handleCancelEdit();
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _delete(id) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.classifications.remove(id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Job classification successfully removed!');
      this.props.app.refreshConfig();
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
