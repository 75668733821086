import Globals from '@/config/Globals';
//
export default class APIDispatchJob {
  constructor(API) {
    this.api = API;
  }

  async getByID(employerID, dispatchJobID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Employer + employerID + '/dispatchJob/' + dispatchJobID;
    return await req.exec();
  }

  async getDispatchNotes(dispatchJobID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Notes + 'dispatch/byJob/' + dispatchJobID;
    return await req.exec();
  }

  async create(dispatchJobObj, employerID) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Employer + employerID + '/dispatchJob/' + Globals.URL_NewIndentifier;
    req.body = { ...dispatchJobObj };
    return await req.exec();
  }

  async update(employerID, dispatchJobID, dispatchJobObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_Employer + employerID + '/dispatchJob/' + dispatchJobID;
    req.body = { ...dispatchJobObj };
    return await req.exec();
  }

  async search(filters) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Employer + 'dispatchJobs/search';
    req.body = { ...filters };
    return await req.exec();
  }
  
  async remove(employerID, dispatchJobID) {
    const req = await this.api.newBaseRequest('DELETE');
    req.path = Globals.APIPath_Employer + employerID + '/dispatchJob/' + dispatchJobID;
    return await req.exec();
  }
  
  async dispatchMembers(employerID, dispatchJobID, body) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Employer + `${employerID}/dispatchJob/${dispatchJobID}/assign`;
    req.body = { ...body };
    return await req.exec();
  }
}
