import Globals from '@/config/Globals';
//
export default class APINotes {
  constructor(API) {
    this.api = API;
  }

  async getAll(relType, noteRelID, noteRelType) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Notes + `${relType}/${noteRelID}/${noteRelType}`;
    return await req.exec();
  }
  
  async getByID(noteID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Note + noteID;
    return await req.exec();
  }

  async create(noteObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Note + Globals.URL_NewIndentifier;
    req.body = { ...noteObj };
    return await req.exec();
  }

  async update(noteObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_Note + noteObj.id;
    delete noteObj.id;
    req.body = { ...noteObj };
    return await req.exec();
  }

  async remove(noteID) {
    const req = await this.api.newBaseRequest('DELETE');
    req.path = Globals.APIPath_Note + noteID;
    return await req.exec();
  }
}
