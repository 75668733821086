import React from "react";
import autoBind from 'react-autobind';
//Components
import { Form, Input, Button, Row, Divider } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
//
import Utils from '@/components/Utils';
import { IDMGlobals } from "@ikonintegration/idmclient";
//resources
import "@/stylesheets/CommonLogin.scss";
import config from "@/config/config";
//
export default class CommonLoginForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.initialUser = this.props.app.urlManager.getQueryParam(IDMGlobals.URLQueryParam_UserEmail);
  }

  async handleSubmit(e) {
    e.preventDefault();
    const formData = await this.form.validateFields();
    if (formData) this.props.onLogin(formData);
  }

  handleApplicationForm() {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.applicationForm);
  }

  //UI
  render() {
    return (
      <Form onSubmit={this.handleSubmit} className='loginForm' {...Utils.propagateRef(this, 'form')}>
        <Form.Item
          name="username"
          initialValue={this.initialUser}
          rules={[
            { transform: (value) => value.trim() },
            { required: true, message: 'Please, type your email!' },
            { type: 'email', message: 'Please, type a valid email address!' },
          ]}
        >
          <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email"/>
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please, type your password!' }]}
        >
        <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" data-dd-privacy="mask" placeholder="Password"/>
        </Form.Item>
        <Form.Item>
          <Button onClick={this.handleSubmit} type="primary" htmlType="submit" className="loginFormLoginButton">
            Sign in
          </Button>
          {this.props.app.idm.externalProviders.isAzureAvailable() && <Row type='flex' justify='center' style={{ marginBottom: '15px', marginTop: '10px' }}>
            <Button className="loginFormLoginButton" onClick={this.props.onProviderLogin.bind(this, 'Azure')}>
              <img width="17px" style={{ marginBottom: '3px', marginRight: '5px' }} alt="Azure login" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/1024px-Microsoft_logo.svg.png?20210729021049" />
              Continue with Azure
            </Button></Row>}
          <a className="loginFormForgot" onClick={this.props.onForgot}>Forgot password?</a>
        </Form.Item>
        <Divider style={{ color: '#999' }}>OR</Divider>
        <Button onClick={this.handleApplicationForm} type="primary" ghost className="loginFormLoginButton">
          Apply for membership
        </Button>
      </Form>
    );
  }
}
