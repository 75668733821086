import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button, message } from 'antd';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import Utils from '@/components/Utils';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import CommonEmployersForm from '@/views/commonSubviews/CommonEmployersForm';
//
import config from "@/config/config";
//
export default class AdminEmployersCreateView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
  }
  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Create Employer`;
  }
  //Actions
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) this._create(formData);
  }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Create Employer" onBack={() => window.history.back()} 
                    extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>Create</Button>]} />
        <Layout.Content>
          <WhiteBox>
            <CommonEmployersForm app={this.props.app} {...Utils.propagateRef(this, 'form')} />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _create(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.employers.create({
      ...data,
      phone: Utils.sanitizePhone(data.phone),
      altPhone: Utils.sanitizePhone(data.altPhone),
      fax: Utils.sanitizePhone(data.fax),
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Employer successfully created!');
      this.form.resetFields();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading(true);
  }
}
