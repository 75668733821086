import React from "react";
import autoBind from 'react-autobind';
import { Collapse, Row, Col, Radio, Divider, Tooltip, Form } from 'antd';
//
import Utils from '@/components/Utils';
import Globals from '@/config/Globals';
import CustomComponent from '@/components/CustomComponent';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
export default class AdminAdminManageAclsForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.adminID = this.props.adminID;

    this.state = {
      isLoading: false,
      hasIDMID: false,
      activePanelKeys: Globals.Components_ForUI.map((i) => i.label),
    };
  }

  componentDidMount() {
    this._fetch();
  }

  handleChangePanel(panelKey) {
    this.setState({ activePanelKeys: panelKey });
  }

  async getAcls() {
    const formData = await this.form.validateFields();

    const acls = Object.keys(formData).map((componentID) => ({
      componentID,
      level: formData[componentID],
    })).filter((acl) => !!acl.level);
    
    return acls;
  }

  render() {
    return (
      <Form {...Utils.propagateRef(this, 'form')}>
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <Collapse activeKey={this.state.activePanelKeys} onChange={this.handleChangePanel}>
          {Globals.Components_ForUI.map((category, categoryIndex) => (
            <Collapse.Panel header={category.label} key={category.label} className='adminACLCollapseSection'>
              {category.components.map((component, componentIndex) => (
                <React.Fragment key={component.id}>
                  <Row className="adminACLRow" justify="space-between">
                    <Col>{component.label}</Col>
                    <Col>
                      <Form.Item name={component.id} initialValue={null}>
                        <Radio.Group className='adminACLRowRadioGroup'>
                          {Globals.ACLS_Levels.map((level) => (
                            <Tooltip key={level.id} title={level.title}>
                              <Radio value={level.id}>{level.label}</Radio>
                            </Tooltip>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  {!(componentIndex == (Globals.Components_ForUI[categoryIndex].components.length - 1)) ? <Divider className="adminACLRowDivider"/> : null}
                </React.Fragment>
              ))}
            </Collapse.Panel>
          ))}
        </Collapse>
      </Form>
    )
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.admins.getAcls(this.adminID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.acls) {
      const acls = resp.body.acls.reduce((acc, curr) => ({
        ...acc,
        [curr.componentID]: curr.level,
      }), {});
      this.form.setFieldsValue(acls);
      this.setState({ isLoading: false, activePanelKeys: Globals.Components_ForUI[0].label });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
