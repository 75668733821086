import React from "react";
import autoBind from 'react-autobind';
import { Checkbox, Form, Row, Divider, Col, Tag, Input, Popover, DatePicker, InputNumber, Descriptions } from 'antd';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
//
import Utils from '@/components/Utils';
import Globals from '@/config/Globals';
//
export default class CommonDuesEntryReceiptForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  setFields(entryObj, employerData) {
    this.setState({ ...entryObj, employer: employerData });
    return this.form.setFieldsValue({
      employerName: (employerData?.name ? `${employerData?.companyNumber} - ${employerData?.name}` : ''),
      batchID: entryObj.batchID || '',
      memberName: entryObj.memberLastName ? `${entryObj.memberFirstName} ${entryObj.memberLastName}` : '',
      memberSIN: entryObj.memberSIN || '',
      receiptAmount: entryObj.amount || '', official: !!entryObj.official,
      print: !!entryObj.print, receiptNumber: entryObj.receiptNumber || '',
      receiptDate: entryObj.receiptDate ? moment(new Date(entryObj.receiptDate), Globals.DefaultUIBirthDateFormat) : null,
    });
  }
  //
  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')} className='batchEditForm'>
        <Form.Item label="Employer" name="employerName" initialValue=''>
          <Input bordered={false} disabled prefix={this._renderEmployerInformationSuffix()} />
        </Form.Item>
        <Row type='flex'>
          <Col>
            <Form.Item label="Batch ID" name="batchID" initialValue=''>
              <Input bordered={false} disabled />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item label="Member Name" name="memberName" initialValue=''>
              <Input bordered={false} disabled />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item label="Member SIN" name="memberSIN" initialValue=''>
              <Input bordered={false} disabled />
            </Form.Item>
          </Col>
        </Row>  
        <Divider />
        <Row>
          <Col>
            <Form.Item label="Type" name="receiptType" rules={[{ required: false }]}>
              <Tag color="geekblue">{(this.state.official ? 'O' : (this.state.receiptNumber ? 'M' : 'N/A'))}</Tag>
            </Form.Item>
          </Col>
          <Col offset={2}>
            <Form.Item label="Receipt #" name="receiptNumber" initialValue=''>
              <Input bordered={false} disabled />
            </Form.Item>
          </Col>
          <Col >
            <Form.Item label="Receipt Date" name="receiptDate" initialValue=''>
              <DatePicker format={Globals.DefaultUIBirthDateFormat} style={{ width: 200 }} bordered={false} disabled />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item label="Receipt Amount" name="receiptAmount" initialValue=''>
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} bordered={false} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label="Is Official?" name="official" valuePropName="checked">
              <Checkbox id="official" disabled bordered={false}/>
            </Form.Item>
          </Col>
          <Col offset={4}>
            <Form.Item label="Print?" name="print" valuePropName="checked">
              <Checkbox id="print" disabled bordered={false} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
  //Private UI
  _renderEmployerInformationSuffix() {
    return (
      this.state.employer ?
        <Popover title={<b>Employer information</b>} content={
          <Descriptions size='small' column={4} style={{ maxWidth: '400px' }}>
            <Descriptions.Item label={<b>Name</b>} span={4}> {this.state?.employer?.name} </Descriptions.Item>
            <Descriptions.Item label={<b>Number</b>} span={2}> {this.state?.employer?.companyNumber} </Descriptions.Item>
            <Descriptions.Item label={<b>Inactive</b>} span={2}> {this.state?.employer?.disabled ? 'Yes' : 'No'} </Descriptions.Item>
            <Descriptions.Item label={<b>Email</b>} span={4}> {this.state?.employer?.email} </Descriptions.Item>
            <Descriptions.Item label={<b>Phone</b>} span={4}> {this.state?.employer?.phone ? Utils.formatPhone(this.state?.employer?.phone) : 'N/A'} </Descriptions.Item>
            <Descriptions.Item label={<b>Alt Phone</b>} span={4}> {this.state?.employer?.altPhone ? Utils.formatPhone(this.state?.employer?.altPhone) : 'N/A'} </Descriptions.Item>
            <Descriptions.Item label={<b>Fax</b>} span={4}> {this.state?.employer?.fax ? Utils.formatPhone(this.state?.employer?.fax) : 'N/A'} </Descriptions.Item>
          </Descriptions>
        }>
          <InfoCircleOutlined className='formInlineButton' />
        </Popover> : <></>
    )
  }
}
