import Globals from '@/config/Globals';
//
export default class APIChartOfAccounts {
  constructor(API) {
    this.api = API;
  }

  async getAll() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_ChartOfAccounts;
    return await req.exec();
  }

  async create(accountObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_ChartOfAccount + Globals.URL_NewIndentifier;
    req.body = { ...accountObj };
    return await req.exec();
  }

  async update(accountObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_ChartOfAccount + accountObj.id;
    delete accountObj.id;
    req.body = { ...accountObj };
    return await req.exec();
  }

  async remove(accountID) {
    const req = await this.api.newBaseRequest('DELETE');
    req.path = Globals.APIPath_ChartOfAccount + accountID;
    return await req.exec();
  }
}
