import md5 from 'md5';
//
import Globals from '@/config/Globals';
import APIRequest from '../APIRequest';
import Utils from '@/components/Utils';
//
export default class APIEmployerFiles {
  constructor(API) {
    this.api = API;
  }

  async getAll(employerID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Employer + employerID + '/erFiles';
    return await req.exec();
  }

  async getByID(employerID, fileID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Employer + employerID + '/erFile/' + fileID;
    return await req.exec();
  }

  async getPending() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Employer + 'erFiles/pending';
    return await req.exec();
  }
  
  async remove(employerID, fileID) {
    const req = await this.api.newBaseRequest('DELETE');
    req.path = Globals.APIPath_Employer + employerID + '/erFile/' + fileID;
    return await req.exec();
  }
  
  async validate(employerID, fileID, contentType, logLevel) {
    const req = await this.api.newBaseRequest('POST');
    if (contentType) {
      req.appendHeader('Accept', contentType);
      req.bodyType = 'JSON/BLOB';
    }
    req.path = Globals.APIPath_Employer + employerID + '/erFile/' + fileID + '/validate' + (logLevel ? '?logLevel=' + logLevel : '');
    return await req.exec();
  }
  
  async commit(employerID, fileID) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Employer + employerID + '/erFile/' + fileID + '/commit';
    return await req.exec();
  }
  
  async getCommitLogs(employerID, fileID, contentType) {
    const req = await this.api.newBaseRequest('POST');
    req.appendHeader('Accept', contentType);
    req.bodyType = 'BLOB';
    req.path = Globals.APIPath_Employer + employerID + '/erFile/' + fileID + '/commit/logs';
    return await req.exec();
  }

  // SignURL
  async _getSignURL(employerID, fileID, uptype) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Employer + employerID + '/fileSign/' + fileID + '?uptype=' + uptype;
    return await req.exec();
  }

  async _createSignURL(employerID, generatedFileID, fileType, uptype) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Employer + employerID + '/fileSign/' + generatedFileID + '?fileType=' + fileType + '&uptype=' + uptype;
    return await req.exec();
  }

  // Download Stuff
  async download(employerID, fileID, uptype) {
    const signedURLResp = await this._getSignURL(employerID, fileID, uptype);

    if (signedURLResp.statusCode != 200 || !signedURLResp.body.fileURL) {
      return signedURLResp;
    }

    return this._getRemoteFile(signedURLResp.body.fileURL);
  }

  async _getRemoteFile(url) {
    const request = new APIRequest('GET');
    request.baseURL = url;
    request.bodyType = 'BLOB';
    return await request.exec();
  }

  // Upload stuff
  async upload(file, employerID, generatedFileID, uptype, submissionPeriod, paymentType, comments) {
    if (file.size > Globals.API_MaxAllowedFileSize) {
      return { error: 'File is too big! Max allowed size is 10mb.', statusCode: -1 };
    }
    
    const signedURLResp = await this._createSignURL(employerID, generatedFileID, file.type, uptype);

    if (signedURLResp.statusCode != 200 || !signedURLResp.body.fileURL) {
      return signedURLResp;
    }

    const signedURL = signedURLResp.body.fileURL;

    const uploadResp = await this._uploadRemoteFile(file, employerID, signedURL);
    
    if (uploadResp.statusCode != 200) {
      return uploadResp;
    }
    
    return this._saveFile(employerID, generatedFileID, uptype, submissionPeriod, paymentType, comments);
  }

  async _uploadRemoteFile(file, employerID, url) {
    const req = new APIRequest('PUT');
    req.baseURL = url;

    req.appendHeader('Content-type', file.type);
    req.appendHeader('Content-length', file.file.length);

    req.body = Utils.dataURItoBlob(file.file);
    req.bodyType = 'BINARY';
    return await req.exec();
  }
  
  async _saveFile(employerID, generatedFileID, uploadType, submissionPeriod, paymentType, comments) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Employer + employerID + '/erFile/' + generatedFileID;
    req.body = { uploadType, submissionPeriod, paymentType, comments };
    return await req.exec();
  }

  // Utils
  encodedFileName(employerID, filename) {
    return md5(employerID + filename + Date.now());
  }
}
