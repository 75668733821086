import React from "react";
import autoBind from 'react-autobind';
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
//
import { Typography, Form, Tag, Row, Col, Select, DatePicker, InputNumber, Divider } from 'antd';
//
import Utils from "@/components/Utils";
import Globals from '@/config/Globals';
//props are: readOnly, app
export default class CommonDuesEntryDuesForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }
  //Form methods
  async validateFields() {
    const formData = await this.form.validateFields();
    if (formData) {
      const data = {
        ...this.state.data,
        ...formData,
        activityCode: 'D',
        transactionCode: 'D',
        transactionDate: (formData.transactionDate ? moment(formData.transactionDate).format(Globals.DefaultAPIDateFormat) : null),
        ...(formData.receiptType == 'O' ? { official: true, print: true } : (formData.receiptType == 'M' ? { official: false, print: true } : { official: false, print: false }))
      };
      //Remove readonly stuff
      delete data.receiptType;
      //
      return data;
    } return false;
  }
  resetFields() { return this.form.resetFields(); }
  setFieldsValue(data, memberObj) {
    const receiptType = (!data ? (((this.state.memberObj?.memberType?.trim() == 'PA' || this.state.memberObj?.memberType?.trim() == 'PB') && (memberObj?.arrearsIO > 0 || memberObj?.arrearsLU > 0))
        ? 'M' : ((this.state.memberObj?.memberType?.trim() == 'PA' || this.state.memberObj?.memberType?.trim() == 'PB') ? null : 'O')) : (data.receiptID ? (data.official ? 'O' : 'M') : null));
    const tmpData = {
      ...(data || {}),
      ...(!data ? {
        transactionDate: (this.props.batchObj?.postingDate && this.props.batchObj?.postingDate.length > 0 ? moment(this.props.batchObj?.postingDate) : ''),
        receiptType: receiptType, amount: 0, lu: 0, io: 0, arrearsIO: 0, arrearsLU: 0,
        monthsPaid: 1,
      } : {
          transactionDate: (data.transactionDate && data.transactionDate.length > 0 ? moment(data.transactionDate) : ''),
          receiptType: receiptType
        }),
        amount: data ? data.amount || 0 : 0
    };
    this.setState({ ...tmpData, memberObj, receiptType }, () => {
      console.log(this.props.batchObj)
      this.form.setFieldsValue(tmpData);
    });
  }

  //Form actions
  handleInputChange(key, value) { 
    const config = this.props.app.sharedCache().getConfig().settings;
    let amount = this.state.amount;
    let suspense = this.state.suspense;
    let receiptNumber = this.state.receiptNumber;

    //Update
    if (key == 'amount') amount = value;
    else if (key == 'suspense') suspense = value;
    else if (key == 'monthsPaid') { //update amount when updating months paid
      if (this.state.memberObj?.memberType?.trim() == 'A' || this.state.memberObj?.memberType?.trim() == 'PA') amount = parseFloat(config.aMemberIo) * value;
      else if (this.state.memberObj?.memberType?.trim() == 'BA' || this.state.memberObj?.memberType?.trim() == 'PB' || this.state.memberObj?.memberType?.trim() == 'FP') amount = parseFloat(config.baMemberIo) * value;
      if (this.form) this.form.setFieldsValue({ amount });
    } else if (['io', 'lu', 'arrearsIO', 'arrearsLU'].indexOf(key) != -1) { //update suspense when updating one of the values on the array
      suspense = parseFloat(this.state.amount || 0) - 
                 parseFloat((key == 'io' ? (value || 0) : (this.state.io || 0))) - 
                 parseFloat((key == 'lu' ? (value || 0) : this.state.lu || 0)) - 
                 parseFloat((key == 'arrearsIO' ? (value || 0) : (this.state.arrearsIO || 0))) - 
                 parseFloat((key == 'arrearsLU' ? (value || 0) : (this.state.arrearsLU || 0)));
      if (this.form) this.form.setFieldsValue({ suspense });
    } else if (key == 'receiptType' && !value) {
      receiptNumber = '';
      if (this.form) this.form.setFieldsValue({ receiptNumber });
    }

    //set state
    this.setState({ [key]: value, amount, suspense, receiptNumber }, () => this._calculateNormalDistributionAndValidate(key));
  }
  handleReceiptNumberChange(event) { this.setState({ receiptNumber: (event && event.target ? event.target.value : '') }); }
  //UI
  render() {
    const distriSum = parseFloat((parseFloat(this.state.io || 0) + parseFloat(this.state.lu || 0) + parseFloat(this.state.suspense || 0) + parseFloat(this.state.arrearsIO || 0) + parseFloat(this.state.arrearsLU || 0)).toFixed(2));
    const config = this.props.app.sharedCache().getConfig().settings;
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Divider orientation='left' style={{ margin: '4px' }}> Dues Entry </Divider>
        {this.props.extra || <></>}
        <Row type='flex'>
          <Col>
            <Form.Item name="monthsPaid" label="Months">
              <InputNumber style={{ minWidth: 50 }} min={0} onChange={this.handleInputChange.bind(this, 'monthsPaid')}/>
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item label="Amount" name="amount" rules={[
              {
                required: false, validator: (r, v, c) => new Promise((resolve) => {
                  if (parseFloat(this.state.amount) < distriSum) throw new Error(`Amount ${Utils.toCurrencyFormat(this.state.amount)} cannot be less than distribution (${Utils.toCurrencyFormat(distriSum)})`);
                  else resolve();
                })
              }
            ]}>
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} style={{ minWidth: 150 }} disabled={this.props.readOnly}
                onChange={this.handleInputChange.bind(this, 'amount')} />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item name="io" label="IO" rules={[{
                required: true, validator: (r, v, c) => new Promise((resolve) => {
                  if (this.state.arrearsIO != undefined && parseFloat(this.state.arrearsIO) != 0) {
                    resolve();
                    return;
                  }
                  // we used to enforce config.aMemberIO or baMemberIO as minimum amounts for IO. This check was removed to allow ibew
                  // to create entries to adjust paidTo using a previous amount of IO (for years when IO increases)
                  if (this.state.memberObj?.memberType?.trim() == 'A' && parseFloat(this.state.io) < 0 && this.state.monthsPaid != 0) throw new Error(`A member IO amount is incorrect!`);
                  else if (this.state.memberObj?.memberType?.trim() == 'BA' && parseFloat(this.state.io) < 0 && this.state.monthsPaid != 0) throw new Error(`BA member IO amount is incorrect!`);
                  else resolve();
                })
              }]}>
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} style={{ minWidth: 150 }}
                           onChange={this.handleInputChange.bind(this, 'io')}/>
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item name="lu" label="LU">
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} style={{ minWidth: 150 }}
                           onChange={this.handleInputChange.bind(this, 'lu')}/>
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col>
            <Form.Item label="Receipt Type" name='receiptType'>
              <Select style={{ minWidth: 50 }} onChange={this.handleInputChange.bind(this, 'receiptType')}>
                <Select.Option value={'O'}>O</Select.Option>
                <Select.Option value={'M'}>M</Select.Option>
                <Select.Option value={null}>N/A</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item name="receiptNumber" label="Receipt Number">
              <InputNumber precision={0} placeholder={!this.state.receiptType ? 'Unavailable' : 'Auto Generated'} style={{ minWidth: 150 }}
                           onChange={this.handleReceiptNumberChange} disabled={!this.state.receiptType}
                           max={2147483647} min={-2147483647}/>
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col>
            <Form.Item name="arrearsIO" label="Arrears IO">
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} style={{ minWidth: 170 }}
                           onChange={this.handleInputChange.bind(this, 'arrearsIO')}/>
            </Form.Item>
          </Col>
          <Col  offset={1}>
            <Form.Item name="arrearsLU" label="Arrears LU">
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} style={{ minWidth: 170 }}
                           onChange={this.handleInputChange.bind(this, 'arrearsLU')}/>
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item name="suspense" label="Suspense">
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} style={{ minWidth: 170 }}
                           onChange={this.handleInputChange.bind(this, 'suspense')}/>
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex' justify='start'>
          <Col>
            <Form.Item label="Transaction Date" name="transactionDate">
              <DatePicker format={Globals.DefaultUIBirthDateFormat} style={{ width: 200, marginRight: '10px' }}/>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Gross Amount" >
              <Typography.Text style={{marginLeft: '20px'}}>${this._calculateGrossValue()}</Typography.Text>
            </Form.Item>
          </Col>
          {this.state.arrearsIO != 0 && this.state.memberObj && <Col offset={1}>
            <Form.Item label="Months Arrears" >
              <Typography.Text style={{ marginLeft: '20px' }}>{(() => {
                const config = this.props.app.sharedCache().getConfig().settings;
                if (this.state.memberObj?.memberType?.trim() == 'A' || this.state.memberObj?.memberType?.trim() == 'PA') return `${parseInt(this.state.arrearsIO / parseFloat(config.aMemberIo))} months`;
                else if (this.state.memberObj?.memberType?.trim() == 'BA' || this.state.memberObj?.memberType?.trim() == 'PB' || this.state.memberObj?.memberType?.trim() == 'FP') return `${parseInt(this.state.arrearsIO / parseFloat(config.baMemberIo))} months`;
                return `0 months`;
              })()}</Typography.Text>
            </Form.Item>
          </Col>}
        </Row>
      </Form>
    )
  }

  /* private UI functions */
  _calculateGrossValue() {
    let ioAmount = 0;
    const safeMonths = Math.max(1, (this.state.monthsPaid || 0));
    const config = this.props.app.sharedCache().getConfig().settings;
    //Get IO amount based on member type
    if (this.state.memberObj?.memberType?.trim() == 'A' || this.state.memberObj?.memberType?.trim() == 'PA') ioAmount = parseFloat(config.aMemberIo * safeMonths) / (config.aMemberIoPercent ? 100.0 : 1);
    else if (this.state.memberObj?.memberType?.trim() == 'BA' || this.state.memberObj?.memberType?.trim() == 'PB' || this.state.memberObj?.memberType?.trim() == 'FP') ioAmount = parseFloat(config.baMemberIo * safeMonths) / (config.baMemberIoPercent ? 100.0 : 1);
    const gross = (parseFloat(this.state?.amount || 0) - ioAmount) / (parseFloat(config?.workingDues || 0) / (config.workingDuesPercent ? 100.0 : 1));
    return Utils.safelyFixCurrency(gross);
  }
  _calculateNormalDistributionAndValidate(key) {
    const config = this.props.app.sharedCache().getConfig().settings;
    //Distribution if updating amount or months paid
    if (key == 'amount' || key == 'monthsPaid') {
      //Counter cash
      if (this.props.employerObj.companyNumber == Globals.CounterCash_OrganizationNumber) {
        this.setState({ io: 0, lu: 0, arrearsIO: 0, arrearsLU: 0, suspense: this.state.amount }, () => {
          if (this.form) this.form.setFieldsValue({ suspense: this.state.suspense, io: this.state.io, lu: this.state.lu, arrearsIO: this.state.arrearsIO, arrearsLU: this.state.arrearsLU });
        })
      } else {
        let ioAmount = 0;
        let { arrearsLU, arrearsIO, io, lu, monthsPaid } = this.state;
        arrearsLU = arrearsLU || 0, arrearsIO = arrearsIO || 0, io = io || 0, lu = lu || 0, monthsPaid = monthsPaid || 0;
        let basicDues = (this.state.memberObj.outOfJurisdiction ? parseFloat(config.basicDuesOutOfJurisdiction) : parseFloat(config.basicDuesInJurisdiction) );
        //Get IO amount based on member type
        if (this.state.memberObj?.memberType?.trim() == 'A' || this.state.memberObj?.memberType?.trim() == 'PA') ioAmount = parseFloat(config.aMemberIo);
        else if (this.state.memberObj?.memberType?.trim() == 'BA' || this.state.memberObj?.memberType?.trim() == 'PB' || this.state.memberObj?.memberType?.trim() == 'FP') ioAmount = parseFloat(config.baMemberIo);
        //If cash code
        if (this.props.employerObj.companyNumber == Globals.Cash_OrganizationNumber && (parseFloatparseFloat(this.state.arrearsIO) > 0 || parseFloat(this.state.arrearsLU) > 0)) {
          arrearsIO = ioAmount * monthsPaid;
          arrearsLU = basicDues * monthsPaid;
        } else {
          const luMonthToDate = parseFloat(this.state.memberObj?.lastDuesEntryLU || 0);
          const ioMonthToDate = parseFloat(this.state.memberObj?.lastDuesEntryIO || 0);
          const duesPrev = luMonthToDate + ioMonthToDate;
          const duesTotal = parseFloat(this.state.amount || 0) - duesPrev;
          let localDues = 0;
          const minDues = (ioAmount + basicDues) * monthsPaid;
          const permitMember = (this.state.memberObj?.memberType?.trim() == 'PA' || this.state.memberObj?.memberType?.trim() == 'PB');
          // is this person employed and in jurisdiction and paying more than unemployed (min) dues?
          if (this.props.employerObj.companyNumber != Globals.Cash_OrganizationNumber && !this.state.memberObj.outOfJurisdiction && (parseFloat(this.state.amount) > minDues || permitMember)) {
            // if a permit member, move IO amount to local
            if (permitMember || monthsPaid == 0) io = 0;
            else io = (ioAmount * monthsPaid) - ioMonthToDate;
            localDues = (duesTotal - io);
            //
            if (localDues <= (7.5 * monthsPaid) && !permitMember) lu = Math.max(localDues, 0);
            else lu = localDues - luMonthToDate;
          } else {
            io = ioAmount * monthsPaid;
            lu = basicDues * monthsPaid
          }

          // now, if the member has arrears, apply amounts to them first
          if (arrearsIO > 0) {
            arrearsIO = io * this.state.monthsPaid;
            if (arrearsIO > this.state.arrearsIO) arrearsIO = this.state.arrearsIO;
            io = io - arrearsIO;
          }
          if (arrearsLU > 0) {
            arrearsLU = basicDues * this.state.monthsPaid;
            if (arrearsLU > this.state.arrearsLU) arrearsLU = this.state.arrearsLU;
            lu = lu - arrearsLU;
          }
        }
        const suspense = parseFloat(this.state.amount || 0) - parseFloat(io || 0) - parseFloat(lu || 0) - parseFloat(arrearsIO || 0) - parseFloat(arrearsLU || 0);
        this.setState({ 
          suspense: Utils.safelyFixCurrency(suspense),
          io: Utils.safelyFixCurrency(io),
          lu: Utils.safelyFixCurrency(lu),
          arrearsIO: Utils.safelyFixCurrency(arrearsIO),
          arrearsLU: Utils.safelyFixCurrency(arrearsLU) 
        }, () => {
          if (this.form) this.form.setFieldsValue({ suspense: this.state.suspense, io: this.state.io, lu: this.state.lu, arrearsIO: this.state.arrearsIO, arrearsLU: this.state.arrearsLU });
        });
      }
    } else if (this.form) this.form.validateFields();
  } 
}
