import React from "react";
import { Drawer, Row, Col, Button, message } from 'antd';
import autoBind from 'react-autobind';
//
import CustomComponent from '@/components/CustomComponent';
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
import Utils from "@/components/Utils";
//
import AdminApplicationsForm from './AdminApplicationsForm';
//
export default class AdminCreateApplicationsDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isVisible: false,
      isLoading: false,
    };
  }

  show() {
    this.setState({ isVisible: true });
  }

  handleClose() {
    this.form.resetState();
    this.setState({
      isVisible: false,
      isLoading: false,
    });
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();

    if (formData) {
      this._submit(formData);
    }
  }

  // UI
  render() {
    return (
      <Drawer
        visible={this.state.isVisible}
        title="Create Application"
        placement="right"
        onClose={this.handleClose}
        width={750}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <AdminApplicationsForm {...Utils.propagateRef(this, 'form')} />
        <Row>
          <Col>
            <Button type="primary" onClick={this.handleSubmit}>Create</Button>
          </Col>
        </Row>
      </Drawer>
    )
  }

  // API Calls
  _uploadSignature(signature) {
    return new Promise((resolve) => {
      const file = signature;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onloadend = async (readerResp) => {
        const generatedFileID = this.props.app.api.applications.encodedFileName(file.name);
        
        file.file = readerResp.currentTarget.result;
        if (!this._isMounted) return;

        const resp = await this.props.app.api.applications.upload(file, generatedFileID);
        if (resp && resp.statusCode == 200) {
          resolve({ generatedFileID });
        } else {
          resolve(resp);
        }
      };
    });
  }

  async _submit({ signature, ...data }) {
    this.startLoading();
    //
    // upload signature
    const signatureResp = await this._uploadSignature(signature);
    //
    if (signatureResp.generatedFileID) {
      const applicationResp = await this.props.app.api.applications.create({
        ...data,
        signatureFileID: signatureResp.generatedFileID,
      });
      if (!this._isMounted) return;

      if (applicationResp.statusCode == 200 && applicationResp.body) {
        this.handleClose();
        message.success('Application successfully created!');
        if (this.props.onCreate) this.props.onCreate();
      } else {
        this.props.app.alertController.showAPIErrorAlert(null, applicationResp);
        this.setState({ isLoading: false });
      }
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, signatureResp);
      this.setState({ isLoading: false });
    }
  }
}
