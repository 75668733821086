import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Form, Row, Col, Select, DatePicker, Button, message } from 'antd';
import { GrDocumentPdf, GrDocumentExcel } from "react-icons/gr";
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import config from "@/config/config";
import Utils from "@/components/Utils";
import Globals from '@/config/Globals';
//
export default class AdminMemberIoReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
  }

  //Live cycle
  componentDidMount() { document.title = `${config.ApplicationName} - IO Per Capita`; }

  //Actions
  async handleSubmit() {
    const formData = await this.form.validateFields();

    if (formData) {
      const startDate = formData.startDate ? moment(formData.startDate).format(Globals.DefaultAPIDateFormat) : null;
      const endDate = formData.endDate ? moment(formData.endDate).format(Globals.DefaultAPIDateFormat) : null;
      this._generate({ startDate, endDate });
    }
  }

  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="IO Per Capita" extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>Generate Report</Button>]} />
        <Layout.Content>
          <WhiteBox>
            <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
              <Row>
                <Col span={4}>
                  <Form.Item name="startDate" label="Start Date" rules={[{ required: true, message: 'This field is required!' }]}>
                    <DatePicker format={Globals.DefaultSimpleDateFormat} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                  <Form.Item name="endDate" label="End Date" rules={[{ required: true, message: 'This field is required!' }]}>
                    <DatePicker format={Globals.DefaultSimpleDateFormat} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  /* private API */
  async _generate({ startDate, endDate }) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.reports.membersIoReport({ startDate, endDate });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      Utils.downloadBlob(resp.body, `IO - ${startDate} to ${endDate}`, 'pdf');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.setState({ isLoading: false });
  }
}
