import React from "react";
import { Button, Popover, Typography, Form, Modal, Checkbox, Row, Divider } from 'antd';
import autoBind from 'react-autobind';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ReactPasswordStrength from "@ikonintegration/react-password-strength";
//
import CustomComponent from "@/components/CustomComponent";
import CommonLoadingView from "./CommonLoadingView";
import Globals from '@/config/Globals';
import Utils from '@/components/Utils';
//resources
import "@/stylesheets/CommonLogin.scss";
//
export default class CommonSetPasswordModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      data:{ userID: null, newPassword: "", passwordConfirmation: "", permanent: true }, 
      isLoading: false, show: false, closeWindowAfterChange: false,
    };
  }

  //Actions
    //Modal
  handleShow(userID, closeWindowAfterChange) {
    this.setState({
      closeWindowAfterChange: closeWindowAfterChange,
      data:{ userID, newPassword: "", passwordConfirmation: "", permanent: true }, 
      isLoading: false, show: true,
    });
  }
  handleHide() { this.setState({show: false}); }
    //Form
  handleCheckChange(id, val) {
    this.setState(prevState => ({
      ...prevState,
      data: { ...prevState.data, [id]: val.target.checked }
    }));
  }
  handleInputPasswordChange(fieldName) {
    return (event) => {
      this.setState(prevState => ({
        ...prevState,
        data: { ...prevState.data, [fieldName]: event.password }
      }));
    };
  }
  async handleChangePassword() {
    const formData = await this.form.validateFields();
    if (formData) {
      if (!this.state.data.newPassword) {
        this.props.app.alertController.showErrorAlert("Error!", "New password is required");
        return;
      } else if (!this.state.data.passwordConfirmation) {
        this.props.app.alertController.showErrorAlert("Error!", "New password confirmation is required");
        return;
      } else if (this.state.data.passwordConfirmation != this.state.data.newPassword) {
        this.props.app.alertController.showErrorAlert("Error!", "Passwords don't match!");
        return;
      }
      this.setState(prevState => ({
        ...prevState,
        data: { ...prevState.data, ...formData },
      }), this._updatePassword);
    }
  }
  
  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.isAuthenticating;
    const faillingRules = this._calculateFaillingRules();
    const passwordRulesPassing = !!(faillingRules && faillingRules.length == 0);
    const PasswordRulesIconComponent = passwordRulesPassing ? CheckCircleOutlined : CloseCircleOutlined;
    return (
      <Modal maskClosable={false} closable={true} title="Set new password:" footer={null} visible={this.state.show} 
             confirmLoading={isLoading} onCancel={this.handleHide}>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <Form key="setpass" layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Form.Item label="* New password">
            <ReactPasswordStrength enabledVisibityToogle minLength={5} minScore={2}
              scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
              changeCallback={this.handleInputPasswordChange('newPassword')}
              inputProps={{ 'data-dd-privacy': 'mask', name: 'newPassword', autoComplete: "off", className: "form-control", required: true }}
            />
            <Popover className='passwordTooltip' getPopupContainer={triggerNode => triggerNode.parentNode} visible={faillingRules && faillingRules.length > 0} content={
              <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '300px' }}>
                <Typography.Title level={4}>Password rules:</Typography.Title>
                <ul>
                  <li>Have at least one lower case character {this._renderRuleStatus(faillingRules, Globals.PASSWORD_VALIDATION_RULES.LOWER)}</li>
                  <li>Have at least one capital letter {this._renderRuleStatus(faillingRules, Globals.PASSWORD_VALIDATION_RULES.UPPER)}</li>
                  <li>Have at least one number {this._renderRuleStatus(faillingRules, Globals.PASSWORD_VALIDATION_RULES.NUMBER)}</li>
                  <li>Not be the same as the account email {this._renderRuleStatus(faillingRules, Globals.PASSWORD_VALIDATION_RULES.MIN_CHARS)}</li>
                  <li>Be at least 8 characters long {this._renderRuleStatus(faillingRules, Globals.PASSWORD_VALIDATION_RULES.PASS_MATCH)}</li>
                </ul>
              </div>
            }>
              {faillingRules && <PasswordRulesIconComponent className={'passwordTooltipIcon ' + (passwordRulesPassing ? 'rulePass' : 'ruleFail')} />}
            </Popover>
          </Form.Item>
          <Form.Item label="* New password confirmation">
            <ReactPasswordStrength enabledVisibityToogle minLength={5} minScore={2}
              scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
              changeCallback={this.handleInputPasswordChange('passwordConfirmation')}
              inputProps={{ 'data-dd-privacy': 'mask', name: 'passwordConfirmation', autoComplete: "off", className: "form-control", required: true }} />
          </Form.Item>
          <Divider orientation='left'>Advanced</Divider>
          <Form.Item label="Permanent change?" name="permanent" valuePropName="checked" initialValue={this.state.data.permanent}>
            <Checkbox id="permanent" onChange={this.handleCheckChange.bind(this, 'permanent')} />
          </Form.Item>
          <Divider></Divider>
          <Row type='flex' justify='end'>
            <Button disabled={(!this.state.data.newPassword || !this.state.data.passwordConfirmation) || (faillingRules && faillingRules.length > 0)}
              onClick={this.handleChangePassword} type='primary'> Set new password </Button>
          </Row>
        </Form>
      </Modal>
    );
  }
  /* private API */
  async _updatePassword() {
    this.startLoading();
    //
    const changeResp = await this.props.app.idm.api.password.update({ newPassword: this.state.data.newPassword, isPermanent: this.state.data.permanent }, this.state.data.userID);
    if (changeResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("","New password was set with success!");
      this.state.show = false;
      setTimeout(() => { if (this.state.closeWindowAfterChange) window.close(); }, 500);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, changeResp);
    } this.stopLoading();
  }
  /* password helpers */
  _renderRuleStatus(faillingRules, ruleType) {
    if (faillingRules && faillingRules.indexOf(ruleType) != -1) return (<CloseCircleOutlined className="ruleFail" />);
    else if (faillingRules) return (<CheckCircleOutlined className="rulePass" />);
    else return (<></>);
  }
  _calculateFaillingRules() {
    if (!(this.state.data?.newPassword?.length > 0 || this.state.data?.passwordConfirmation?.length > 0)) return null;
    const faillingRules = [];
    const pass = this.state.data.newPassword;
    const passC = this.state.data.passwordConfirmation;

    //Check lower
    if (!(/[a-z]/.test(pass))) faillingRules.push(Globals.PASSWORD_VALIDATION_RULES.LOWER);
    //Check upper
    if (!(/[A-Z]/.test(pass))) faillingRules.push(Globals.PASSWORD_VALIDATION_RULES.UPPER);
    //Check number
    if (!(/[0-9]/.test(pass))) faillingRules.push(Globals.PASSWORD_VALIDATION_RULES.NUMBER);
    //Check min chars
    if (pass && pass.length < 8) faillingRules.push(Globals.PASSWORD_VALIDATION_RULES.MIN_CHARS);
    //Check confirmation
    if ((pass && pass != passC) || !passC) faillingRules.push(Globals.PASSWORD_VALIDATION_RULES.PASS_MATCH);
    return faillingRules;
  }
}
