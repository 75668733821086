import React from "react";
import autoBind from 'react-autobind';
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
//
import { Typography, Form, Input, Row, Col, Select, DatePicker, InputNumber, Divider } from 'antd';
//
import Utils from "@/components/Utils";
import Globals from '@/config/Globals';
//props are: readOnly, app
export default class CommonDuesEntryOtherForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }
  //Form methods
  async validateFields() {
    const formData = await this.form.validateFields();
    if (formData) {
      const data = {
        ...this.state.data,
        ...formData,
        activityCode: 'O',
        transactionCode: 'O',
        statusDate: (formData.statusDate ? moment(formData.statusDate).format(Globals.DefaultAPIDateFormat) : null),
        transactionDate: (formData.transactionDate ? moment(formData.transactionDate).format(Globals.DefaultAPIDateFormat) : null),
        ...(formData.receiptType == 'O' ? { official: true, print: true } : (formData.receiptType == 'M' ? { official: false, print: true } : { official: false, print: false }))
      };
      //Remove readonly stuff
      delete data.receiptType;
      //
      return data;
    } return false;
  }
  resetFields() { return this.form.resetFields(); }
  setFieldsValue(data, memberObj) {
    const receiptType = (!data ? (((this.state.memberObj?.memberType?.trim() == 'PA' || this.state.memberObj?.memberType?.trim() == 'PB') && (memberObj?.arrearsIO > 0 || memberObj?.arrearsLU > 0))
        ? 'M' : ((this.state.memberObj?.memberType?.trim() == 'PA' || this.state.memberObj?.memberType?.trim() == 'PB') ? null : 'O')) : (data.receiptID ? (data.official ? 'O' : 'M') : null));
    const tmpData = {
      ...(data || {}),
      ...(!data ? {
        statusDate: '',
        transactionDate: (this.props?.batchObj?.postingDate && this.props?.batchObj?.postingDate.length > 0 ? moment(this.props?.batchObj?.postingDate) : ''),
        receiptType: receiptType, amount: 0, ioInitiation: 0, luInitiation: 0, luReinstatement: 0, ioReinstatement: 0,
        pensionBenefitAdmission: 0,
      } : {
          transactionDate: (data.transactionDate && data.transactionDate.length > 0 ? moment(data.transactionDate) : ''),
          statusDate: (data.statusDate && data.statusDate.length > 0 ? moment(data.statusDate) : ''),
          receiptType: receiptType
        }),
    };
    this.setState({ ...tmpData, memberObj, receiptType }, () => {
      this.form.setFieldsValue(tmpData);
    });
  }

  //Form actions
  handleInputChange(key, value) { 
    let amount = this.state.amount;
    let suspense = this.state.suspense;
    let receiptNumber = this.state.receiptNumber;
    let receiptType = this.state.receiptType;
    //Update
    if (key == 'amount') amount = value;
    else if (key == 'suspense') suspense = value;
    else if (['ioInitiation', 'luInitiation', 'ioReinstatement', 'luReinstatement', 'pensionBenefitAdmission'].indexOf(key) != -1) { //update suspense when updating one of the values on the array
      suspense = parseFloat(this.state.amount || 0) - 
                 parseFloat((key == 'ioInitiation' ? (value || 0) : (this.state.ioInitiation || 0))) - 
                 parseFloat((key == 'luInitiation' ? (value || 0) : this.state.luInitiation || 0)) - 
                 parseFloat((key == 'luReinstatement' ? (value || 0) : (this.state.luReinstatement || 0))) - 
                 parseFloat((key == 'ioReinstatement' ? (value || 0) : (this.state.ioReinstatement || 0))) - 
                 parseFloat((key == 'pensionBenefitAdmission' ? (value || 0) : (this.state.pensionBenefitAdmission || 0)));
      if (this.form) this.form.setFieldsValue({ suspense });
    } else if (key == 'receiptType' && !value) {
      receiptType = value;
      receiptNumber = '';
      if (this.form) this.form.setFieldsValue({ receiptNumber });
    } else if (key == 'memberStatus') {
      if (value == Globals.MemberStatus_Options.MEMBER_STATUS_RETURN || value == Globals.MemberStatus_Options.MEMBER_STATUS_BA_TO_A ||
          value == Globals.MemberStatus_Options.MEMBER_STATUS_NEW || value == Globals.MemberStatus_Options.MEMBER_STATUS_REINSTATEMENT) receiptType = 'O';
      else if (value == Globals.MemberStatus_Options.MEMBER_STATUS_A_TO_BA || value == Globals.MemberStatus_Options.MEMBER_STATUS_DROPPED ||
               value == Globals.MemberStatus_Options.MEMBER_STATUS_NAME_CHANGED || value == Globals.MemberStatus_Options.MEMBER_STATUS_BA_WITHDRAWAL || 
               value == Globals.MemberStatus_Options.MEMBER_STATUS_TRANSFER_IN || value == Globals.MemberStatus_Options.MEMBER_STATUS_PENSION || 
               value == Globals.MemberStatus_Options.MEMBER_STATUS_WITHDRAWAL_CARD || value == Globals.MemberStatus_Options.MEMBER_STATUS_EXPELLED || 
               value == Globals.MemberStatus_Options.MEMBER_STATUS_DECEASED) receiptType = 'M';
      else if (!value) {
        receiptType = null;
        receiptNumber = '';
      }
    }
    //set state
    this.setState({ [key]: value, amount, suspense, receiptNumber, receiptType }, () => this._calculateNormalDistributionAndValidate(key));
  }
  handleReceiptNumberChange(event) { this.setState({ receiptNumber: (event && event.target ? event.target.value : '') }); }
  //UI
  render() {
    const config = this.props.app.sharedCache().getConfig().settings;
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Divider orientation='left' style={{ margin: '4px' }}> Other Entry </Divider>
        {this.props.extra || <></>}
        <Row type='flex'>
          <Col>
            <Form.Item label="Activity Code" name="memberStatus" style={{width: '200px'}} rules={[{ required: true, message: 'This field is required!' }]} >
              <Select placeholder="Select..." onChange={this.handleInputChange.bind(this, 'memberStatus')}>
                {this.props.app.sharedCache().getConfig().memberStatuses.map((code) => (
                  <Select.Option key={code.id} value={code.id}>{code.statusCode}: {code.description}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item label="Code Change Date" name="statusDate">
              <DatePicker format={Globals.DefaultUIBirthDateFormat} style={{ width: 180, marginRight: '10px' }}
                          disabled={!this.state.receiptType}/>
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item label="Transaction Date" name="transactionDate">
              <DatePicker format={Globals.DefaultUIBirthDateFormat} style={{ width: 180, marginRight: '10px' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col>
            <Form.Item label="Amount" name="amount">
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} style={{ minWidth: 150 }} disabled={this.props.readOnly}
                onChange={this.handleInputChange.bind(this, 'amount')}/>
            </Form.Item>
          </Col>
          <Col offset={1} span={10}>
            <Form.Item label="Description" name="description">
              <Input.TextArea autoSize={{ minRows: 1, maxRows: 10 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col>
            <Form.Item label="Receipt Type" name='receiptType'>
              <Select style={{ minWidth: 50 }} onChange={this.handleInputChange.bind(this, 'receiptType')}>
                <Select.Option value={'O'}>O</Select.Option>
                <Select.Option value={'M'}>M</Select.Option>
                <Select.Option value={null}>N/A</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item name="receiptNumber" label="Receipt Number">
              <InputNumber precision={0} placeholder={!this.state.receiptType ? 'Unavailable' : 'Auto Generated'} style={{ minWidth: 150 }}
                onChange={this.handleReceiptNumberChange} disabled={!this.state.receiptType}
                max={2147483647} min={-2147483647} />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col>
            <Form.Item name="ioInitiation" label="Initiation IO">
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} style={{ minWidth: 170 }}
                onChange={this.handleInputChange.bind(this, 'ioInitiation')} />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item name="luInitiation" label="Initiation LU">
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} style={{ minWidth: 170 }}
                onChange={this.handleInputChange.bind(this, 'luInitiation')} />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item name="pensionBenefitAdmission" label="Benefits">
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} style={{ minWidth: 170 }}
                onChange={this.handleInputChange.bind(this, 'pensionBenefitAdmission')} />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col>
            <Form.Item name="ioReinstatement" label="Reinstatement IO">
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} style={{ minWidth: 170 }}
                onChange={this.handleInputChange.bind(this, 'ioReinstatement')} />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item name="luReinstatement" label="Reinstatement LU">
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} style={{ minWidth: 170 }}
                onChange={this.handleInputChange.bind(this, 'luReinstatement')} />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item name="suspense" label="Suspense">
              <InputNumber precision={2} formatter={Globals.CurrencyInput_Validator} parser={Globals.CurrencyInput_Parser} style={{ minWidth: 170 }}
                onChange={this.handleInputChange.bind(this, 'suspense')} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  /* private UI functions */
  _calculateNormalDistributionAndValidate(key) {
    const config = this.props.app.sharedCache().getConfig().settings;
    //Distribution
    if (key == 'amount' || key == 'ioInitiation' || key == 'luInitiation' || key == 'ioReinstatement' || key == 'luReinstatement' || key == 'pensionBenefitAdmission') {
      //Status
      let { ioInitiation, luInitiation, pensionBenefitAdmission, ioReinstatement, luReinstatement } = this.state;
      if ((this.state.memberStatus == Globals.MemberStatus_Options.MEMBER_STATUS_RETURN && this.state.memberObj.memberType?.trim() == 'A') ||
          (this.state.memberStatus == Globals.MemberStatus_Options.MEMBER_STATUS_BA_TO_A && this.state.memberObj.memberType == 'BA')) {
        pensionBenefitAdmission = config.pensionBenefitAdmission;
      } else if (this.state.memberStatus == Globals.MemberStatus_Options.MEMBER_STATUS_NEW) {
        if (this.state.memberObj.memberType?.trim() == 'A') {
          pensionBenefitAdmission = config.pensionBenefitAdmission;
          ioInitiation = config.aMemberInitiation * (config.ioInitiation / (config.ioInitiationPercent ? 100.0 : 1.0));
          luInitiation = config.aMemberInitiation - ioInitiation;
        } else {
          ioInitiation = config.baMemberInitiation * (config.ioInitiation / (config.ioInitiationPercent ? 100.0 : 1.0));
          luInitiation = config.baMemberInitiation - ioInitiation;
        }
      } else if (this.state.memberStatus == Globals.MemberStatus_Options.MEMBER_STATUS_REINSTATEMENT) {
        if (this.state.memberObj.memberType?.trim() == 'A') {
          ioReinstatement = config.aMemberIoReinstatement;
          luReinstatement = config.aMemberLuReinstatement;
        } else {
          ioReinstatement = config.baMemberIoReinstatement;
          luReinstatement = config.baMemberLuReinstatement;
        }
      }
      //Set stats and fields
      let obj = { ioInitiation, luInitiation, pensionBenefitAdmission, ioReinstatement, luReinstatement };
      delete obj[key];
      if (key == 'ioInitiation') delete obj.luInitiation;
      if (key == 'luInitiation') delete obj.ioInitiation;
      this.setState(obj, () => {
        this.form.setFieldsValue(obj);
      });
    } else if (this.form) this.form.validateFields();
  } 
}
