import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Button, message, Popover, Tag, Tooltip, Form, Select, Row, Col } from 'antd';
import {
  IssuesCloseOutlined, CheckCircleOutlined, CloseCircleOutlined, EditOutlined,
  ToolOutlined, PoweroffOutlined, FileTextOutlined, DollarOutlined, ContactsOutlined,
} from '@ant-design/icons';
import { FaGraduationCap } from 'react-icons/fa';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import TableActions from '@/views/commonComponents/TableActions';
import Utils from "@/components/Utils";
import config from '@/config/config';
import Globals from "@/config/Globals";
//
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import CommonSetPasswordModal from '@/views/commonComponents/CommonSetPasswordModal';
import CommonSearchBar from '@/views/commonComponents/CommonSearchBar';
import CommonEmployerSelectionModal from "@/views/commonComponents/CommonEmployerSelectionModal";
//
export default class AdminMembersView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.employerName = this.props.app.urlManager.getQueryParam('n') || null;
    this.fromEmployers = !!this.employerName;

    this.state = {
      isLoading: false, data: [], units: [], dispatchLists: [],
      sortedInfo: null, ...this._getInitialState(),
    };
  }
  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Members`;
    const { unitsNumbers, dispatchLists } = this.props.app.sharedCache().getConfig();
    this.setState({ units: unitsNumbers, dispatchLists });
    this._fetch(true);
    
    // Populate employer search modal component
    const employerID = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Employer) || null;
    if (!this.fromEmployers && employerID) {
      this.employerSelectionModal.setEmployer(employerID, { preventFetch: true });
    }
  }
  //Actions
    //Search actions
  handleSearch(term) {
    this.setState(prevState => ({
      ...prevState,
      searchObj: { ...prevState.searchObj, term },
      sortedInfo: null,
      currentPage: 1
    }), this._fetch);
  }
    //Table + add button actions
  handleCreateMember() { 
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.memberCreate);
  }
  handleFilterChange(pagination, filters, sortedInfo) { this.setState({ sortedInfo }, this._fetch); }
  handlePagination(currentPage) { this.setState({ currentPage }, this._fetch); }
    //Rows actions
  handleActivateUser(userObj) { 
    this._activateUser(userObj); 
  }
  handleToggleEnable(userObj) {
    if (userObj.idmDisabled) this._enableUser(userObj);
    else this._disableUser(userObj);
  }
  handleInvalidatePassword(userObj) { 
    // this._resetUsersPassword(userObj); 
    message.error('Not implemented!');
  }
  handleSetUserPassword(userObj) { 
    // this.changePassModal.handleShow(userObj.idmID); 
    message.error('Not implemented!');
  }
  handleUserEdit(userObj) { 
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.memberUpdate, null, userObj.id); 
  }
  handleWorkHistory(userObj) { 
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.memberWorkHistory, { n: encodeURIComponent(userObj.fullname) }, userObj.id); 
  }
  handleNotes(userObj) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.notes, { n: encodeURIComponent(userObj.fullname) }, Globals.Notes_relTypes.member.routeAlias, userObj.id);
  }
  handleDispatchNotes(userObj) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.memberDispatchNotes, { n: encodeURIComponent(userObj.fullname) }, userObj.id);
  }
  handleTrainingHistory(userObj) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.trainingHistory, { n: encodeURIComponent(userObj.fullname) }, userObj.id);
  }
  handleDues(userObj) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.memberDuesHistory, { n: encodeURIComponent(userObj.fullname) }, userObj.id);
  }
  // filters
  handleSearchEmployer() {
    this.employerSelectionModal.show();
  }

  handleSelectEmployer(employer, args) {
    if (args?.preventFetch) {
      this.setState(prevState => ({
        ...prevState,
        searchObj: { ...prevState.searchObj, employerID: employer.id, },
        selectedEmployer: employer,
        [Globals.URLQueryParam_EmploymentStatus]: Globals.MemberSearch_EmploymentStatus.ANY.key,
        [Globals.URLQueryParam_MemberStatus]: Globals.MemberSearch_EmploymentStatus.ANY.key,
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        searchObj: {
          ...prevState.searchObj,
          employerID: employer.id,
          [Globals.URLQueryParam_EmploymentStatus]: Globals.MemberSearch_EmploymentStatus.ANY.key,
          [Globals.URLQueryParam_MemberStatus]: Globals.MemberSearch_Status.ANY.key,
        },
        sortedInfo: null,
        currentPage: 1,
        selectedEmployer: employer,
      }), this._fetch);
    }
  }

  handleDeselectEmployer() {
    this.setState(prevState => ({
      ...prevState,
      searchObj: { ...prevState.searchObj, employerID: null, },
      sortedInfo: null,
      currentPage: 1,
      selectedEmployer: null,
    }), this._fetch);
  }

  handleChangeFilter(key) {
    return (value) => {
      this.setState(prevState => ({
        ...prevState,
        searchObj: {
          ...prevState.searchObj,
          [key]: value == 'none' ? null : value,
        },
        sortedInfo: null,
        currentPage: 1,
      }), this._fetch);
    };
  }

  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const props = {
      onChange: this.handleFilterChange, rowKey: 'id', loading: this.props.isLoading,
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage, showSizeChanger: false, hideOnSinglePage: true, position: 'bottom',
        total: this.state.total, onChange: this.handlePagination, current: this.state.currentPage,
      }
    };

    const columns = [
      { title: 'Name', key: 'name',
        render: record => <Tooltip title={record.fullname}>{record.firstName} {record.lastName}</Tooltip>,
        sorter: (a, b) => ((`${a.firstName || ''} ${a.lastName || ''}`).toLowerCase().trim()).localeCompare(
          (`${b.firstName || ''} ${b.lastName || ''}`).toLowerCase().trim()
        ),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order
      },
      {
        title: 'SIN', dataIndex: 'sin', key: 'sin',
        sorter: (a, b) => (a.sin || '').localeCompare((b.sin || '')),
        sortOrder: sortedInfo.columnKey === 'sin' && sortedInfo.order
      },
      {
        title: 'Status', key: 'status', 
        sorter: (a, b) => (a.currentStatus || a.priorStatusCode || '').localeCompare((b.currentStatus || b.priorStatus || '')),
        sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
        render: (props) => {
          return (
            <Popover title="Member Status" content={
              <ul style={{padding: '0 0 0 10px'}}>
                {props.currentStatus && 
                  <li><b>Current status</b>: {props.currentStatus} - {props.currentStatusCodeDescription}</li>}
                {props.priorStatus &&
                  <li><b>Prior status</b>: {props.priorStatus} - {props.priorStatusCodeDescription}</li>}
              </ul>
            }>
              <Tag color="geekblue">{(props.currentStatus || props.priorStatus || '')}</Tag>
            </Popover>
          )
        },
      },
      {
        title: 'Type', key: 'type',
        sorter: (a, b) => (a.memberType || '').localeCompare((b.memberType || '')),
        sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order,
        render: (props) => {
          return (
            <Popover title="Member Type" content={
              <ul style={{ padding: '0 0 0 10px' }}>
                <li><b>Current Type</b>: {props.memberType} - {this.props.app.sharedCache().getMemberStatusByTypeCode(props.memberType)?.description || 'N/A'}</li>
              </ul>
            }>
              <Tag color="purple">{(props.memberType || '')}</Tag>
            </Popover>
          )
        },
      },
      { title: 'Phone', key: 'phone',
        render: (props) => Utils.formatPhone(props.phone || props.cell || ''),
        sorter: (a, b) => Utils.formatPhone(a.phone || a.cell || '').localeCompare(Utils.formatPhone(b.phone || b.cell || '')),
        sortOrder: sortedInfo.columnKey === 'phone' && sortedInfo.order
      },
      {
        title: 'Paid Thru', key: 'duesPaidThru', dataIndex: 'duesPaidThru',
        sorter: (a, b) => (a.duesPaidThru ? new Date(a.duesPaidThru) : new Date(-1)).getTime() - ((b.duesPaidThru ? new Date(b.duesPaidThru) : new Date(-1)).getTime()),
        sortOrder: sortedInfo.columnKey === 'duesPaidThru' && sortedInfo.order,
        render: (props) => (props ? Utils.getDateOnUIFormat(new Date(props)) : ''),
      },
      {
        title: 'Employer', key: 'currentEmployerName', dataIndex: 'currentEmployerName',
        sorter: (a, b) => (a.currentEmployerName || '').localeCompare((b.currentEmployerName || '')),
        sortOrder: sortedInfo.columnKey === 'currentEmployerName' && sortedInfo.order
      },
      { title: 'Actions', width: 150, key: 'Actions', align: 'right',
        render: props => {
          return (<span className='tableButtonContainer'>
                    <TableActions
                      options={[
                        { label: 'Dues History', icon: DollarOutlined, onClick: this.handleDues.bind(this, props) },
                        { label: 'Work History', icon: ContactsOutlined, onClick: this.handleWorkHistory.bind(this, props) },
                        { label: 'Notes', icon: FileTextOutlined, onClick: this.handleNotes.bind(this, props) },
                        { label: 'Dispatch Notes', icon: FileTextOutlined, onClick: this.handleDispatchNotes.bind(this, props) },
                        { label: 'Training History', icon: FaGraduationCap, onClick: this.handleTrainingHistory.bind(this, props), iconProps: { style: { marginRight: 6 } } },
                        {
                          alwaysVisible: true, label: 'Activate Online ID', icon: PoweroffOutlined, hide: props.idmID,
                          confirm: {
                            message: `Do you really want to activate member '${props.fullname}'?`,
                            onConfirm: this.handleActivateUser.bind(this, props),
                          },
                        },
                        {
                          label: (props.idmDisabled ? 'Enable Online ID': 'Disable Online ID'),
                          icon: props.idmDisabled ? CheckCircleOutlined : CloseCircleOutlined, hide: !props.idmID,
                          confirm: {
                            message: `Do you really want to ${props.idmDisabled ? "enable" : "disable"} member '${props.fullname}'?`,
                            onConfirm: this.handleToggleEnable.bind(this, props),
                          },
                        },
                        {
                          label: 'Invalidate password', icon: IssuesCloseOutlined, hide: !props.idmID,
                          confirm: {
                            message: `Do you really want to invalidate member '${props.fullname}' password?`,
                            onConfirm: this.handleInvalidatePassword.bind(this, props),
                          },
                        },
                        {
                          label: 'Set password', icon: ToolOutlined, hide: !props.idmID,
                          confirm: {
                            message: `Do you really want to set member '${props.fullname}' a new password?`,
                            onConfirm: this.handleSetUserPassword.bind(this, props),
                          },
                        },
                        { alwaysVisible: true, label: 'Edit member', icon: EditOutlined, onClick: this.handleUserEdit.bind(this, props) },
                      ]}
                    />
                  </span>);
        }
      },
    ];

    const pageHeaderProps = {
      ...(this.fromEmployers ? { onBack: () => window.history.back() } : {}),
    };

    return (
      <Layout.Content className="pageContent">
        <CommonEmployerSelectionModal
          app={this.props.app}
          onSelect={this.handleSelectEmployer}
          {...Utils.propagateRef(this, 'employerSelectionModal')}
        />

        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <CommonSetPasswordModal {...Utils.propagateRef(this, 'changePassModal')} app={this.props.app} alertController={this.props.app.alertController}/>
        <PageHeader
          title={this.employerName ? `${this.employerName}'s Members` : 'Members'}
          extra={[<Button key="1" onClick={this.handleCreateMember} type='primary'>Create Member</Button>]}
          {...pageHeaderProps}
        />
        <Layout.Content>
          <CommonSearchBar alwaysEnabled defaultValue={this.state.searchObj.term} handleSearch={this.handleSearch}/>
          <WhiteBox>
            <Form layout="vertical">
              {!this.fromEmployers && (
                <Row>
                  <Col>
                    <Form.Item label="Employer">
                      {!this.state.selectedEmployer && <Button type="ghost" onClick={this.handleSearchEmployer}>Click to find an employer</Button>}
                      {this.state.selectedEmployer && (
                        <span>
                          {this.state.selectedEmployer.name}
                          <Tooltip title="Remove Filter">
                            <Button icon={<CloseCircleOutlined />} type="link" onClick={this.handleDeselectEmployer} />
                          </Tooltip>
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={4}>
                  <Form.Item label="Employment Status">
                    <Select disabled={!!this.state.selectedEmployer} value={this.state.searchObj[Globals.URLQueryParam_EmploymentStatus] || Globals.MemberSearch_EmploymentStatus.ANY.key} onChange={this.handleChangeFilter(Globals.URLQueryParam_EmploymentStatus)}>
                      {Object.keys(Globals.MemberSearch_EmploymentStatus).map((item) => (
                        <Select.Option
                          key={Globals.MemberSearch_EmploymentStatus[item].key}
                          value={Globals.MemberSearch_EmploymentStatus[item].key}
                        >
                          {Globals.MemberSearch_EmploymentStatus[item].name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={4} offset={1}>
                  <Form.Item label="Member Status">
                    <Select disabled={!!this.state.selectedEmployer} value={this.state.searchObj[Globals.URLQueryParam_MemberStatus] || Globals.MemberSearch_Status.ANY.key} onChange={this.handleChangeFilter(Globals.URLQueryParam_MemberStatus)}>
                      {Object.keys(Globals.MemberSearch_Status).map((item) => (
                        <Select.Option
                          key={Globals.MemberSearch_Status[item].key}
                          value={Globals.MemberSearch_Status[item].key}
                        >
                          {Globals.MemberSearch_Status[item].name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={4} offset={1}>
                  <Form.Item label="Unit">
                    <Select defaultValue={Number(this.state.searchObj[Globals.URLQueryParam_Unit]) || 'none'} onChange={this.handleChangeFilter(Globals.URLQueryParam_Unit)}>
                      <Select.Option value="none">None</Select.Option>
                      {this.state.units.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.description}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={6} offset={1}>
                  <Form.Item label="Dispatch List">
                    <Select defaultValue={Number(this.state.searchObj[Globals.URLQueryParam_DispatchList]) || 'none'} onChange={this.handleChangeFilter(Globals.URLQueryParam_DispatchList)}>
                      <Select.Option value="none">None</Select.Option>
                      {this.state.dispatchLists.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </WhiteBox>
          <WhiteBox>
            <Table dataSource={this.state.data} columns={columns} {...props}/>
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // Utils
  _getInitialState() {
    const search = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SearchTerm) || null;
    const employerID = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Employer) || null;
    const currentPage = parseInt(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Page) || 1);
    const columnKey = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SortField) || '';
    const order = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SortOrder) || '';
    const employmentStatus = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_EmploymentStatus) || null;
    const status = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_MemberStatus) || null;
    const unitID = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Unit) || null;
    const dispatchListID = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_DispatchList) || null;

    return {
      searchObj: {
        term: search, employerID,
        employmentStatus: employerID ? Globals.MemberSearch_EmploymentStatus.ANY.key : employmentStatus,
        status: employerID ? Globals.MemberSearch_Status.ANY.key : status,
        unitID, dispatchListID,
      },
      currentPage, sortedInfo: { columnKey, order },
      total: 0,
      selectedEmployer: null,
    };
  }
  _setSearchQueryParams() {
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_Page, this.state.currentPage);
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SearchTerm, (this.state.searchObj.term || null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_Employer, (this.state.searchObj.employerID || null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_EmploymentStatus, (this.state.searchObj.employmentStatus || null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_MemberStatus, (this.state.searchObj.status || null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_Unit, (this.state.searchObj.unitID || null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_DispatchList, (this.state.searchObj.dispatchListID || null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SortField, (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.columnKey : ''));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SortOrder, (this.state.sortedInfo ? this.state.sortedInfo.order : null));
  }
  _getSearchFilterObject() {
    //page
    const from = (Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1));
    //sort
    const sortField = (this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey ? this.state.sortedInfo.columnKey : '');
    const sortOrder = (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : '');
    return {
      term: this.state.searchObj.term || '',
      filter: {
        from, sortField,
        sortOrder: (sortOrder == 'descend' ? 'desc' : 'asc'),
        ...(this.state.searchObj.employerID ? { employerID: this.state.searchObj.employerID } : {}),
        ...(this.state.searchObj.employmentStatus ? { employmentStatus: this.state.searchObj.employmentStatus } : {}),
        ...(this.state.searchObj.status ? { status: this.state.searchObj.status } : {}),
        ...(this.state.searchObj.unitID ? { unitID: this.state.searchObj.unitID } : {}),
        ...(this.state.searchObj.dispatchListID ? { dispatchListID: this.state.searchObj.dispatchListID } : {}),
      },
    };
  }

  _shouldFetchAutomatically() {
    return !!(
      this.state.searchObj.employerID
      || this.state.searchObj.employmentStatus
      || this.state.searchObj.status
      || this.state.searchObj.unitID
      || this.state.searchObj.dispatchListID
    );
  }

  // API calls
  async _fetch(firstLoad) {
    if (!this._isMounted) return;
    if (firstLoad && !this._shouldFetchAutomatically()) return;
    //
    this.setState({ data: [], total: 0, isLoading: true });
    this._setSearchQueryParams();
    //request
    const resp = await this.props.app.api.members.search(this._getSearchFilterObject());
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.members) {
      this.setState({ data: resp.body.members, total: resp.body.total, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  async _activateUser(userObj) {
    this.startLoading();
    const resp = await this.props.app.api.members.activate(userObj.id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', `Member '${userObj.fullname}' activated with success! We've emailed the user a temporary password.`);
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  async _enableUser(userObj) {
    this.startLoading();
    const resp = await this.props.app.idm.api.user.enable(userObj.idmID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', `Member '${userObj.fullname}' enabled with success! This process can take up to a minute and change may not be immediately reflected on the table below.`);
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  async _disableUser(userObj) {
    this.startLoading();
    const resp = await this.props.app.idm.api.user.disable(userObj.idmID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', `Member '${userObj.fullname}' disabled with success! This process can take up to a minute and change may not be immediately reflected on the table below.`);
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  async _resetUsersPassword(userObj) {
    this.startLoading();
    const resetResp = await this.props.app.idm.api.password.reset(userObj.email);
    if (!this._isMounted) return;
    if (resetResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', `Member '${userObj.fullname}' password invalidated with success!`);
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resetResp);
      this.stopLoading(true);
    }
  }
  async _activateUser(userObj) {
    this.startLoading();
    const resp = await this.props.app.api.members.activate(userObj.id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', `Member '${userObj.fullname}' activated with success! We've emailed the user a temporary password.`);
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
