import React from "react";
import autoBind from 'react-autobind';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import Utils from '@/components/Utils';
import config from '@/config/config';
//
import { Layout, PageHeader, Form, Input, Button, Row, Col, Divider, Table, Tooltip, Popconfirm, message } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
export default class AdminDepartmentsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      data: [],
      isEditing: false,
      editingObject: {},
      sortedInfo: null,
    };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Departments`;
    this._fetch();
  }

  handleManageClick(option) {
    this.props.app.urlManager.pushPage(option.route);
  }

  handleDelete(item) {
    this._delete(item.id);
  }

  handleEdit(item) {
    this.form.setFieldsValue({ name: item.name });
    this.setState({ isEditing: true, editingObject: item });
  }

  handleCancelEdit() {
    this.form.resetFields();
    this.setState({ isEditing: false, editingObject: null });
  }

  async handleAddOrSave() {
    const formData = await this.form.validateFields();
    if (this.state.isEditing) this._edit(formData);
    else this._add(formData);
  }

  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const props = { onChange: this.handleFilterChange, rowKey: 'id', loading: this.props.isLoading };

    const columns = [
      { title: 'Name', dataIndex: 'name', key: 'name', width: 150,
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order
      },
      { title: 'Actions', width: 80, key: 'Actions',
        render: props => {
          return (<span className='tableButtonContainer'>
                    <Tooltip placement="bottomLeft" title='Delete Department'>
                      <Popconfirm title={`Do you really want to delete '${props.name}'?`} placement="bottomRight" onConfirm={this.handleDelete.bind(this, props)} okText="Yes" cancelText="No">
                        <Button variant="none" icon={<DeleteOutlined />} shape="circle"/>
                      </Popconfirm>
                    </Tooltip>{' '}
                    <Tooltip placement="bottomLeft" title='Edit Department'>
                      <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleEdit.bind(this, props)}/>
                    </Tooltip>
                  </span>);
        }
      }
    ];

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Departments" onBack={() => window.history.back()} />
        <Layout.Content>
          <WhiteBox>
            <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
              <Row>
                <Col span={8}>
                  <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please, type the department name!' }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                  <Button type="primary" style={{ marginTop: 30 }} onClick={this.handleAddOrSave}>
                    {this.state.isEditing ? 'Save' : 'Add'}
                  </Button>
                  {this.state.isEditing && (
                    <Button type="link" style={{ marginTop: 30 }} onClick={this.handleCancelEdit}>Cancel</Button>
                  )}
                </Col>
              </Row>
            </Form>

            <Divider />

            <Table style={{ opacity: this.state.isEditing ? 0.3 : 1 }} dataSource={this.state.data} columns={columns} {...props} />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.departments.getAll();
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.departments) {
      this.setState({ data: resp.body.departments, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _add(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.departments.create(data);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Department successfully created!');
      this.props.app.refreshConfig();
      this.form.resetFields();
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _edit(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.departments.update({
      id: this.state.editingObject.id,
      name: data.name,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Department successfully updated!');
      this.props.app.refreshConfig();
      this.form.resetFields();
      this.handleCancelEdit();
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _delete(id) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.departments.remove(id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Department successfully removed!');
      this.props.app.refreshConfig();
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
