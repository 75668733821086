import Globals from '@/config/Globals';
//
export default class APIConfig {
  constructor(API) {
    this.api = API;
  }

  async getConfig() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Config;
    return await req.exec();
  }
}
