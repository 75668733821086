import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Tooltip, Popconfirm, Button } from 'antd';
import { IssuesCloseOutlined, CheckCircleOutlined, CloseCircleOutlined, EditOutlined, ToolOutlined, PoweroffOutlined, LockOutlined } from '@ant-design/icons';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import Utils from "@/components/Utils";
import config from '@/config/config';
import Globals from "@/config/Globals";
//
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import CommonSetPasswordModal from '@/views/commonComponents/CommonSetPasswordModal';
import CommonSearchBar from '@/views/commonComponents/CommonSearchBar';
//
const { Content } = Layout;
export default class AdminAdminsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      data: [],
      sortedInfo: null,
      ...this._getInitialState(),
    };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Admins`;
    this._fetch();
  }

  handleSearch(term) {
    this.setState({
      searchObj: { term },
      sortedInfo: null,
      currentPage: 1
    }, this._fetch);
  }

  handleCreateAdmin() {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.adminsCreate);
  }

  handleActivateUser(userObj) {
    this._activateUser(userObj);
  }

  handleToggleEnable(userObj) {
    if (userObj.idmDisabled) this._enableUser(userObj);
    else this._disableUser(userObj);
  }

  handleInvalidatePassword(userObj) {
    this._resetUsersPassword(userObj);
  }

  handleSetUserPassword(userObj) {
    this.changePassModal.handleShow(userObj.idmID);
  }

  handleUserEdit(userObj) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.adminsUpdate, null, userObj.id);
  }

  handleManageAcls(userObj) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.adminsManageAcls, { n: `${userObj.firstName} ${userObj.lastName}` }, userObj.id);
  }

  handleFilterChange(pagination, filters, sortedInfo) {
    this.setState({ sortedInfo }, this._fetch);
  }

  handlePagination(currentPage) {
    this.setState({ currentPage }, this._fetch);
  }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const props = {
      onChange: this.handleFilterChange, rowKey: 'id', loading: this.props.isLoading,
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage, showSizeChanger: false, hideOnSinglePage: true, position: 'bottom',
        total: this.state.total, onChange: this.handlePagination, current: this.state.currentPage,
      }
    };

    const columns = [
      {
        title: 'First name', dataIndex: 'firstName', key: 'firstName', width: 150,
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        sortOrder: sortedInfo.columnKey === 'firstName' && sortedInfo.order
      },
      {
        title: 'Last name', dataIndex: 'lastName', key: 'lastName', width: 150,
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        sortOrder: sortedInfo.columnKey === 'lastName' && sortedInfo.order
      },
      {
        title: 'Email', dataIndex: 'email', key: 'email', width: 150,
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order
      },
      {
        title: 'Actions', width: 200, key: 'Actions',
        render: props => {
          return (<span className='tableButtonContainer'>
            {!props.idmID && (
              <>
                <Tooltip placement="bottomLeft" title="Activate User">
                  <Popconfirm title={`Do you really want to activate user '${props.firstName + ' ' + props.lastName}'?`} placement="bottomRight" onConfirm={this.handleActivateUser.bind(this, props)} okText="Yes" cancelText="No">
                    <Button variant="none" icon={<PoweroffOutlined />} shape="circle" />
                  </Popconfirm>
                </Tooltip>{' '}
              </>
            )}
            {props.idmID && (
              <>
                <Tooltip placement="bottomLeft" title={(props.idmDisabled ? 'Enable user' : 'Disable user')}>
                  <Popconfirm title={`Do you really want to ${props.idmDisabled ? "enable" : "disable"} user '${props.firstName + ' ' + props.lastName}'?`} placement="bottomRight" onConfirm={this.handleToggleEnable.bind(this, props)} okText="Yes" cancelText="No">
                    <Button variant="none" icon={props.idmDisabled ? <CheckCircleOutlined /> : <CloseCircleOutlined />} shape="circle" />
                  </Popconfirm>
                </Tooltip>{' '}
                <Tooltip placement="bottomLeft" title='Invalidate password'>
                  <Popconfirm title={`Do you really want to invalidate user '${props.firstName + ' ' + props.lastName}' password?`} placement="bottomRight" onConfirm={this.handleInvalidatePassword.bind(this, props)} okText="Yes" cancelText="No">
                    <Button variant="none" icon={<IssuesCloseOutlined />} shape="circle" />
                  </Popconfirm>
                </Tooltip>{' '}
                <Tooltip placement="bottomLeft" title='Set password'>
                  <Popconfirm title={`Do you really want to set user '${props.firstName + ' ' + props.lastName}' a new password?`} placement="bottomRight" onConfirm={this.handleSetUserPassword.bind(this, props)} okText="Yes" cancelText="No">
                    <Button variant="none" icon={<ToolOutlined />} shape="circle" />
                  </Popconfirm>
                </Tooltip>{' '}
              </>
            )}
            <Tooltip placement="bottomLeft" title='Edit user'>
              <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleUserEdit.bind(this, props)} />
            </Tooltip>{' '}
            <Tooltip placement="bottomLeft" title='Manage Access'>
              <Button variant="none" icon={<LockOutlined />} shape="circle" onClick={this.handleManageAcls.bind(this, props)} />
            </Tooltip>
          </span>);
        }
      },
    ];

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <CommonSetPasswordModal {...Utils.propagateRef(this, 'changePassModal')} app={this.props.app} alertController={this.props.app.alertController} />
        <PageHeader title="Admins" extra={[<Button key="1" onClick={this.handleCreateAdmin} type='primary'>Create Admin</Button>]} />
        <Layout.Content>
          <CommonSearchBar alwaysEnabled defaultValue={this.state.searchObj.term} handleSearch={this.handleSearch} />

          <WhiteBox>
            <Table
              dataSource={this.state.data}
              columns={columns}
              {...props}
            />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // Utils
  _getInitialState() {
    const search = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SearchTerm) || null;
    const currentPage = parseInt(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Page) || 1);
    const columnKey = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SortField) || 'firstName';
    const order = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SortOrder) || 'ascend';
    return {
      searchObj: { term: search || null },
      currentPage, sortedInfo: { columnKey, order },
      total: 0
    };
  }

  _setSearchQueryParams() {
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_Page, this.state.currentPage);
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SearchTerm, (this.state.searchObj.term || null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SortField, (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.columnKey : ''));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SortOrder, (this.state.sortedInfo ? this.state.sortedInfo.order : null));
  }

  _getSearchFilterObject() {
    //page
    const from = (Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1));
    //sort
    const sortField = (this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey ? this.state.sortedInfo.columnKey : '');
    const sortOrder = (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : '');
    return {
      term: this.state.searchObj.term || '',
      filter: {
        from,
        sortField,
        sortOrder: (sortOrder == 'descend' ? 'desc' : 'asc'),
      },
    };
  }

  // API calls
  async _fetch() {
    if (!this._isMounted) return;
    //
    this.setState({ data: [], total: 0, isLoading: true });
    this._setSearchQueryParams();
    //
    const resp = await this.props.app.api.admins.search(this._getSearchFilterObject());
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.admins) {
      this.setState({ data: resp.body.admins, total: resp.body.total, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _activateUser(userObj) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.admins.activate(userObj.id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', `User '${userObj.firstName + ' ' + userObj.lastName}' activated with success! We've emailed the user a temporary password.`);
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _enableUser(userObj) {
    this.startLoading();
    //request
    const resp = await this.props.app.idm.api.user.enable(userObj.idmID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', `User '${userObj.firstName + ' ' + userObj.lastName}' enabled with success!`);
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _disableUser(userObj) {
    this.startLoading();
    //request
    const resp = await this.props.app.idm.api.user.disable(userObj.idmID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', `User '${userObj.firstName + ' ' + userObj.lastName}' disabled with success! This process can take up to a minute and change may not be immediately reflected on the table below.`);
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _resetUsersPassword(userObj) {
    this.startLoading();
    //request
    const resetResp = await this.props.app.idm.api.password.reset(userObj.email);
    if (!this._isMounted) return;
    if (resetResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', `User '${userObj.firstName + ' ' + userObj.lastName}' password invalidated with success! This process can take up to a minute and change may not be immediately reflected on the table below`);
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resetResp);
      this.stopLoading(true);
    }
  }
}
