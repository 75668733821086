import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Tooltip, Popconfirm, Button, message } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import config from '@/config/config';
//
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import Globals from "@/config/Globals";
import Utils from "@/components/Utils";
//
import AdminCreateContactsDrawer from './AdminCreateContactsDrawer';
import AdminEditContactsDrawer from './AdminEditContactsDrawer';
import AdminEmployerOptions, { AdminEmployerOptionTypes } from '@/views/commonComponents/AdminEmployerOptions';
//
export default class AdminContactsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.employerID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    this.employerName = this.props.app.urlManager.getQueryParam('n');
    this.state = { isLoading: false, data: [] };
    this.viewOptions = new AdminEmployerOptions(AdminEmployerOptionTypes.CONTACTS, this.employerID, this.props.app, this.employerName);
  }
  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Contacts`;
    this._fetch();

    const operation = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Operation);
    if (operation === 'new') {
      this.createModal.show(this.employerID);
    }
  }
  //Actions
  handleCreate() { this.createModal.show(this.employerID); }
  handleEdit(contactObj) { this.updateModal.show(this.employerID, contactObj.id); }
  handleDelete(contactObj) {
    this._delete(this.employerID, contactObj.id);
  }
  handleFilterChange(pagination, filters, sortedInfo) { this.setState({ sortedInfo }); }
  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const props = {
      onChange: this.handleFilterChange, rowKey: 'id', loading: this.props.isLoading,
    };

    const columns = [
      { title: 'First name', dataIndex: 'firstName', key: 'firstName', width: 150,
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        sortOrder: sortedInfo.columnKey === 'firstName' && sortedInfo.order
      },
      { title: 'Last name', dataIndex: 'lastName', key: 'lastName', width: 150,
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        sortOrder: sortedInfo.columnKey === 'lastName' && sortedInfo.order
      },
      { title: 'Email', dataIndex: 'email', key: 'email', width: 150,
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order
      },
      { title: 'Actions', width: 200, key: 'Actions',
        render: props => {
          return (<span className='tableButtonContainer'>
                    <Tooltip placement="bottomLeft" title='Edit Contact'>
                      <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleEdit.bind(this, props)}/>
                    </Tooltip>{' '}
                    <Tooltip placement="bottomLeft" title='Delete Contact'>
                      <Popconfirm title={`Do you really want delete this contact?`} placement="bottomRight" onConfirm={this.handleDelete.bind(this, props)} okText="Yes" cancelText="No">
                        <Button variant="none" icon={<DeleteOutlined />} shape="circle"/>
                      </Popconfirm>
                    </Tooltip>
                  </span>);
        }
      },
    ];

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <AdminCreateContactsDrawer app={this.props.app} onCreate={this._fetch} {...Utils.propagateRef(this, 'createModal')} />
        <AdminEditContactsDrawer app={this.props.app} onUpdate={this._fetch} {...Utils.propagateRef(this, 'updateModal')} />
        <PageHeader
          title={`${this.employerName || ''} Contacts`}
          onBack={() => window.history.back()}
          extra={this.viewOptions.getOptions().concat([<Button key="btn" onClick={this.handleCreate} type='primary'>Create Contact</Button>])}
        />
        <Layout.Content>
          <WhiteBox>
            <Table
              dataSource={this.state.data}
              columns={columns}
              {...props}
            />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.employerContacts.getAll(this.employerID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.contacts) {
      this.setState({ data: resp.body.contacts, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _delete(employerID, contactID) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.employerContacts.remove(employerID, contactID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Contact successfully removed!');
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
