import React from "react";
import { Layout, PageHeader, Button, Table, Tooltip, Popconfirm, message } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import autoBind from 'react-autobind';
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import AdminCreateNotesDrawer from './AdminCreateNotesDrawer';
import AdminEditNotesDrawer from './AdminEditNotesDrawer';
import AdminEmployerOptions, { AdminEmployerOptionTypes } from '@/views/commonComponents/AdminEmployerOptions';
import AdminMemberOptions, { AdminMemberOptionTypes } from "@/views/commonComponents/AdminMemberOptions";
//
import config from '@/config/config';
import Globals from "@/config/Globals";
import Utils from "@/components/Utils";
//
export default class AdminNotesView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //
    this.relID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID2_Placeholder, this);
    const relType = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    if (!relType) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.homepage); }
    //
    this.relType = Globals.Notes_relTypes[relType];
    this.relName = this.props.app.urlManager.getQueryParam('n');

    const employerOptions = new AdminEmployerOptions(AdminEmployerOptionTypes.NOTES, this.relID, this.props.app, this.relName);
    const memberOptions = new AdminMemberOptions(AdminMemberOptionTypes.NOTES, this.relID, this.props.app, this.relName);
    this.viewOptions = relType == 'employer' ? employerOptions : memberOptions;
    
    this.state = { isLoading: false, data: [], sortedInfo: null };
  }
  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Notes`;
    this._fetch();
  }
  //Actions
  handleCreateNote() { this.createDrawer.show(this.relType.apiKey, this.relID); }
  handleEdit(noteObj) { this.editDrawer.show(noteObj.id); }
  handleDelete(noteObj) { this._delete(noteObj.id); }
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const props = { onChange: this.handleFilterChange, rowKey: 'id', loading: this.props.isLoading };

    const columns = [
      { title: 'Date', dataIndex: 'createdOn', key: 'createdOn', width: 150,
        render: date => date ? moment(date).format(Globals.DefaultUIBirthDateFormat) : '-',
        sorter: (a, b) => (new Date(a.createdOn).getTime()) - (new Date(b.createdOn).getTime()),
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order
      },
      { title: 'Content', dataIndex: 'content', key: 'content', width: 150,
        render: content => <Tooltip title={content?.length > 40 ? content : null}>{Utils.truncateString(content, 40)}</Tooltip>,
        sorter: (a, b) => a.content.localeCompare(b.content),
        sortOrder: sortedInfo.columnKey === 'content' && sortedInfo.order
      },
      { title: 'Reminder Date', dataIndex: 'reminderDate', key: 'reminderDate', width: 150,
        render: date => date ? moment(date).format(Globals.DefaultUIBirthDateFormat) : '-',
        sorter: (a, b) => (new Date(a.reminderDate).getTime()) - (new Date(b.reminderDate).getTime()),
        sortOrder: sortedInfo.columnKey === 'reminderDate' && sortedInfo.order
      },
      { title: 'Actions', width: 200, key: 'Actions',
        render: props => {
          return (<span className='tableButtonContainer'>
                    <Tooltip placement="bottomLeft" title='Edit Note'>
                      <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleEdit.bind(this, props)}/>
                    </Tooltip>{' '}
                    <Tooltip placement="bottomLeft" title='Delete Note'>
                      <Popconfirm title={`Do you really want delete this note?`} placement="bottomRight" onConfirm={this.handleDelete.bind(this, props)} okText="Yes" cancelText="No">
                        <Button variant="none" icon={<DeleteOutlined />} shape="circle"/>
                      </Popconfirm>
                    </Tooltip>
                  </span>);
        }
      },
    ];

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <AdminCreateNotesDrawer app={this.props.app} onCreate={this._fetch} {...Utils.propagateRef(this, 'createDrawer')} />
        <AdminEditNotesDrawer app={this.props.app} onUpdate={this._fetch} {...Utils.propagateRef(this, 'editDrawer')} />
        <PageHeader
          title={`${this.relName || this.relType.name} Notes`}
          onBack={() => window.history.back()}
          extra={
            !this.relType.hideViewOptions
              ? this.viewOptions.getOptions().concat([<Button key="btn" onClick={this.handleCreateNote} type='primary'>Create Note</Button>])
              : [<Button key="btn" onClick={this.handleCreateNote} type='primary'>Create Note</Button>]
          }
        />
        <Layout.Content>
          <WhiteBox>
            <Table
              columns={columns}
              dataSource={this.state.data}
              {...props}
            />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _fetch(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.notes.getAll('any', this.relID, this.relType.apiKey);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.notes) {
      const data = resp.body.notes.sort((a, b) => b.id - a.id);
      this.setState({ data, isLoading: false});
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  
  async _delete(noteID) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.notes.remove(noteID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Note successfully deleted!');
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
