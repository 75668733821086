import React from "react";
import autoBind from 'react-autobind';
import { Row, Col, Form, Input, Select, DatePicker, Button, Checkbox, Divider, message, Radio, Result } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
//
import config from "@/config/config";
import CustomComponent from "@/components/CustomComponent";
import WhiteBox from "../commonComponents/WhiteBox";
//
import "@/stylesheets/ApplicationFormView.scss";
import Utils from "@/components/Utils";
import SignatureModal from "../commonComponents/SignatureModal";
//
import logo from '@/images/logoLarge.png';
import CommonLoadingView from "../commonComponents/CommonLoadingView";
import Globals from "@/config/Globals";
//
export default class ApplicationFormView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      sigModalVisible: false,
      signature: null,
      industryOther: false,
      becomeIbewOther: false,
      success: null,
    };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Apply for membership`;
  }

  // Actions
  handleSignatureOpen() {
    this.setState({ sigModalVisible: true });
  }
  
  handleSignatureCancel() {
    this.setState({ sigModalVisible: false });
  }

  handleSignatureSave(signature) {
    this.setState({ signature, sigModalVisible: false });
  }

  handleChangeIndustry(value) {
    if (value == 'other') {
      this.setState({ industryOther: true });
    } else {
      this.form.setFieldsValue({ industryOther: '' });
      this.setState({ industryOther: false });
    }
  }

  handleChangeBecomeIbew(value) {
    if (value == 'other') {
      this.setState({ becomeIbewOther: true });
    } else {
      this.form.setFieldsValue({ becomeIbewOther: '' });
      this.setState({ becomeIbewOther: false });
    }
  }

  handleBackToLogin() {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.login);
  }

  async handleSubmit() {
    if (!this.state.signature) {
      message.error('Provide your signature before submit!');
    }

    let formData = await this.form.validateFields();

    if (formData && this.state.signature) {
      const membershipReason = this.state.becomeIbewOther ? formData.becomeIbewOther : formData.membershipReason;
      const industry = this.state.industryOther ? formData.industryOther : formData.industry;
      delete formData.becomeIbewOther;
      delete formData.industryOther;

      formData = {
        ...formData,
        country: Globals.DefaultApplicationsCountry,
        birthDate: formData.birthDate ? moment(formData.birthDate).format(Globals.DefaultAPIDateFormat) : null,
        hireDate: formData.hireDate ? moment(formData.hireDate).format(Globals.DefaultAPIDateFormat) : null,
        previouslyMember: !!formData.previouslyMember,
        membershipReason,
        industry,
      };

      this._submit(formData);
    }
  }

  // UI
  render() {
    return (
      <div className="application-form-container">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <SignatureModal
          {... Utils.propagateRef(this, 'sigModal')}
          isVisible={this.state.sigModalVisible}
          onSave={this.handleSignatureSave}
          onCancel={this.handleSignatureCancel}
        />

        <WhiteBox className="application-form-content">
          {this.state.success && (
            <Result
              status="success"
              title="Success!"
              subTitle="Thank you for your application, we will be in touch when it has been processed."
              extra={[
                <Button key="1" icon={<ArrowLeftOutlined /> } onClick={this.handleBackToLogin} type="primary">
                  Back to login
                </Button>
              ]}
            />
          )}
          {!this.state.success && (
            <>
              <div className="alert-content">
              <img src={logo} alt="I.B.E.W." width="200" />

              <div className="alert">
                <h3>OBLIGATION OF I.B.E.W.®</h3>
                <p>
                  "I, the undersigned, in the presence of members of the International Brotherhood of Electrical Workers®, make application to join the I.B.E.W.®, and promise and agree to conform to and abide by the Constitution and laws of the I.B.E.W.® and its Local Unions. I will further the purpose for which the I.B.E.W.® is instituted. I will bear allegiance to it and will not sacrifice its interest in any manner." I am applying for membership in Local 258 of the I.B.E.W. (the "Union"). In applying for membership, I understand that the Unionintends to apply to be certified as my exclusive bargaining agent and to represent me in collective bargaining. I hereby give the Unio my permission to disclose, retain and use my personal information in accordance with its Privacy Policy.
                </p>
              </div>
            </div>

            <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
              <Divider orientation="left">Personal Information</Divider>

              <Row>
                <Col xs={24} sm={24} md={{ span: 3 }}>
                  <Form.Item name="prefix" label="Prefix">
                    <Select>
                      <Select.Option value="none">None</Select.Option>
                      <Select.Option value="MR">MR</Select.Option>
                      <Select.Option value="MS">MS</Select.Option>
                      <Select.Option value="MRS">MRS</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={{ span: 6, offset: 1 }}>
                  <Form.Item name="firstName" label="First Name" rules={[{ required: true, message: 'This field is required!' }]}>
                    <Input placeholder="Joe" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={{ span: 2, offset: 1 }}>
                  <Form.Item name="middleName" label="M.I">
                    <Input placeholder="W" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={{ span: 6, offset: 1 }}>
                  <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: 'This field is required!' }]}>
                    <Input placeholder="Doe" />
                  </Form.Item>
                </Col>
                {/* <Col xs={24} sm={24} md={{ span: 3, offset: 1 }}>
                  <Form.Item name="suffix" label="Suffix">
                    <Select>
                      <Select.Option value="none">None</Select.Option>
                      <Select.Option value="JR">JR</Select.Option>
                      <Select.Option value="II">II</Select.Option>
                      <Select.Option value="III">III</Select.Option>
                      <Select.Option value="IV">IV</Select.Option>
                      <Select.Option value="V">V</Select.Option>
                    </Select>
                  </Form.Item>
                </Col> */}
              </Row>

              <Row>
                <Col xs={24} md={24} md={5}>
                  <Form.Item name="birthDate" label="Date of Birth" rules={[{ required: true, message: 'This field is required!' }]}>
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={{ span: 4, offset: 1 }}>
                  <Form.Item name="sin" label="Social Insurance NO." rules={[
                    { required: true, message: 'This field is required!' },
                    { len: 9, message: 'Must have 9 characters!' },
                  ]}>
                    <Input placeholder="XXX-XXX-XXX" />
                  </Form.Item>
                </Col>
                <Col span={3} offset={1}>
                  <Form.Item label="Sex" name="sex" rules={[{ required: true, message: 'This field is required!' }]}>
                    <Radio.Group>
                    <Radio value="M">M</Radio>
                    <Radio value="F">F</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Divider orientation="left">Contact Information</Divider>
              <Row>
                <Col xs={24} sm={24} md={7}>
                  <Form.Item name="email" label="Email" rules={[{ required: true, message: 'This field is required!' }]}>
                    <Input placeholder="joedoe@example.com" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={{ span: 5, offset: 1 }}>
                  <Form.Item name="phone" label="Telephone NO.">
                    <Input placeholder="(XXX) XXX-XXXX" />
                  </Form.Item>
                </Col>
              </Row>
              
              <Divider orientation="left">Address Information</Divider>

              <Row>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="street1" label="Address 1 (Street & Number)" rules={[{ required: true, message: 'This field is required!' }]}>
                    <Input placeholder="128 Main St." />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={{ span: 11, offset: 1 }}>
                  <Form.Item name="street2" label="Address 2 (Street & Number)">
                    <Input placeholder="128 Main St." />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={24} md={6}>
                  <Form.Item name="city" label="City" rules={[{ required: true, message: 'This field is required!' }]}>
                    <Input placeholder="Vancouver" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={{ span: 4, offset: 1 }}>
                  <Form.Item name="province" label="Province" rules={[
                    { required: true, message: 'This field is required!' },
                    { len: 2, message: 'Must have 2 characters!' },
                  ]}>
                    <Input placeholder="BC" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={{ span: 4, offset: 1 }}>
                  <Form.Item name="postalCode" label="Postal Code" rules={[{ required: true, message: 'This field is required!' }]}>
                    <Input placeholder="V5J-2FK" />
                  </Form.Item>
                </Col>
              </Row>

              <Divider orientation="left">Employer Information</Divider>

              <Row>
                <Col xs={24} sm={24} md={{ span: 8 }}>
                  <Form.Item name="currentEmployer" label="Present Employer">
                    <Input placeholder="Company Inc." />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} md={{ span: 5, offset: 1 }}>
                  <Form.Item name="hireDate" label="Date of Hire">
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={24} md={6}>
                  <Form.Item name="industry" label="Industry where you are employed">
                    <Select onChange={this.handleChangeIndustry}>
                      <Select.Option value="Traffic Control">Traffic Control</Select.Option>
                      <Select.Option value="Government">Government</Select.Option>
                      <Select.Option value="Inside Construction & Maintenance">Inside Construction & Maintenance</Select.Option>
                      <Select.Option value="Utility">Utility</Select.Option>
                      <Select.Option value="Manufacturing">Manufacturing</Select.Option>
                      <Select.Option value="other">Other</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {this.state.industryOther && (
                  <Col xs={24} sm={24} md={{ span: 6, offset: 1 }}>
                    <Form.Item name="industryOther" label="Other...">
                      <Input />
                    </Form.Item>
                  </Col>
                )}
                <Col xs={24} sm={24} md={{ span: 7, offset: 1 }}>
                  <Form.Item name="previouslyMember" label="Have you ever been a member of I.B.E.W.®?" valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={24} md={10}>
                  <Form.Item name="membershipReason" label="How did you become an I.B.E.W.® member?">
                    <Select onChange={this.handleChangeBecomeIbew}>
                      <Select.Option value="I was organized">I was organized</Select.Option>
                      <Select.Option value="I was organized as an apprentice">I was organized as an apprentice</Select.Option>
                      <Select.Option value="I was selected for an apprenticeship program">I was selected for an apprenticeship program</Select.Option>
                      <Select.Option value="Iam a new hire">Iam a new hire</Select.Option>
                      <Select.Option value="other">Other</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {this.state.becomeIbewOther && (
                  <Col xs={24} sm={24} md={{ span: 9, offset: 1 }}>
                    <Form.Item name="becomeIbewOther" label="Other...">
                      <Input />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Divider orientation="left">Extra Information</Divider>
              <Row>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item name="tradeQualification" label="Trade Qualification">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item name="redSealNumber" label="Red Seal Number">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />

              <Row style={{ marginTop: 32 }}>
                <Col span={24}>
                  <div className="signature-container">
                    <div className={this.state.signature ? 'signature-area filled' : 'signature-area'} onClick={this.handleSignatureOpen}>
                      {!this.state.signature && <span>Click here to sign</span>}
                      {this.state.signature && <img src={this.state.signature} alt="Signature" />}
                    </div>
                    <div className="divider" />
                    <span>Signature</span>
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 24 }} justify="center">
                <Col>
                  <Button type="ghost" onClick={this.handleBackToLogin} icon={<ArrowLeftOutlined />}>Back to login</Button>{' '}
                  <Button type="primary" onClick={this.handleSubmit}>Submit Application</Button>
                </Col>
              </Row>
            </Form>
            </>
          )}
        </WhiteBox>
      </div>
    );
  }

  // API Calls
  _uploadSignature() {
    return new Promise((resolve) => {
      const file = Utils.base64toFile(this.state.signature, 'signature');
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onloadend = async (readerResp) => {
        const generatedFileID = this.props.app.api.applications.encodedFileName(file.name);
        
        file.file = readerResp.currentTarget.result;
        if (!this._isMounted) return;

        const resp = await this.props.app.api.applications.upload(file, generatedFileID);
        if (resp && resp.statusCode == 200) {
          resolve({ generatedFileID });
        } else {
          resolve(resp);
        }
      };
    });
  }

  async _submit(data) {
    this.startLoading();
    //
    // upload signature
    const signatureResp = await this._uploadSignature();
    //
    if (signatureResp.generatedFileID) {
      const applicationResp = await this.props.app.api.applications.create({
        ...data,
        signatureFileID: signatureResp.generatedFileID,
      });
      if (!this._isMounted) return;

      console.log(applicationResp);

      if (applicationResp.statusCode == 200 && applicationResp.body) {
        this.setState({ isLoading: false, success: true });
      } else {
        this.props.app.alertController.showAPIErrorAlert(null, applicationResp);
        this.setState({ isLoading: false });
      }
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, signatureResp);
      this.setState({ isLoading: false });
    }
  }
}
