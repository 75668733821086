import React from "react";
import { Layout, Row, Col, Result, Button, Typography, Collapse } from 'antd';
import autoBind from 'react-autobind';
import { WarningOutlined, CloseCircleOutlined, CaretRightOutlined } from '@ant-design/icons';
//
import config from "@/config/config";
import { IDMGlobals } from "@ikonintegration/idmclient";
//
import Logo from '../commonComponents/Logo';
import CustomComponent from "@/components/CustomComponent";
import CommonLoadingView from "../commonComponents/CommonLoadingView";
import CommonBoxLinks from '../commonComponents/CommonBoxLinks';
//
import "@/stylesheets/CommonRegistration.scss";
//
export default class CommonAuthErrorView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    window.resizeTo(620, 500);
    this.state = { error: this.props.location.state, isLoading: false};
  }
  componentDidMount() {
    document.title = `${config.ApplicationName} - Authentication error`;
    super.componentDidMount();
  }
  //Actions
  handleLogin() { this.props.app.urlManager.pushPage(config.ApplicationRoutes.login); }
  handleContactAdministrator() { this.props.app.urlManager.openPage(config.ApplicationRoutes.contactAdmin); }
  //UI
  render() {
    let isLoading = this.state.isLoading || this.props.app.isAuthenticating;
    return (
      <Layout.Content className='pageContentFullScreen'>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <Layout.Content className='registerContainer'>
          <Row type="flex" justify="center" align="middle" className='authLogoRow'><Col align="center"> <Logo /> </Col></Row>
          <Row type="flex" justify="center" align="middle">
            <Col span={18} className='registerBoxContainer' align='middle' justify="center">
              <div className='registerBox'>
                <Row>
                  <Col align="center"> {this._renderResultByState()} </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <CommonBoxLinks width={500}/>
        </Layout.Content>
      </Layout.Content>
    );
  }


  /* private methods*/
  _renderResultByState() {
    const challengeState = (this.state.error && this.state.error.challengeState ? this.state.error.challengeState :
                            (this.state.error && this.state.error.errCode ? this.state.error.errCode : ''));
    if (challengeState == IDMGlobals.AuthorizationChallengeState_Disabled) {
      return (
        <Result status="warning" title="User is disabled"
          subTitle="Your user is currently disabled on system. If you believe this is a mistake, please contact the administrator."
          extra={[
            <Button type="primary" key="console" onClick={this.handleContactAdministrator}>Contact</Button>,
            <Button key="buy" onClick={this.handleLogin}>Login again</Button>,
          ]}>
          {this._renderDefaultErrorDescription()}
        </Result>
      );
    } else if (challengeState == IDMGlobals.AuthorizationChallengeState_Locked) {
      return (
        <Result status="warning" title="User is locked"
          subTitle="Your user is locked. If you believe this is a mistake, please contact the administrator."
          extra={[
            <Button type="primary" key="console" onClick={this.handleContactAdministrator}>Contact</Button>,
            <Button key="buy" onClick={this.handleLogin}>Login again</Button>,
          ]}>
          {this._renderDefaultErrorDescription()}
        </Result>
      );
    } else if (challengeState == IDMGlobals.AuthorizationErrorCode_NoAppAccess) {
      return (
        <Result status="warning" title="No application access"
          subTitle="You do not have permissions to access the application you are trying to access. If the application have registration enabled, please, go the application and try to login. If you believe this is a mistake, please contact the administrator."
          extra={[
            <Button type="primary" key="console" onClick={this.handleContactAdministrator}>Contact</Button>,
            <Button key="buy" onClick={this.handleLogin}>Login again</Button>,
          ]}>
          {this._renderDefaultErrorDescription()}
        </Result>
      );
    } else {
      return (
        <Result status="error" title="Authentication error"
          subTitle="Authentication could not be completed. If you believe this is a mistake, please contact the administrator."
          extra={[
            <Button type="primary" key="console" onClick={this.handleContactAdministrator}>Contact</Button>,
            <Button key="buy" onClick={this.handleLogin}>Login again</Button>,
          ]}>
          {this._renderDefaultErrorDescription()}
        </Result>
      );
    }
  }

  _renderDefaultErrorDescription() {
    return (
      <div className="desc">
        <Typography.Paragraph>
          <Typography.Text strong style={{ fontSize: 16 }}>Request context:</Typography.Text>
        </Typography.Paragraph>
        <Typography.Paragraph>
          <WarningOutlined /> TransactionID: {(this.state.error && this.state.error.transactionID ? this.state.error.transactionID : '')}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <CloseCircleOutlined style={{ color: 'red' }} /> Error type:
            {(this.state.error && this.state.error.challengeState ? this.state.error.challengeState :
              (this.state.error && this.state.error.errCode ? this.state.error.errCode : ''))}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <CloseCircleOutlined style={{ color: 'red' }} /> Error message:
            {(this.state.error && this.state.error.message ? this.state.error.message :
              (this.state.error && this.state.error.err ? this.state.error.err : ''))}
        </Typography.Paragraph>
        <Collapse bordered={false} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
          <Collapse.Panel header="Detailed information" key="1" >
            <Typography.Paragraph align='left'><pre>{JSON.stringify(this.state.error, null, 2)}</pre></Typography.Paragraph>
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  }
}
