import React from "react";
import autoBind from 'react-autobind';
import { Popover, Row, Col, Input, Button, Divider, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
//
import CustomComponent from '@/components/CustomComponent';
//
export default class CommonMemberSelectionPopover extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      searchTerm: '',
      data: [],
    };
  }
  //Actions
    //Search actions
  handleChange(e) { this.setState({ searchTerm: e.target.value }); }
  handleSearch() { this._search(); }
    //Table actions
  handleSelectItem(item) { if (this.props.onSelect) this.props.onSelect(item); }
  //UI
  render() {
    const disabledEmployers = this.props.disable || [];

    const columns = [
      { title: 'Name', key: 'name', render: (props) => `${props.firstName}${(props.middleName ? ` ${props.middleName}` : '')}${(props.lastName ? ` ${props.lastName}` : '')}` },
      { title: 'SIN', key: 'sin', dataIndex: 'sin' },
      {
        title: '', key: 'select', align: 'right',
        render: (item) => (
          <Button type="primary" size="small" onClick={this.handleSelectItem.bind(this, item)}
            disabled={disabledEmployers.includes(item.id)}>
            Select
          </Button>
        )
      }
    ];

    return (
      <Popover title="Search and select an Member" trigger='click' content={
          <div style={{ width: '500px' }}>
            <Row>
              <Col span={19}>
                <Input prefix={<SearchOutlined />} size="large" onPressEnter={this.handleSearch}
                  placeholder="Search..." value={this.state.searchTerm} onChange={this.handleChange} autoFocus />
              </Col>
              <Col offset={1}>
                <Button size="large" type="primary" onClick={this.handleSearch}>Search</Button>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={this.state.data}
                  rowKey="id" pagination={{ pageSize: 5 }}
                  loading={this.state.isLoading}
                />
              </Col>
            </Row>
          </div>
        }
      >
        {this.props.children}
      </Popover>
    )
  }

  // API Calls
  async _search() {
    if (!this._isMounted) return;
    //
    this.setState({ data: [], isLoading: true });
    //request
    const resp = await this.props.app.api.members.search({
      term: this.state.searchTerm || '',
      filter: {},
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.members) {
      this.setState({ data: resp.body.members, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
