import React from "react";
import { Switch } from "react-router-dom";
import CustomRoute from "./components/CustomRoute";
//Common Auth views
//import ErrorView from "./views/ErrorView";
import CommonLoginView from "./views/authSubviews/CommonLoginView";
import CommonRegistrationConfirmationView from './views/authSubviews/CommonRegistrationConfirmationView';
import CommonRegistrationConfirmationResetView from './views/authSubviews/CommonRegistrationConfirmationResetView';
import CommonResetPasswordRequestView from './views/authSubviews/CommonResetPasswordRequestView';
import CommonResetPasswordConfirmationView from './views/authSubviews/CommonResetPasswordConfirmationView';
import CommonNewPasswordSetup from './views/authSubviews/CommonNewPasswordSetup';
import CommonAuthErrorView from './views/authSubviews/CommonAuthErrorView';
import CommonRedirectView from './views/authSubviews/CommonRedirectView';
import CommonMemberWorkHistoryView from './views/commonSubviews/memberWorkHistory/CommonMemberWorkHistoryView';
import ApplicationFormView from './views/authSubviews/ApplicationFormView';
import CommonMemberDuesHistoryView from './views/commonSubviews/CommonMemberDuesHistoryView';
import CommonMemberDispatchNotesView from './views/commonSubviews/CommonMemberDispatchNotesView';
//Common
import CommonRemittancesView from "./views/commonSubviews/CommonRemittancesView";
import CommonDispatchJobView from "./views/commonSubviews/dispatchJob/CommonDispatchJobView";
import CommonCreateDispatchJobView from "./views/commonSubviews/dispatchJob/CommonCreateDispatchJobView";
import CommonUpdateDispatchJobView from "./views/commonSubviews/dispatchJob/CommonUpdateDispatchJobView";
import CommonTrainingHistoryView from "./views/commonSubviews/trainingHistory/CommonTrainingHistoryView";
//Member
import MemberDashboardView from "./views/memberSubviews/MemberDashboardView";
//Admin
import AdminDashboardView from "./views/adminSubviews/AdminDashboardView";
import AdminProfileView from "./views/adminSubviews/AdminProfileView";
import AdminDispatchView from "./views/adminSubviews/AdminDispatchView";
import AdminDispatchMembersView from "./views/adminSubviews/AdminDispatchMembersView";
import AdminResetYTD from "./views/adminSubviews/AdminResetYTD";
import AdminApplicationsView from "./views/adminSubviews/applications/AdminApplicationsView";
  //Admin Reports
import AdminArrearsLettersReportView from './views/adminSubviews/reports/AdminArrearsLettersReportView';
import AdminMembersLabelReportView from './views/adminSubviews/reports/member/AdminMembersLabelReportView';
import AdminMembersArrearsReportView from './views/adminSubviews/reports/member/AdminMembersArrearsReportView';
import AdminMembersListReportView from './views/adminSubviews/reports/member/AdminMembersListReportView';
import AdminMembersDuesPostingReportView from './views/adminSubviews/reports/member/AdminMembersDuesPostingReportView';
import AdminCustomMembersReportView from './views/adminSubviews/reports/member/AdminCustomMembersReportView';
import AdminShopStewardsReportView from './views/adminSubviews/reports/employer/AdminShopStewardsReportView';
import AdminEmployersLabelReportView from './views/adminSubviews/reports/employer/AdminEmployersLabelReportView';
import AdminMemberDispatchListReportView from './views/adminSubviews/reports/member/AdminMemberDispatchListReportView';
import AdminMemberTaxReport from './views/adminSubviews/reports/member/AdminMemberTaxReport';
import AdminActiveMembersReport from './views/adminSubviews/reports/member/AdminActiveMembersReportView';
import AdminMemberDispatchSlipReportView from './views/adminSubviews/reports/member/AdminMemberDispatchSlipReportView';
import AdminMemberIoReportView from './views/adminSubviews/reports/member/AdminMemberIoReportView';
import AdminEmploymentByClassificationReportView from './views/adminSubviews/reports/AdminEmploymentByClassificationReportView';
import AdminMembersDuesYTDReportView from './views/adminSubviews/reports/member/AdminMembersDuesYTD';
  //Admin config
import AdminSettingsView from "./views/adminSubviews/config/AdminSettingsView";
import AdminDepartmentsView from "./views/adminSubviews/config/AdminDepartmentsView";
import AdminSectorsView from "./views/adminSubviews/config/AdminSectorsView";
import AdminSchedulesView from "./views/adminSubviews/config/AdminSchedulesView";
import AdminAppConfigView from "./views/adminSubviews/config/AdminAppConfigView";
import AdminDuesRatesView from "./views/adminSubviews/config/AdminDuesRatesView";
import AdminStatusCodeView from "./views/adminSubviews/config/AdminStatusCodeView";
import AdminTrainingCodesView from "./views/adminSubviews/config/AdminTrainingCodesView";
import AdminUnitsView from "./views/adminSubviews/config/AdminUnitsView";
import AdminDispatchListView from "./views/adminSubviews/config/AdminDispatchListView";
import AdminChartOfAccountsView from "./views/adminSubviews/config/AdminChartOfAccountsView";
import AdminClassificationsView from "./views/adminSubviews/config/AdminClassificationsView";
import AdminWorkActivityView from "./views/adminSubviews/config/AdminWorkActivityView";
import AdminJobClassificationsView from './views/adminSubviews/config/AdminJobClassificationsView';
  //Admin admin
import AdminAdminsView from "./views/adminSubviews/admin/AdminAdminsView";
import AdminAdminsCreateView from "./views/adminSubviews/admin/AdminAdminCreateView";
import AdminAdminsUpdateView from "./views/adminSubviews/admin/AdminAdminUpdateView";
import AdminAdminsManageAclsView from "./views/adminSubviews/admin/AdminAdminManageAclsView";
  //Admin employer
import AdminEmployersView from "./views/adminSubviews/employer/AdminEmployersView";
import AdminEmployersCreateView from "./views/adminSubviews/employer/AdminEmployersCreateView";
import AdminEmployersUpdateView from "./views/adminSubviews/employer/AdminEmployersUpdateView";
import AdminNotesView from "./views/adminSubviews/employer/AdminNotesView";
import AdminContactsView from "./views/adminSubviews/employer/AdminContactsView";
import AdminRemittancesView from "./views/adminSubviews/employer/AdminRemittancesView";
import AdminImportRemittanceView from "./views/adminSubviews/employer/AdminImportRemittanceView";
import AdminDispatchNotesView from "./views/adminSubviews/AdminDispatchNotesView";
  //Admin member
import AdminMembersView from './views/adminSubviews/member/AdminMembersView';
import AdminMemberCreateView from './views/adminSubviews/member/AdminMemberCreateView';
import AdminMemberUpdateView from './views/adminSubviews/member/AdminMemberUpdateView';
  //Admin Batches
import AdminBatchesSearchView from './views/adminSubviews/batch/AdminBatchesSearchView';
import AdminBatchEditView from './views/adminSubviews/batch/AdminBatchEditView';
// Employer
import EmployerDashboardView from "./views/employerSubviews/EmployerDashboardView";
import EmployerProfileView from "./views/employerSubviews/EmployerProfileView";
// Member
import MemberProfileView from "./views/memberSubviews/profile/MemberProfileView";
//
import config from "./config/config";
import Globals from "./config/Globals";

export default class AppRoutes extends React.Component {
  render() {
    return this._renderPrivilegedRoutes();
  }
  _renderPrivilegedRoutes() {
    //not logged? - default landing page
    if (!this.props.appRef.props.app.idm.isLogged() || this.props.appRef.props.app.isAuthenticating) {
      return (
        <Switch>
          <CustomRoute path={config.ApplicationRoutes.login} exact component={CommonLoginView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.registrationConfirmationReset} exact component={CommonRegistrationConfirmationResetView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.registrationConfirmation} exact component={CommonRegistrationConfirmationView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.registrationConfirmationMail} exact component={CommonRegistrationConfirmationView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.resetPassword} exact component={CommonResetPasswordRequestView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.resetPasswordRaw} exact component={CommonResetPasswordRequestView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.resetPasswordConfirmation} exact component={CommonResetPasswordConfirmationView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.setupPassword} exact component={CommonNewPasswordSetup} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.authError} exact component={CommonAuthErrorView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.applicationForm} exact component={ApplicationFormView} appRef={this.props.appRef}/>
          <CustomRoute component={CommonLoginView} appRef={this.props.appRef}/>
        </Switch>
      );
    }
    
    //Logged but being redirected
    if (this.props.appRef.props.app.idm.isLogged() && this.props.appRef.props.app.redirectMode) {
      return (
        <Switch>
          <CustomRoute component={CommonRedirectView} appRef={this.props.appRef}/>
        </Switch>
      );
    }
    
    //Logged ad admin
    if (this.props.appRef.props.app.isAdmin()) {
      return (
        <Switch>
          {/* Non-ACL protected routes */}
          <CustomRoute path={config.ApplicationRoutes.homepage} exact component={AdminDashboardView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.profile} exact component={AdminProfileView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.adminSettings} exact component={AdminSettingsView} appRef={this.props.appRef}/>
          {/* ACL protected routes */}
          <CustomRoute path={config.ApplicationRoutes.adminSettingsDepartments} exact component={AdminDepartmentsView} appRef={this.props.appRef}
                       componentID={Globals.Components.DEPTS_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.adminSettingsSectors} exact component={AdminSectorsView} appRef={this.props.appRef}
                       componentID={Globals.Components.SECTORS_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.adminSettingsSchedules} exact component={AdminSchedulesView} appRef={this.props.appRef}
                       componentID={Globals.Components.SCHEDULES_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.admins} exact component={AdminAdminsView} appRef={this.props.appRef}
                       componentID={Globals.Components.ADMINS_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.adminsCreate} exact component={AdminAdminsCreateView} appRef={this.props.appRef}
                      componentID={Globals.Components.ADMINS_MANAGEMENT} accessLevel={Globals.AllWriteLevels}/>
          <CustomRoute path={config.ApplicationRoutes.adminsUpdate} exact component={AdminAdminsUpdateView} appRef={this.props.appRef}
                       componentID={Globals.Components.ADMINS_MANAGEMENT} accessLevel={Globals.AllWriteLevels}/>
          <CustomRoute path={config.ApplicationRoutes.employers} exact component={AdminEmployersView} appRef={this.props.appRef}
                       componentID={Globals.Components.EMPLOYERS_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.employersCreate} exact component={AdminEmployersCreateView} appRef={this.props.appRef}
                       componentID={Globals.Components.EMPLOYERS_MANAGEMENT} accessLevel={Globals.AllWriteLevels}/>
          <CustomRoute path={config.ApplicationRoutes.employersUpdate} exact component={AdminEmployersUpdateView} appRef={this.props.appRef}
                       componentID={Globals.Components.EMPLOYERS_MANAGEMENT} accessLevel={Globals.AllWriteLevels}/>
          <CustomRoute path={config.ApplicationRoutes.adminsManageAcls} exact component={AdminAdminsManageAclsView} appRef={this.props.appRef}
                       componentID={Globals.Components.ADMINS_MANAGEMENT_PERMISSIONS} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.employerRemittances} exact component={CommonRemittancesView} appRef={this.props.appRef}
                       componentID={Globals.Components.EMPLOYER_ELECTRONIC_REMITTANCE} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.adminSettingsAppConfig} exact component={AdminAppConfigView} appRef={this.props.appRef}
                       componentID={Globals.Components.SETTINGS_GENERAL} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.adminSettingsDuesRates} exact component={AdminDuesRatesView} appRef={this.props.appRef}
                       componentID={Globals.Components.SETTINGS_DUE_RATES} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.adminRemittances} exact component={AdminRemittancesView} appRef={this.props.appRef}
                       componentID={Globals.Components.EMPLOYER_ELECTRONIC_REMITTANCE} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.adminImportRemittance} exact component={AdminImportRemittanceView} appRef={this.props.appRef}
                       componentID={Globals.Components.EMPLOYER_ELECTRONIC_REMITTANCE} accessLevel={Globals.AllWriteLevels}/>
          <CustomRoute path={config.ApplicationRoutes.notes} exact component={AdminNotesView} appRef={this.props.appRef}
                       componentID={Globals.Components.NOTES_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.contacts} exact component={AdminContactsView} appRef={this.props.appRef}
                       componentID={Globals.Components.EMPLOYER_CONTACTS_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.adminSettingsMemberStatus} exact component={AdminStatusCodeView} appRef={this.props.appRef}
                       componentID={Globals.Components.MEMBER_STATUS_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.adminSettingsTrainingCodes} exact component={AdminTrainingCodesView} appRef={this.props.appRef}
                       componentID={Globals.Components.TRAINING_CODES_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.adminSettingsUnits} exact component={AdminUnitsView} appRef={this.props.appRef}
                       componentID={Globals.Components.UNIT_NUMBERS_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.adminSettingsDispatchList} exact component={AdminDispatchListView} appRef={this.props.appRef}
                       componentID={Globals.Components.DISPATCH_LIST_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.adminSettingsChartOfAccounts} exact component={AdminChartOfAccountsView} appRef={this.props.appRef}
                       componentID={Globals.Components.ACCOUNTS_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.adminSettingsClassifications} exact component={AdminClassificationsView} appRef={this.props.appRef}
                       componentID={Globals.Components.MEMBERS_CLASSIFICATION_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.adminSettingsJobClassifications} exact component={AdminJobClassificationsView} appRef={this.props.appRef}
                       componentID={Globals.Components.CLASSIFICATION_MANAGEMENT} accessLevel={Globals.AllLevels} />
          <CustomRoute path={config.ApplicationRoutes.adminSettingsWorkActivityCodes} exact component={AdminWorkActivityView} appRef={this.props.appRef}
                       componentID={Globals.Components.WORK_ACTIVITIES_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.members} exact component={AdminMembersView} appRef={this.props.appRef}
                       componentID={Globals.Components.MEMBERS_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.memberCreate} exact component={AdminMemberCreateView} appRef={this.props.appRef}
                       componentID={Globals.Components.MEMBERS_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.memberUpdate} exact component={AdminMemberUpdateView} appRef={this.props.appRef}
                       componentID={Globals.Components.MEMBERS_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.memberWorkHistory} exact component={CommonMemberWorkHistoryView} appRef={this.props.appRef}
                       componentID={Globals.Components.EMPLOYER_WORK_HISTORY_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.memberDuesHistory} exact component={CommonMemberDuesHistoryView} appRef={this.props.appRef}
                       componentID={Globals.Components.MEMBER_DUES_ENTRIES_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.reportMembersLabel} exact component={AdminMembersLabelReportView} appRef={this.props.appRef}
                       componentID={Globals.Components.REPORT_MEMBERS_LABEL} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.memberDispatchNotes} exact component={CommonMemberDispatchNotesView} appRef={this.props.appRef}
                       componentID={Globals.Components.NOTES_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.employerDispatchNotes} exact component={AdminDispatchNotesView} appRef={this.props.appRef}
                       componentID={Globals.Components.NOTES_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.reportShopStewards} exact component={AdminShopStewardsReportView} appRef={this.props.appRef}
                       componentID={Globals.Components.REPORT_SHOP_STEWARDS} accessLevel={Globals.AllLevels}/>
          <CustomRoute path={config.ApplicationRoutes.reportMembersArrears} exact component={AdminMembersArrearsReportView} appRef={this.props.appRef}
                       componentID={Globals.Components.REPORT_MEMBER_ARREARS} accessLevel={Globals.AllLevels} />
          <CustomRoute path={config.ApplicationRoutes.reportEmployersLabel} exact component={AdminEmployersLabelReportView} appRef={this.props.appRef}
                       componentID={Globals.Components.REPORT_EMPLOYERS_LABEL} accessLevel={Globals.AllLevels} />
          <CustomRoute path={config.ApplicationRoutes.reportTaxReceipts} exact component={AdminMemberTaxReport} appRef={this.props.appRef}
                       componentID={Globals.Components.REPORT_TAX_RECEIPTS} accessLevel={Globals.AllLevels} />
          <CustomRoute path={config.ApplicationRoutes.reportActiveMembers} exact component={AdminActiveMembersReport} appRef={this.props.appRef}
                       componentID={Globals.Components.REPORT_ACTIVE_MEMBERS} accessLevel={Globals.AllLevels} />   
          <CustomRoute path={config.ApplicationRoutes.ioPerCapita} exact component={AdminMemberIoReportView} appRef={this.props.appRef}
                       componentID={Globals.Components.REPORT_IO} accessLevel={Globals.AllLevels} />
          <CustomRoute path={config.ApplicationRoutes.reportDispatchSlip} exact component={AdminMemberDispatchSlipReportView} appRef={this.props.appRef}
                       componentID={Globals.Components.REPORT_DISPATCH_SLIP} accessLevel={Globals.AllLevels} />
          <CustomRoute path={config.ApplicationRoutes.dispatchJob} exact component={CommonDispatchJobView} appRef={this.props.appRef}
                       componentID={Globals.Components.DISPATCH_JOBS_MANAGEMENT} accessLevel={Globals.AllLevels} />
          <CustomRoute path={config.ApplicationRoutes.crateDispatchJob} exact component={CommonCreateDispatchJobView} appRef={this.props.appRef}
                       componentID={Globals.Components.DISPATCH_JOBS_MANAGEMENT} accessLevel={Globals.AllWriteLevels} />
          <CustomRoute path={config.ApplicationRoutes.duesBatches} exact component={AdminBatchesSearchView} appRef={this.props.appRef}
                      componentID={Globals.Components.EMPLOYER_DUES_BATCHES_MANAGEMENT} accessLevel={Globals.AllWriteLevels} />
          <CustomRoute path={config.ApplicationRoutes.duesBatchUpdate} exact component={AdminBatchEditView} appRef={this.props.appRef}
                      componentID={Globals.Components.EMPLOYER_DUES_BATCHES_MANAGEMENT} accessLevel={Globals.AllWriteLevels} />
          <CustomRoute path={config.ApplicationRoutes.updateDispatchJob} exact component={CommonUpdateDispatchJobView} appRef={this.props.appRef}
                       componentID={Globals.Components.DISPATCH_JOBS_MANAGEMENT} accessLevel={Globals.AllWriteLevels} />
          <CustomRoute path={config.ApplicationRoutes.reportEmploymentByClassification} exact component={AdminEmploymentByClassificationReportView} appRef={this.props.appRef}
                       componentID={Globals.Components.REPORT_EMPLOYMENT_BY_CLASSIFICATION} accessLevel={Globals.AllWriteLevels} />
          <CustomRoute path={config.ApplicationRoutes.reportMembersDuesYTD} exact component={AdminMembersDuesYTDReportView} appRef={this.props.appRef}
                       componentID={Globals.Components.REPORT_MEMBERS_DUES_YTD} accessLevel={Globals.AllLevels} />
          {/* TODO - Review ACL OF THIS REPORT */}
          <CustomRoute path={config.ApplicationRoutes.reportMembersList} exact component={AdminMembersListReportView} appRef={this.props.appRef}
                       componentID={[
                          Globals.Components.REPORT_MEMBERS_BY_EMPLOYER,
                          Globals.Components.REPORT_MEMBERS_BY_CLASSIFICATION,
                          Globals.Components.REPORT_MEMBERS_BY_SECTOR,
                       ]} accessLevel={Globals.AllLevels} validationMode="any" />
          <CustomRoute path={config.ApplicationRoutes.reportMembersDuesPosting} exact component={AdminMembersDuesPostingReportView} appRef={this.props.appRef}
                       componentID={Globals.Components.REPORT_DUES_POSTING} accessLevel={Globals.AllLevels} />
          <CustomRoute path={config.ApplicationRoutes.reportDispatchList} exact component={AdminMemberDispatchListReportView} appRef={this.props.appRef}
                       componentID={Globals.Components.REPORT_MEMBER_DISPATCH_LIST} accessLevel={Globals.AllLevels} />
          <CustomRoute path={config.ApplicationRoutes.reportCustomMembersReport} exact component={AdminCustomMembersReportView} appRef={this.props.appRef}
                       componentID={Globals.Components.REPORT_MEMBERS_CUSTOM} accessLevel={Globals.AllLevels} />
          <CustomRoute path={config.ApplicationRoutes.trainingHistory} exact component={CommonTrainingHistoryView} appRef={this.props.appRef}
                       componentID={Globals.Components.PERSON_TRAINING_MANAGEMENT} accessLevel={Globals.AllLevels} />
          <CustomRoute path={config.ApplicationRoutes.resetYTD} exact component={AdminResetYTD} appRef={this.props.appRef}
                      componentID={Globals.Components.CURRENT_YEAR_ROLLOVER} accessLevel={Globals.AllLevels} />
          <CustomRoute path={config.ApplicationRoutes.applications} exact component={AdminApplicationsView} appRef={this.props.appRef}
                      componentID={Globals.Components.MEMBERSHIP_APPLICATION} accessLevel={Globals.AllLevels} />
          <CustomRoute path={config.ApplicationRoutes.reportArrearsLetters} exact component={AdminArrearsLettersReportView} appRef={this.props.appRef}
                       componentID={Globals.Components.REPORT_MEMBERS_ARREARS_REPORT} accessLevel={Globals.AllLevels} />
          {/* TODO - Review ACL */}
          <CustomRoute path={config.ApplicationRoutes.dispatchMembers} exact component={AdminDispatchMembersView} appRef={this.props.appRef}
                       componentID={Globals.Components.REPORT_MEMBERS_CUSTOM} accessLevel={Globals.AllLevels} />
          {/* TODO - Review ACL - not sure if this view is for dispatch jobs ot dispatch lists */}
          <CustomRoute path={config.ApplicationRoutes.dispatch} exact component={AdminDispatchView} appRef={this.props.appRef}
                       componentID={Globals.Components.DISPATCH_JOBS_MANAGEMENT} accessLevel={Globals.AllLevels}/>
          <CustomRoute component={AdminDashboardView} appRef={this.props.appRef}/>
        </Switch>
      )
    }
    
    //Logged as user
    if (this.props.appRef.props.app.isEmployer()) {
      return (
        <Switch>
          <CustomRoute path={config.ApplicationRoutes.remittances} exact component={CommonRemittancesView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.homepage} exact component={EmployerDashboardView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.profile} exact component={EmployerProfileView} appRef={this.props.appRef}/>
          <CustomRoute component={EmployerDashboardView} appRef={this.props.appRef}/>
        </Switch>
      );
    }
    
    //Logged as member
    if (this.props.appRef.props.app.isMember()) {
      return (
        <Switch>
          <CustomRoute path={config.ApplicationRoutes.homepage} exact component={MemberDashboardView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.profile} exact component={MemberProfileView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.duesHistory} exact component={CommonMemberDuesHistoryView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.workHistory} exact component={CommonMemberWorkHistoryView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.memberDispatch} exact component={CommonMemberDispatchNotesView} appRef={this.props.appRef}/>
          <CustomRoute component={MemberDashboardView} appRef={this.props.appRef}/>
        </Switch>
      );
    }
    
    //Unknown state
    console.log("No routes to be served :/")
    return (<Switch></Switch>);
  }
}
