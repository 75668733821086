import Globals from '@/config/Globals';
//
export default class APIDuesBatch {
  constructor(API) {
    this.api = API;
  }
  async search(filter) {
    const req = await this.api.newBaseRequest('POST');
    req.path = '/employer/duesBatches/search';
    req.body = { ...filter };
    return await req.exec();
  }
  
  async getByID(employerID, batchID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = `/employer/${employerID}/duesBatch/${batchID}`;
    return await req.exec();
  }

  async create(batchDate, employerID) {
    const req = await this.api.newBaseRequest('POST');
    req.path = `/employer/${employerID}/duesBatch/new`;
    req.body = { postingDate: batchDate };
    return await req.exec();
  }

  // async update(noteObj) {
  //   const req = await this.api.newBaseRequest('PUT');
  //   req.path = Globals.APIPath_Note + noteObj.id;
  //   delete noteObj.id;
  //   req.body = { ...noteObj };
  //   return await req.exec();
  // }

  async remove(employerID, batchID) {
    const req = await this.api.newBaseRequest('DELETE');
    req.path = `/employer/${employerID}/duesBatch/${batchID}`;
    return await req.exec();
  }
}
