import Globals from '@/config/Globals';
//
export default class APIEmployers {
  constructor(API) {
    this.api = API;
  }

  async getAll() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Employers;
    return await req.exec();
  }

  async getByID(employerID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Employer + employerID;
    return await req.exec();
  }

  async getByIDMID(employerID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Employer + 'idmid/' + employerID;
    return await req.exec();
  }

  async create(employerObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Employer + Globals.URL_NewIndentifier;
    req.body = { ...employerObj };
    return await req.exec();
  }

  async update(employerObj, setResourcesToken) {
    const req = await this.api.newBaseRequest('PUT');
    if (setResourcesToken) {
      const resourcesToken = await this.api.idm.session.data.getResourcesToken();
      if (resourcesToken) req.appendHeader('ResourcesToken', resourcesToken);
    }
    req.path = Globals.APIPath_Employer + employerObj.id;
    delete employerObj.id;
    req.body = { ...employerObj };
    return await req.exec();
  }

  async activate(employerID) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Employer + 'activate/' + employerID;
    return await req.exec();
  }

  async search(searchObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Employers + 'search';
    req.body = { ...searchObj };
    return await req.exec();
  }
}
