import Globals from '@/config/Globals';
//
export default class APIDispatchSlipRun {
  constructor(API) {
    this.api = API;
  }
  async getAll() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_DispatchSlipsRun;
    return await req.exec();
  }
  async markAsPrinted(IDs) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_DispatchSlipsRun + '/markAsPrinted';
    req.body = { slips: IDs }
    return await req.exec();
  }

  async print(IDs) {
    const req = await this.api.newBaseRequest('POST');
    req.bodyType = 'JSON/BLOB';
    req.path = '/report' + Globals.APIPath_DispatchSlipsRun + '/print';
    req.body = { slips: IDs }
    return await req.exec();
  }
}
