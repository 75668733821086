import React from 'react';
import autoBind from 'react-autobind';
//
import { Menu, Avatar, Dropdown, Tag, Typography } from 'antd';
import { UserOutlined, ExportOutlined } from '@ant-design/icons';
//
export default class IDMUserBadge extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  render() {
    return (
      <Dropdown overlay={this._renderDropdownMenu()}>
        <Tag className="idmBadgeContainer">
          <Avatar justify="end" size={this.props.squeeze ? 30 : 40} className="idmBadgeUserIcon">
            <Typography.Text className="idmBadgeUserText">{this.props.user?.firstName?.substr(0, 1)}</Typography.Text>
          </Avatar>
        </Tag>
      </Dropdown>
    );
  }
  _renderDropdownMenu() {
    return (
      <Menu className="userPopoverList">
        <Menu.Item disabled>
          <UserOutlined />
          {this.props.user?.firstName + ' ' + this.props.user?.lastName}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item as="button" onClick={this.props.openProfile}>
          Profile
        </Menu.Item>
        <Menu.Item as="button" onClick={this.props.changePassword}>
          Change password
        </Menu.Item>
        <Menu.Item as="button" onClick={this.props.logoutHandler}>
          <ExportOutlined /> Logout
        </Menu.Item>
      </Menu>
    );
  }
}
