import React from "react";
import { Button, Row, Col, Form, Input } from 'antd';
import autoBind from 'react-autobind';
//
import Utils from '@/components/Utils';
//
export default class CommonRegistrationConfirmationForm extends React.Component {
    constructor(props) {
      super(props);
      autoBind(this);
    }
    async validatedForm() {
      const formData = await this.form.validateFields();
      if (formData) return formData;
      return null;
    }
    render() {
      return(
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')} className='registerConfirmationForm'>
          <Row type="flex" justify="center" align="middle">
            <Col span={22}>
              <Form.Item name="regCode" label="Confirmation code"
                rules={[ { required: true, message: 'Please, type the confirmation code!' }]}>
                <Input placeholder="Code" />
              </Form.Item>
            </Col>
          </Row>
          {this.props.dataSource.state.data.regToken == '' &&
            <Row type="flex" justify="center" align="middle">
              <Col span={22}>
                <Form.Item name="regToken" label="Confirmation token" 
                rules={[{ required: true, message: 'Please, type the confirmation token!' }]}>
                  <Input placeholder="Token" />
                </Form.Item>
              </Col>
            </Row>}

          <a className="resendConfirmationLink" onClick={this.props.onResend}>Haven't received the confirmation email?</a>
          <Row type="flex" justify="center" align="middle">
            <Button block className="registerConfirmationButton" type="primary" htmlType="submit" onClick={this.props.handleConfirmation}> Confirm </Button>
          </Row>
        </Form>
      );
    }
}
