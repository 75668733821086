import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button, message } from 'antd';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import Utils from '@/components/Utils';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import Globals from "@/config/Globals";
import config from "@/config/config";
//
import AdminAdminsForm from './AdminAdminForm';
//
export default class AdminAdminUpdateView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.adminID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    this.state = { isLoading: false, adminObj: null};
  }
  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Update Admin`;
    this._fetch();
  }
  //Actions
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) {
      //Check if changing password, if yes, prompt
      if (this.state.adminObj && this.state.adminObj.email != formData.email && this.state.adminObj.idmID) {
        const confirmation = await this.props.app.alertController.showPromptAlert('Attention!', `Admin email will be updated to "${formData.email}". Are you sure you want to update it? (type the new email address to confirm)`);
        if (confirmation != formData.email) return;
      }
      //Update
      this._update(formData);
    }
  }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Update Admin" onBack={() => window.history.back()} extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>Save</Button>]} />
        <Layout.Content>
          <WhiteBox>
            <AdminAdminsForm app={this.props.app} {...Utils.propagateRef(this, 'form')} />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }
  /* private API */
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.admins.getByID(this.adminID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.form.setFields(resp.body);
      this.setState({adminObj: resp.body, isLoading: false});
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ adminObj: null, isLoading: false });
    }
  }
  async _update(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.admins.update({
      id: this.adminID, ...data,
      country: Globals.DefaultAdminCountry,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Admin successfully updated!');
      await this._fetch();
      this.props.app.refreshConfig();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
