import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Form, Row, Col, Select, DatePicker, Button, Tag, Input, message, Checkbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { GrDocumentPdf, GrDocumentExcel } from "react-icons/gr";
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import config from "@/config/config";
import CommonEmployerSelectionModal from "@/views/commonComponents/CommonEmployerSelectionModal";
import Utils from "@/components/Utils";
import Globals from "@/config/Globals";
//
export default class AdminMembersDuesPostingReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      selectedEmployers: [],
    };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Dues Posting`;
  }

  handleSearchEmployer() {
    this.employerSelectionModal.show();
  }

  handleSelectEmployer(employer) {
    this.setState((prevState) => ({
      ...prevState,
      selectedEmployers: [...prevState.selectedEmployers, employer],
    }));
  }

  handleDeselectEmployer(removedEmployer) {
    this.setState((prevState) => ({
      ...prevState,
      selectedEmployers: prevState.selectedEmployers.filter((employer) => employer.id !== removedEmployer.id),
    }));
  }
  
  async handleSubmit() {
    const formData = await this.form.validateFields();

    if (formData) {
      formData.employerID = this.state.selectedEmployers.map((employer) => employer.id);
      formData.employerID = formData.employerID.length < 1 ? null : formData.employerID;

      const [from, to] = (formData.dateRange || []);
      formData.from = from?.format(Globals.DefaultAPIDateFormat) || null;
      formData.to = to?.format(Globals.DefaultAPIDateFormat) || null;
      delete formData.dateRange;

      const contentType = formData.fileFormat;
      delete formData.fileFormat;

      const validFilters = Object.keys(formData).filter((key) => !!formData[key]);
      if (validFilters.length < 1) {
        message.error('Your export will generate too many results, please select one or more filters before requesting the export.');
        return;
      }

      this._generate(formData, contentType);
    }
  }

  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonEmployerSelectionModal
          app={this.props.app}
          onSelect={this.handleSelectEmployer}
          disable={this.state.selectedEmployers.map((employer) => employer.id)}
          {...Utils.propagateRef(this, 'employerSelectionModal')}
        />

        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Dues Posting" extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>Generate Report</Button>]} />
        <Layout.Content>
          <WhiteBox>
            <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
              <Row>
                <Col>
                  <Form.Item label="Employers">
                    {this.state.selectedEmployers.length > 0 && (
                      this.state.selectedEmployers.map((employer) => (
                        <Tag
                          key={employer.id} closable
                          onClose={this.handleDeselectEmployer.bind(this, employer)}
                        >
                          {employer.name}
                        </Tag>
                      ))
                    )}
                    <Tag className="site-tag-plus" onClick={this.handleSearchEmployer}>
                      <PlusOutlined /> Select Employer
                    </Tag>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={4}>
                  <Form.Item name="fileFormat" label="File Format" rules={[{ required: true, message: 'This field is required.' }]}>
                    <Select placeholder="Select...">
                      <Select.Option value="application/pdf"><GrDocumentPdf style={{ marginRight: '10px' }} />PDF</Select.Option>
                      <Select.Option value="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"><GrDocumentExcel style={{ marginRight: '10px' }} />Excel</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                  <Form.Item name="sin" label="Member (SIN)">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item name="dateRange" label="From To Dates">
                    <DatePicker.RangePicker format={Globals.DefaultSimpleDateFormat} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                  <Form.Item name="hasInitFee" label="Initiation fee provided" valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  /* private API */
  async _generate(data, contentType) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.reports.duesPosting(data, contentType);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      Utils.downloadBlob(resp.body, `dues-posting-${moment(new Date()).format(Globals.DefaultSimpleDateFormat)}`, contentType === 'application/pdf' ? 'pdf' : 'xlsx');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.setState({ isLoading: false });
  }
}
