import React from "react";
import autoBind from 'react-autobind';
import { Button, Layout, message, PageHeader, Table } from "antd";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
import Globals from "@/config/Globals";
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
import WhiteBox from "@/views/commonComponents/WhiteBox";
import config from "@/config/config";
import AdminMemberOptions, { AdminMemberOptionTypes } from "@/views/commonComponents/AdminMemberOptions";
//
import CommonCreateTrainingHistoryDrawer from './CommonCreateTrainingHistoryDrawer';
import CommonEditTrainingHistoryDrawer from './CommonEditTrainingHistoryDrawer';
import Utils from "@/components/Utils";
import TableActions from "@/views/commonComponents/TableActions";
//
export default class CommonTrainingHistoryView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.memberName = this.props.app.urlManager.getQueryParam('n');
    this.memberID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);

    this.viewOptions = new AdminMemberOptions(AdminMemberOptionTypes.TRAINING, this.memberID, this.props.app, this.memberName);

    const { trainingCodes } = this.props.app.sharedCache().getConfig();

    this.state = {
      isLoading: false,
      sortedInfo: null,
      trainingCodes: trainingCodes || [],
      trainings: [],
    };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Training History`;
    this._fetch();
  }

  handleCreate() {
    this.createDrawer.show();
  }

  handleEditEntry(training) {
    this.updateDrawer.show(training.id);
  }

  handleDeleteEntry(training) {
    this._delete(training.id);
  }

  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  
  render() {
    const pageHeader = this.props.app.isAdmin()
      ? { onBack: () => window.history.back(), title: `${this.memberName || ''} Training History` } 
      : { title: 'Training History' };

    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const props = {
      onChange: this.handleFilterChange, rowKey: 'id', loading: this.state.isLoading,
    };

    const columns = [
      {
        title: 'Course', dataIndex: 'trainingID', key: 'trainingID',
        render: (trainingID) => {
          const training = this.state.trainingCodes.find((training) => training.id === trainingID);

          if (training) {
            return `${training.code} - ${training.name}`;
          }

          return null;
        },
        sorter: (a, b) => a.trainingID.localeCompare(b.trainingID),
        sortOrder: sortedInfo.columnKey === 'trainingID' && sortedInfo.order
      },
      { title: 'Training Date', dataIndex: 'trainingDate', key: 'trainingDate',
        render: date => date ? moment(date).format(Globals.DefaultUIBirthDateFormat) : '-',
        sorter: (a, b) => (new Date(a.trainingDate).getTime()) - (new Date(b.trainingDate).getTime()),
        sortOrder: sortedInfo.columnKey === 'trainingDate' && sortedInfo.order
      },
      {
        title: 'Actions', width: 200, key: 'Actions', align: 'right',
        render: props => {
          return (<span className='tableButtonContainer'>
            <TableActions
              options={[
                { alwaysVisible: true, label: 'Edit Entry', icon: EditOutlined, onClick: this.handleEditEntry.bind(this, props) },
                { 
                  alwaysVisible: true, label: 'Delete Entry', icon: DeleteOutlined,
                  confirm: {
                    message: 'Do you really want to delete this training?',
                    onConfirm: this.handleDeleteEntry.bind(this, props),
                  },
                },
              ]}
            />
          </span>);
        }
      },
    ];

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <CommonCreateTrainingHistoryDrawer app={this.props.app} memberID={this.memberID} onCreate={this._fetch} {...Utils.propagateRef(this, 'createDrawer')} />
        <CommonEditTrainingHistoryDrawer app={this.props.app} memberID={this.memberID} onUpdate={this._fetch} {...Utils.propagateRef(this, 'updateDrawer')} />
        <PageHeader {...pageHeader} extra={this.viewOptions.getOptions().concat([<Button key="btn" onClick={this.handleCreate} type='primary'>Create Training</Button>])} />
        <Layout.Content>
          <WhiteBox>
            <Table
              dataSource={this.state.trainings}
              columns={columns}
              {...props}
            />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    );
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.trainingHistory.getAll(this.memberID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body.trainings) {
      const trainings = resp.body.trainings.sort((a, b) => {
        return (new Date(b.trainingDate).getTime()) - (new Date(a.trainingDate).getTime())
      });

      this.setState({ trainings, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false });
    }
  }
  
  async _delete(trainingID) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.trainingHistory.remove(this.memberID, trainingID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Training successfully removed!');
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false });
    }
  }
}
