import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button, message } from 'antd';
import { Link } from "react-router-dom";
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import Utils from '@/components/Utils';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import Globals from "@/config/Globals";
import config from "@/config/config";
//
import MemberProfileForm from './MemberProfileForm';
//
export default class MemberProfileView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.memberID = this.props.app.sharedCache().getUser().id;
    this.state = { isLoading: false, memberObj: null };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Profile`;
    this._fetch();
  }
  
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) {
      //Check if changing email, if yes, prompt
      if (this.state.memberObj && this.state.memberObj.email != formData.email) {
        const confirmation = await this.props.app.alertController.showPromptAlert('Attention!', `Your email will be updated to "${formData.email}". Are you sure you want to update it? (type the new email address to confirm)`);
        if (confirmation != formData.email) return;
      }
      //update it
      this._update(formData);
    }
  }

  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader
          title="Profile"
          onBack={() => window.history.back()}
          extra={[
            <Button key="1" onClick={this.handleSubmit} type='primary'>Save</Button>,
          ]}
        />
        <Layout.Content>
          <WhiteBox>
            <MemberProfileForm editMode isProfile app={this.props.app} {...Utils.propagateRef(this, 'form')} />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.members.getByID(this.memberID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      console.log(resp.body);
      this.setState({ memberObj: resp.body, isLoading: false });
      this.form.setFields({
        ...resp.body,
        phone: Utils.formatPhone(resp.body.phone),
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false, memberObj: null });
    }
  }
  
  async _update(data) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.members.update({
      ...data,
      id: this.memberID,
      idmID: this.state.memberObj.idmID,
      middleName: this.state.memberObj.middleName,
      phone: Utils.sanitizePhone(data.phone),
    }, true);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      if (resp.body && resp.body.emailChangeConfirmationToken) {
        this.props.app.alertController.showSuccessAlert("", `Email updated with success. Please, confirm the new email by entering the code you just received!`);
        await this.props.app.idm.authenticator.logout();
        this.props.app.urlManager.pushPage(config.ApplicationRoutes.registrationConfirmation, null, resp.body.emailChangeConfirmationToken, data.email); //push to main page
      } else {
        message.success('Profile successfully updated!');
        this._fetch();
      }
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    } 
  }
}
