import React from "react";
import autoBind from 'react-autobind';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import config from '@/config/config';
//
import { Layout, PageHeader, Form, Row, Col, Input, InputNumber, Select, Button, message } from 'antd';
import Utils from "@/components/Utils";
const { Content } = Layout;
//
export default class AdminAppConfigView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      data: {},
    };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Application Configuration`;
    this._fetch();
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();
    formData.adminErUploadEmail = formData.adminErUploadEmail.join(',');
    this._update(formData);
  }

  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Application Configuration" onBack={() => window.history.back()} extra={[
          <Button key="save" type="primary" onClick={this.handleSubmit}>Save</Button>
        ]} />
        <Layout.Content>
          <WhiteBox>
            <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="adminErUploadEmail"
                    label="Electronic Remittance Notification Emails"
                  >
                    <Select
                      mode="tags"
                      placeholder="Please select"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={6}>
                  <Form.Item
                    name="localUnionNumber"
                    label="Local Union Number"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              
              <Row>
                <Col span={6}>
                  <Form.Item
                    name="financialSecretary"
                    label="Financial Secretary"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={6}>
                  <Form.Item
                    name="postingYear"
                    label="Posting Year"
                  >
                    <InputNumber style={{ width: '100%' }} max={9999} disabled />
                  </Form.Item>
                </Col>
              </Row>
              
              <Row>
                <Col span={6}>
                  <Form.Item
                    name="officialReceiptSequence"
                    label="Official Receipt"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={6}>
                  <Form.Item
                    name="miscellaneousReceiptSequence"
                    label="Miscellaneous Receipt"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.settings.getGeneral();
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      delete resp.body.transactionID;
      this.setState({ data: resp.body, isLoading: false });
      this.form.setFieldsValue({
        ...resp.body,
        adminErUploadEmail: resp.body.adminErUploadEmail ? resp.body.adminErUploadEmail.split(',').map(item => item.trim()) : [],
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  
  async _update(data) {
    this.startLoading();
    //
    delete data.miscellaneousReceiptSequence;
    delete data.officialReceiptSequence;
    //
    const resp = await this.props.app.api.settings.updateGeneral({
      ...this.state.data,
      ...data,
      postingYear: String(data.postingYear || ''),
      postingMonth: String(data.postingMonth || ''),
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Application Configuration successfully updated!');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading(true);
  }
}
