import React from 'react';
import autoBind from 'react-autobind';
import { Divider, Row, Button, Modal } from 'antd';
import SignatureCanvas from 'react-signature-canvas'
//
import '@/stylesheets/SignatureModal.scss';
import Utils from '@/components/Utils';
import CustomComponent from '@/components/CustomComponent';
//
export default class SignatureModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
  }
  //Actions
  handleCancel() { this.props.onCancel(); }
  handleSave() {
    const data = this.sigCanvas.getTrimmedCanvas().toDataURL();
    this.props.onSave(data);
  }
  handleSignatureEnd() { this.forceUpdate(); }
  clear() { this.sigCanvas.clear(); }
  //UI
  render() {
    return (
      <Modal maskClosable={false} closable={false} title="Signature"
             footer={null} visible={this.props.isVisible} width={550} confirmLoading={this.state.isLoading}>
        {this._renderContent()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }
  /* subcomponents */
  _renderContent() {
    return (
      <div className="commonStyleDivContainer">
        <SignatureCanvas penColor='black' canvasProps={{width: 500, height: 200, className: 'signatureCanvas'}} onEnd={this.handleSignatureEnd} {...Utils.propagateRef(this, 'sigCanvas')}/>
      </div>
    );
  }
  _renderButtonsFooter() {
    const safeEmpty = (this.sigCanvas ? this.sigCanvas.isEmpty() : true);
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button disabled={this.state.isLoading} key="back" onClick={this.handleCancel}> Cancel </Button>
        <Button disabled={this.state.isLoading || safeEmpty} key="save" type='primary' onClick={this.handleSave} className='signatureSaveButton'> Save </Button>
      </Row>
    );
  }
}
