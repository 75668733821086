import React from "react";
import autoBind from 'react-autobind';
//
import CustomComponent from '@/components/CustomComponent';
//
import { Form, Input, Row, Col, Checkbox, Divider } from 'antd';
import Utils from "@/components/Utils";
//
export default class AdminContactsForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  validateFields() {
    return this.form.validateFields();
  }

  resetFields() {
    return this.form.resetFields();
  }

  setFieldsValue(data) {
    return this.form.setFieldsValue(data);
  }

  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row>
          <Col span={8}>
            <Form.Item name="firstName" label="First name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item name="middleName" label="Middle name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item name="lastName" label="Last name">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        
        <Divider orientation="left">Address Information</Divider>
        <Row>
          <Col span={24}>
            <Form.Item name="street1" label="Address">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        
        <Row>
          <Col span={24}>
            <Form.Item name="street2" label="Address 2">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={3}>
            <Form.Item
              name="country"
              label="Country"
              rules={[{ required: true, message: 'This field is required!' }, { max: 2, message: 'Country must have only 2 characters' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item name="province" label="Province">
              <Input />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item name="city" label="City">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={7}>
            <Form.Item name="postalCode" label="Postal Code">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">Contact Information</Divider>

        <Row>
          <Col span={14}>
            <Form.Item name="email" label="Email">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item name="phone" label="Phone">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item name="altPhone" label="Alt Phone">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item name="fax" label="Fax">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}
