import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import autoBind from 'react-autobind';
import { Divider } from 'antd';
import { FileTextOutlined, HddOutlined, DollarOutlined, EditOutlined, ExperimentOutlined, DoubleRightOutlined } from '@ant-design/icons';
//
import config from '@/config/config';
import Globals from '@/config/Globals';
//
import '@/stylesheets/AdminMemberOptions.scss';
//
export const AdminMemberOptionTypes = {
  DUES: 0,
  DISPATCH_NOTES: 1,
  WORK_HISTORY: 2,
  MEMBER: 3,
  TRAINING: 4,
  NOTES: 5,
  //
  NONE: 99,
};
//
export default class AdminMemberOptions {
  constructor(selectedOption, memberID, app, optionalMemberName) {
    autoBind(this);
    this.selectedOption = selectedOption;
    this.memberObj = { id: memberID, name: (optionalMemberName || '') };
    this.app = app;
    this.isHiddingAll = false;
  }
  parseUrl(path, params) {
    if (params) {
      return this.app.urlManager.parseUrl(path, { n: encodeURIComponent(this.memberObj.name) }, ...params);
    }

    return this.app.urlManager.parseUrl(path, { n: encodeURIComponent(this.memberObj.name) }, this.memberObj.id);
  }
  getOptions() {
    const options = [];
    //Should hide all?
    if (this.isHiddingAll) return options;
    //Dues
      // TODO: add ACL here
      //this.app.acl.hasPermission(Globals.Components.MEMBER_DUES_ENTRIES_MANAGEMENT, Globals.AllLevels)
    if (this.selectedOption != AdminMemberOptionTypes.DUES && true) options.push(
      <Fragment key="1">
        <Link to={this.parseUrl(config.ApplicationRoutes.memberDuesHistory)} className='admMembLink'><DollarOutlined className='admMembLinkIcon' /><strong>Dues</strong></Link>
        <Divider type="vertical" className="admMembDivider" />
      </Fragment>
    );
    //Work History
    if (this.selectedOption != AdminMemberOptionTypes.WORK_HISTORY && this.app.acl.hasPermission(Globals.Components.MEMBER_DUES_ENTRIES_MANAGEMENT, Globals.AllLevels)) options.push(
      <Fragment key="2">
        <Link to={this.parseUrl(config.ApplicationRoutes.memberWorkHistory)} className='admMembLink'><HddOutlined className='admMembLinkIcon' /><strong>Work History</strong></Link>
        <Divider type="vertical" className="admMembDivider"/>
      </Fragment>
    );
    //Training
    if (this.selectedOption != AdminMemberOptionTypes.TRAINING && this.app.acl.hasPermission(Globals.Components.PERSON_TRAINING_MANAGEMENT, Globals.AllLevels)) options.push(
      <Fragment key="3">
        <Link to={this.parseUrl(config.ApplicationRoutes.trainingHistory)} className='admMembLink'><ExperimentOutlined className='admMembLinkIcon' /><strong>Training</strong></Link>
        <Divider type="vertical" className="admMembLinkDivider" />
      </Fragment>
    )
    //Notes
    if (this.selectedOption != AdminMemberOptionTypes.NOTES && this.app.acl.hasPermission(Globals.Components.NOTES_MANAGEMENT, Globals.AllLevels)) options.push(
      <Fragment key="4">
        <Link to={this.parseUrl(config.ApplicationRoutes.notes, [Globals.Notes_relTypes.member.routeAlias, this.memberObj.id])} className='admMembLink'><FileTextOutlined className='admMembLinkIcon' /><strong>Notes</strong></Link>
        <Divider type="vertical" className="admMembLinkDivider" />
      </Fragment>
    )
    //Dispatch notes
    if (this.selectedOption != AdminMemberOptionTypes.DISPATCH_NOTES && this.app.acl.hasPermission(Globals.Components.NOTES_MANAGEMENT, Globals.AllLevels)) options.push(
      <Fragment key="5">
        <Link to={this.parseUrl(config.ApplicationRoutes.memberDispatchNotes)} className='admMembLink'><DoubleRightOutlined className='admMembLinkIcon' /><strong>Dispatch Notes</strong></Link>
        <Divider type="vertical" className="admMembLinkDivider" />
      </Fragment>
    )
    //Profile
    if (this.selectedOption != AdminMemberOptionTypes.MEMBER && this.app.acl.hasPermission(Globals.Components.MEMBERS_MANAGEMENT, Globals.AllLevels)) options.push(
      <Fragment key="6">
        <Link to={this.parseUrl(config.ApplicationRoutes.memberUpdate)} className='admMembLink'><EditOutlined className='admMembLinkIcon' /><strong>Profile</strong></Link>
        <Divider type="vertical" className="admMembLinkDivider" />
      </Fragment>
    )
    return options;
  }
  //Public helpers
  hideAll() { this.isHiddingAll = true; }
  setMemberName(memberName) { this.memberObj = { ...this.memberObj, name: memberName || '' }; }
}
