import React from "react";
import autoBind from 'react-autobind';
import { Form, Row, Col, Select, DatePicker } from "antd";
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
import Utils from "@/components/Utils";
import Globals from "@/config/Globals";
//
export default class CommonCreateTrainingHistoryDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    const { trainingCodes } = this.props.app.sharedCache().getConfig();

    this.state = {
      trainingCodes: trainingCodes || [],
    };
  }

  resetFields() {
    this.form.resetFields();
  }

  setFieldsValue(data) {
    this.form.setFieldsValue({
      ...data,
      trainingDate: data.trainingDate ? moment(data.trainingDate) : null,
    });
  }

  async validateFields() {
    const formData = await this.form.validateFields();

    if (formData) {
      return {
        ...formData,
        trainingDate: formData.trainingDate ? moment(formData.trainingDate).format(Globals.DefaultAPIDateFormat) : null,
      };
    }

    return false;
  }
  
  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row>
          <Col span={12}>
            <Form.Item name="trainingID" label="Course" rules={[{ required: true, message: 'This field is required!' }]}>
              <Select placeholder="Select Training...">
                {this.state.trainingCodes.map((training) => (
                  <Select.Option key={training.id} value={training.id}>
                    {training.code} - {training.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item name="trainingDate" label="Training Date">
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}
