import React, { Component } from "react";
import autoBind from 'react-autobind';
//
import AppRoutes from "./AppRoutes";
//
import "./stylesheets/AppViewController.scss";
//
import { IDMGlobals } from "@ikonintegration/idmclient";
import config from "./config/config";
import Globals from "./config/Globals";
//
import { Layout, Alert } from 'antd';
import { isIE } from 'react-device-detect';
//
export default class UnauthorizedViewController extends Component {

  constructor(props) {
    super(props);
    autoBind(this);
    this.props.app.props.history.location.state = undefined; //reset history state if restarting app
    this.props.app.idm.sessionLoadErrorHandler = this.sessionLoadErrorHandler.bind(this);
  }
  //UI
  render() {
    return this.renderPage();
  }
  renderPage() {
    return (
      <Layout style={{height:"100vh"}}>
        {isIE && <Alert message={Globals.CompabilityMessage} banner />}
        <Layout.Content style={{height:"100%", overflowY: 'scroll'}}>
          <AppRoutes appRef={this}/>
        </Layout.Content>
      </Layout>
    );
  }

  //Handle login cases from User interaction and IDM clients redirect
  async sessionLoadErrorHandler(loginResp, username, isRenew) {
    if (loginResp.statusCode == 400) {
      this.props.app.alertController.showAPIErrorAlert(null, loginResp);
    } else if (loginResp.statusCode == 200 || loginResp.statusCode == 401) {
      const challengeState = (loginResp.body && loginResp.body.challengeState ? loginResp.body.challengeState :
                              (loginResp.body && loginResp.body.errCode ? loginResp.body.errCode : ''));
      //Success
      if (challengeState == IDMGlobals.AuthorizationChallengeState_Authorized) {
        this.props.app.alertController.showSuccessAlert("", `Welcome!`); //welcome message
        await this.props.app.sharedCache().loadCache(); //load app cache manually
        this.props.app.urlManager.pushPage(config.ApplicationRoutes.homepage); //push to main page
      }
      //Reset pass required
      else if (challengeState == IDMGlobals.AuthorizationChallengeState_PasswordResetRequired) {
        this.props.app.alertController.showWarningAlert('Could not login!', loginResp.body.message);
        this.props.app.urlManager.pushPage(config.ApplicationRoutes.resetPasswordConfirmation, null, username); //push to main page
      }
      //New pass required
      else if (challengeState == IDMGlobals.AuthorizationChallengeState_NewPasswordRequired) {
        this.props.app.alertController.showWarningAlert('Could not login!', loginResp.body.message);
        this.props.app.urlManager.pushPage(config.ApplicationRoutes.setupPassword, null, username, loginResp.body.challengeContext); //push to main page
      }
      //Confirmation required
      else if (challengeState == IDMGlobals.AuthorizationChallengeState_ConfirmationRequired) {
        this.props.app.alertController.showWarningAlert('Could not login!', loginResp.body.message);
        console.debug(loginResp)
        this.props.app.urlManager.pushPage(config.ApplicationRoutes.registrationConfirmation, null, loginResp.body.registrationToken, username); //push to main page
      }
      //Handling auth challenges errors
      else if (challengeState != IDMGlobals.AuthorizationChallengeState_Forbidden &&
               challengeState != IDMGlobals.AuthorizationChallengeState_NotFound) {
        this.props.app.alertController.showWarningAlert('Could not login!', loginResp.body.message);
        this.props.app.urlManager.pushPage(config.ApplicationRoutes.authError, null, null, null, loginResp.body); //push to main page
      }
      //Unhandled error
      else if (!isRenew) {
        this.props.app.alertController.showAPIErrorAlert("Login error!", loginResp);
      }
    } else { //probably connection error or server is offline :/
      this.props.app.alertController.showAPIErrorAlert("Unknown error!", JSON.stringify(loginResp));
    }
  }
}
