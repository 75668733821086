import React from "react";
import autoBind from 'react-autobind';
import { Modal, Row, Col, Input, Button, Divider, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
//
import CustomComponent from '@/components/CustomComponent';
//
export default class CommonMemberSelectionModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      isVisible: false,
      searchTerm: '',
      data: [],
    };
  }

  show() {
    this.setState({ isVisible: true });
  }
  
  setMember(memberID, args) {
    this._getByID(memberID, args);
  }

  handleClose() {
    this.setState({
      isVisible: false,
    });
  }

  handleChange(e) {
    this.setState({ searchTerm: e.target.value });
  }

  handleSearch() {
    this._search();
  }

  handleSelectItem(item) {
    if (this.props.onSelect) {
      this.props.onSelect(item);
    }

    this.handleClose();
  }

  //UI
  render() {
    const disabledMembers = this.props.disable || [];

    const columns = [
      { title: 'Name', dataIndex: 'fullname', key: 'fullname' },
      {
        title: '', key: 'select', align: 'right',
        render: (item) => (
          <Button
            type="primary"
            size="small"
            onClick={this.handleSelectItem.bind(this, item)}
            disabled={disabledMembers.includes(item.id)}
          >
            Select
          </Button>
        )
      }
    ];
    
    return (
      <Modal
        title="Search and select a Member"
        visible={this.state.isVisible}
        onCancel={this.handleClose}
        footer={null}
        width={700}
      >
        <Row>
          <Col span={19}>
            <Input prefix={<SearchOutlined />} size="large" onPressEnter={this.handleSearch}
                  placeholder="Search..." value={this.state.searchTerm} onChange={this.handleChange} autoFocus />
          </Col>
          <Col offset={1}>
            <Button size="large" type="primary" onClick={this.handleSearch}>Search</Button>
          </Col>
        </Row>

        <Divider />
        
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={this.state.data}
              rowKey="id"
              loading={this.state.isLoading}
            />
          </Col>
        </Row>
      </Modal>
    )
  }

  // API Calls
  async _search() {
    if (!this._isMounted) return;
    //
    this.setState({ data: [], isLoading: true });
    //request
    const resp = await this.props.app.api.members.search({
      term: this.state.searchTerm || '',
      filter: {},
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.members) {
      this.setState({ data: resp.body.members, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  
  async _getByID(memberID, args) {
    if (!this._isMounted) return;
    //
    this.setState({ isLoading: true });
    //request
    const resp = await this.props.app.api.members.getByID(memberID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      if (this.props.onSelect) {
        this.props.onSelect(resp.body, args);
      }

      this.setState({ isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
