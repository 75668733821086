import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Form, Row, Col, Select, Button, Checkbox } from 'antd';
import { GrDocumentPdf, GrDocumentExcel } from "react-icons/gr";
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import config from "@/config/config";
import Utils from "@/components/Utils";
//
export default class AdminMemberDispatchListReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    const { dispatchLists, unitsNumbers } = this.props.app.sharedCache().getConfig();

    this.state = {
      isLoading: false,
      dispatchLists: dispatchLists || [],
      units: unitsNumbers || [],
      allUnits: false,
    };
  }
  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Dispatch List`;
  }
  //Actions
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) {
      const contentType = formData.fileFormat;
      delete formData.fileFormat;
      this._generate(formData, contentType);
    }
  }
  handleChangeAllUnits({ target: { checked } }) {
    if (checked) {
      this.setState({ allUnits: true });
      this.form.setFieldsValue({
        unitID: [],
      });
    } else {
      this.setState({ allUnits: false });
    }
  }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Dispatch List" extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>Generate Report</Button>]} />
        <Layout.Content>
          <WhiteBox>
            <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
              <Row>
                <Col span={4}>
                  <Form.Item name="fileFormat" label="File Format" rules={[{ required: true, message: 'This field is required.' }]}>
                    <Select placeholder="Select...">
                      <Select.Option value="application/pdf"><GrDocumentPdf style={{marginRight: '10px'}}/>PDF</Select.Option>
                      <Select.Option value="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"><GrDocumentExcel style={{ marginRight: '10px' }}/>Excel</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item name="dispatchListID" label="Dispatch List" rules={[{required: true, message: 'Please, select a dispatch list!'}]}>
                    <Select placeholder="Select...">
                      {this.state.dispatchLists.map((list) => (
                        <Select.Option key={list.id} value={list.id}>{list.number} - {list.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item name="unitID" label="Units">
                    <Select placeholder="Select..." mode="multiple" disabled={this.state.allUnits}>
                      {this.state.units.map((unit) => (
                        <Select.Option key={unit.id} value={unit.id}>{unit.number} - {unit.description}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: 10 }}>
                  <Form.Item label=" ">
                    <Checkbox onChange={this.handleChangeAllUnits}>All units</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  /* private API */
  async _generate(data, contentType) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.reports.dispatchList(data, contentType);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      const dispatchList = this.state.dispatchLists.find((d) => d.id == data.dispatchListID);
      Utils.downloadBlob(resp.body, `DispatchList - ${dispatchList.name}`, contentType === 'application/pdf' ? 'pdf' : 'xlsx');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.setState({ isLoading: false });
  }
}
