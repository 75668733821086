import React from "react";
import autoBind from 'react-autobind';
import { Link } from "react-router-dom";
import { Layout, Menu, Alert, Divider, Typography, Row } from 'antd';
import {
  TeamOutlined, SettingOutlined, CloudUploadOutlined, FileTextOutlined, ContactsOutlined,
  ContainerOutlined, DoubleRightOutlined, MenuOutlined, CloseOutlined, DollarOutlined,
} from '@ant-design/icons';
import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import { RiAdminLine } from 'react-icons/ri'

//
import { isIE } from 'react-device-detect';
//
import AppRoutes from "@/AppRoutes";
import CustomComponent from '@/components/CustomComponent';
//
import CommonChangePasswordModal from "@/views/commonComponents/CommonChangePasswordModal";
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import Logo from '@/views/commonComponents/Logo';
import ACLComponent from '@/components/ACL/ACLComponent';
import MenuBadge from '@/views/commonComponents/MenuBadge';
import IDMUserBadge from './views/commonComponents/IDMUserBadge';
//
import config from "@/config/config";
//
import "@/stylesheets/AppViewController.scss";
import Globals from "./config/Globals";
//
export default class AuthorizedViewController extends CustomComponent {

  constructor(props) {
    super(props);
    autoBind(this);
    this.props.app.props.history.location.state = undefined; //reset history state if restarting app
    this.handleLogout = this.handleLogout.bind(this);
    document.title = config.ApplicationName;
    this.state = {
      collapsed: false, isLoading: false,
      isSidebarVisible: true, userObj: null
    };
  }
  //Life cycle
  async componentDidMount() {
    super.componentDidMount();
    //Collapse logic
    if (window.innerWidth < 940) this.setState({ collapsed: true, isSidebarVisible: window.innerWidth > 650 });
    //Support future apps asking change pass
    if (this.props.app.changingPassword) {
      this.props.app.changingPassword = false;
      if (this.changePassModal) this.changePassModal.handleShow(true);
    }
    //Get user obj
    const userObj = await this.props.app.idm.session.data.getUserObject();
    this.setState({ userObj });
  }
  //Actions
  async handleLogout() {
    this.startLoading();
    await this.props.app.idm.authenticator.logout();
    this.props.app.urlManager.pushPage("/");
  }
  handleChangePassword() { this.changePassModal.handleShow(); }
  handleOpenProfile() { this.props.app.urlManager.pushPage(config.ApplicationRoutes.profile) }
  //Collapsable siderbar
  onCollapse() { this.setState({ collapsed: !this.state.collapsed }); };
  toggleShowSidebar() { this.setState({ isSidebarVisible: !this.state.isSidebarVisible }); };
  //UI
  render() {
    return this.renderPage();
  }
  renderPage() {
    return (
      <Layout className="container-layout">
        {isIE && <Alert message={Globals.CompabilityMessage} banner />}
        {this._renderHeader()}
        <Layout>
          {this._renderUserNavigationBar()}
          <Layout>
            <Layout.Content className="main-content">
              <CommonLoadingView isLoading={this.state.isLoading} />
              <AppRoutes appRef={this} />
            </Layout.Content>
            {this._renderChangePasswordModal()}
          </Layout>
        </Layout>
      </Layout>
    );
  }
  _renderHeader() {
    const ibewUser = this.props.app.sharedCache().getUser() || {};
    const { departments = [] } = this.props.app.sharedCache().getConfig();
    const userDepartment = departments.find((department) => department.id == ibewUser.departmentID) || {};
    const { pathname } = window.location;
    const squeeze = pathname.includes('/member/update/') || pathname.includes('/member/new');
    
    return (
      <Layout.Header className={`appHeader ${squeeze ? 'squeeze' : ''}`}>
        <div className="headerContainer">
          <div style={{ display: 'flex' }}>
            <div className="mobileHeaderMenu">
              {!this.state.isSidebarVisible && (
                <button type="button" onClick={this.toggleShowSidebar} className="menuIcon">
                  <MenuOutlined style={{ color: '#fff' }} />
                </button>
              )}
              {this.state.isSidebarVisible && (
                <button type="button" onClick={this.toggleShowSidebar} className="menuIcon">
                  <CloseOutlined style={{ color: '#fff' }} />
                </button>
              )}
            </div>

            <Logo isHeading={true} squeeze={squeeze} />
            {!squeeze && <div className='lighthingHeader'></div>}
          </div>

          <div className="userInfo">
            <div className="details">
              <span>{this.props.app.isEmployer() ? ibewUser.name : ibewUser.firstName + ' ' + ibewUser.lastName}</span>
              {!squeeze && this.props.app.isAdmin() && <strong>Department: {userDepartment.name}</strong>}
            </div>
            {this.props.app.idm.isLogged() && (
              <IDMUserBadge squeeze={squeeze} user={this.state.userObj} openProfile={this.handleOpenProfile}
                changePassword={this.handleChangePassword} logoutHandler={this.handleLogout} />
            )}
          </div>
        </div>
      </Layout.Header>
    );
  }
  _renderUserNavigationBar() {
    // this should not happen, but it take our precautions :p
    if (!this.props.app.idm.isLogged()) return <></>;
    return this.state.isSidebarVisible ? this._renderPrivilegedNavigation() : null;
  }
  _renderPrivilegedNavigation() {
    const ibewUser = this.props.app.sharedCache().getUser() || {};
    const pathName = this.props.app.props.history.location.pathname;
    //
    if (this.props.app.isAdmin()) {
      return (
        <Layout.Sider theme="light" width={220} className="sideNavigation" collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
          <Row className="sideNavigationMenu" type='flex' style={{ height: '100%', alignContent: 'space-between' }}>
          <Menu mode="inline" selectedKeys={[pathName]}>
              <ACLComponent key={config.ApplicationRoutes.adminRemittances} component={Menu.Item} acl={this.props.app.acl}
                componentID={Globals.Components.EMPLOYER_ELECTRONIC_REMITTANCE} accessLevel={Globals.AllLevels}>
                <MenuBadge count={this.props.app.sharedCache().getPendingImportCount()} />
                <CloudUploadOutlined />
                <span>Remittances</span>
                <Link to={config.ApplicationRoutes.adminRemittances}></Link>
              </ACLComponent>

              <ACLComponent key="Dues" component={Menu.SubMenu} acl={this.props.app.acl}
                title={<span><DollarOutlined /> <span>Dues</span> </span>}
                validationMode="any" accessLevel={Globals.AllLevels} componentID={[
                  Globals.Components.EMPLOYER_DUES_BATCHES_MANAGEMENT,
                  Globals.Components.CURRENT_YEAR_ROLLOVER,
                ]}>
                <ACLComponent key={config.ApplicationRoutes.duesBatches} component={Menu.Item} acl={this.props.app.acl}
                  componentID={Globals.Components.EMPLOYER_DUES_BATCHES_MANAGEMENT} accessLevel={Globals.AllLevels}>
                  <span>Dues Batches</span>
                  <Link to={config.ApplicationRoutes.duesBatches}></Link>
                </ACLComponent>
                <ACLComponent key={config.ApplicationRoutes.resetYTD} component={Menu.Item} acl={this.props.app.acl}
                  componentID={Globals.Components.CURRENT_YEAR_ROLLOVER} accessLevel={Globals.AllLevels}>
                  <span>Reset YTD</span>
                  <Link to={config.ApplicationRoutes.resetYTD}></Link>
                </ACLComponent>
              </ACLComponent>
              

              <ACLComponent key={config.ApplicationRoutes.dispatchJob} component={Menu.Item} acl={this.props.app.acl}
                componentID={Globals.Components.DISPATCH_JOBS_MANAGEMENT} accessLevel={Globals.AllLevels}>
                <DoubleRightOutlined />
                <span>Dispatch</span>
                <Link to={config.ApplicationRoutes.dispatchJob}></Link>
              </ACLComponent>

              <Divider orientation='left'>
                {!this.state.collapsed && <Typography.Text style={{ opacity: .3 }}>Users</Typography.Text>}
              </Divider>

              <ACLComponent key={config.ApplicationRoutes.employers} component={Menu.Item} acl={this.props.app.acl}
                componentID={Globals.Components.EMPLOYERS_MANAGEMENT} accessLevel={Globals.AllLevels}>
                <HiOutlineOfficeBuilding className='customSideIcon'/>
                <span className='nav-text'>Employers</span>
                <Link to={config.ApplicationRoutes.employers}></Link>
              </ACLComponent>

              {/* TODO - ADD ACLS */}
              <ACLComponent key={config.ApplicationRoutes.members} component={Menu.Item} acl={this.props.app.acl}>
                <TeamOutlined />
                <span>Members</span>
                <Link to={config.ApplicationRoutes.members}></Link>
              </ACLComponent>
              
              <ACLComponent key={config.ApplicationRoutes.applications} component={Menu.Item}
                componentID={Globals.Components.MEMBERSHIP_APPLICATION} acl={this.props.app.acl} accessLevel={Globals.AllLevels}>
                <MenuBadge count={this.props.app.sharedCache().getApplicationsCount()} />
                <FileTextOutlined />
                <span>Applications</span>
                <Link to={config.ApplicationRoutes.applications}></Link>
              </ACLComponent>

              <ACLComponent key={config.ApplicationRoutes.admins} component={Menu.Item} acl={this.props.app.acl}
                componentID={Globals.Components.ADMINS_MANAGEMENT} accessLevel={Globals.AllLevels}>
                <RiAdminLine className='customSideIcon' />
                <span className='nav-text'>Administrators</span>
                <Link to={config.ApplicationRoutes.admins}></Link>
              </ACLComponent>

              <Divider></Divider>
              {/* TODO - change ACLs to be done by report */}
              <ACLComponent key="Reports" component={Menu.SubMenu} acl={this.props.app.acl}
                title={<span><ContainerOutlined /> <span>Reports</span> </span>}
                validationMode="any" accessLevel={Globals.AllLevels} componentID={[
                  Globals.Components.REPORT_MEMBERS_BY_EMPLOYER,
                  Globals.Components.REPORT_MEMBERS_BY_CLASSIFICATION,
                  Globals.Components.REPORT_MEMBERS_BY_SECTOR,
                  Globals.Components.REPORT_MEMBERS_LABEL,
                  Globals.Components.REPORT_EMPLOYERS_LABEL,
                  Globals.Components.REPORT_SHOP_STEWARDS,
                  Globals.Components.REPORT_MEMBER_ARREARS,
                  Globals.Components.REPORT_MEMBER_DISPATCH_LIST,
                  Globals.Components.REPORT_MEMBERS_CUSTOM,
                  Globals.Components.REPORT_DUES_POSTING,
                  Globals.Components.REPORT_TAX_RECEIPTS,
                  Globals.Components.REPORT_IO,
                  Globals.Components.REPORT_ACTIVE_MEMBERS,
                  Globals.Components.REPORT_MEMBERS_DUES_YTD
                ]}>
                <ACLComponent component={Menu.ItemGroup} acl={this.props.app.acl} key="member" title="Member"
                  validationMode="any" accessLevel={Globals.AllLevels} componentID={[
                    Globals.Components.REPORT_MEMBERS_BY_EMPLOYER,
                    Globals.Components.REPORT_MEMBERS_BY_CLASSIFICATION,
                    Globals.Components.REPORT_MEMBERS_BY_SECTOR,
                    Globals.Components.REPORT_MEMBERS_LABEL
                  ]}>
                  <ACLComponent key={config.ApplicationRoutes.reportMembersLabel} component={Menu.Item} acl={this.props.app.acl}
                    componentID={Globals.Components.REPORT_MEMBERS_LABEL} accessLevel={Globals.AllLevels}>
                    <span>Labels</span>
                    <Link to={config.ApplicationRoutes.reportMembersLabel}></Link>
                  </ACLComponent>
                  <ACLComponent key={config.ApplicationRoutes.reportMembersList} component={Menu.Item} acl={this.props.app.acl}
                    componentID={[
                      Globals.Components.REPORT_MEMBERS_BY_EMPLOYER,
                      Globals.Components.REPORT_MEMBERS_BY_CLASSIFICATION,
                      Globals.Components.REPORT_MEMBERS_BY_SECTOR,
                    ]} accessLevel={Globals.AllLevels} validationMode="any">
                    <span>Lists</span>
                    <Link to={config.ApplicationRoutes.reportMembersList}></Link>
                  </ACLComponent>
                  <ACLComponent key={config.ApplicationRoutes.reportCustomMembersReport} component={Menu.Item} acl={this.props.app.acl}
                    componentID={Globals.Components.REPORT_MEMBERS_CUSTOM} accessLevel={Globals.AllLevels}>
                    <span>Custom</span>
                    <Link to={config.ApplicationRoutes.reportCustomMembersReport}></Link>
                  </ACLComponent>
                  <ACLComponent key={config.ApplicationRoutes.ioPerCapita} component={Menu.Item} acl={this.props.app.acl}
                    componentID={Globals.Components.REPORT_IO} accessLevel={Globals.AllLevels}>
                    <span>IO Per Capita</span>
                    <Link to={config.ApplicationRoutes.ioPerCapita}></Link>
                  </ACLComponent>
                  <ACLComponent key={config.ApplicationRoutes.reportActiveMembers} component={Menu.Item} acl={this.props.app.acl}
                    componentID={Globals.Components.REPORT_ACTIVE_MEMBERS} accessLevel={Globals.AllLevels}>
                    <span>Active Members</span>
                    <Link to={config.ApplicationRoutes.reportActiveMembers}></Link>
                  </ACLComponent>
                </ACLComponent>

                <ACLComponent component={Menu.ItemGroup} acl={this.props.app.acl} key="employer" title="Employer"
                  validationMode="any" accessLevel={Globals.AllLevels} componentID={[
                    Globals.Components.REPORT_SHOP_STEWARDS,
                    Globals.Components.REPORT_EMPLOYERS_LABEL,
                  ]}>
                  <ACLComponent key={config.ApplicationRoutes.reportEmployersLabel} component={Menu.Item} acl={this.props.app.acl}
                    componentID={Globals.Components.REPORT_EMPLOYERS_LABEL} accessLevel={Globals.AllLevels}>
                    <span>Labels</span>
                    <Link to={config.ApplicationRoutes.reportEmployersLabel}></Link>
                  </ACLComponent>
                  {/* <Menu.Item key="8">Lists</Menu.Item> */}
                  <ACLComponent key={config.ApplicationRoutes.reportShopStewards} component={Menu.Item} acl={this.props.app.acl}
                    componentID={Globals.Components.REPORT_SHOP_STEWARDS} accessLevel={Globals.AllLevels}>
                    <span>Shop Stewards</span>
                    <Link to={config.ApplicationRoutes.reportShopStewards}></Link>
                  </ACLComponent>
                </ACLComponent>
                <ACLComponent component={Menu.ItemGroup} acl={this.props.app.acl} key="dues" title="Dues"
                  validationMode="any" accessLevel={Globals.AllLevels} componentID={[
                    Globals.Components.REPORT_MEMBER_ARREARS
                  ]}>
                  <ACLComponent key={config.ApplicationRoutes.reportArrearsLetters} component={Menu.Item} acl={this.props.app.acl}
                    componentID={Globals.Components.REPORT_MEMBER_ARREARS} accessLevel={Globals.AllLevels}>
                    <span>Arrears Letters</span>
                    <Link to={config.ApplicationRoutes.reportArrearsLetters}></Link>
                  </ACLComponent>
                  <ACLComponent key={config.ApplicationRoutes.reportMembersArrears} component={Menu.Item} acl={this.props.app.acl}
                    componentID={Globals.Components.REPORT_MEMBER_ARREARS} accessLevel={Globals.AllLevels}>
                    <span>Arrears</span>
                    <Link to={config.ApplicationRoutes.reportMembersArrears}></Link>
                  </ACLComponent>
                  <ACLComponent key={config.ApplicationRoutes.reportMembersDuesPosting} component={Menu.Item} acl={this.props.app.acl}
                    componentID={Globals.Components.REPORT_DUES_POSTING} accessLevel={Globals.AllLevels}>
                    <span>Dues Posting</span>
                    <Link to={config.ApplicationRoutes.reportMembersDuesPosting}></Link>
                  </ACLComponent>
                  <ACLComponent key={config.ApplicationRoutes.reportTaxReceipts} component={Menu.Item} acl={this.props.app.acl}
                    componentID={Globals.Components.REPORT_TAX_RECEIPTS} accessLevel={Globals.AllLevels}>
                    <span>Tax</span>
                    <Link to={config.ApplicationRoutes.reportTaxReceipts}></Link>
                  </ACLComponent>
                  <ACLComponent key={config.ApplicationRoutes.reportMembersDuesYTD} component={Menu.Item} acl={this.props.app.acl}
                    componentID={Globals.Components.REPORT_MEMBERS_DUES_YTD} accessLevel={Globals.AllLevels}>
                    <span>Dues YTD</span>
                    <Link to={config.ApplicationRoutes.reportMembersDuesYTD}></Link>
                  </ACLComponent>
                </ACLComponent>

                <ACLComponent component={Menu.ItemGroup} acl={this.props.app.acl} key="dispatch" title="Dispatch"
                  validationMode="any" accessLevel={Globals.AllLevels} componentID={[
                    Globals.Components.REPORT_MEMBER_DISPATCH_LIST,
                    Globals.Components.REPORT_DISPATCH_SLIP,
                    Globals.Components.REPORT_EMPLOYMENT_BY_CLASSIFICATION,
                  ]}>
                  <ACLComponent key={config.ApplicationRoutes.reportDispatchList} component={Menu.Item} acl={this.props.app.acl}
                    componentID={Globals.Components.REPORT_MEMBER_DISPATCH_LIST} accessLevel={Globals.AllLevels}>
                    <span>Dispatch List</span>
                    <Link to={config.ApplicationRoutes.reportDispatchList}></Link>
                  </ACLComponent>
                  <ACLComponent key={config.ApplicationRoutes.reportDispatchSlip} component={Menu.Item} acl={this.props.app.acl}
                    componentID={Globals.Components.REPORT_DISPATCH_SLIP} accessLevel={Globals.AllLevels}>
                    <span>Slip</span>
                    <Link to={config.ApplicationRoutes.reportDispatchSlip}></Link>
                  </ACLComponent>
                  <ACLComponent key={config.ApplicationRoutes.reportEmploymentByClassification} component={Menu.Item} acl={this.props.app.acl}
                    componentID={Globals.Components.REPORT_EMPLOYMENT_BY_CLASSIFICATION} accessLevel={Globals.AllLevels}>
                    <span>Employment by Classification</span>
                    <Link to={config.ApplicationRoutes.reportEmploymentByClassification}></Link>
                  </ACLComponent>
                </ACLComponent>
              </ACLComponent>
            </Menu>
            <Menu mode="inline" selectedKeys={[pathName]}>
              <ACLComponent key={config.ApplicationRoutes.adminSettings} component={Menu.Item} acl={this.props.app.acl}>
                <SettingOutlined />
                <span>Settings</span>
                <Link to={config.ApplicationRoutes.adminSettings}></Link>
              </ACLComponent>
            </Menu>
          </Row>
        </Layout.Sider>
      );
    }

    if (this.props.app.isEmployer()) {
      return (
        <Layout.Sider theme="light" className="sideNavigation" collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
          <Menu mode="inline" className="sideNavigationMenu" selectedKeys={[pathName]} style={{ height: '100%' }}>
            <ACLComponent
              key={config.ApplicationRoutes.remittances}
              component={Menu.Item}
              acl={this.props.app.acl}
            >
              <CloudUploadOutlined />
              <span>Remittances</span>
              <Link to={config.ApplicationRoutes.remittances}></Link>
            </ACLComponent>
            {ibewUser.dispatchEnabled && (
              <ACLComponent
                key={config.ApplicationRoutes.dispatchJob}
                component={Menu.Item}
                acl={this.props.app.acl}
              >
                <DoubleRightOutlined />
                <span>Dispatch</span>
                <Link to={config.ApplicationRoutes.dispatchJob}></Link>
              </ACLComponent>
            )}
          </Menu>
        </Layout.Sider>
      );
    }

    if (this.props.app.isMember()) {
      return (
        <Layout.Sider theme="light" className="sideNavigation" collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
          <Menu mode="inline" className="sideNavigationMenu" selectedKeys={[pathName]} style={{ height: '100%' }}>
            <Menu.Item key={config.ApplicationRoutes.duesHistory}>
              <DollarOutlined />
              <span>Dues History</span>
              <Link to={config.ApplicationRoutes.duesHistory}></Link>
            </Menu.Item>
            
            <Menu.Item key={config.ApplicationRoutes.workHistory}>
              <ContactsOutlined />
              <span>Work History</span>
              <Link to={config.ApplicationRoutes.workHistory}></Link>
            </Menu.Item>
            
            <Menu.Item key={config.ApplicationRoutes.memberDispatch}>
              <DoubleRightOutlined />
              <span>Dispatch</span>
              <Link to={config.ApplicationRoutes.memberDispatch}></Link>
            </Menu.Item>
          </Menu>
        </Layout.Sider>
      );
    }
    
    return (<></>);
  }
  //Extra UI
  _renderChangePasswordModal() {
    return (<CommonChangePasswordModal ref={f => (this.changePassModal = f)} app={this.props.app} />);
  }
}
