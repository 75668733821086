import React from "react";
import autoBind from 'react-autobind';
import { Layout, PageHeader, Form, Row, Col, Select, DatePicker, Button } from 'antd';
import { GrDocumentPdf, GrDocumentExcel } from "react-icons/gr";
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import config from "@/config/config";
import Utils from "@/components/Utils";
import Globals from '@/config/Globals';
//
export default class AdminMembersArrearsReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
  }
  //Live cycle
  componentDidMount() { document.title = `${config.ApplicationName} - Members Arrears`; }
  //Actions
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) {
      const date = formData.arrearsDate ? moment(formData.arrearsDate).format(Globals.DefaultSimpleDateFormat) : null;
      const contentType = formData.fileFormat;
      this._generate({ arrearsDate: date }, contentType);
    }
  }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Members Arrears" extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>Generate Report</Button>]} />
        <Layout.Content>
          <WhiteBox>
            <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
              <Row>
                <Col span={4}>
                  <Form.Item name="fileFormat" label="File Format" rules={[{ required: true, message: 'This field is required.' }]}>
                    <Select placeholder="Select...">
                      <Select.Option value="application/pdf"><GrDocumentPdf style={{ marginRight: '10px' }} />PDF</Select.Option>
                      <Select.Option value="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"><GrDocumentExcel style={{ marginRight: '10px' }} />Excel</Select.Option>
                      <Select.Option value="application/importFile"><GrDocumentExcel style={{ marginRight: '10px' }} />Import File</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                  <Form.Item name="arrearsDate" label="Arrears Date">
                    <DatePicker format={Globals.DefaultSimpleDateFormat} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  /* private API */
  async _generate(data, contentType) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.reports.membersArrears(data, contentType);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      Utils.downloadBlob(resp.body, `Member Arrears - ${moment(new Date()).format('MMYY')}`, contentType === 'application/pdf' ? 'pdf' : 'xlsx');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.setState({ isLoading: false });
  }
}
