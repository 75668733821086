import React from "react";
import { Drawer, Row, Col, Button, message } from 'antd';
import autoBind from 'react-autobind';
//
import CustomComponent from '@/components/CustomComponent';
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
import Utils from "@/components/Utils";
//
import AdminApplicationsForm from './AdminApplicationsForm';
//
export default class AdminEditApplicationsDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isVisible: false,
      isLoading: false,
      applicationID: null,
    };
  }

  show(applicationID) {
    this.setState({ isVisible: true, applicationID }, this._fetch);
  }

  handleClose() {
    this.form.resetState();
    this.setState({
      isVisible: false,
      isLoading: false,
      applicationID: null,
    });
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();

    if (formData) {
      this._submit(formData);
    }
  }

  // UI
  render() {
    return (
      <Drawer
        visible={this.state.isVisible}
        title="Edit Application"
        placement="right"
        onClose={this.handleClose}
        width={750}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <AdminApplicationsForm editMode {...Utils.propagateRef(this, 'form')} />
        <Row>
          <Col>
            <Button type="primary" onClick={this.handleSubmit}>Save</Button>
          </Col>
        </Row>
      </Drawer>
    )
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //
    // request
    const resp = await this.props.app.api.applications.getByID(this.state.applicationID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.form.setFieldsValue(resp.body);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.setState({ isLoading: false });
  }

  async _submit(data) {
    this.startLoading();
    // request
    const resp = await this.props.app.api.applications.update(this.state.applicationID, data);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.handleClose();
      message.success('Application successfully updated!');
      if (this.props.onUpdate) this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, applicationResp);
      this.setState({ isLoading: false });
    }
  }
}
