import Globals from '@/config/Globals';
//
export default class APIClassifications {
  constructor(API) {
    this.api = API;
  }

  async getAll() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Classifications;
    return await req.exec();
  }

  async create(classificationObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Classification + Globals.URL_NewIndentifier;
    req.body = { ...classificationObj };
    return await req.exec();
  }

  async update(classificationObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_Classification + classificationObj.id;
    delete classificationObj.id;
    req.body = { ...classificationObj };
    return await req.exec();
  }

  async remove(classificationID) {
    const req = await this.api.newBaseRequest('DELETE');
    req.path = Globals.APIPath_Classification + classificationID;
    return await req.exec();
  }
}
