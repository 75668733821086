import Globals from '@/config/Globals';
//
export default class APISchedules {
  constructor(API) {
    this.api = API;
  }

  async getAll() {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Schedules;
    return await req.exec();
  }

  async getByID(scheduleID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Schedule + scheduleID;
    return await req.exec();
  }

  async create(scheduleObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Schedule + Globals.URL_NewIndentifier;
    req.body = { ...scheduleObj };
    return await req.exec();
  }

  async update(scheduleObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_Schedule + scheduleObj.id;
    delete scheduleObj.id;
    req.body = { ...scheduleObj };
    return await req.exec();
  }

  async remove(scheduleID) {
    const req = await this.api.newBaseRequest('DELETE');
    req.path = Globals.APIPath_Schedule + scheduleID;
    return await req.exec();
  }
}
