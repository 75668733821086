import React from "react";
import autoBind from 'react-autobind';
import { Form, Row, Col, Input, Select, Divider, DatePicker, Checkbox, Upload, Button, message, Radio } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
import Utils from "@/components/Utils";
import Globals from "@/config/Globals";
//
export default class AdminApplicationsForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      industryOther: false,
      becomeIbewOther: false,
      signature: null,
    };
  }

  resetState() {
    this.setState({
      industryOther: false,
      becomeIbewOther: false,
      signature: null,
    });
  }

  handleChangeIndustry(value) {
    if (value == 'Other') {
      this.setState({ industryOther: true });
    } else {
      this.form.setFieldsValue({ industryOther: '' });
      this.setState({ industryOther: false });
    }
  }

  handleChangeBecomeIbew(value) {
    if (value == 'Other') {
      this.setState({ becomeIbewOther: true });
    } else {
      this.form.setFieldsValue({ becomeIbewOther: '' });
      this.setState({ becomeIbewOther: false });
    }
  }

  handleSelectFile({ file }) {
    if (!file) return;
    
    let ext = file.name?.split('.');
    ext = ext[ext?.length - 1];
    if (ext?.toLowerCase() !== 'png') {
      message.error('Only .PNG files are accepted!');
      return;
    }

    this.setState({ signature: file });
  }

  async validateFields() {
    if (!this.props.editMode && !this.state.signature) {
      message.error('Provide the member signature before submit!');
    }

    const formData = await this.form.validateFields();

    if ((!this.props.editMode && formData && this.state.signature) || (this.props.editMode && formData)) {
      const membershipReason = this.state.becomeIbewOther ? formData.becomeIbewOther : formData.membershipReason;
      const industry = this.state.industryOther ? formData.industryOther : formData.industry;
      delete formData.becomeIbewOther;
      delete formData.industryOther;

      return {
        ...formData,
        ...(!this.props.editMode ? { signature: this.state.signature } : {}),
        country: Globals.DefaultApplicationsCountry,
        birthDate: formData.birthDate ? moment(formData.birthDate).format(Globals.DefaultAPIDateFormat) : null,
        hireDate: formData.hireDate ? moment(formData.hireDate).format(Globals.DefaultAPIDateFormat) : null,
        previouslyMember: !!formData.previouslyMember,
        membershipReason,
        industry,
      };
    }

    return false;
  }

  setFieldsValue(data) {
    const membershipReason = Globals.App_HowDidYouBecomeAnIbewMember.includes(data.membershipReason) ? data.membershipReason : 'Other';
    const becomeIbewOther = membershipReason == 'Other' ? data.membershipReason : null;
    this.handleChangeBecomeIbew(membershipReason);
    
    const industry = Globals.App_Industry.includes(data.industry) ? data.industry : 'Other';
    const industryOther = industry == 'Other' ? data.industry : null;
    this.handleChangeIndustry(industry);

    this.form.setFieldsValue({
      ...data,
      birthDate: data.birthDate ? moment(data.birthDate) : null,
      hireDate: data.hireDate ? moment(data.hireDate) : null,
      membershipReason,
      becomeIbewOther,
      industry,
      industryOther,
    });
  }

  // UI
  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row>
          <Col xs={24} sm={24} md={{ span: 4 }}>
            <Form.Item name="prefix" label="Prefix">
              <Select>
                <Select.Option value="none">None</Select.Option>
                <Select.Option value="MR">MR</Select.Option>
                <Select.Option value="MS">MS</Select.Option>
                <Select.Option value="MRS">MRS</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={{ span: 19, offset: 1 }}>
            <Form.Item name="firstName" label="First Name" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input placeholder="Joe" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={{ span: 4 }}>
            <Form.Item name="middleName" label="M.I">
              <Input placeholder="W" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={{ span: 19, offset: 1 }}>
            <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input placeholder="Doe" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={24} md={6}>
            <Form.Item name="birthDate" label="Date of Birth" rules={[{ required: true, message: 'This field is required!' }]}>
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={{ span: 8, offset: 1 }}>
            <Form.Item name="sin" label="Social Insurance NO." rules={[
              { required: true, message: 'This field is required!' },
              { len: 9, message: 'Must have 9 characters!' },
            ]}>
              <Input placeholder="XXX-XXX-XXX" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={{ span: 4, offset: 1 }}>
            <Form.Item label="Sex" name="sex" rules={[{ required: true, message: 'This field is required!' }]}>
              <Radio.Group>
              <Radio value="M">M</Radio>
              <Radio value="F">F</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">Contact Information</Divider>
        <Row>
          <Col xs={24} sm={24} md={15}>
            <Form.Item name="email" label="Email" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input placeholder="joedoe@example.com" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={{ span: 8, offset: 1 }}>
            <Form.Item name="phone" label="Telephone NO.">
              <Input placeholder="(XXX) XXX-XXXX" />
            </Form.Item>
          </Col>
        </Row>
        
        <Divider orientation="left">Address Information</Divider>

        <Row>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="street1" label="Address 1 (Street & Number)" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input placeholder="128 Main St." />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={{ span: 11, offset: 1 }}>
            <Form.Item name="street2" label="Address 2 (Street & Number)">
              <Input placeholder="128 Main St." />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="city" label="City" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input placeholder="Vancouver" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={{ span: 5, offset: 1 }}>
            <Form.Item name="province" label="Province" rules={[
              { required: true, message: 'This field is required!' },
              { len: 2, message: 'Must have 2 characters!' },
            ]}>
              <Input placeholder="BC" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={{ span: 5, offset: 1 }}>
            <Form.Item name="postalCode" label="Postal Code" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input placeholder="V5J-2FK" />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">Employer Information</Divider>

        <Row>
          <Col xs={24} sm={24} md={{ span: 12 }}>
            <Form.Item name="currentEmployer" label="Present Employer">
              <Input placeholder="Company Inc." />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} md={{ span: 6, offset: 1 }}>
            <Form.Item name="hireDate" label="Date of Hire">
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={{ span: 24 }}>
            <Form.Item name="previouslyMember" label="Have you ever been a member of I.B.E.W.®?" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={11}>
            <Form.Item name="industry" label="Industry where you are employed">
              <Select onChange={this.handleChangeIndustry}>
                {Globals.App_Industry.map((item) => (
                  <Select.Option value={item} key={item}>{item}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {this.state.industryOther && (
            <Col xs={24} sm={24} md={{ span: 12, offset: 1 }}>
              <Form.Item name="industryOther" label="Other...">
                <Input />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row>
          <Col xs={24} sm={24} md={11}>
            <Form.Item name="membershipReason" label="How did you become an I.B.E.W.® member?">
              <Select onChange={this.handleChangeBecomeIbew}>
                {Globals.App_HowDidYouBecomeAnIbewMember.map((item) => (
                  <Select.Option value={item} key={item}>{item}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {this.state.becomeIbewOther && (
            <Col xs={24} sm={24} md={{ span: 12, offset: 1 }}>
              <Form.Item name="becomeIbewOther" label="Other...">
                <Input />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Divider orientation="left">Extra Information</Divider>
        <Row>
          <Col xs={24} sm={24} md={24}>
            <Form.Item name="tradeQualification" label="Trade Qualification">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24}>
            <Form.Item name="redSealNumber" label="Red Seal Number">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {!this.props.editMode && (
          <Row style={{ margin: '24px 0' }}>
            <Col span={24}>
              {this._renderUploadDragger()}
            </Col>
          </Row>
        )}
      </Form>
    )
  }

  _renderUploadDragger() {
    return (
      <Upload.Dragger beforeUpload={() => false} onChange={this.handleSelectFile} fileList={[]}>
        <p className="ant-upload-drag-icon">
          {<InboxOutlined />}
        </p>
        <p className="ant-upload-text">
          {this.state.signature ? this.state.signature.name : 'Click or drag member signature'}
        </p>
        <p className="ant-upload-hint">
          Only .PNG files are accepted.
        </p>
        <Button icon={<UploadOutlined />} style={{ marginTop: 10 }}>Select File...</Button>
      </Upload.Dragger>
    );
  }
}
