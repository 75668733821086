import React from "react";
import autoBind from 'react-autobind';
import moment from 'moment';
//
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import CustomComponent from '@/components/CustomComponent';
import AdminContactsForm from './Forms/AdminContactsForm';
//
import { Drawer, Row, Col, Button, message } from 'antd';
import Utils from "@/components/Utils";
//
export default class AdminEditContactsDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      isVisible: false,
      employerID: null,
      contactID: null,
    };
  }

  show(employerID, contactID) {
    this.setState({ isVisible: true, employerID, contactID }, this._fetch);
  }

  handleClose() {
    this.form.resetFields();
    this.setState({
      isLoading: false,
      isVisible: false,
      employerID: null,
      contactID: null,
    });
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();

    if (formData) {
      this._update(formData);
    }
  }

  render() {
    return (
      <Drawer
        visible={this.state.isVisible}
        title="Edit Contact"
        placement="right"
        onClose={this.handleClose}
        width={800}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <AdminContactsForm {...Utils.propagateRef(this, 'form')} />
        <Row>
          <Col>
            <Button type="primary" onClick={this.handleSubmit}>Save</Button>
          </Col>
        </Row>
      </Drawer>
    )
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.employerContacts.getByID(
      this.state.employerID, this.state.contactID,
    );
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      const data = resp.body;
      this.setState({ data, isLoading: false });
      this.form.setFieldsValue({
        ...data,
        phone: Utils.formatPhone(resp.body.phone),
        altPhone: Utils.formatPhone(resp.body.altPhone),
        fax: Utils.formatPhone(resp.body.fax),
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  
  async _update(data) {
    this.startLoading();
    //request
    const payload = {
      ...this.state.data,
      ...data,
      phone: Utils.sanitizePhone(data.phone),
      altPhone: Utils.sanitizePhone(data.altPhone),
      fax: Utils.sanitizePhone(data.fax),
    };
    //
    delete payload.organizationID;
    delete payload.personID;
    delete payload.transactionID;
    delete payload.addressID;
    //
    Object.keys(payload).forEach((key) => {
      if (payload[key] == null || payload[key] == '') delete payload[key];
    });
    //
    const resp = await this.props.app.api.employerContacts.update(payload);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.handleClose();
      message.success('Contact successfully updated!');
      if (this.props.onUpdate) this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
