import React from "react";
import { Button, Form, Modal, DatePicker, Row, Col, Tooltip } from 'antd';
import autoBind from 'react-autobind';
import { CloseCircleOutlined } from '@ant-design/icons';
//
import CustomComponent from "@/components/CustomComponent";
import CommonLoadingView from "./CommonLoadingView";
import Globals from '@/config/Globals';
import Utils from '@/components/Utils';
import config from "@/config/config";
//
import CommonEmployerSelectionPopover from '@/views/commonComponents/CommonEmployerSelectionPopover';
//
export default class AdminDuesBatchCreateModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      batchDate: null, selectedEmployer: null,
      isLoading: false, show: false,
    };
  }

  //Actions
    //Modal
  show() {
    this.setState({ batchDate: null, selectedEmployer: null, isLoading: false, show: true }, () => {
      this.form.resetFields();
    });
  }
  handleHide() { this.setState({ show: false, isLoading: false, batchDate: null, selectedEmployer: null });  }
    //Employer
  handleSelectEmployer(employer, args) { this.setState({ selectedEmployer: employer }, this.form.validateFields); }
  handleDeselectEmployer() { this.setState({ selectedEmployer: null }, this.form.validateFields); }
    //Form
  async handleCreateBatch() {
    const formData = await this.form.validateFields();
    if (formData) this._createBatch(formData.batchDate.format(Globals.DefaultSimpleDateFormat), this.state.selectedEmployer.id);
  }
  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.isAuthenticating;
    return (
      <Modal maskClosable={true} closable={true} title="Create batch" footer={null} visible={this.state.show} confirmLoading={isLoading} 
             onCancel={this.handleHide} afterClose={this.handleHide}>
        <CommonLoadingView isLoading={isLoading} isFixed={true}/>
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row type='flex' justify='start' align='middle'>
            <Col>
              <Form.Item label="Employer" name='employer' rules={[{ required: true, validator: (r,v,c) => {this.state.selectedEmployer ? c() : c('Please, select an employer!')} }]}>
                {!this.state.selectedEmployer ?
                <CommonEmployerSelectionPopover app={this.props.app} onSelect={this.handleSelectEmployer} {...Utils.propagateRef(this, 'employerSelectionModal')}>
                  <Button type="ghost">Click to find an employer</Button>
                </CommonEmployerSelectionPopover> 
                : 
                <span>
                  {this.state.selectedEmployer.name}
                  <Tooltip title="Remove Filter">
                    <Button icon={<CloseCircleOutlined />} type="link" onClick={this.handleDeselectEmployer} />
                  </Tooltip>
                </span>}
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' justify='start' align='middle'>
            <Col>
              <Form.Item name="batchDate" label="Batch Date" rules={[{required: true, message: 'Please, select a batch date!'}]}>
                <DatePicker format={Globals.DefaultSimpleDateFormat} style={{ width: '100%' }}/>
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' justify='end'>
            <Button onClick={this.handleCreateBatch} type='primary'> Create </Button>
          </Row>
        </Form>
      </Modal>
    );
  }
  /* private API */
  async _createBatch(date, employerID) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.duesBatch.create(date, employerID);
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", `Dues batch created with success for '${this.state.selectedEmployer.name}' with the date '${date}'!`);
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.duesBatchUpdate, {}, employerID, resp.body.id);
      this.handleHide();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    } 
  }
}
