import React from "react";
import autoBind from 'react-autobind';
import moment from 'moment';
import { Layout, PageHeader, Table, Tooltip, Popconfirm, Button, Dropdown, Menu, message } from 'antd';
import { DeleteOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';

import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import Utils from '@/components/Utils';
import config from '@/config/config';
import Globals from "@/config/Globals";
import CommonEmployerSelectionModal from "@/views/commonComponents/CommonEmployerSelectionModal";
//
export default class AdminRemittancesView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, data: [], uploadType: null };
  }

  componentDidMount() {
    document.title = `${config.ApplicationName} - Remittances`;
    this._fetch();
  }

  handleImport(fileObj) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.adminImportRemittance, null, fileObj.employerID, fileObj.id);
  }

  handleDownload(fileObj) {
    this._download(fileObj.employerID, fileObj);
  }

  handleDelete(fileObj) {
    this._delete(fileObj.employerID, fileObj.id);
  }

  handleSearchEmployer(uploadType) {
    return () => {
      this.setState({ uploadType }, this.employerSelectionModal.show);
    };
  }

  handleSelectEmployer(employer) {
    this.props.app.urlManager.pushPage(
      config.ApplicationRoutes.employerRemittances,
      {
        n: employer.name,
        [Globals.URLQueryParam_Operation]: this.state.uploadType === 'arrears' ? 'arrears' : 'new',
      },
      employer.id,
    );
  }

  render() {
    const props = {
      onChange: this.handleFilterChange, rowKey: 'id', loading: this.props.isLoading,
    };

    const columns = [
      { title: 'Type', dataIndex: 'uploadType', key: 'uploadType', width: 100,
        render: (type) => Globals.Remittance_UpTypes[type]?.name || '-',
      },
      { title: 'Submission Period', dataIndex: 'submissionPeriod', key: 'submissionPeriod', width: 150,
        render: (period) => moment(period).format(Globals.DefaultMonthDateFormat),
      },
      { title: 'Employer', dataIndex: 'employerName', key: 'employerName', width: 150 },
      { title: 'Submission Date', dataIndex: 'createdOn', key: 'createdOn', width: 150,
        render: (period) => moment(period).format(Globals.DefaultUIBirthDateFormat),
      },
      { title: 'Payment Type', dataIndex: 'paymentType', key: 'paymentType', width: 150,
        render: (value) => Globals.Remittance_PaymentTypes[value]?.name || value,
      },
      { title: 'Actions', key: 'actions', width: 150,
        render: (props) => (
          <span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title="Import" onClick={this.handleImport.bind(this, props)}>
              <Button variant="none" icon={<UploadOutlined />} shape="circle"/>
            </Tooltip>{' '}
            <Tooltip placement="bottomLeft" title="Download" onClick={this.handleDownload.bind(this, props)}>
              <Button variant="none" icon={<DownloadOutlined />} shape="circle"/>
            </Tooltip>{' '}
            <Tooltip placement="bottomLeft" title="Delete">
              <Popconfirm
                title={`Do you really want to delete remittance from '${moment(props.submissionPeriod).format(Globals.DefaultMonthDateFormat)}'?`}
                placement="bottomRight"
                onConfirm={this.handleDelete.bind(this, props)}
                okText="Yes"
                cancelText="No"
              >
                <Button variant="none" icon={<DeleteOutlined />} shape="circle"/>
              </Popconfirm>
            </Tooltip>
          </span>
        ),
      },
    ];

    return (
      <Layout.Content className="pageContent">
        <CommonEmployerSelectionModal
          app={this.props.app}
          onSelect={this.handleSelectEmployer}
          {...Utils.propagateRef(this, 'employerSelectionModal')}
        />
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader title="Pending Remittances" extra={[
          <Dropdown 
            key="1"
            overlay={(
            <Menu>
              <Menu.Item onClick={this.handleSearchEmployer('employer')}>Employer</Menu.Item>
              <Menu.Item onClick={this.handleSearchEmployer('arrears')}>Arrears</Menu.Item>
            </Menu>
          )}>
            <Button type="primary" style={{margin: '0px 0px 10px 0px'}}>Create Remittance</Button>
          </Dropdown>
        ]} />
        <Layout.Content>
          <WhiteBox>
            <Table
              dataSource={this.state.data}
              columns={columns}
              {...props}
            />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // API Calls
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.api.employerFiles.getPending();
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.files) {
      const data = resp.body.files.sort((a, b) => {
        const aDate = new Date(a.submissionPeriod).getTime();
        const bDate = new Date(b.submissionPeriod).getTime();
        return aDate - bDate || (a.employerName > b.employerName ? 1 : -1);
      });

      this.setState({ data, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  
  async _delete(employerID, fileID) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.employerFiles.remove(employerID, fileID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Remittance successfully deleted!');
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  
  async _download(employerID, fileObject) {
    this.startLoading();
    //request
     const resp = await this.props.app.api.employerFiles.download(employerID, fileObject.fileID, fileObject.uploadType);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      Utils.downloadBlob(resp.body, `${fileObject.employerName}-${moment(fileObject.submissionPeriod).format(Globals.DefaultMonthDateFormat)}`, 'xlsx');
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    } this.stopLoading(true);
  }
}
