import React from "react";
import autoBind from 'react-autobind';
import { Button, Layout, PageHeader, Form, Row, Col, Select, Input, Divider, Table, Tooltip, message } from 'antd';
import moment from 'moment';
import { DeleteOutlined, EditOutlined, FileTextOutlined, CloseCircleOutlined } from '@ant-design/icons';
//
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import TableActions from '@/views/commonComponents/TableActions';
import CommonEmployerSelectionModal from "@/views/commonComponents/CommonEmployerSelectionModal";
//
import config from '@/config/config';
import Globals from '@/config/Globals';
import Utils from "@/components/Utils";
//
export default class CommonDispatchJobView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.employerName = this.props.app.urlManager.getQueryParam('n');

    const { dispatchLists, dispatchStatuses } = this.props.app.sharedCache().getConfig();

    this.state = {
      isLoading: false,
      dispatchLists: dispatchLists || [],
      dispatchStatuses: dispatchStatuses || [],
      data: [],
      sortedInfo: null,
      selectedEmployer: null,
      ...this._getInitialState(),
    };
  }

  //Life cycle
  componentDidMount() {
    document.title = `${config.ApplicationName} - Dispatch Jobs`;

    // Populate employer search modal component
    const employerID = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Employer) || null;
    if (employerID) {
      this.employerSelectionModal.setEmployer(employerID);
    } else {
      this._fetch(true);
    }
  }

  //Actions
  handleFilterChange(pagination, filters, sortedInfo) {
    this.setState({ sortedInfo }, this._fetch);
  }

  handleCreate() {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.crateDispatchJob, { [Globals.URLQueryParam_Employer]: this.state.selectedEmployer?.id });
  }

  handlePagination(currentPage) {
    this.setState({ currentPage }, this._fetch);
  }

  async handleSearch() {
    const formData = await this.form.validateFields();
    if (formData) {
      this.setState((prevState) => ({
        ...prevState,
        searchObj: {
          ...prevState.searchObj,
          ...formData,
        },
      }), this._fetch);
    }
  }

  handleEdit(props) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.updateDispatchJob, null, props.employerID, props.id);
  }

  handleDelete(props) {
    this._delete(props.employerID, props.id);
  }
  
  handleDispatchNotes(props) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.employerDispatchNotes, null, props.employerID, props.id);
  }

  handleNotes(props) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.notes, null, Globals.Notes_relTypes.dispatchJob.routeAlias, props.id);
  }

  handleSearchEmployer() {
    this.employerSelectionModal.show();
  }

  handleSelectEmployer(employer) {
    this.setState((prevState) => ({
      ...prevState,
      selectedEmployer: employer,
      searchObj: {
        ...prevState.searchObj,
        employerID: employer.id,
      },
    }), this._fetch);
  }

  handleDeselectEmployer() {
    this.setState((prevState) => ({
      ...prevState,
      selectedEmployer: null,
      searchObj: {
        ...prevState.searchObj,
        employerID: null,
      },
    }), this._fetch);
  }

  //UI
  render() {
    const pageHeader = this.props.app.isAdmin()
      ? { onBack: () => window.history.back(), title: `${this.employerName || ''} Dispatch Job` } 
      : { title: 'Dispatch Job' };

    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const props = {
      onChange: this.handleFilterChange, rowKey: 'id', loading: this.state.isLoading,
      // scroll: { x: 1200 },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage, showSizeChanger: false, hideOnSinglePage: true, position: 'bottom',
        total: this.state.total, onChange: this.handlePagination, current: this.state.currentPage,
      }
    };

    const columns = [
      {
        title: 'Job Number', dataIndex: 'jobNumber', key: 'jobNumber', width: 60,
        sorter: (a, b) => a.jobNumber?.localeCompare(b.jobNumber),
        sortOrder: sortedInfo.columnKey === 'jobNumber' && sortedInfo.order
      },
      {
        title: 'Status', dataIndex: 'dispatchStatusID', key: 'dispatchStatusID', width: 40,
        render: (id) => (
          this.props.app.sharedCache().getDispatchStatusByCode(id)?.name || '-'
        ),
        sorter: (a, b) => a.dispatchStatusID - b.dispatchStatusID,
        sortOrder: sortedInfo.columnKey === 'dispatchStatusID' && sortedInfo.order
      },
      {
        title: 'Details', dataIndex: 'details', key: 'details', width: 70,
        sorter: (a, b) => a.details?.localeCompare(b.details),
        sortOrder: sortedInfo.columnKey === 'details' && sortedInfo.order
      },
      {
        title: 'Received Date', dataIndex: 'receivedDate', key: 'receivedDate', width: 70,
        render: (date) => date ? moment(date).format(Globals.DefaultUIBirthDateFormat) : '-',
        sorter: (a, b) => (new Date(a.receivedDate).getTime()) - (new Date(b.receivedDate).getTime()),
        sortOrder: sortedInfo.columnKey === 'receivedDate' && sortedInfo.order
      },
      {
        title: 'Work Location', dataIndex: 'workLocation', key: 'workLocation', width: 70,
        sorter: (a, b) => a.workLocation?.localeCompare(b.workLocation),
        sortOrder: sortedInfo.columnKey === 'workLocation' && sortedInfo.order
      },
      {
        title: 'Actions', width: 100, key: 'Actions', align: 'right', fixed: 'right',
        render: props => {
          return (<span className='tableButtonContainer'>
            <TableActions
              options={[
                { alwaysVisible: true, label: 'Edit', icon: EditOutlined, onClick: this.handleEdit.bind(this, props) },
                {
                  alwaysVisible: true, label: 'Delete', icon: DeleteOutlined,
                  confirm: {
                    message: 'Are you sure you want to delete this Dispatch Job?',
                    onConfirm: this.handleDelete.bind(this, props),
                  },
                },
                { label: 'Dispatch Notes', icon: FileTextOutlined, onClick: this.handleDispatchNotes.bind(this, props) },
                { label: 'Notes', icon: FileTextOutlined, onClick: this.handleNotes.bind(this, props) },
              ]}
            />
          </span>);
        },
      },
    ];

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader {...pageHeader} extra={[<Button type="primary" onClick={this.handleCreate}>Create Dispatch Job</Button>]} />
        <Layout.Content>
          <WhiteBox>
            <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
              {this.props.app.isAdmin() && (
                <Row>
                  <CommonEmployerSelectionModal
                    app={this.props.app}
                    onSelect={this.handleSelectEmployer}
                    {...Utils.propagateRef(this, 'employerSelectionModal')}
                  />

                  <Col span={24}>
                    <Form.Item label="Employer">
                      {!this.state.selectedEmployer && <Button type="ghost" onClick={this.handleSearchEmployer}>Click to find an employer</Button>}
                      {this.state.selectedEmployer && (
                        <span>
                          {this.state.selectedEmployer.name}
                          <Tooltip title="Deselect">
                            <Button icon={<CloseCircleOutlined />} type="link" onClick={this.handleDeselectEmployer} />
                          </Tooltip>
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={24} sm={24} md={3}>
                  <Form.Item name="term" label="Job Number">
                    <Input defaultValue={this.state.searchObj.term} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={{ span: 6, offset: 1 }}>
                  <Form.Item name="dispatchListID" label="Dispatch List">
                    <Select placeholder="Select..." defaultValue={this.state.searchObj.dispatchListID}>
                      {this.state.dispatchLists.map((list) => (
                        <Select.Option key={list.id} value={list.id}>
                          {list.number} - {list.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={{ span: 3, offset: 1 }}>
                  <Form.Item name="status" label="Status">
                    <Select placeholder="Select..." defaultValue={this.state.searchObj.status}>
                      {this.state.dispatchStatuses.map((status) => (
                        <Select.Option key={status.id} value={status.id}>{status.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={{ span: 4, offset: 1 }}>
                  <Form.Item label=" ">
                    <Button type="primary" onClick={this.handleSearch}>Search</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <Divider />

            <Table
              dataSource={this.state.data}
              columns={columns}
              {...props}
            />
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    )
  }

  // Utils
  _getInitialState() {
    const search = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SearchTerm) || null;
    const dispatchListID = Number(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_DispatchList)) || null;
    const status = Number(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Status)) || null;
    const currentPage = parseInt(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Page) || 1);
    const columnKey = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SortField) || 'receivedDate';
    const order = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SortOrder) || 'descend';
    const user = this.props.app.sharedCache().getUser();
    const employerID = this.props.app.isAdmin()
      ? Number(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Employer, this)) || null
      : user.id;

    return {
      searchObj: {
        term: search || null,
        dispatchListID: dispatchListID || null,
        status: status || null,
        employerID,
      },
      currentPage, sortedInfo: { columnKey, order },
      total: 0
    };
  }

  _setSearchQueryParams() {
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_Employer, (this.state.searchObj.employerID || null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SearchTerm, (this.state.searchObj.term || null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_DispatchList, (this.state.searchObj.dispatchListID || null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_Status, (this.state.searchObj.status || null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_Page, this.state.currentPage);
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SortField, (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.columnKey : ''));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SortOrder, (this.state.sortedInfo ? this.state.sortedInfo.order : null));
  }

  _getSearchFilterObject() {
    //page
    const from = (Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1));
    //sort
    const sortField = (this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey ? this.state.sortedInfo.columnKey : '');
    const sortOrder = (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : '');
    return {
      jobNumber: this.state.searchObj.term || '',
      dispatchListID: this.state.searchObj.dispatchListID || '',
      status: this.state.searchObj.status || '',
      employerID: this.state.searchObj.employerID || '',
      filter: {
        from,
        sortField,
        sortOrder: (sortOrder == 'descend' ? 'desc' : 'asc'),
      },
    };
  }

  _shouldFetchAutomatically() {
    return !!(
      this.state.searchObj.term
      || this.state.searchObj.status
      || this.state.searchObj.employerID
      || this.state.searchObj.dispatchListID
    );
  }

  // API Calls
  async _fetch(firstLoad) {
    if (!this._isMounted) return;
    if (firstLoad && !this._shouldFetchAutomatically()) return;
    //
    this.setState({ data: [], total: 0, isLoading: true });
    this._setSearchQueryParams();
    //
    const resp = await this.props.app.api.dispatchJob.search(this._getSearchFilterObject());
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.jobs) {
      this.setState({ data: resp.body.jobs, total: resp.body.total, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }

  async _delete(employerID, dispatchJobID) {
    this.startLoading();
    //request
    const resp = await this.props.app.api.dispatchJob.remove(employerID, dispatchJobID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Dispatch Job successfully removed!');
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
