import React from "react";
import autoBind from 'react-autobind';
import { Form, Row, Col, Select, DatePicker, Input, Button, Tooltip, message } from 'antd';
import { PlusCircleOutlined, SyncOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
//
import CustomComponent from '@/components/CustomComponent';
import Utils from "@/components/Utils";
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import CommonEmployerSelectionModal from "@/views/commonComponents/CommonEmployerSelectionModal";
import config from "@/config/config";
import Globals from "@/config/Globals";
//
export default class CommonDispatchJobForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    const {
      classifications, unitsNumbers, dispatchLists, dispatchStatuses, trainingCodes,
    } = this.props.app.sharedCache().getConfig();

    this.state = {
      selectedEmployer: null,
      contacts: [],
      classifications: classifications || [],
      units: unitsNumbers || [],
      lists: dispatchLists || [],
      statuses: dispatchStatuses || [],
      trainingCodes: trainingCodes || [],
    };
  }

  componentDidMount() {
    if (this.props.employerID) {
      this.employerSelectionModal.setEmployer(this.props.employerID);
    }
  }

  handleCreateContact() {
    const path = config.ApplicationRoutes.contacts.replace(':id', this.state.selectedEmployer?.id);
    window.open(`${path}?${Globals.URLQueryParam_Operation}=new`, '_blank');
  }

  async handleRefreshContacts() {
    this.startLoading();
    await this._getContacts();
    this.stopLoading(true);
  }

  handleSearchEmployer() {
    this.employerSelectionModal.show();
  }

  handleSelectEmployer(employer) {
    this.setState({ selectedEmployer: employer }, this._getContacts);
    this.form.setFieldsValue({
      receivedDate: moment(new Date()),
      receivedTime: moment(new Date()).format('HH:mm'),
// Must be indented like that because text area (on UI) recognize tabs as well
reportTo: `${employer.street1 || ''}
${employer.street2 || ''}
${employer.city || ''}, ${employer.province || ''}`,
    });
  }

  handleDeselectEmployer() {
    this.setState({ selectedEmployer: null, contacts: [] });
  }

  setFieldsValue(data) {
    this.form.setFieldsValue({
      ...data,
      startDate: data.startDate ? moment(data.startDate) : null,
      receivedDate: data.receivedDate ? moment(data.receivedDate) : null,
    });
  }

  async validateFields() {
    if (!this.state.selectedEmployer) {
      message.error('Select an Employer!');
    }

    const data = await this.form.validateFields();

    if (data && this.state.selectedEmployer) {
      return {
        ...data,
        startDate: data.startDate ? moment(data.startDate).format(Globals.DefaultSimpleDateFormat) : null,
        receivedDate: data.receivedDate ? moment(data.receivedDate).format(Globals.DefaultSimpleDateFormat) : null,
        trainings: data.trainings ? data.trainings : [],
        dispatchStatusID: !data.dispatchStatusID ? 1 : data.dispatchStatusID,
        employerID: this.state.selectedEmployer.id,
      };
    }

    return false;
  }

  //UI
  render() {
    return (
      <>
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <CommonEmployerSelectionModal
          app={this.props.app}
          onSelect={this.handleSelectEmployer}
          {...Utils.propagateRef(this, 'employerSelectionModal')}
        />
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row>
            <Col span={24}>
              <Form.Item label="* Employer">
                {!this.state.selectedEmployer && <Button type="ghost" onClick={this.handleSearchEmployer}>Click to find an employer</Button>}
                {this.state.selectedEmployer && (
                  <span>
                    {this.state.selectedEmployer.name}
                    <Tooltip title="Deselect">
                      <Button icon={<CloseCircleOutlined />} type="link" onClick={this.handleDeselectEmployer} />
                    </Tooltip>
                  </span>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                label="Contact Person"
                name="contactID"
                extra={
                  this.state.selectedEmployer && <>
                    <Button type="link" icon={<PlusCircleOutlined />} onClick={this.handleCreateContact}>Create new</Button>
                    <Button type="link" icon={<SyncOutlined />} onClick={this.handleRefreshContacts}>Refresh</Button>
                  </>
                }
              >
                <Select placeholder="Select Contact...">
                  {this.state.contacts.map((contact) => (
                    <Select.Option key={contact.id} value={contact.id}>
                      {contact.firstName} {contact.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={{ span: 6, offset: 1 }}>
              <Form.Item label="Job No.">
                <Input disabled defaultValue="<auto>" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={{ span: 4, offset: 1 }}>
              <Form.Item label="Start Date" name="startDate">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={{ span: 3, offset: 1 }}>
              <Form.Item label="Start Time" name="startTime" rules={[{ type: 'pattern', pattern: new RegExp(/^\d{2}:\d{2}(:\d{2})?$/), message: 'Format "00:00".' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={{ span: 12 }}>
              <Form.Item label="Details" name="details">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={{ span: 11, offset: 1 }}>
              <Form.Item label="Work Location" name="workLocation">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={{ span: 7 }}>
              <Form.Item label="Shop Steward" name="shopSteward">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={{ span: 7, offset: 1 }}>
              <Form.Item label="List" name="dispatchListID">
                <Select placeholder="Select Unemployed List...">
                  {this.state.lists.map((list) => (
                    <Select.Option key={list.id} value={list.id}>
                      {list.number} - {list.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={{ span: 8, offset: 1 }}>
              <Form.Item label="Designation" name="designation">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={{ span: 7 }}>
              <Form.Item label="Classification" name="classificationID">
                <Select placeholder="Select Classification...">
                  {this.state.classifications.map((classification) => (
                    <Select.Option key={classification.id} value={classification.id}>
                      {classification.classificationNumber} - {classification.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={{ span: 16, offset: 1 }}>
              <Form.Item label="Units" name="jobUnits" rules={[{ required: true, message: 'This field is required!' }]}>
                <Select mode="multiple" placeholder="Select Units...">
                  {this.state.units.map((unit) => (
                    <Select.Option key={unit.id} value={unit.id}>
                      {unit.number} ({unit.description})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={{ span: 12 }}>
              <Form.Item label="Instructions" name="instructions">
                <Input.TextArea />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={{ span: 11, offset: 1 }}>
              <Form.Item label="Report to" name="reportTo">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={{ span: 24 }}>
              <Form.Item label="Named Requests" name="namedRequests">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={{ span: 24 }}>
              <Form.Item label="Special Training Required" name="trainings">
                <Select mode="multiple" placeholder="Select...">
                  {this.state.trainingCodes.map((code) => (
                    <Select.Option key={code.id} value={code.id}>
                      {code.code} - {code.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={{ span: 4 }}>
              <Form.Item label="Status" name="dispatchStatusID">
                <Select placeholder="Select Status..." defaultValue={1} disabled={!this.props.app.isAdmin()}>
                  {this.state.statuses.map((status) => (
                    <Select.Option key={status.id} value={status.id}>
                      {status.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={{ span: 4, offset: 1 }}>
              <Form.Item label="Received Date" name="receivedDate">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={{ span: 3, offset: 1 }}>
              <Form.Item label="Received Time" name="receivedTime" rules={[{ type: 'pattern', pattern: new RegExp(/^\d{2}:\d{2}(:\d{2})?$/), message: 'Format "00:00".' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    )
  }

  // API Calls

  async _getContacts() {
    this.startLoading();
    const resp = await this.props.app.api.employerContacts.getAll(this.state.selectedEmployer.id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.contacts) {
      this.setState({ contacts: resp.body.contacts });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading(true);
  }
}
