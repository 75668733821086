import React from "react";
import autoBind from 'react-autobind';
import moment from 'moment';
//
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import CustomComponent from '@/components/CustomComponent';
import AdminContactsForm from './Forms/AdminContactsForm';
//
import { message, Drawer, Row, Col, Button } from 'antd';
import Utils from "@/components/Utils";
//
export default class AdminCreateContactsDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      isVisible: false,
      employerID: null,
    };
  }

  show(employerID) {
    this.setState({ isVisible: true, employerID });
  }

  handleClose() {
    this.form.resetFields();
    this.setState({
      isLoading: false,
      isVisible: false,
      employerID: null,
    });
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();

    if (formData) {
      this._create(formData);
    }
  }

  render() {
    return (
      <Drawer
        visible={this.state.isVisible}
        title="Create Contact"
        placement="right"
        onClose={this.handleClose}
        width={800}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <AdminContactsForm {...Utils.propagateRef(this, 'form')} />
        <Row>
          <Col>
            <Button type="primary" onClick={this.handleSubmit}>Create</Button>
          </Col>
        </Row>
      </Drawer>
    )
  }

  // API Calls
  async _create(data) {
    this.startLoading();
    //request
    const { employerID } = this.state;
    const resp = await this.props.app.api.employerContacts.create({
      ...data,
      employerID,
      phone: Utils.sanitizePhone(data.phone),
      altPhone: Utils.sanitizePhone(data.altPhone),
      fax: Utils.sanitizePhone(data.fax),
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.handleClose();
      message.success('Contact successfully created!');
      if (this.props.onCreate) this.props.onCreate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
