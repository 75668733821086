import Globals from '@/config/Globals';
//
export default class APIDuesEntry {
  constructor(API) {
    this.api = API;
  }

  async getByID(employerID, batchID, entryID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = `/employer/${employerID}/duesBatch/${batchID}/duesEntry/${entryID}`;
    return await req.exec();
  }
  async getByMemberID(memberID, entryID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = `/member/${memberID}/duesEntry/${entryID}`;
    return await req.exec();
  }
  async create(employerID, batchID, entryObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = `/employer/${employerID}/duesBatch/${batchID}/duesEntry/new`;
    req.body = { ...entryObj };
    return await req.exec();
  }
  async update(employerID, batchID, entryID, entryObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = `/employer/${employerID}/duesBatch/${batchID}/duesEntry/${entryID}`;
    req.body = { ...entryObj };
    return await req.exec();
  }
  async remove(employerID, batchID, entryID) {
    const req = await this.api.newBaseRequest('DELETE');
    req.path = `/employer/${employerID}/duesBatch/${batchID}/duesEntry/${entryID}`;
    return await req.exec();
  }
}
