import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import autoBind from 'react-autobind';
import { Divider } from 'antd';
import { ContactsOutlined, FileTextOutlined, TeamOutlined, DollarOutlined, EditOutlined } from '@ant-design/icons';
//
import config from '@/config/config';
import Globals from '@/config/Globals';
//
import '@/stylesheets/AdminEmployerOptions.scss';
//
export const AdminEmployerOptionTypes = {
  CONTACTS: 0,
  EMPLOYEES: 1,
  NOTES: 2,
  EMPLOYER: 3,
  REMITTANCES: 4,
  //
  NONE: 99,
};
//
export default class AdminEmployerOptions {
  constructor(selectedOption, employerID, app, optionalEmployerName, parent) {
    autoBind(this);
    this.selectedOption = selectedOption;
    this.employerObj = { id: employerID, name: (optionalEmployerName || '') };
    this.app = app;
    this.parent = parent;
    this.isHiddingAll = false;
  }
  parseUrl(path, params) {
    if (params) {
      return this.app.urlManager.parseUrl(path, ...params);
    }

    return this.app.urlManager.parseUrl(path, { n: encodeURIComponent(this.employerObj.name) }, this.employerObj.id);
  }
  getOptions() {
    const options = [];
    //Should hide all?
    if (this.isHiddingAll) return options;
    //Contacts
    if (this.selectedOption != AdminEmployerOptionTypes.CONTACTS && this.app.acl.hasPermission(Globals.Components.EMPLOYER_CONTACTS_MANAGEMENT, Globals.AllLevels)) options.push(
      <Fragment key="2">
        <Link to={this.parseUrl(config.ApplicationRoutes.contacts)} className='admEmpLink'><ContactsOutlined className='admEmpLinkIcon' /><strong>Contacts</strong></Link>
        <Divider type="vertical" className="admEmpDivider"/>
      </Fragment>
    )
    //Employees
    if (this.selectedOption != AdminEmployerOptionTypes.EMPLOYEES && this.app.acl.hasPermission(Globals.Components.MEMBERS_MANAGEMENT, Globals.AllLevels)) options.push(
      <Fragment key="3">
        <Link to={this.parseUrl(config.ApplicationRoutes.members, [{ [Globals.URLQueryParam_Employer]: this.employerObj.id, n: this.employerObj.name }])} onClick={this._handleEmployees} className='admEmpLink'><TeamOutlined className='admEmpLinkIcon' /><strong>Employees</strong></Link>
        <Divider type="vertical" className="admEmpDivider" />
      </Fragment>
    )
    //Notes
    if (this.selectedOption != AdminEmployerOptionTypes.NOTES && this.app.acl.hasPermission(Globals.Components.NOTES_MANAGEMENT, Globals.AllLevels)) options.push(
      <Fragment key="4">
        <Link to={this.parseUrl(config.ApplicationRoutes.notes, [{ n: encodeURIComponent(this.employerObj.name) }, Globals.Notes_relTypes.employer.routeAlias, this.employerObj.id])} className='admEmpLink'><FileTextOutlined className='admEmpLinkIcon' /><strong>Notes</strong></Link>
        <Divider type="vertical" className="admEmpDivider" />
      </Fragment>
    )
    //Remittances
    if (this.selectedOption != AdminEmployerOptionTypes.REMITTANCES && this.app.acl.hasPermission(Globals.Components.EMPLOYER_ELECTRONIC_REMITTANCE, Globals.AllLevels)) options.push(
      <Fragment key="6">
        <Link to={this.parseUrl(config.ApplicationRoutes.employerRemittances)} onClick={this._handleRemittances} className='admEmpLink'><DollarOutlined className='admEmpLinkIcon' /><strong>Remittances</strong></Link>
        <Divider type="vertical" className="admEmpDivider" />
      </Fragment>
    )
    //Profile
    if (this.selectedOption != AdminEmployerOptionTypes.EMPLOYER && this.app.acl.hasPermission(Globals.Components.EMPLOYERS_MANAGEMENT, Globals.AllLevels)) options.push(
      <Fragment key="5">
        <Link to={this.parseUrl(config.ApplicationRoutes.employersUpdate)} onClick={this._handleProfile} className='admEmpLink'><EditOutlined className='admEmpLinkIcon' /><strong>Profile</strong></Link>
        <Divider type="vertical" className="admEmpDivider" />
      </Fragment>
    )
    return options;
  }
  //Public helpers
  hideAll() { this.isHiddingAll = true; }
  setEmployerName(employerName) {
    this.employerObj = { ...this.employerObj, name: employerName || '' };
    this.parent.forceUpdate();
  }
}
