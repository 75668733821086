import md5 from 'md5';
//
import Globals from '@/config/Globals';
import APIRequest from '../APIRequest';
import Utils from '@/components/Utils';
//
export default class APIApplications {
  constructor(API) {
    this.api = API;
  }

  async search(filters) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Applications + 'search';
    req.body = { ...filters };
    return await req.exec();
  }

  async getByID(applicationID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Application + applicationID;
    return await req.exec();
  }

  async approve(applicationID, approveObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Application + applicationID + '/approve';
    req.body = { ...approveObj };
    return await req.exec();
  }

  async reject(applicationID, rejectObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Application + applicationID + '/reject';
    req.body = { ...rejectObj };
    return await req.exec();
  }

  async create(applicationObj) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Application + Globals.URL_NewIndentifier;
    req.body = { ...applicationObj };
    return await req.exec();
  }

  async update(applicationID, applicationObj) {
    const req = await this.api.newBaseRequest('PUT');
    req.path = Globals.APIPath_Application + applicationID;
    req.body = { ...applicationObj };
    return await req.exec();
  }

  // Upload
  async upload(file, generatedFileID) {
    if (file.size > Globals.API_MaxAllowedFileSize) {
      return { error: 'Signature File is too big! Max allowed size is 10mb.', statusCode: -1 };
    }

    const signedURLResp = await this._createSignURL(generatedFileID, file.type);

    if (signedURLResp.statusCode != 200 || !signedURLResp.body.fileURL) {
      return signedURLResp;
    }

    const signedURL = signedURLResp.body.fileURL;

    return this._uploadRemoteFile(file, signedURL);
  }

  async _createSignURL(generatedFileID, fileType) {
    const req = await this.api.newBaseRequest('POST');
    req.path = Globals.APIPath_Application + 'signature/fileSign/' + generatedFileID + '?fileType=' + fileType;
    return await req.exec();
  }

  async _uploadRemoteFile(file, url) {
    const req = new APIRequest('PUT');
    req.baseURL = url;

    req.appendHeader('Content-type', file.type);
    req.appendHeader('Content-length', file.file.length);

    req.body = Utils.dataURItoBlob(file.file);
    req.bodyType = 'BINARY';
    return await req.exec();
  }

  // Download Stuff
  async _getSignURL(applicationID) {
    const req = await this.api.newBaseRequest('GET');
    req.path = Globals.APIPath_Application + 'form/fileSign/' + applicationID;
    return await req.exec();
  }

  async _getRemoteFile(url) {
    const request = new APIRequest('GET');
    request.baseURL = url;
    request.bodyType = 'BLOB';
    return await request.exec();
  }

  async download(applicationID) {
    const signedURLResp = await this._getSignURL(applicationID);

    if (signedURLResp.statusCode != 200 || !signedURLResp.body.fileURL) {
      return signedURLResp;
    }

    return this._getRemoteFile(signedURLResp.body.fileURL);
  }

  // Utils
  encodedFileName(filename) {
    const random = Math.floor(Math.random() * (99999 - 9) + 9);
    return md5(random + filename + Date.now());
  }
}
